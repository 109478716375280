.connect-page {
  background-color: #f9f9f9;

  .connect-search-modal {
    z-index: 999;
    position: absolute;
    max-width: 489px;
    max-height: 450px;
    overflow-y: auto;
    width: 80%;
    border-radius: 0px 0px 12px 12px;
    background-color: #ffffff;
    border: 1px solid #6C6D6F;
    border-top: none;
    left: 0px;
    top: 38px;
    right: 0px;
    margin-top: -0.9rem;
    box-shadow: 0px 9px 25px 0px #0000001a;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px;
    padding-top: 40px;

    .searched-suggestion-wrap {
      height: 80px;
      border-radius: 12px;
      border: 1px solid #dcdcdc;
      padding: 10px 15px 10px 15px;
      display: flex;
      justify-content: start;
      gap: 16px;
      align-items: center;

      .search-profile-icon {
        border-radius: 100%;
        object-fit: cover;
        border: 4px solid white;
        height: 59px;
        width: 59px;
      }

      .searched-name {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        color: #25272d;
      }
      .searched-role {
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
        color: #25272d;
        padding-left: 4px;
      }
    }
    .searched-suggestion-wrap:hover {
      background-color: antiquewhite;
    }
    .see-all-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      cursor: pointer;
    }
    .see-all-wrapper:hover {
      color: #ee762f;
    }
  }

  .connect-filter-modal {
    .field-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: #25272d;
    }
  }

  .search-wrapper {
    z-index: 1000;
    display: flex;
    justify-content: start;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #6c6d6f;
    border-radius: 12px;
    width: 80%;
    max-width: 490px;
    position: relative;
    .search-icon {
      margin: 11.2px 10px 11.2px 17px;
    }
    .input-style {
      border: none;
      font-size: 14px;
      color: #25272D;
      font-weight: 400;
      z-index: 1000;
    }
    .input-style::placeholder {
      font-style: italic;
      font-size: 14px;
      font-weight: 400;
      color: #25272D; 
    }
    .clearstyle {
      position: absolute;
      right: 20px;
    }
  }
  .content-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: "#25272D";
    margin: 22px 0px 28px 0px;
  }
  .connect-page-heading{
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #25272d;
    margin: 2px 0px 25px 0px;
  }
  .paginate-style {
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }
  .content-style {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    @media (max-width: 767px) {
      justify-content: center;
      padding-bottom: 24px;
    }
  }
  .fav-connections {
    width: 100%;
    .fav-overflow {
      // display: grid;
      // grid-template-columns: 2% 96% 2%;
      .scroll-icon-wrap {
        display: flex;
        justify-content: center;
        align-items: center;

        .scroll-icon-style {
          display: block;
          @media (max-width: 767px) {
            display: none;
          }
        }
      }

      .fav-content-bloc {
        padding: 12px 0px 12px 0px;
        display: flex;
        gap: 16px;
        flex-wrap: nowrap;
        overflow-x: scroll;
        scrollbar-width: none;
        white-space: nowrap;

        .fav-card-wrap {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 16px;

          .fav-img-wrap {
            position: relative;
            cursor: pointer;
            .profile-img-style {
              border-radius: 100%;
              object-fit: cover;
              border: 4px solid white;
              height: 100px;
              width: 100px;
              box-shadow: 0px 4px 20px 0px #0000001f;
            }
          }

          .fav-text {
            cursor: pointer;
            font-size: 16px;
            font-weight: 600;
            line-height: 28px;
            color: #25272d;
          }
        }
        .user-cards {
          display: flex;
          flex-wrap: nowrap;
        }
      }
    }
  }

  .suggestion-heading {
    font-size: 24px;
    font-weight: 600;
    color: #25272d;
    line-height: 36px;
    margin-bottom: 13px;
    @media(max-width: 768px) {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 13px;
    }
  }

  
  .suggestion-desc {
    color: #25272d;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    @media(max-width: 768px) {
      font-size: 12px;
      line-height: 28px;
  }
  }
  .see-all-btn-scrollable {
    color: #ee7830;
    display: flex;
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
    justify-content: end;
    border: none;
    max-height: 50px;
    @media (min-width: 768px) {
      background-color: #ffffff;
    }

    .text-button {
      position: relative;
      display: inline-block;
      cursor: pointer;
      text-decoration: none;
      // margin: 20px 0px 26px 0px;
      /* Styling for the underline */
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 18px;
        width: 100%;
        height: 2px;
        background-color: #ee7830;
      }
    }
  }
  .see-all-btn {
    color: #ee7830;
    display: flex;
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
    justify-content: center;
    border: none;
    background-color: #ffffff;

    .text-button {
      position: relative;
      display: inline-block;
      cursor: pointer;
      text-decoration: none;
      margin: 20px 0px 26px 0px;
      /* Styling for the underline */
      // &::after {
      //   content: "";
      //   position: absolute;
      //   left: 0;
      //   bottom: 2px;
      //   width: 100%;
      //   height: 2px;
      //   background-color: #ee7830;
      // }
    }
  }
  .request-count {
    position: absolute;
    top: -12px;
    right: -10px;
    background-color: #ee762f;
    border-radius: 50%;
    color: #ffffff;
    padding: 4px;
    font-size: 9px;
    min-width: 20px;
    text-align: center;
    font-weight: 400;
  }
  .suggestion-wrapper {
    padding-top: 35px;

    .suggestion-content {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      

      @media(max-width: 768px) {
        justify-content: center;
    }

    }

    .user-suggestions {
      display: flex;
      justify-content: start;
      align-items: center;
      height: 398px;
    }
    .suggestion-card-wrapper {
      display: flex;
      align-items: center;
      overflow: hidden;
      gap: 10px;
      margin-top: 16px;
    }
    .user-cards {
      display: flex;
      align-items: center;
      overflow-x: hidden;
      scroll-snap-type: x mandatory;
      width: calc(100% + 20px);
      transition: transform 0.3s ease;
      scroll-behavior: smooth;
      gap: 24px;
    }
  }
  .connect-wrapper {
    display: flex;
    padding: 36px 37px 36px 37px;
    // min-height: calc(100dvh - 56px);

    .sidebar-wrapper {
      display: inline-block;
      max-width: 352px;
      @media (max-width: 767px) {
        display: none;
      }

      .profile-img-style {
        border-radius: 100%;
        object-fit: cover;
        border: 4px solid white;
        height: 70px;
        width: 70px;
      }
      .sidebar {
        position: relative;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        background-color: white;

        .sidebar-toggle {
          position: absolute;
          right: -11px;
          top: 16px;
          width: 28px;
          height: 28px;
          border: none;
          border-radius: 100%;
          cursor: pointer;
        }
        .connect-user-wrapper {
          border-radius: 12px 12px 0px 0px;
          border: 1px solid #ee762f;
          background-color: #444e4e;
          height: 146px;
          // padding-top: 24px;
          // padding-left: 24px;
          .profile-wraper {
            position: relative;
            top: -120px;
            left: 24px;
            height: 0px;
            .connect-user-profile {
              display: flex;

              .user-detail-wrapper {
                margin-left: 12px;
                margin-top: 10px;

                .user-name {
                  color: #25272d;
                  font-size: 20px;
                  font-weight: 600;
                  line-height: 28px;
                  width: 200px;
                  text-wrap: wrap;
                }

                .role-wrapper {
                  // max-width: 70px;
                  max-height: 20px;
                  width: fit-content;

                  .user-role {
                    margin: 5px 7px 5px 0px;
                    color: #ee762f;
                    border: 1px solid #ee762f;
                    border-radius: 14px;
                    font-size: 10px;
                    font-weight: 500;
                    display: flex;
                    justify-content: start;
                    background-color: #ffffff;
                    width: 100%;
                  }
                }
              }
            }

            .connect-user-connections {
              color: #25272d;
              font-size: 14px;
              font-weight: 500;
              line-height: 21px;
              margin-bottom: 16px;
              margin-top: 9px;
              margin-right: 36px;
              display: grid;
              grid-template-columns: 40% 60%;
              column-gap: 19px;
              .connect-events {
                // margin-left: 28px;
              }
            }
          }
        }

        .menu-list {
          padding: 28px 20px 28px 20px;
          a {
            text-decoration: none;
          }
          .item-wrapper {
            border-radius: 12px;
            width: 316px;
          }
          .menu-item {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .highlight {
              height: 64px;
              width: 12px;
              border-radius: 12px 0px 0px 12px;
            }
            .first-item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .icon-sidebar {
                margin-left: 11px;
                height: 24px;
                width: 24px;
              }
            }
            .menu-title {
              padding-left: 16px;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              color: #25272d;
            }
          }
        }
      }

      .collapsed-sidebar {
        position: relative;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        background-color: white;

        .collapsed-toggle {
          position: absolute;
          right: -12px;
          top: 16px;
          width: 28px;
          height: 28px;
          border: none;
          border-radius: 100%;
          border: none;
        }

        .collapsed-user-profile {
          height: 0px;
        }
        .collapsed-menu-list {
          padding-top: 30px;
          display: flex;
          flex-direction: column;

          .collapsed-menu-item {
            padding: 14px 38px 24px 38px;
          }
        }
      }
    }

    .connect-content-wrapper {
      width: 100%;
      background-color: #ffffff;
      padding: 30px 20px 32px 20px;
      margin-left: 20px;
      border-radius: 12px;

      .search-filter-wrapper {
        display: flex;
        justify-content: space-between;

        @media (max-width: 1240px) {
          flex-wrap: wrap;
          gap: 16px;
        }

        .btn-text {
          font-size: 14px;
          font-weight: 500;
        }

        /* .search-wrapper {
          display: flex;
          justify-content: start;
          align-items: center;
          border: 1px solid #6c6d6f;
          border-radius: 12px;
          width: 80%;
          max-width: 490px;
          position: relative;
          .search-icon {
            margin: 12px 10px 12px 17px;
          }
          .input-style {
            border: none;
          }
          .clearstyle {
            position: absolute;
            right: 20px;
          }
        } */

        .filter-map-wrapper {
          display: flex;
          align-items: center;
          gap: 16px;

          .filter-wrap {
            display: flex;
            border: 1px solid #6c6d6f;
            border-radius: 12px;
            padding: 11.2px 15.2px 11.2px 15.2px;
            gap: 8px;
          }
           .filter-wrap:hover{
            background-color: #feefe7;
            border-radius: 12px;
            border: 1px solid #ee762f;
            cursor: pointer;
           }

           .filter-active{
            display: flex;
            background-color: #feefe7;
            border-radius: 12px;
            border: 1px solid #ee762f;
            padding: 11.2px 15.2px 11.2px 15.2px;
            gap: 8px;
            cursor: pointer;
           }

          .map-btn-wrapper {
            display: flex;
            align-items: center;
            border: 1px solid #6c6d6f;
            border-radius: 12px;
            gap: 8px;
            padding: 11.2px 15.2px 11.2px 15.2px;
          }

          .map-btn-wrapper:hover {
            background-color: #feefe7;
            border-radius: 12px;
            border: 1px solid #ee762f;
            cursor: pointer;
          }
        }
      }

      .no-data-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .content-wrapper {
          // padding-top: 161px;
          text-align: center;
          height: 60vh;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
               
          .shadow-wrap {
            display: flex;
            justify-content: center;

            .shadow {
              width: 156px;
              height: 14px;
              background-color: #f3f3f3;
              border-radius: 50%;
              margin-top: 11px;
            }
          }

          .title-text {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: #6c6d6f;
          }

          .desc-text {
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            color: #6c6d6f;
            margin-top: 12px;

            .click-text {
              color: #ee7830;
              font-weight: 500;
              cursor: pointer;
            }
          }
        }
      }
      // .suggestion-wrapper {
      //   padding-top: 35px;

      //   .suggestion-content {
      //     display: flex;
      //     flex-wrap: wrap;
      //     gap: 24px;
      //   }

      //   .user-suggestions {
      //     display: flex;
      //     justify-content: start;
      //     align-items: center;
      //     height: 398px;
      //   }
      //   .suggestion-card-wrapper {
      //     display: flex;
      //     align-items: center;
      //     overflow: hidden;
      //     gap: 10px;
      //     margin-top: 16px;
      //   }
      //   .user-cards {
      //     display: flex;
      //     align-items: center;
      //     overflow-x: hidden;
      //     scroll-snap-type: x mandatory;
      //     width: calc(100% + 20px);
      //     transition: transform 0.3s ease;
      //     scroll-behavior: smooth;
      //     gap: 24px;
      //   }
      // }
    }
  }

  .connect-mobile-content {
    padding-top: 96px;
    width: 100%;

    .no-data-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .content-wrapper {
        padding-top: 161px;
        padding-bottom: 161px;
        text-align: center;

        .shadow-wrap {
          display: flex;
          justify-content: center;

          .shadow {
            width: 156px;
            height: 14px;
            background-color: #f3f3f3;
            border-radius: 50%;
            margin-top: 11px;
          }
        }

        .title-text {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #6c6d6f;
        }

        .desc-text {
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          color: #6c6d6f;
          margin-top: 12px;

          .click-text {
            color: #ee7830;
            font-weight: 500;
            cursor: pointer;
          }
        }
      }
    }
  }
  .dropdown-top-bar {
    position: relative;
    z-index: 1;

    @media (min-width: 768px) {
      display: none;
    }
    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #000;
      opacity: 0;
      transition: opacity 0.3s ease;
      z-index: 80;
    }

    .top-bar {
      position: absolute;
      background-color: #fff;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #dcdcdc;
      border-radius: 12px;
      margin: 24px 0px 24px 0px;
      padding: 12px 28px 12px 28px;
      z-index: 101;

      .selected-title {
        font-weight: 500;
        font-size: 16px;
        color: #ee762f;
        margin-left: 16px;
      }

      .icon-menu-expand {
        height: 7px;
        width: 12px;
        display: flex;
        align-items: center;
      }
    }
  }

  .dropdown-content {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #dcdcdc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 101;
    border-radius: 12px;
    margin: 92px 0px 24px 0px;
    padding: 14px 28px 14px 28px;

    .dropdown-item {
      margin-top: 20px;
      margin-bottom: 20px;

      .menu-title {
        font-weight: 500;
        font-size: 16px;
        color: #25272d;
        margin-left: 16px;
      }
    }
  }
}
