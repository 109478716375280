.event_card_outer {
    width: 100%;
    border-radius: 12px 12px 18px 18px;
    transition: all 0.3s ease-in;
    // background: #ee793017;
    display: flex;
    flex-direction: column;
    gap: 14px;

    &:hover {
        cursor: pointer;
    }


    .media_container {
        position: relative;
        width: 100%;
        aspect-ratio: 1/1;
        background: #ee793017;
        border-radius: 12px;

        .amt_layer {
            position: absolute;
            bottom: 16px;
            right: 24px;
        }

        .join_layer {
            position: absolute;
            bottom: 16px;
            right: 24px;
        }

        .listing_type_layer {
            position: absolute;
            top: 12px;
            left: 12px;
        }

        .distance_layer {
            position: absolute;
            top: 50px;
            left: 12px;
        }

        .share_layer {
            position: absolute;
            top: 16px;
            right: 16px;
        }

        .favorite_layer {
            position: absolute;
            top: 56px;
            right: 16px;
        }
    }

    .event_details_section {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .title_section {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            text-align: left;
            color: #25272D;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .stamp_wrap {
            display: flex;
            flex-direction: column;
            gap: 6px;

            .stamp_section {
                display: flex;
                align-items: center;
                gap: 8px;

                .stamp_img {
                    width: 16px;
                    height: 16px;
                }

                .sub_details {
                    width: 85%;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    color: #25272D;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }



        .author_section {
            background: #DCDCDC;
            border-radius: 40px;
            padding: 6px 9px;
            box-sizing: border-box;
            display: flex;
            gap: 8px;
            align-items: center;

            .author_name {
                width: 100%;
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                color: #25272D;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }


}



//amount aud
.amount_wrap {
    width: fit-content;
    height: fit-content;
    padding: 2px 6px;
    // gap: 4px;
    border-radius: 8px;
    background: #EE7830;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #FFFFFF;
}

//amount aud
.join_wrap {
    width: fit-content;
    height: fit-content;
    padding: 2px 6px;
    // gap: 4px;
    border-radius: 8px;
    background: #EE7830;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #FFFFFF;
}

.listing_type_wrap {
    background: #EE7830;
    width: fit-content;
    padding: 4px 10px;
    gap: 6px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #ffffff;

    &.virtual_type {
        background: #0099FF;
    }
}

.distance_wrap {
    background: #00000052;
    display: flex;
    padding: 5px 8px;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    color: #fff;

}

//avatar container
.avatar_img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
}

.slider {
    width: 100%;
    aspect-ratio: 1/1;
    margin: auto;
    overflow: hidden;
    position: relative;

    .btn_back,
    .btn_next {
        background: rgba(255, 255, 255, 0.517);
        border: none;
        cursor: pointer;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        position: absolute;
        top: calc(50% - 12px);
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 4px 4px 0px #00000040;
        transition: all 0.2s ease-in-out;

        &:hover {
            background: rgb(255, 255, 255);
        }

        &:disabled {
            cursor: not-allowed;
            background: rgba(255, 255, 255, 0.321);
        }


    }

    .btn_back {
        left: 16px;
    }

    .btn_next {
        right: 16px;
    }

    .dots_container {
        position: absolute;
        bottom: 16px;
        transition: transform 0.5s ease-in-out;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        gap: 4px;

        .dots_item {
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #FFFFFFBF;

            &.dots_active {
                width: 8px;
                height: 8px;
                background: #ffffff;
            }
        }
    }


    .slides {
        display: flex;
        transition: transform 0.5s ease-in-out;
        width: 100%;
        aspect-ratio: 1/1;



        .event_media {
            width: 100%;
            flex-shrink: 0;
            aspect-ratio: 1/1;
            border-radius: 12px;
            object-fit: cover;
        }
    }

    @media screen and (min-width:600px) {
        transition: all 0.3s ease-in-out;

        .btn_back,
        .btn_next {
            display: none;
            transition: all 0.3s ease-in-out;
        }

        &:hover {

            .btn_back,
            .btn_next {
                display: flex;
                transition: all 0.3s ease-in-out;
            }

        }

    }

}