.textfield_wrapper {

    position: relative;
    padding: 10px 45px 8px 12px;
    border: 1px solid #F3F3F3;
    border-radius: 10px;

    .text_input {
        padding: 0;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #25272D;
        border: none !important;
        min-height: auto;

    }

    .text_field_label {
        width: 100%;
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
        color: #25272D;

    }

    .word_count {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
        color: #25272D;
    }

    .clear_field {
        cursor: pointer;
        position: absolute;
        top: 26px;
        right: 10px;
    }


}