.notification-icon {
  position: relative;
  .active-dot {
    position: absolute;
    top: -13%;
    left: 20px;
  }
  img {
    cursor: pointer;
  }
}
.notification-page-wrap {
  min-height: calc(100vh - 92px);
  // margin-top: 92px;
  padding-top: 32px;
  // background: #f6f6f6;
  background: #fff;

  .content-wrap {
    max-width: 1112px;
    border-radius: 12px;
    margin: 0 auto;
    // background: #fff;
    min-height: calc(100vh - 121px);

    .top-section {
      position: relative;
      padding-top: 42px;
      padding-bottom: 36px;

      .back_arrow {
        position: absolute;
        left: 104px;
        top: 75px;
        cursor: pointer;
        width: 24px;
        height: 24px;
      }
      .heading-wrapper {
        display: flex;
        justify-content: space-between;
        .heading {
          color: #25272d;
          text-align: center;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          @media screen and (max-width: 768px) {
            font-size: 20px;
          }
        }
        .heading-right {
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
          color: #ee762f;
        }
      }
    }

    .divider-heading {
      margin-top: 32px;
      margin-bottom: 24px;
      height: 4px;
      background: #fcfcfc;
    }

    .notifaction-box-wrap {
      .notifaction-box {
        padding-bottom: 16px;
        // max-width: 826px;
        // margin: 0 auto;
        position: relative;
        padding-left: 24px;
        margin-bottom: 20px;

        &:not(:last-child) {
          border-bottom: 0.5px solid #8f939a16;
        }

        .active-dot {
          position: absolute;
          left: 0px;
          top: 1px;
          cursor: pointer;
        }

        .title {
          cursor: pointer;
          display: -webkit-box;
          max-width: 826px;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          color: var(--SteelBlue, #25272d);
          text-overflow: ellipsis;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          margin-bottom: 4px;
        }

        .notification-time {
          display: -webkit-box;
          width: 269px;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          color: var(--SecondarySteel, #8f939a);
          text-overflow: ellipsis;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }
  }
}
@media screen and (max-width: "1280px") {
  .notification-page-wrap {
    .content-wrap {
      max-width: 900px;
    }
  }
}
@media screen and (max-width: "950px") {
  .notification-page-wrap {
    .content-wrap {
      max-width: 800px;
    }
  }
}

@media screen and (max-width: "768px") {
  .notification-page-wrap {
    .content-wrap {
      max-width: 650px;
      .top-section {
        padding: 0px 12px;
        padding-top: 32px;

        .back_arrow {
          left: 32px;
          top: 37px;
        }
      }
    }

    .divider-heading {
      height: 1px;
    }

    .notifaction-box-wrap {
      padding: 20px;

      .title {
        -webkit-line-clamp: 2;
      }
    }
  }
}
