.promotion-page {
  background: #fff;

  .content-box {
    padding: 42px 16px 32px;
    max-width: 1284px;
    margin: 0 auto;

    .content-title {
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      text-align: left;
      color: #25272d;
      margin-bottom: 6px;
    }

    .content-subtitle {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #535a67;
    }
  }

  hr {
    color: #dcdcdc;
    margin-top: 0;
  }
}

.paination-container {
  display: flex;
  justify-content: center;
  margin-top: 57px;
  margin-bottom: 16px;

  // .page-itrm {
  //   font-size: 16px;
  //   font-weight: 400;
  //   line-height: 24px;
  //   color: #25272d;
  // }

  // .active>a {
  //   width: 30px;
  //   height: 30px;
  //   margin-top: 5px;
  //   display: inline-flex;
  //   align-items: center;
  //   justify-content: center;
  //   font-size: 16px;
  //   font-weight: 600;
  //   line-height: 24px;
  // }
}

// promotion detail page
.promotion-detail-page {
  background: #fff;

  .content-box {
    padding: 42px 16px 24px;
    max-width: 1284px;
    margin: 0 auto;

    .title {
      font-size: 32px;
      font-weight: 500;
      line-height: 48px;
      text-align: left;
      color: #25272d;
      margin-bottom: 6px;
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .promocard-img {
      height: 450px;
      width: 100%;
      object-fit: cover;
    }
  }

  .details-content {
    padding-top: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .desc {
      max-width: 661px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #1c1f21;
    }

    .find-more-box {
      padding: 24px 36px 42px 32px;
      background-color: #ee783014;
      width: 100%;
      max-width: 466px;

      .others {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .time {
          img {
            margin-top: 2px;
          }

          display: flex;
          align-items: center;
          gap: 4px;
          color: #25272d;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
        }
      }

      .btn-box {
        margin-top: 24px;
        width: 100%;
      }
    }
  }

  hr {
    color: #dcdcdc;
    margin-bottom: 40px;
    margin-top: 0;
    height: 1px;
  }

  .other-related-box {
    margin-top: 40px;
    max-width: 1284px;
    margin: 0 auto;

    .heading {
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      text-align: left;
      color: #25272d;
      margin-bottom: 16px;
      padding-left: 16px;
    }
  }
}

.promotion-detail-page,
.promotion-page {
  .card-list-wrap {
    max-width: 1284px;
    margin: 0 auto;
    padding: 0 16px;

    .listings-card {
      display: grid;
      gap: 24px;
      grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
      padding-bottom: 50px;

      .card-wrap {
        border: 1px solid #dcdcdc;
        border-radius: 12px;
        position: relative;

        .card-media {
          .videoicon {
            position: absolute;
            z-index: 2;
            left: 50%;
            transform: translateX(-50%);
            top: 65px;
          }

          .event_img {
            height: 194px;
            width: 100%;
            border-radius: 12px 12px 0 0;
            object-fit: cover;
          }
        }

        .card-content {
          padding: 16px 13px;

          .title {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;
            max-height: 40px;
            color: #25272d;
            margin-bottom: 8px;
          }

          .others {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #8f939a;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 21px;
            margin-bottom: 8px;

            .time {
              display: flex;
              align-items: center;
              gap: 4px;
              color: #8f939a;
            }
          }

          .desc {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 60px;
            color: #8f939a;
          }
        }

        .find-out-box {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: #ee783014;
          border-radius: 0 0 12px 12px;

          .click-here {
            width: 100%;
            padding: 10px;
            display: flex;
            justify-content: center;
            border-top: 1px solid #ee7830;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            background: var(--Orange-Gradient,
                linear-gradient(167deg, #ee7830 -15.49%, #ee762f 149.41%));
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        .promo-link-title {
          display: flex;
          align-items: center;
          gap: 4px;
        }
      }
    }
  }

  .no-promotion-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 100px;
    flex-direction: column;

    .no-promotion-title {
      margin-top: 16px;
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
      letter-spacing: 0em;
      text-align: center;
      color: #8f939a;
    }

    .no-promotion-desc {
      margin-top: 12px;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      max-width: 500px;
      color: #8f939a;
    }
  }
}