.how-it-works-wrapper {
  padding: 104px 0px;
  background-image: url("../img/signupWelcome/how-it-works-cover.webp");
  background-size: cover;
  background-position: center;
  height: 361px;
  width: 100%;
  display: flex;
  justify-content: center;

  .section-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
    .heading {
      font-size: 32px;
      font-weight: 700;
      line-height: 42px;
    }
    .sub-heading {
      font-size: 18px;
      font-weight: 400;
      line-height: 29.5px;
    }
    .btn-div {
      margin-top: 21px;
      background: #ee7830;
      font-size: 22px;
      font-weight: 600;
      line-height: 24px;
      padding: 16px 37px;
      border-radius: 0px;
      cursor: pointer;
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 41px 45px;
    height: 190px;

    .section-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .heading {
        font-size: 16px;
        line-height: 23px;
        text-align: center;
      }
      .sub-heading {
        font-size: 9.86px;
        line-height: 16.51px;
      }
      .btn-div {
        margin-top: 11.5px;
        font-size: 12.05px;
        line-height: 13px;
        padding: 8px 19px;
        border-radius: 2.19px;
      }
    }
  }
}
