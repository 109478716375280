/* autoprefixer: off */
.landing-page-wrapper {
  margin: 0 auto;
  background: #fdfdfd;
  position: relative;

  .header-landing-wrap {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
    padding: 23px 55px;
    display: flex;
    justify-content: space-between;

    .h-l-logo {
      margin-left: 146px;
    }

    .left-logo {
      align-items: center;
    }

    .header-buttons {
      display: flex;
      justify-content: space-between;
    }

    .right-box {
      display: flex;
      gap: 66px;
      align-items: center;

      ul {
        list-style: none;
        padding-left: 0;
        margin: 0;
        display: flex;
      }

      .menus {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          height: 100%;
          border-left: 1px solid #d9d9d9;
          right: -33px;
        }

        ul {
          gap: 32px;

          li {
            a {
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              color: #25272d;
              text-decoration: none;
              min-width: 80px;

              &.active {
                color: #ee772f;
              }
            }
          }
        }
      }

      .icon-menu {
        ul {
          gap: 16px;
          align-items: center;

          li {
            img {
              cursor: pointer;
            }

            &.menu-des {
              .inner {
                display: flex;
                border: 1px solid #d9d9d9;
                border-radius: 100px;
                padding: 4px;
                padding-left: 14px;
                align-items: center;
                gap: 8px;

                .profile {
                  width: 36px;
                  height: 36px;
                  border-radius: 50%;
                  border: 2.21538px solid #ffffff;
                  filter: drop-shadow(0px 0px 6.64615px rgba(0, 0, 0, 0.12));
                  object-fit: cover;
                }
              }

              @media screen and (max-width: "768px") {
                .inner {
                  .profile {
                    width: 32px;
                    height: 32px;
                  }

                  .menu-icon {
                    width: 22px;
                    height: 22px;
                  }
                }
              }
            }
          }

          li.header-notify-icon {
            position: relative;

            .notify-badge {
              position: absolute;
              background: #ee762f;
              border-radius: 100%;
              display: flex;
              top: -7px;
              right: -7px;

              .nine-plus {
                font-size: 10px;
                color: #ffffff;
                // padding: 2px 4px;
                border-radius: 100%;
                width: 16px;
                height: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              .no-plus {
                font-size: 10px;
                color: #ffffff;
                // padding: 2px 4px;
                border-radius: 100%;
                width: 15px;
                height: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }

  .white-btn-wrap {
    padding: 10px 32px;
    border-radius: 100px;
    border: 1px solid var(--Gray-6, #f2f2f2);
    background: var(--FitnessWhite, #fff);
    color: var(--SteelBlue, #25272d);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-right: 20px;

    &:active,
    &:hover {
      box-shadow: none;
    }
  }

  .register-btn {
    padding: 10px 32px;
    border-radius: 100px;
    background: var(--Orange-Gradient,
        linear-gradient(167deg, #ee7830 -15.49%, #ee762f 149.41%));
    color: var(--FitnessWhite, #fff);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    &:active,
    &:hover {
      background: linear-gradient(0deg,
          rgba(0, 0, 0, 0.04) 0%,
          rgba(0, 0, 0, 0.04) 100%),
        linear-gradient(167deg, #ee7830 -15.49%, #ee762f 149.41%);
    }
  }

  .hero-landing-wrap {
    background-image: url("../img/hero-yoga.png");
    background-repeat: no-repeat;
    background-position-x: right;
    top: 0;
    right: 0;
    width: 100%;
    padding: 0px 200px;
    margin-top: 80px;
    padding-bottom: 80px;

    .mobile-hero-img {
      display: none;
    }

    .get-started {
      display: flex;

      .get-start-button-mobile {
        display: none;
      }

      .image-arrow {
        position: relative;
        cursor: pointer;
        margin-left: 20px;

        .ring {
          position: relative;
          width: 92px;
        }

        .outer-circle {
          position: absolute;
          width: 68px;
          height: 68px;
          background-color: #ee7830;
          opacity: 0.1;
          border-radius: 50%;
          bottom: 5px;
          left: 14px;
        }

        .inner-circle {
          position: absolute;
          top: 8%;
          left: 22px;
          width: 52px;
          height: 52px;
          background-color: #ee7830;
          border-radius: 50%;
          opacity: 1;

          .arrow {
            position: absolute;
            top: 14px;
            left: 10px;
            transition: transform 0.3s ease;
            width: 34px;
          }
        }
      }

      .image-arrow:hover .arrow {
        transform: rotate(50deg);
      }
    }

    .left-box {
      padding-top: 90px;
      max-width: 706px;
      padding-bottom: 30px;

      .hero-title-wrap {
        position: relative;

        .top {
          color: var(--SteelBlue, #25272d);
          font-size: 40px;
          font-style: normal;
          font-weight: 600;
          line-height: 50px;
        }

        .bottom {
          color: var(--SteelBlue, #25272d);
          font-size: 40px;
          font-style: normal;
          font-weight: 700;
          line-height: 50px;
        }
      }

      .heiglight-img {
        position: absolute;
        left: 470px;
        bottom: 75px;
      }
    }

    .member-text {
      margin-top: 0px;
      margin-left: 75px;
      color: var(--SecondarySteel, #8f939a);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;

      .mobileArrow {
        display: none;
      }
    }

    .hero-desc-box {
      max-width: 660px;
      margin-top: 30px;
      margin-bottom: 30px;

      .hero-desc {
        color: var(--SecondarySteel, #8f939a);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 29.5px;
      }
    }

    .social-wrap {
      .social-links {
        display: flex;
        align-items: center;
        gap: 44px;

        ul {
          padding: 0 !important;
          margin: 0 !important;
        }

        li {
          list-style: none;
        }
      }
    }
  }

  .about-landing-wrap {
    margin: 100px auto;

    .about-btn-wrap {
      text-align: center;

      .about-btn {
        display: inline-flex;
        padding: 8px 32px;
        align-items: flex-start;
        gap: 4px;
        border-radius: 200px;
        background: #fee0ce;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        color: #ee762f;
        border: none;
        outline: none;
      }
    }

    .title-box {
      margin-top: 32px;
      text-align: center;
      color: var(--SteelBlue, #25272d);
      text-align: center;
      font-size: 48px;
      font-style: normal;
      font-weight: 600;
      line-height: 72px;
    }

    .about-box-wrap {
      display: flex;
      justify-content: space-between;
      padding-left: 200px;
      padding-top: 100px;

      .about-img {
        .about-img1 {
          position: absolute;
          left: 24px;
          top: -10px;
        }

        .about-img3 {
          position: absolute;
          top: "-84px";
        }

        .about-img4 {
          position: absolute;
          top: 112px;
        }
      }
    }

    .flex-wrap {
      flex-wrap: wrap;
    }

    .wrap-rev {
      flex-wrap: wrap-reverse;
    }

    .pr-200 {
      padding-right: 200px;
    }

    .pr-100 {
      padding-right: 100px;
    }

    .mt-100 {
      margin-top: 100px;
    }

    .content-box {
      max-width: 377px;
      position: relative;

      .title {
        color: var(--SteelBlue, #25272d);
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 48px;
        position: relative;
        margin-bottom: 20px;

        .highlight-near-you {
          position: absolute;
          left: 130px;
        }
      }

      .sub-title {
        color: var(--SecondarySteel, #8f939a);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 29.5px;
      }

      .wait-btn {
        margin-top: 32px;
        display: flex;
        align-items: center;
        gap: 12px;
        cursor: pointer;
        max-width: 180px;

        .wait-label {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          color: #ee762f;
        }

        img {
          transition: transform 0.3s ease;
          transform: translateX(0px);
        }

        &:hover {
          img {
            transform: translateX(10px);
          }
        }
      }

      .content-icon-act {
        position: absolute;
        right: 48px;
      }

      .content-icon-msg {
        position: absolute;
        bottom: -10px;
        right: -48px;
      }

      .content-icon-earn {
        position: absolute;
        top: -36px;
        left: -36px;
      }
    }
  }

  .Journey-landing-wrap {
    background: var(--Orange-Gradient,
        linear-gradient(167deg, #ee7830 -15.49%, #ee762f 149.41%));
    padding-top: 72px;
    padding-bottom: 92px;
    text-align: center;

    .title {
      color: var(--FitnessWhite, #fff);
      text-align: center;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 48px;
      margin-bottom: 16px;
    }

    .sub-title {
      color: var(--FitnessWhite, #fff);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 29.5px;
    }
  }

  .PrivacyTerms-landing-wrap {
    display: flex;
    justify-content: space-around;
    height: 160px;
    width: 100%;
    background-color: #25272d;
    padding-top: 60px;

    .PrivacyTerms-text {
      color: white;
      font-size: 20px;
      font-weight: 400;
    }

    .PrivacyTerms-icons {
      display: flex;
      justify-content: space-around;
      height: fit-content;
      width: 300px;
    }

    .icons {
      cursor: pointer;
    }

    .icons:hover {
      color: #ee762f;
    }
  }

  .download-btn-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 24px;
    gap: 24px;
    flex-wrap: wrap;
  }

  .comming-btn-wrap {
    width: 256px;
    height: 56px;
    flex-shrink: 0;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    background: #f29258;
    position: relative;
    outline: none;
    border: none;
    color: var(--FitnessWhite, #fff);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;

    .coming-soon {
      position: absolute;
      bottom: -12px;
      right: 71px;
      display: inline-flex;
      padding: 4px 16px;
      align-items: flex-start;
      gap: 4px;
      border-radius: 100px;
      background: var(--FitnessWhite, #fff);
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      color: #ee762f;
    }
  }

  .get-start-btn-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    color: #ee762f;
    background-color: transparent;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 72px;

    &:hover {
      span>svg {
        fill: #25272d;
        stroke: #25272d;
      }
    }
  }

  .promote-landing-wrap {
    background: #fdfdfd;
    display: flex;

    .left-enquire {
      padding: 100px;
      width: 70%;

      .promote-title {
        font-size: 16px;
      }

      .promote-sub-title {
        font-size: 28px;
        font-weight: 600;

        .promote-sub-title1 {
          color: "#EE7830";
        }
      }

      .vector3 {
        position: relative;
        bottom: 10px;
      }

      .enquire-button {
        margin-top: 50px;
        display: flex;
        border: 0px solid;
        border-radius: 100px;
        background-color: #ee7830;
        padding: 16px 24px 16px 32px;
        font-size: 16px;
        font-weight: 700;
        color: #ffffff;
      }
    }

    .right-enquire {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 100px 100px 100px 0px;
      background-color: #f8f9fa;

      .card-enquire {
        max-width: 240px;
        height: fit-content;

        .heading {
          font-size: 20px;
          font-weight: 600;
          color: #25272d;
        }

        .text-para {
          font-size: 16px;
          font-weight: 400;
          color: #8f939a;
        }
      }

      .part-1 {
        display: flex;
        justify-content: space-around;
      }

      .part-2 {
        display: flex;
        justify-content: space-around;
        margin-top: 50px;
      }
    }
  }

  .join-landing-wrap {
    display: flex;
    width: 100%;
    justify-content: space-between;
    overflow: hidden;

    .join-sign-in {
      padding-left: 100px;
      padding-top: 100px;
      width: 60%;

      .heading-1 {
        font-size: 16px;
        font-weight: 500;
        color: #8f939a;
      }

      .heading-2 {
        margin-top: 12px;
        width: 70%;
        font-size: 32px;
        font-weight: 600;
        color: #25272d;
      }

      .heading-3 {
        margin-top: 23px;
        font-size: 16px;
        font-weight: 400;
        color: #8f939a;
        width: 62%;
      }

      .signup {
        margin-top: 44px;

        .custom-input {
          border: none;
          border-bottom: 4px solid #f3f3f3;
          padding: 8px;
          outline: none;
          width: 250px;
          padding-bottom: 15px;
        }

        .ml {
          margin-left: 20px;
        }

        .wid {
          width: 519px;
          margin-top: 20px;
        }

        .custom-input:focus {
          border-color: #ee7830;
          caret-color: #ee7830;
        }

        .textAddChat {
          border: none;
          outline: none;
          width: 80%;
          margin-top: 50px;
        }

        .heading-1 {
          font-size: 16px;
          font-weight: 500;
          color: #25272d;
        }

        .signup-headings {
          display: flex;
          margin: 16px 0px;
          align-items: center;

          .Business {
            padding-left: 20px;
            padding-bottom: 10px;
            font-size: 32px;
            font-weight: 600;
            color: #ee7830;
          }

          .vector2 {
            position: relative;
            bottom: 25px;
          }
        }

        .form-input {
          display: flex;
        }

        .btn-contents {
          display: flex;
          margin-top: 30px;
          align-items: center;
          padding-bottom: 60px;

          .btn-text {
            font-size: 12px;
            font-weight: 400;
            color: #8f939a;
            width: 44%;
          }

          .join-button {
            display: flex;
            margin-left: 60px;
            border: 0px solid;
            border-radius: 100px;
            background-color: #ee7830;
            padding: 16px 24px 16px 32px;
            font-size: 16px;
            font-weight: 700;
            color: #ffffff;
          }

          .vector1 {
            position: relative;
            bottom: 20px;
            left: 25px;
          }
        }
      }
    }

    .join-bg {
      width: 40%;

      .join-background {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media (max-width: 768px) {
    .hero-landing-wrap {
      background-image: none;
      padding: 0px;
      display: flex;
      flex-direction: column;

      .left-box {
        padding-top: 0px;

        .hero-title-wrap {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 40px;

          .top {
            font-size: 22px;
            line-height: 39px;
          }

          .bottom {
            font-size: 22px;
            line-height: 39px;
          }

          .heiglight-img {
            position: relative;
            left: 150px;
            bottom: 100px;
          }
        }
      }

      .member-text {
        order: 2;
        margin: 30px auto;
        font-size: 10px;
        position: relative;

        .mobileArrow {
          display: block;
          position: absolute;
          left: -87px;
          bottom: -5px;
        }
      }

      .get-started {
        order: 3;
        margin: 0px auto;

        .getStartButton {
          display: none;
        }

        .get-start-button-mobile {
          display: block;
        }

        .image-arrow {
          .ring {
            position: relative;
            width: 73px;
          }

          .outer-circle {
            width: 50px;
            height: 50px;
            bottom: 5px;
            left: 14px;
          }

          .inner-circle {
            width: 40px;
            height: 40px;
            top: 12%;
            left: 19px;

            .arrow {
              width: 30px;
              top: 8px;
              left: 5px;
            }
          }
        }
      }

      .mobile-img {
        order: 4;
        width: 100%;

        .mobile-hero-img {
          display: block;
          width: 100%;
          margin: 0px auto;
          height: 370px;
          object-fit: contain;
        }
      }

      .hero-desc-box {
        order: 1;
        width: 350px;
        margin: 0px auto;
        text-align: center;

        .hero-desc {
          font-size: 14px;
        }
      }

      .social-wrap {
        order: 5;
        width: fit-content;
        margin: 10px auto;

        .social-links {
          gap: 30px;
        }
      }
    }

    .about-landing-wrap {
      margin: 0;

      .about-btn-wrap {
        padding: 30px 30px 0px;
      }

      .title-box {
        display: none;
      }

      .about-box-wrap {
        width: 350px;
        margin: 0px auto;
        padding-left: 0px;
        padding-top: 60px;

        .about-img {
          width: 350px;
          margin-top: 30px;

          .about-back-img {
            width: 350px;
          }

          .about-img2 {
            width: 350px;
          }

          .about-img3 {
            position: relative;
            width: 350px;
          }

          .about-img4 {
            left: 0px;
            top: 73px;
          }

          .about-img5 {
            width: 350px;
          }
        }
      }

      .pr-100 {
        padding-right: 0px;
      }

      .pr-200 {
        padding-right: 0px;
      }

      .content-box {
        .title {
          font-size: 20px;

          .highlight-near-you {
            position: absolute;
            left: 78px;
          }
        }

        .sub-title {
          font-size: 14px;
        }
      }
    }

    .Journey-landing-wrap {
      margin-top: 50px;
      width: 100%;
      padding: 50px;

      .title {
        font-size: 20px;
        line-height: 32px;
      }

      .sub-title {
        font-size: 15px;
        line-height: 22px;
      }
    }

    .promote-landing-wrap {
      flex-direction: column;

      .left-enquire {
        width: 100%;
        padding: 30px;
        margin-top: 20px;
        text-align: center;

        .promote-sub-title {
          font-size: 20px;
          font-weight: 600;
          margin-top: 20px;

          .promote-sub-title1 {
            color: #25272d;
          }

          .mobile-br {
            display: none;
          }
        }

        .enquire-button {
          margin: 50px auto;
        }
      }

      .right-enquire {
        padding: 0px;
        padding-bottom: 40px;

        .part-1 {
          flex-direction: column;
          width: fit-content;
          margin: 10px auto;

          .card-enquire {
            text-align: center;
            margin-top: 20px;

            .text-para {
              margin-top: 10px;
            }
          }
        }

        .part-2 {
          flex-direction: column;
          width: fit-content;
          margin: 10px auto;

          .card-enquire {
            text-align: center;
            margin-top: 20px;

            .text-para {
              margin-top: 10px;
            }
          }
        }
      }
    }

    .join-landing-wrap {
      .join-sign-in {
        padding: 30px;
        width: 100%;

        .heading {
          .heading-2 {
            margin-top: 12px;
            width: 90%;
            font-size: 20px;
          }

          .heading-3 {
            font-size: 14px;
            width: 100%;
          }
        }

        .signup {
          .signup-headings {
            position: relative;

            .join-img1 {
              width: 143px;
            }

            .Business {
              padding-left: 6px;
              font-size: 18px;
              padding-bottom: 0px;
            }

            .vector2 {
              position: absolute;
              right: 0;
              top: -14px;
            }
          }

          .form-input {
            flex-direction: column;
          }

          .custom-input {
            width: 100%;
            margin-top: 20px;
          }

          .ml {
            margin-left: 0px;
          }

          .wid {
            width: 100%;
          }

          .btn-contents {
            flex-direction: column;
            align-items: center;
            margin: 0px;
            padding-top: 24px;
            padding-bottom: 0px;

            .btn-text {
              width: 100%;
              text-align: center;
              padding: 0px 20px;
            }

            .join-button {
              text-align: center;
              margin-top: 20px;
              margin-left: 0px;
            }

            .vector1 {
              display: none;
            }
          }
        }
      }

      .join-bg {
        display: none;
      }
    }

    .PrivacyTerms-landing-wrap {
      flex-direction: column-reverse;
      padding: 40px;

      .PrivacyTerms-text {
        font-size: 15px;
        width: fit-content;
        margin: 0px auto;
      }

      .PrivacyTerms-icons {
        width: fit-content;
        gap: 30px;
        margin: 0px auto;
      }
    }
  }
}

.landing-page-wrapper {
  .header-landing-wrap {
    position: fixed;
    background-color: #ffffff;
  }
}

@media screen and (max-width: "768px") {
  .landing-page-wrapper {
    .header-landing-wrap {
      padding: 1rem;

      .h-l-logo {
        margin-left: 0px;
      }
    }
  }
}