.connectpage-wrap {
  background: #f9f9f9;
  padding-top: 27px;
  margin-top: 94px;

  .content-box {
    max-width: 1112px;
    border-radius: 12px;
    margin: 0 auto;
    background: var(--FitnessWhite, #fff);
    min-height: calc(100vh - 121px);
  }

  .top-section {
    padding: 32px;
    display: flex;
    justify-content: space-between;

    .left {
      .title {
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
      }

      .subtitle {
        color: var(--SecondarySteel, #8f939a);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;

      .filter-icon {
        width: 48px;
        height: 48px;
        flex-shrink: 0;
        border-radius: 12px;
        background: #ee762f;
        padding: 12px;
        cursor: pointer;
      }

      .toogle-btn-wrap {
        border-radius: 10px;
        background: var(--FitnessNonSelected, #f3f3f3);
        display: inline-flex;
        padding: 4px;
        align-items: flex-start;
        gap: 4px;
        max-width: 240px;

        .active-btn {
          border-radius: 8px;
          border: 1px solid var(--Orange-Gradient, #ee7830);
          background: linear-gradient(167deg,
              rgba(238, 120, 48, 0.2) -15.49%,
              rgba(238, 118, 47, 0.2) 149.41%);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          color: #ee7830;
          padding: 10px;
          display: inline-flex;
          align-items: flex-start;
          gap: 4px;
          cursor: pointer;
        }

        .disable-btn {
          color: var(--SecondarySteel, #8f939a);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          display: inline-flex;
          align-items: flex-start;
          gap: 4px;
          padding: 11px 10px;
          cursor: pointer;
        }
      }
    }

    @media screen and (max-width: "768px") {
      .left {
        .title {
          font-size: 20px;
        }

        .subtitle {
          font-size: 12px;
        }
      }

      .hidden-text {
        display: none;
      }
    }
  }

  .carousel-box {
    margin: 54px auto;
  }

  @media screen and (max-width: "768px") {
    .content-box {
      padding: 16px;

      .left {
        .title {
          font-size: 20px;
        }

        .subtitle {
          font-size: 12px;
        }
      }

      .hidden-text {
        display: none;
      }
    }
  }
}

.connect-card {
  .card-content-wrap {
    border-radius: 12px;
    border: 1px solid gray;
    margin: 0 12px;
    height: 470px;
    width: 308px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: flex-end;

    .background-image {
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 100%;
      filter: blur(5px);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }

    .backk {
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 50%;
      filter: none !important;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }

    .like-btn {
      position: absolute;
      right: 16px;
      top: 16px;
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }

    .content {
      position: relative;
      z-index: 1;
      padding: 16px;

      .card-img-wrap {
        width: 130px;
        height: 130px;

        .img {
          background-size: cover;
          width: 126px;
          height: 126px;
          border-radius: 50%;
          border: 4px solid #fff;
        }
      }

      .shadow-profile {
        img {
          box-shadow: 0px 4px 20px 0px #0000001F !important;
        }
      }

      .people-pill {
        border: 1.5px solid #F3F3F3;
        border-radius: 38px;
        padding: 2px 10px 2px 10px;
        width: auto;
        font-size: 12px;
        color: #141414;
      }

      .username {
        margin-top: 16px;
        color: var(--FitnessWhite, #fff);
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
      }

      .location-info {
        display: inline-flex;
        gap: 3px;
        color: var(--FitnessNonSelected, #f3f3f3);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }

      .interest-chip-container {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;

        .interest-chip-box {
          // display: flex;
          gap: 8px;
          // flex-wrap: wrap;
          max-height: 60px;
          // overflow: hidden;
          min-height: 60px;
          // align-items: start;

          .chip {
            padding: 2px 8px;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            background: rgba(255, 255, 255, 0.3);
            color: #fff;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            max-width: 278px;
            display: inline-block;
            width: fit-content;
            margin-left: 8px;
          }
        }
      }

      .button-box {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 24px;

        .profile {
          display: flex;
          width: 134px;
          height: 40px;
          padding: 8px 16px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: 8px;
          background: #f2f3f3;
          color: var(--SecondarySteel, #8f939a);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        .connect {
          display: flex;
          width: 134px;
          height: 40px;
          padding: 16px 4px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: 8px;
          background: var(--Orange-Gradient,
              linear-gradient(167deg, #ee7830 -15.49%, #ee762f 149.41%));
          box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.88);
          color: var(--White, #fff);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }

        .connect-s {
          display: flex;
          width: 80%;
          height: 40px;
          padding: 16px 4px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: 8px;
          background: var(--Orange-Gradient,
              linear-gradient(167deg, #ee7830 -15.49%, #ee762f 149.41%));
          box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.88);
          color: var(--White, #fff);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }

        .disabled-connect-s {
          margin: auto;
          width: 80%;
          display: grid;
          padding: 8px 4px;
          line-height: 24px;
          border-radius: 8px;
          background: #f2f3f3;
          color: #62656a;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          cursor: text;
        }

        .disabled-connect {
          width: 134px;
          display: flex;
          padding: 8px 4px;
          line-height: 24px;
          border-radius: 8px;
          background: #f2f3f3;
          color: #8f939a;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          cursor: text;

          img {
            filter: invert(0.5);
          }
        }
      }
    }
  }
}

.partial-card {
  height: 300px !important;
}

.connect-search-user {
  max-width: 877px !important;
}

.search-list-user {
  display: grid;
  gap: 17px;

  .on-list-user {
    display: flex;
    justify-content: space-between;
    padding: 15px 12px;
    padding-left: 0;
    align-items: center;
    position: relative;
    cursor: pointer;

    &:last-child {
      &::after {
        display: none;
      }
    }

    a {
      display: grid;
    }

    .top-part {
      display: flex;
      gap: 6px;

      .img-box {
        img {
          border-radius: 50%;
          object-fit: cover;
          width: 48px;
          height: 48px;
        }
      }

      h6 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: rgba(37, 39, 45, 0.7);
        word-break: break-all;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #8f939a;
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
  }

  @media screen and (max-width: "768px") {
    .on-list-user {
      padding-right: 0;
    }
  }
}

.connect-card-map {
  .card-content-wrap {
    border-radius: 12px;
    position: relative;
    display: flex;
    align-items: flex-end;

    .content {
      position: relative;
      z-index: 1;
      padding: 16px;
      width: 100%;

      .card-img-wrap {
        width: 130px;
        height: 130px;

        .img {
          background-size: cover;
          width: 126px;
          height: 126px;
          border-radius: 50%;
          border: 4px solid #fff;
        }

        .vid {
          // background-size: cover;
          width: 126px;
          height: 126px;
          border-radius: 50%;
          border: 4px solid #fff;
          object-fit: cover;
        }
      }

      .shadow-profile {
        img {
          box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.88) !important;
        }
      }

      .username {
        color: var(--SteelBlue, #25272d);
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
      }

      .location-info {
        color: var(--SecondarySteel, #8f939a);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }

      .interest-chip-box {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
        max-height: 68px;
        overflow: hidden;

        .chip {
          padding: 4px 8px;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          border: 1px solid var(--FitnessNonSelected, #f3f3f3);
          background: #fff;
          color: var(--SteelBlue, #25272d);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      .button-box {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 24px;

        .profile {
          display: flex;
          width: 152px;
          height: 40px;
          padding: 8px 16px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: 8px;
          background: #f2f3f3;
          color: var(--SecondarySteel, #8f939a);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        .connect {
          display: flex;
          width: 152px;
          height: 40px;
          padding: 16px 44px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: 8px;
          background: var(--Orange-Gradient,
              linear-gradient(167deg, #ee7830 -15.49%, #ee762f 149.41%));
          color: var(--White, #fff);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }

        .disabled-connect {
          width: 134px;
          display: flex;
          padding: 8px 4px;
          line-height: 24px;
          border-radius: 8px;
          background: #f2f3f3;
          color: #8f939a;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          cursor: text;

          img {
            filter: invert(0.5);
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .carousel-button-cutsom {
    display: none;
  }
}

.cluster-group-data {
  position: absolute !important;
  bottom: 0 !important;
  width: 100% !important;
}

.cluster-group-data-individual {
  position: absolute !important;
  bottom: 0 !important;
}

@media (max-width: 900px) {
  .cluster-group-data {
    width: 100% !important;
  }
}