.landing-new-page {
  //header
  .new-landing-header-wrap {
    position: sticky;
    top: 0;
    z-index: 300;
    background: #ffffff;
    .new-landing-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 64px;
      max-width: 1290px;
      padding: 16px;
      margin: 0 auto;
    }
    .join-as-a-member {
      cursor: pointer;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      align-items: center;
      text-align: center;
      text-decoration-line: underline;
      color: #25272d;
    }
  }
  // Hero section
  .register-info-box {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ee7730;
    height: 54px;
    gap: 12px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    position: relative;
    .close-infobox {
      position: absolute;
      right: 64px;
      top: 11px;
      cursor: pointer;
    }
    @media screen and (max-width: "900px") {
      display: none;
    }
  }
  .video-background {
    position: relative;
    width: 100%;
    height: calc(100vh - 118px);
    overflow: hidden;
    @media screen and (max-width: "900px") {
      height: calc(100vh - 64px);
    }
    .video {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: translate(-50%, -50%);
      z-index: -1;
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background: rgba(0, 0, 0, 0.53);
      z-index: 0;
    }
    .content {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      text-align: center;
      padding: 20px;
      gap: 32px;

      .logo-box {
        // width: 120px;
        // height: 124px;
        display: flex;
        align-items: center;
        justify-content: center;
        // border: 1px solid #ffffff;
        // border-radius: 18px;
        // // background: #ee7730;
        img.animated-gif {
          width: 120px;
          height: 120px;
          object-fit: fill;
        }
        @media screen and (max-width: "786px") {
          // width: 60px;
          // height: 60px;
          // border-radius: 10px;
          img.animated-gif {
            width: 60px;
            height: 60px;
          }
        }
      }

      .title {
        max-width: 1120px;
        font-size: 70px;
        font-weight: 700;
        line-height: 95px;
        text-align: center;
        text-transform: uppercase;
        color: #ee7730;
        span {
          color: #ffffff;
        }
        @media screen and (max-width: "786px") {
          max-width: 320px;
          font-size: 32px;
          line-height: 52px;
        }
      }
      .sub-title {
        font-size: 20px;
        font-weight: 600;
        line-height: 29.5px;
        text-align: center;
        color: #ffffff;
        padding: 0px 16px;
      }
      .button-box {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 32px;
        .hero-btn {
          padding: 12px 16px;
          border-radius: 100px;
          font-size: 18px;
          font-weight: 700;
          line-height: 24px;
          text-align: center;
          outline: none;
          border: none;
          width: 293px;
          // &:focus,
          // &:active {
          //   background: #be652f;
          // }
          &:hover {
            background: #be652f;
          }
        }
        .get-started {
          background: #ee7830;
          color: #ffffff;
        }
        .book-demo {
          color: #070707;
          background: #ffffff;
        }
      }
    }
  }
  .orange-span {
    color: #ee762f;
  }
  .orange-btn {
    padding: 12px 16px;
    border-radius: 100px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    outline: none;
    border: none;
    width: 293px;
    background: #ee7830;
    color: #ffffff;
    cursor: pointer;
    // &:focus,
    // &:active {
    //   background: #be652f;
    // }
    &:hover {
      background: #be652f;
    }
  }
  //   Get early section
  .get-early-section {
    max-width: 1220px;
    margin: 0 auto;
    background-color: #ffffff;
    .content-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 80px 16px;
      gap: 50px;
      .content-left {
        flex: 1;
        max-width: 617px;
        img {
          width: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .content-right {
        flex: 1;
        padding-right: 16px;
        min-width: 350px;
        .title {
          font-size: 32px;
          font-weight: 600;
          line-height: 48px;
          text-align: left;
          color: #070707;
          margin-bottom: 32px;
        }
        .description {
          font-size: 18px;
          font-weight: 400;
          line-height: 28px;
          color: #666666;
          display: block !important;
        }
        .button-box {
          margin-top: 32px;
        }
      }
    }
    @media screen and (max-width: "1024px") {
      .content-box {
        flex-direction: column;
      }
    }
    @media screen and (max-width: "768px") {
      .content-box {
        padding: 0px 16px 32px;
        gap: 34px;
        .content-right {
          .title {
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 16px;
            text-align: center;
          }
          .description {
            font-size: 16px;
            line-height: 28px;
            text-align: center;
          }
          .button-box {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
  // about us section
  .landing-about-us {
    background: #f9f9f9;
    .about-us-top {
      max-width: 1320px;
      padding: 80px 16px;
      margin: 0 auto;
      .title {
        font-size: 32px;
        font-weight: 600;
        line-height: 48px;
        text-align: center;
        color: #070707;
        margin-bottom: 32px;
      }
      .description {
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        text-align: center;
        color: #666666;
        display: block !important;
      }
      .welcome-text {
        font-size: 22px;
        font-weight: 600;
        line-height: 28px;
        text-align: center;
        margin-top: 32px;
      }
    }
    .phone-image-box {
      margin-top: 64px;
      text-align: center;
      img {
        max-width: 875px;
        width: 100%;
        object-fit: cover;
      }
    }
    .phone-image-box-mob {
      text-align: center;
      padding: 0px 16px;
      margin-bottom: 16px;
      display: none;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
    @media screen and (max-width: "900px") {
      .about-us-top {
        padding: 32px 16px;
        .welcome-text {
          font-size: 16px;
          font-weight: 500;
          line-height: 28px;
        }
        .phone-image-box {
          display: none;
        }
        .phone-image-box-mob {
          display: block;
        }
      }
    }
  }

  // about us section
  .started-today-section {
    background-color: #ffffff;

    .content-box {
      max-width: 1288px;
      padding: 80px 0px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 90px;
      .content-left {
        max-width: 648px;
        .image-box {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 20px;
          .image-wrap {
            position: relative;
            .image-text-box {
              position: absolute;
              bottom: 16px;
              left: 16px;
              right: 16px;
              border-radius: 12px;
              background-color: #ffffff45;
              height: 56px;
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #ffffff;
              overflow: hidden;

              &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: inherit;
                filter: blur(100px); /* Adjust the blur amount as needed */
                z-index: -1;
              }
            }

            img {
              max-width: 288px;
              object-fit: contain;
              border-radius: 20px;
              z-index: 1;
            }
            .bg-1 {
              background-image: url("../img/lightbg1.png");
            }
            .bg-2 {
              background-image: url("../img/darkbg2.png");
            }
            .bg-3 {
              background-image: url("../img/darkbg3.png");
            }
            .bg-4 {
              background-image: url("../img/lightbg4.png");
            }
          }
        }
      }
      .content-right {
        flex: 1;
        max-width: 601px;
        width: 100%;
        margin-top: 32px;
        .title {
          font-size: 32px;
          font-weight: 600;
          line-height: 48px;
          text-align: left;
          color: #070707;
          margin-bottom: 32px;
        }
        .description {
          font-size: 18px;
          font-weight: 400;
          line-height: 28px;
          color: #666666;
          display: block !important;
        }
        .benefits-list {
          margin-top: 32px;
          ul {
            margin: 0;
            padding: 0;
            li {
              display: inline-flex;
              align-items: center;
              gap: 12px;
              font-size: 18px;
              font-weight: 400;
              line-height: 28px;
              text-align: left;
              margin: 0 0 24px 0;
              list-style: none;
              color: #666666;
            }
          }
        }
        .button-box {
          margin-top: 32px;
        }
      }
    }
    @media screen and (max-width: "900px") {
      .content-box {
        padding: 0px 16px 32px;
        gap: 34px;
        .content-left {
          .image-box {
            .image-wrap {
              .image-text-box {
                left: 8px;
                right: 8px;
                font-size: 10px;
                font-weight: 500;
                line-height: 15px;
                height: 31px;
              }
              img {
                width: 100%;
              }
            }
          }
        }
        .content-right {
          .title {
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 16px;
            text-align: center;
          }
          .description {
            font-size: 16px;
            line-height: 28px;
            text-align: center;
          }
          .button-box {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }

  // Promote & Elevate section ;
  .promote-section {
    background: #fff2ea;
    .content-box {
      max-width: 1288px;
      padding: 80px 0px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 90px;
      .content-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        .small-heading {
          font-size: 32px;
          font-weight: 600;
          line-height: 24px;
          color: #070707;
          text-align: left;
        }
        .title {
          margin-top: 24px;
          max-width: 377px;
          font-size: 24px;
          font-weight: 500;
          line-height: 48px;
          text-align: left;
          color: #070707;
        }
        .button-box {
          margin-top: 52px;
        }
      }
      .content-right {
        max-width: 735px;
        .mt-32 {
          margin-top: 32px;
        }
        .title {
          font-size: 20px;
          font-weight: 600;
          line-height: 30px;
          color: #070707;
          margin-bottom: 12px;
        }
        .description {
          font-size: 18px;
          font-weight: 400;
          line-height: 29px;
          color: #666666;
        }
        .button-box {
          display: none;
        }
      }
    }
    @media screen and (max-width: "900px") {
      .content-box {
        padding: 0px 16px 32px;
        gap: 0px;
        .content-left {
          padding: 32px 12px;
          .small-heading {
            width: 100%;
            text-align: center;
          }
          .title {
            font-size: 20px;
            line-height: 32px;
            font-weight: 600;
            width: 100%;
            text-align: center;
          }
          .button-box {
            display: none;
          }
        }
        .content-right {
          .title {
            span {
              color: #070707 !important;
            }
            text-align: center;
          }
          .description {
            text-align: center;
            font-size: 16px;
          }
          .button-box {
            display: flex;
            justify-content: center;
            margin-top: 32px;
          }
        }
      }
    }
  }

  //journy section
  .Journey-section {
    background-image: url("../img/journybg.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 72px;
    padding-bottom: 92px;
    text-align: center;

    .title {
      font-size: 32px;
      font-weight: 600;
      line-height: 48px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 16px;
    }

    .sub-title {
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      text-align: center;
      color: #ffffff;
    }
    .download-btn-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 24px;
      gap: 24px;
      flex-wrap: wrap;
    }

    .comming-btn-wrap {
      width: 256px;
      height: 56px;
      flex-shrink: 0;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      background: #ee762f;
      position: relative;
      outline: none;
      border: none;
      color: var(--FitnessWhite, #fff);
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      cursor: auto;

      .coming-soon {
        position: absolute;
        bottom: -12px;
        right: 71px;
        display: inline-flex;
        padding: 4px 16px;
        align-items: flex-start;
        gap: 4px;
        border-radius: 100px;
        background: #fff;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        color: #ee762f;
      }
    }
    @media screen and (max-width: "900px") {
      padding: 32px 0px 48px 0;
      .title {
        font-size: 20px;
        line-height: 30px;
      }
      .sub-title {
        font-size: 16px;
        line-height: 28px;
        padding: 0 20px;
      }
    }
  }
  // co-founder section

  .founder-section {
    background: #f9f9f9;
    .content-box {
      max-width: 1320px;
      padding: 80px 0px;
      margin: 0 auto;
      .title {
        font-size: 32px;
        font-weight: 700;
        line-height: 72px;
        text-align: center;
        color: #070707;
        margin-bottom: 32px;
      }

      .founder-top {
        display: flex;
        gap: 60px;
        flex-wrap: nowrap;
      }
      .founder-bottom {
        display: flex;
        gap: 60px;
        flex-wrap: nowrap;
        margin-top: 64px;
      }
      .about-founder {
        flex: 1;
        font-size: 16px;
        font-weight: 500;
        line-height: 30px;
        text-align: left;
        color: #666666;
        max-width: 987px;
      }
      .image-box {
        flex: 0 0 auto;
        img {
          max-width: 250px;
          width: 100%;
          object-fit: cover;
        }
      }
    }
    @media (max-width: 900px) {
      .content-box {
        padding: 32px 16px;
        .title {
          font-size: 20px;
          line-height: 30px;
        }
        .founder-top {
          flex-wrap: wrap;
          gap: 16px;
        }
        .founder-bottom {
          flex-wrap: wrap;
          margin-top: 24px;
          gap: 16px;
        }
        .image-box {
          width: 100%;
          text-align: center;
        }
        .about-founder {
          text-align: center;
        }
      }
    }
  }
  // mission section
  .mission-section {
    background: #ffffff;
    .content-box {
      max-width: 1288px;
      padding: 80px 0px;
      margin: 0 auto;
      .title {
        font-size: 32px;
        font-weight: 600;
        line-height: 48px;
        text-align: center;
        color: #070707;
      }
      .sub-title {
        margin-top: 32px;
        font-size: 24px;
        font-weight: 500;
        line-height: 48px;
        text-align: center;
        color: #000000;
        position: relative;
      }
      .mission-card-list {
        display: flex;
        justify-content: center;
        gap: 32px;
        flex-wrap: wrap;
        margin-top: 48px;
        .mission-card-wrap {
          max-width: 404px;
          padding: 80px 30px 60px;
          border: 2px solid #ffac71;
          border-radius: 52px;
          .icon {
            display: flex;
            justify-content: center;
            svg {
              width: 64px;
              height: 64px;
            }
          }
          .title {
            margin-top: 40px;
            margin-bottom: 32px;
            font-size: 18px;
            font-weight: 700;
            line-height: 27px;
            text-align: center;
            color: #ee8031;
          }
          .desc {
            font-size: 14px;
            font-weight: 500;
            line-height: 30px;
            text-align: center;
            color: #666666;
            padding: 0px 6px;
          }
        }
      }
    }
    @media screen and (max-width: "900px") {
      .content-box {
        padding: 32px 16px;
        .title {
          font-size: 20px;
          line-height: 28px;
        }
        .sub-title {
          padding: 0px 32px;
          font-size: 16px;
          font-weight: 600;
          line-height: 28px;
          margin: 32px -4px 0px;
        }
        .mission-card-list {
          gap: 16px;
          .mission-card-wrap {
            border-radius: 20px;
            padding: 24px;
            .icon {
              img {
                width: 24px;
                height: 24px;
              }
            }
            .title {
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
              margin: 16px 0px;
            }
            .sub-title {
              font-size: 14px;
              font-weight: 400;
              line-height: 28px;
            }
          }
        }
      }
    }
  }
  //book event section
  .book-event-section {
    background-image: url("../img/getintouchbg.png");
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;

    .content-box {
      max-width: 1288px;
      padding: 80px 0px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      gap: 60px;
      .content-left {
        display: grid;
        place-items: center;

        .title {
          font-size: 28px;
          font-weight: 600;
          line-height: 48px;
          text-align: left;
          margin-bottom: 20px;
          color: #ffffff;
          min-width: 435px;
        }
        .sub-title {
          font-size: 24px;
          font-weight: 600;
          line-height: 48px;
          text-align: left;
          margin-bottom: 24px;
          color: #ffffff;
        }
      }
      .content-right {
        .book-event-top {
          .small-title {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            margin-bottom: 20px;
            color: #ffffff;
          }
          .title {
            font-size: 32px;
            font-weight: 600;
            line-height: 48px;
            text-align: left;
            margin-bottom: 24px;
          }
        }
        .mt-32 {
          margin-top: 32px;
        }
        .form-input-box {
          max-width: 546px;
          .name-phone-box {
            display: flex;
            gap: 24px;
            flex-wrap: wrap;
          }
          .text-base {
            border: none;
            border-bottom: 4px solid #f3f3f3;
            padding: 8px;
            outline: none;
            width: 100%;
            color: #ffffff;
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
            max-width: 546px;
            width: 100%;
          }

          .text-base:focus {
            border-bottom: 4px solid #ffffff;
            outline: 0;
          }

          .text-field {
            min-height: 90px;
            padding-top: 16px;
            position: relative;
            flex: 1;
          }

          .text-field input,
          .text-field textarea {
            display: inline-block;
            padding: 8px;
            background: transparent;
            border-radius: 0;
            min-height: 34px;
          }

          .text-field span {
            font-size: 20px;
            font-weight: 400;
            line-height: 28px;
            position: absolute;
            pointer-events: none;
            left: 0px;
            top: 30px;
            transition: 0.3s;
            color: #ffffff;
          }

          .text-field input:focus + span,
          .text-field input:not(:placeholder-shown) + span,
          .text-field textarea:focus + span,
          .text-field textarea:not(:placeholder-shown) + span {
            top: 0px;
            left: 0px;
            background-color: transparent;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            text-align: left;
          }

          .text-field input:focus:invalid + span,
          .text-field input:not(:placeholder-shown):invalid + span,
          .text-field textarea:focus:invalid + span,
          .text-field textarea:not(:placeholder-shown):invalid + span {
            color: #ffffff;
          }
        }
      }
      .button-box {
        display: flex;
        justify-content: space-between;

        flex-wrap: wrap;
        align-items: center;
        gap: 24px;
        .agree-text {
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          text-align: left;
          color: #ffffff;
          max-width: 316px;
          a {
            text-decoration: underline !important;
            color: #ffffff;
          }
        }
      }
    }

    .custom-input {
      border: none;
      border-bottom: 4px solid #f3f3f3;
      padding: 8px;
      outline: none;
      width: 100%;
      color: #ffffff;
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      max-width: 546px;
      width: 100%;
      &:focus {
        color: #ffffff;
      }
      &::placeholder {
        color: #ffffff;
      }
    }

    @media screen and (max-width: "768px") {
      .content-box {
        flex-wrap: wrap;
        padding: 32px 16px;
        gap: 28px;
        .content-left {
          width: 100%;
          .title {
            font-size: 20px;
            font-weight: 600;
            line-height: 48px;
            text-align: center;
            min-width: 0;
            width: 100%;
            margin-bottom: 0px;
          }
          .sub-title {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: center;
            margin-bottom: 0px;
            width: 100%;
          }
        }
        .content-right {
          .book-event-top {
            .small-title {
              text-align: center;
            }
            .title {
              font-size: 20px;
              line-height: 30px;
              text-align: center;
              padding: 0 16px 24px !important;
              margin-bottom: 0px;
            }
          }
        }
        .name-phone-box {
          flex-direction: column;
          gap: 0px !important;
        }
      }
      .button-box {
        justify-content: center !important;
      }
    }
  }
}
.join-fitness-popup-wrap {
  width: 100% !important;
  max-width: 668px !important;
  background-color: #ffffff;
  border-radius: 52px !important;
  padding: 0px !important;
  min-height: fit-content !important;

  .modal-content {
    border: none !important;
    .form-box {
      display: flex;
      flex-direction: column;
      margin: 40px;
      gap: 32px;
    }
    .orange-btn {
      padding: 12px 16px;
      border-radius: 100px;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
      outline: none;
      border: none;
      background: #ee7830;
      color: #ffffff;
      cursor: pointer;
      // &:focus,
      // &:active {
      //   background: #be652f;
      // }
      &:hover {
        background: #be652f;
      }
    }
    .do-later {
      font-size: 16px;
      font-weight: 600;
      line-height: 32px;
      text-align: center;
      cursor: pointer;
      color: #666666;
    }
  }
  .title {
    font-size: 44px;
    font-weight: 800;
    line-height: 66px;
    text-align: center;
    color: #000000;
  }
  .sub-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    color: #666666;
  }

  .input_text_footer {
    border: 1px solid #8f939a;
    border-radius: 5px;
    padding: 11.5px 13px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #25272d;
    width: 100%;
    resize: none;
    min-height: auto;

    &::placeholder {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      color: #8f939a;
    }
  }

  .input_error {
    margin-top: 8px;
    margin-left: 5px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: red;
  }
  @media screen and (max-width: 768px) {
    .modal-content {
      .title {
        font-size: 24px;
        font-weight: 800;
        line-height: 36px;
      }
      .sub-title {
        font-size: 14px;
        font-weight: 800;
        line-height: 26px;
      }
      .form-box {
        margin: 24px;
      }
      .orange-btn {
        font-size: 12px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
      }
    }
  }
}

.book-event-popup-wrap {
  width: 100%;
  box-sizing: border-box;
  padding: 0px;
  max-width: 1300px !important;
  background: #141414;

  @media screen and (max-width: 600px) {
    padding: 0px !important;
    margin: 16px;
  }

  .modal-content {
    background-image: url("../img/getintouchbg.png");
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    border-radius: 0;
    border: 0;
    padding: 0px 40px;

    @media screen and (max-width: 600px) {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    .modal-body {
      padding: 0;
      position: relative;
      .content-box {
        padding: 40px 0px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        gap: 90px;
        .content-left {
          display: grid;
          place-items: center;
          .title {
            font-size: 28px;
            font-weight: 600;
            line-height: 48px;
            text-align: left;
            margin-bottom: 20px;
            color: #ffffff;
            min-width: 435px;
          }
          .sub-title {
            font-size: 24px;
            font-weight: 600;
            line-height: 48px;
            text-align: left;
            margin-bottom: 24px;
            color: #ffffff;
          }
        }
        .content-right {
          .book-event-top {
            .small-title {
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              text-align: left;
              margin-bottom: 20px;
              color: #ffffff;
            }
            .title {
              font-size: 28px;
              font-weight: 600;
              line-height: 42px;
              text-align: left;
              margin-bottom: 24px;
            }
          }
          .mt-32 {
            margin-top: 32px;
          }
          .form-input-box {
            max-width: 546px;
            .name-phone-box {
              display: flex;
              gap: 24px;
              flex-wrap: wrap;
            }
            .text-base {
              border: none;
              border-bottom: 4px solid #f3f3f3;
              padding: 8px;
              outline: none;
              width: 100%;
              color: #ffffff;
              font-size: 20px;
              font-weight: 600;
              line-height: 28px;
              max-width: 546px;
              width: 100%;
            }

            .text-base:focus {
              border-bottom: 4px solid #ffffff;
              outline: 0;
            }

            .text-field {
              min-height: 90px;
              padding-top: 16px;
              position: relative;
              flex: 1;
            }

            .text-field input,
            .text-field textarea {
              display: inline-block;
              padding: 8px;
              background: transparent;
              border-radius: 0px;
              min-height: 34px;
            }

            .text-field span {
              font-size: 20px;
              font-weight: 400;
              line-height: 28px;
              position: absolute;
              pointer-events: none;
              left: 0px;
              top: 30px;
              transition: 0.3s;
              color: #ffffff;
            }

            .text-field input:focus + span,
            .text-field input:not(:placeholder-shown) + span,
            .text-field textarea:focus + span,
            .text-field textarea:not(:placeholder-shown) + span {
              top: 0px;
              left: 0px;
              background-color: transparent;
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
              text-align: left;
            }

            .text-field input:focus:invalid + span,
            .text-field input:not(:placeholder-shown):invalid + span,
            .text-field textarea:focus:invalid + span,
            .text-field textarea:not(:placeholder-shown):invalid + span {
              color: #ffffff;
            }
          }
        }
        .button-box {
          display: flex;
          justify-content: space-between;

          flex-wrap: wrap;
          align-items: center;
          gap: 24px;
          .agree-text {
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            text-align: left;
            color: #ffffff;
            max-width: 316px;
            a {
              text-decoration: underline !important;
              color: #ffffff;
            }
          }
        }
      }
    }

    .orange-span {
      color: #ee762f;
    }
    .orange-btn {
      padding: 12px 16px;
      border-radius: 100px;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      text-align: center;
      outline: none;
      border: none;
      width: 293px;
      background: #ee7830;
      color: #ffffff;
      cursor: pointer;
      // &:focus,
      // &:active {
      //   background: #be652f;
      // }
      &:hover {
        background: #be652f;
      }
    }

    .close_container {
      cursor: pointer;
      position: absolute;
      right: -15px;
      top: 10px;
      @media screen and (max-width: 600px) {
        // display: none;
        right: 0px;
        top: 0px;
      }
    }

    @media screen and (max-width: "900px") {
      padding: 16px !important;
      .modal-body {
        .content-box {
          padding: 40px 0 0;
          flex-wrap: wrap;
          gap: 24px;
          overflow-x: hidden;
          .content-left {
            display: none;
            // width: 100%;
            // .title {
            //   font-size: 20px;
            //   font-weight: 600;
            //   line-height: 48px;
            //   min-width: 0px;
            //   text-align: center;
            //   margin-bottom: 0px;
            // }

            // .sub-title {
            //   font-size: 16px;
            //   font-weight: 500;
            //   line-height: 24px;
            //   text-align: center;
            //   margin-bottom: 0px;
            //   width: 100%;
            // }
          }
          .content-right {
            .book-event-top {
              .small-title {
                text-align: center;
              }
              .title {
                font-size: 20px;
                line-height: 30px;
                text-align: center;
                padding: 0 16px 24px;
                margin-bottom: 0px;
              }
            }
          }
          .name-phone-box {
            flex-direction: column;
            gap: 0px !important;
          }
        }
        .button-box {
          justify-content: center !important;
        }
      }
    }
  }
}
.landing-footer {
  background-color: #25272d;
  a {
    text-decoration: none;
    color: inherit;
  }
  .footer-container {
    max-width: 1290px;
    margin: auto auto;
    .footer-first-part {
      padding-top: 60px;
      padding-bottom: 60px;
      color: #ffffff;
      .links-box {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        padding-left: 16px;
        padding-right: 16px;
        ul {
          margin-top: 22px;
          li {
            margin-bottom: 16px;
            width: -moz-fit-content;
            width: fit-content;
            cursor: pointer;
            &:hover {
              color: #ff7800;
            }
          }
        }
      }
      .locate-us .locate-item-container {
        margin-top: 22px;
        margin-bottom: 22px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        .locate-item {
          display: flex;
          gap: 10px;
          align-items: center;
          &:hover {
            color: #ff7800;
          }
        }
        p {
          margin-bottom: 0px;
          word-wrap: break-word;
          word-break: break-all;
          cursor: pointer;
        }
      }

      .follow-us .follow-icon-container {
        display: flex;
        gap: 24px;
        margin-top: 20px;
        margin-bottom: 20px;
        .img-container {
          cursor: pointer;
          width: 20px;
          height: 20px;
        }

        .img-container img {
          width: 100%;
        }
        .img-container img:nth-child(2) {
          display: none;
        }
        .img-container:hover img:nth-child(2) {
          display: block;
        }
        .img-container:hover img:nth-child(1) {
          display: none;
        }
      }
      .download-image {
        position: relative;
        .coming-soon {
          position: absolute;
          bottom: -12px;
          right: 21px;
          display: inline-flex;
          padding: 2px 8px;
          align-items: flex-start;
          gap: 4px;
          border-radius: 100px;
          background: #25272d;
          text-align: center;
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          color: #ffffff;
          border: 1px solid #f4f4f4;
        }
      }
      @media screen and (max-width: "900px") {
        padding-bottom: 24px;
      }
    }
    .footer-second-part {
      border-top: 1px solid #f3f3f3;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 14px;
      color: #ffffff;
      padding-top: 20px;
      padding-bottom: 28px;
      padding-left: 16px;
      padding-right: 16px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap-reverse;
      .footer-terms {
        display: flex;
        gap: 16px;
      }
      @media screen and (max-width: "900px") {
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 0.3px;
        text-align: left;
        border: none;
        .footer-terms {
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
          text-align: right;
        }
      }
    }
    .follow-store-container {
      display: flex;
      gap: 10px;
    }
  }
}
@media screen and (max-width: "940px") {
  .modal-dialog-centered {
    min-height: calc(100vh - 40px);
  }
}
