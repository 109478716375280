/* autoprefixer: off */
.logo_nav {
  border-bottom: 1px solid rgba(143, 147, 154, 0.16);
  padding: 1.2rem;
  padding-left: 1rem;
}

.mobileLinks{
  display: none !important;
  @media screen and (max-width: "768px") {
    display: block !important;
  }
}

.borderBottomGrey{
  border-bottom: 1px solid #F3F3F3;
}

@media screen and (max-width: "576px") {
  .logo_nav {
    padding-left: 1rem;

    img {
      padding-left: 0rem;
    }
  }
}

.login {
  height: 100vh;

  @media screen and (max-width: "600px") {
    height: calc(100vh - 72px);
  }

  .col1 {
    text-align: center;
    position: relative;

    .img-slider {
      height: 100vh;

      .slick-list {
        height: 100%;
      }

      .slider-item {
        height: 100vh;

        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(to top,
              rgba(0, 0, 0, 0) 0%,
              rgb(0, 0, 0) 100%);
          opacity: 0.2;
        }

        img {
          width: 100%;
          min-height: 807px;
          height: 100vh;
        }
      }
    }

    .txtb {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      padding: 100px 0px;

      .guest-button {
        width: fit-content;
        margin: 0px auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .slider-box {
          position: relative;
          justify-content: center;
          display: flex;
          padding-bottom: 20px;
          height: 100%;

          .slider-bg {
            width: 129px;
            height: 5px;
            border-radius: 10px;
            background-color: white;
            opacity: 0.5;
            position: relative;
            top: 0;
            overflow: hidden;
          }

          .slider {
            width: 43px;
            height: 5px;
            border-radius: 10px;
            cursor: pointer;
            position: absolute;
            transition: transform 0.3s ease;
            top: 0;
            left: 0px;

            &.active {
              background-color: white;
              opacity: 1;
            }
          }
        }
      }
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #25272d;
      padding: 30px 60px 0;
    }
  }

  .col2 {
    display: flex;

    flex-direction: column;

    padding: 20px 9%;

    h1 {
      color: var(--SteelBlue, #25272d);
      font-size: clamp(16px, 2.5vw, 44px);
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 0px;
    }

    h5 {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding-bottom: 12px;
      color: #25272d;
    }

    p {
      font-weight: 400;
      font-size: clamp(12px, 1.5vw, 20px);
      line-height: 32px;
      // padding-top: 8px;
      padding-bottom: 16px;
      color: #8f939a;
      font-style: normal;
      margin: 0;
    }

    .forgot_password_wrap{
      display: flex;
      width: 100%;
      align-items: center;
    }

    .forgot_password {
      display: inline-block;
      margin: 0 auto;
      margin-bottom: 24px;
      margin-top: 10px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-decoration: none;
      color: #25272d;
      text-align: center;
      font-style: normal;
      width: fit-content;
      &:hover{
        text-decoration: underline;
      }
    }

    .continue_container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      margin: 22px 0;
      margin-bottom: 10px;

      .line {
        height: 2px;
        background-image: linear-gradient(to right, #f2f2f2, #8f939a00);

        width: 50%;
      }

      .continue_with {
        display: flex;
        align-items: center;
        text-align: center;

        font-weight: 500;
        font-size: 12px;
        line-height: 24px;
        color: #8f939a;
        width: fit-content;
        flex-shrink: 0;
      }
    }

    .other_otions {
      display: flex;
      list-style: none;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 15px;
      margin-bottom: 2px;

      li {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 0px 32px 0px 12px;
        border: 1px solid #f6f6f7;
        border-radius: 12px;
      }
    }

    .no_account {
      padding: 8px 0 8px;
      text-align: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #25272d;

      a {
        background: linear-gradient(167.07deg,
            #ee7830 -15.49%,
            #ee762f 149.41%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }

    .policies {
      text-align: center;
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      color: #8f939a;
    }

    .backtologin {
      margin-top: 25px;
      background: transparent;
      color: #25272d;
      display: inline-block;
      width: fit-content;
      margin: 25px auto 0;
    }

    @media screen and (max-width: "768px") {
      h1 {
        color: var(--SteelBlue, #25272d);
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      p {
        color: var(--SecondarySteel, #8f939a);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.3125rem;
      }

      .no_account {
        color: var(--SteelBlue, #25272d);
        text-align: center;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        a {
          background: var(--Orange-Gradient,
              linear-gradient(167deg, #ee7830 -15.49%, #ee762f 149.41%));
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      .other_otions {
        display: flex;
        list-style: none;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 15px;
        flex-direction: row;
        padding: 0px;

        li {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0px;
          border: 0px;
          padding: 0px;
          margin: 0px;
        }
      }
    }

    @media screen and (max-width: "768px") {
      .other_otions {
        li {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0px;
          border: 0px;
          padding: 0px;
          margin: 0px;
        }
      }
    }
  }

  @media screen and (max-width: "768px") {
    .col2 {
      padding: 1.2rem;
      margin-top: 0rem;
    }
  }
}

.select_user_title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 12px;
  color: #25272d;
}

.select_user_wrapper {
  display: flex;
  gap: 30px;
  margin-top: 25px;

  .all-inputs-radio-button {
    position: relative;
    flex: 1 1;

    img {
      position: absolute;
      z-index: 9;
      left: 50%;
      transform: translateX(-50%);
      top: -14px;
    }
  }

  .form_group {
    flex: 1;

    .input_box {
      background: #ffffff;
      border: 1px solid rgba(143, 147, 154, 0.12);
      border-radius: 12px;
      padding: 36px 25px 30px;
      text-align: center;
      height: 100%;
      cursor: pointer;
      position: relative;

      input {
        position: absolute;
        width: 100%;
        height: calc(100% + 25px);
        top: -25px;
        left: 0;
        opacity: 0;
        z-index: 99;
      }

      input[type="radio"] {
        cursor: pointer;
      }

      &::before {
        content: "";
        position: absolute;
        z-index: 0;
        border: 1px solid rgba(143, 147, 154, 0.12);
        border-bottom: 1px solid #fff;
        top: -30px;
        left: 50%;
        transform: translateX(-50%);
        width: 56px;
        height: 28px;
        border-radius: 10rem 10rem 0 0;
      }

      &::after {
        content: "";
        position: absolute;
        top: 36px;
        right: 24px;
        background-image: url("../img/checkmark-circle.svg");
        width: 24px;
        height: 24px;
        display: none;
      }

      .input_label {
        position: initial;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
      }

      .extramsg {
        font-weight: 400;
        padding-top: 12px;
        padding-bottom: 0px;
        color: #8f939a;
        font-size: 10px;
        line-height: 16px;
        min-height: 60px;
      }
    }

    &.active {
      .input_box {
        background: linear-gradient(167.07deg,
            rgba(238, 120, 48, 0.12) -15.49%,
            rgba(238, 118, 47, 0.12) 149.41%);
        border-color: #ee7831;

        &:before {
          background: linear-gradient(167.07deg,
              rgba(238, 120, 48, 0.12) -15.49%,
              rgba(238, 118, 47, 0.12) 149.41%);
          border-color: #ee7831;
          border-bottom-color: #fcefe6;
        }

        &::after {
          display: inherit;
        }
      }
    }
  }
}

@media screen and (max-width: "476px") {
  .select_user_wrapper {
    gap: 10px;

    .form_group {
      height: 90%;
    }
  }
}

.policy-wrapper {
  .tandp-options {
    margin-left: -16px;
    margin-right: -16px;

    .options-title {
      font-family: Montserrat;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      .active-title {
        background: var(--Orange-Gradient,
            linear-gradient(167deg, #ee7830 -15.49%, #ee762f 149.41%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .active-or-line {
        height: 2px;
        background: var(--Orange-Gradient,
            linear-gradient(167deg, #ee7830 -15.49%, #ee762f 149.41%));
      }
    }
  }
}

.all-collapse-setup-with-button {
  display: grid;
  align-content: space-between;
  padding-bottom: 40px;
}

.collapse-policy {
  overflow: auto;
  margin-right: -20px;
  padding-right: 20px;
}

.collapse-par {
  position: sticky;
  bottom: 0;
  background: #fff;
  padding: 10px 0 20px 0px;

  .collapse-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    cursor: pointer;

    .collapse-header-left {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      display: flex;
      align-items: center;
      gap: 12px;
      color: #25272d;

      img.active {
        display: none;
      }
    }

    @media screen and (max-width: "768px") {
      .collapse-header-left {
        color: var(--SteelBlue, #25272d);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    img.nagive {
      display: none;
    }
  }

  .collapse-bottom {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #25272d;
    text-align: left;
    max-height: 0;
    overflow: hidden;
  }

  &.active {
    position: initial;

    .collapse-header {
      .collapse-header-left {
        background: linear-gradient(167.07deg,
            #ee7830 -15.49%,
            #ee762f 149.41%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        img.active {
          display: inherit;
        }

        img.defalt {
          display: none;
        }
      }

      img.nagive {
        display: inherit;
      }

      img.plus {
        display: none;
      }
    }

    .collapse-bottom {
      max-height: fit-content;
    }
  }
}

.profile-managment {
  padding: 16px 55px 55px;
  background: #f6f6f6;
}

@media screen and (max-width: "768px") {
  .profile-managment {
    padding: 0;
    background: #f6f6f6;
  }
}

.profile-managment-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 55px;
}

.main-section {
  display: flex;
  gap: 14px;
  align-items: stretch;
  font-style: normal;
  // padding-bottom: 24px;
  // height: 80px;
  // align-items: center;
  // padding-bottom: 24px;

  .all-main-section-left {
    display: grid;
    margin-left: 56px;

    @media screen and (max-width: "1400px") {
      margin-left: 0px;
    }

    .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #8f939a;
      padding: 32px 0 8px 30px;
    }
  }

  .main-section-left {
    background-color: #ffffff;
    border-radius: 12px;
    width: 428px;
    padding: 24px;
  }

  .main-section-right {
    background-color: #ffffff;
    border-radius: 12px;
    width: 100%;
    max-width: 875px;
    padding: 24px;
    margin-bottom: 0;
    min-height: 600px;

    &.training_experience {
      padding: 0;
      padding-top: 6px;
    }

    &.password-management {
      padding: 36px 32px;

      .all-resetpassword {
        margin-bottom: 60px;
      }
    }
  }
}

.main-section-people {
  display: flex;
  gap: 16px;
  align-items: stretch;
  font-style: normal;
  height: 80px;
  align-items: center;
  padding: 10px 15px 10px 15px;
}

.main-section-left-top {
  padding-bottom: 36px;

  .dl-email-verified {
    margin-top: 36px;
    padding: 12px;
    background-color: #eb5757;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    gap: 12px;

    .dl-title {
      color: #fff;

      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }

    .dl-subtitle {
      color: #fff;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      text-decoration-line: underline;
    }
  }

  .dl-email-verified.active {
    background-color: #40b67d;
  }

  .main-section-left-top-left {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }

  .main-section-left-top-extra {
    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: 16px;
  }

  .content-part {
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #8f939a;
    }

    h6 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 28px;
      color: #25272d;
      white-space: nowrap;
      width: 170px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .logout-desing {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      cursor: pointer;

      &:nth-child(2) {
        display: none;
      }
    }

    &:hover {
      img {
        &:nth-child(2) {
          display: inherit;
        }

        &:nth-child(1) {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: "768px") {
  .main-section {
    padding: 0;
    margin: 0;
    display: flex;
    // justify-content: center;
    align-items: center;
    margin: auto;

    .all-main-section-left {
      margin: 0;
      padding: 0;
      width: 100%;
      max-width: 100%;

      .main-section-left {
        width: 100%;
        max-width: 768px;
        margin: 0;
        padding: 0;
      }

      .content-part {
        p {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #8f939a;
        }

        h6 {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 28px;
          width: fit-content;
          color: #25272d;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .main-section-left-top {
    padding: 22px;

    .main-section-left-top-extra {
      justify-content: space-between;
      gap: 8px;
    }

    .dl-email-verified {
      .dl-title {
        font-size: 12px;
      }

      .dl-subtitle {
        font-size: 10px;
      }
    }

    // .logout-desing {
    //   display: none;
    // }
  }
}

.main-section-left-mid {
  display: flex;
  justify-content: center;
  gap: 87px;
  border-bottom: 2px solid rgba(243, 243, 243, 1);
  padding-bottom: 18px;
  margin-bottom: 20px;

  .main-section-card {
    width: 100%;
    border: 1px solid #8f939a;
    background: #f3f3f3;
    border-radius: 12px;
    padding: 19px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    .earning_text {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #25272d;

      span {
        font-size: 16px;
        color: #ee7830;
        margin-left: 8px;
        font-weight: 600;
        line-height: 28px;
      }
    }
  }
}

@media screen and (max-width: "768px") {
  .main-section-left-mid {
    margin: 18px 24px;
  }
}

.main-section-left-bottom {
  .first-menu {
    ul {
      list-style: none;
      padding-left: 0;
      margin: 0;

      li {
        position: relative;

        a,
        p {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          gap: 12px;
          color: #25272d;
          border-radius: 12px;
          padding: 20px 24px;
          text-decoration: none;
          position: relative;
          overflow: hidden;
          cursor: pointer;

          img {
            &:nth-child(2) {
              display: none;
            }
          }

          &.active {
            background: linear-gradient(167.07deg,
                rgba(238, 120, 48, 0.05) -15.49%,
                rgba(238, 118, 47, 0.05) 149.41%);
            color: #ee762f;

            img {
              &:nth-child(2) {
                display: inherit;
              }

              &:nth-child(1) {
                display: none;
              }
            }

            &:after {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              width: 12px;
              height: 100%;
              background: linear-gradient(167.07deg,
                  #ee7830 -15.49%,
                  #ee762f 149.41%);
            }
          }
        }

        .unlink_box {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          gap: 12px;
          color: #25272d;
          border-radius: 12px;
          padding: 22px 24px;
          text-decoration: none;
          position: relative;
          overflow: hidden;
          color: #8f939a;
          justify-content: space-between;

          div {
            display: flex;
            gap: 15px;
            align-items: center;
          }

          span {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            gap: 10px;
            display: flex;
            align-items: center;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media screen and (max-width: "768px") {
  .main-section-left-bottom {
    .first-menu {
      ul {
        li {

          a,
          p {
            color: var(--SteelBlue, #25272d);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
          }
        }
      }
    }
  }
}

.profile-managment-right {
  .profile_box {
    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      color: #25272d;
      margin-bottom: 36px;
    }
  }

  @media screen and (max-width: "768px") {
    .profile_box {
      .title {
        font-weight: 600;
        font-size: 20px;
        line-height: normal;
        color: #25272d;
        padding-bottom: 12px;
        position: relative;

        .back_arrow {
          position: absolute;
          left: 0px;
          top: 40%;
          transform: translateY(-50%);
          cursor: pointer;
          width: 32px;
          height: 32px;
        }
      }
    }
  }

  &.inner-policy-pages-setup {
    padding: 0;

    .manage_profile {
      padding-top: 0px !important;

      .margin-top-adjust {
        margin-top: 0px !important;
      }

      &.container {
        padding: 0;

        &>a {
          display: none;
        }

        .profile_box {
          .title {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .remove_image {
    padding-bottom: 0;

    .text {
      margin-top: 0;
    }
  }
}

.image-upload {
  text-align: center;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 36px;
  cursor: pointer;
  display: grid;

  label {
    cursor: pointer;
  }

  .profile-pic {
    width: 155px;
    height: 155px;
    border-radius: 100%;
    margin-bottom: 8px;
    border: 7px solid #fff;
    filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.12));
    object-fit: cover;

    &.video {
      overflow: hidden;
      position: relative;

      .videoicon {
        position: absolute;

        left: 50%;
        transform: translate(-50%, -50%);
        top: 50%;
      }

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  p {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    background: linear-gradient(167.07deg, #ee7830 -15.49%, #ee762f 149.41%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}

@media screen and (max-width: "576px") {
  .image-upload {
    .profile-pic {
      width: 115px;
      height: 115px;
    }
  }
}

.main-section-form-button {
  display: grid;

  margin-top: 30px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #25272dcc;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  z-index: 100;

  &.show {
    display: flex;
  }

  .modal-section {
    width: 100%;
    max-width: 619px;
    background: #ffffff;
    border-radius: 12px;
    padding: 46px 32px 36px;
    display: grid;
    gap: 100px;

    &.log_out_modal {
      max-width: 450px;
      gap: 20px;
    }

    .modal-top-left {}

    .modal-top-part {
      text-align: center;

      button {
        background: transparent;
        padding: 0;
        text-align: right;
        border: 0;
        width: 100%;

        img {
          float: right;
          cursor: pointer;
        }
      }

      h5 {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: #25272d;
        margin-bottom: 12px;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #8f939a;
        margin-bottom: 36px;
      }
    }

    &.v-modal {
      padding: 40px 0px;
      gap: 60px;

      .modal-top-part {
        max-height: 400px;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 5px;
        }

        h4 {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: #25272d;
          padding-bottom: 24px;
          padding-left: 20px;
          padding-right: 20px;
          border-bottom: 1px solid #edeeef;
          margin-bottom: 0px;
        }

        ul {
          margin-bottom: 0px;

          &.mytabs {
            display: flex;
            gap: 37px;
            align-items: center;
            padding-bottom: 36px;
            justify-content: center;

            li {
              display: flex;
              gap: 4px;
              align-items: center;
              justify-content: center;
              padding: 0 10px;
              padding-top: 18px;
              position: relative;
              cursor: pointer;

              &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 4px;
                background: transparent;
                border-radius: 12px 12px 0px 0px;
                transform: rotate(180deg);
              }

              &.active {
                font-weight: 600;

                &::after {
                  background: linear-gradient(167.07deg,
                      #ee7830 -15.49%,
                      #ee762f 149.41%);
                }
              }
            }
          }
        }

        .all-mid-part {
          h5 {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #25272d;
            text-align: left;

            margin-bottom: 12px;
          }

          .week_days {
            .text-danger {
              position: absolute;
              bottom: 5px;
            }
          }

          .weeklayset {
            display: flex;
            gap: 8px;
            list-style: none;
            padding-bottom: 24px;
            padding-left: 0;

            li {
              flex: 1;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              color: #25272d;
              padding: 10px 20px;
              border: 1px solid #DCDCDC;
              border-radius: 8px;
              cursor: pointer;

              &.active {
                background: linear-gradient(167.07deg,
                    rgba(238, 120, 48, 0.12) -15.49%,
                    rgba(238, 118, 47, 0.12) 149.41%),
                  #ffffff;
                color: #ee762f;
                border: 1px solid #ee7831;
              }
            }
          }

          .all-radio-button_with-check {
            margin-bottom: 24px;
          }
        }
      }

      .all-button {
        padding: 0 20px;

        .all-button-top {
          display: flex;
          gap: 15px;

          .first-parts {
            max-width: 200px;
            width: 100%;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #25272d;
            justify-content: center;
            background: transparent;
            border: 0;
            flex: 1.2;
          }

          .common_btn {
            width: 100%;
            flex: 2;
          }
        }

        p {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          color: #8f939a;
          margin-top: 32px;
          margin-bottom: 0px;
        }
      }
    }
  }
}

@media screen and (max-width: "768px") {
  .modal {
    .modal-section {
      margin: 1rem;
      display: block;

      &.v-modal {
        padding: 1rem;
        margin: 0;
      }

      .modal-top-part {
        h5 {
          color: var(--SteelBlue, #25272d);
          text-align: center;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        p {
          color: var(--SecondarySteel, #8f939a);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
        }

        ul {
          &.mytabs {
            padding-left: 0px;
          }
        }
      }
    }
  }
}

.two-row-input {
  display: flex;
  gap: 20px;

  .form_group {
    width: 100%;
    margin-bottom: 14px;

    &.flex-1 {
      flex: 1;
    }

    &.flex-3 {
      flex: 3;
    }
  }
}

.main-section-training-experience {
  column-count: 2;
  row-gap: 18px;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -9px;

  .grid-setup {
    flex: 0 0 auto;
    width: 50%;
    float: left;
    padding: 0 9px;
    display: grid;

    img {
      width: 100%;
      height: 265px;
      object-fit: cover;
    }

    .card-body {
      border: 1px solid #e0e0e0;
      border-radius: 0px 0px 12px 12px;
      padding: 8px 12px 24px;

      h5 {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 125%;
        color: #25272d;
        margin-bottom: 8px;
      }

      p.optional {
        text-align: right;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        color: #8f939a;
      }
    }
  }
}

.input-number {
  position: relative;
  margin-bottom: 8px;

  input {
    background: #f8f8f8;
    /* FitnessNonSelected */

    border: 1px solid #f3f3f3;
    border-radius: 12px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 8px;
    width: 100%;
    color: #25272d;

    &:disabled {
      background: #fafafa;
    }
  }

  p.uppert {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #25272d;
  }
}

.input-file-upload {
  position: relative;
  width: 100%;
  margin-bottom: 36px;

  input {
    display: none;
  }

  label {
    border: 2px dashed #bdbdbd;
    border-radius: 12px;
    background: #fafafa;
    display: grid;
    width: 100%;
    margin: 0;
    text-align: center;
    padding: 44px 15px;

    img {
      margin: 0 auto;
      margin-bottom: 16px;
    }

    h5 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
      color: #25272d;
      margin-bottom: 4px;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 125%;
      color: #8f939a;
    }
  }
}

.uploaded-files-list {
  display: grid;
  gap: 24px;

  .uploaded-files-all-right-cont {
    width: 100%;
  }

  .uploaded-files {
    display: flex;
    align-items: center;
    gap: 8px;
    border: 1px solid #ee762f;
    padding: 16px;
    border-radius: 12px;

    img.file-img {
      background: #f2f2f2;
      padding: 13px;
      border-radius: 50%;
    }

    .uploaded-files-top {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      align-items: center;
      margin-bottom: 6px;

      h5 {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
        gap: 12px;
        color: #25272d;

        span {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
          color: #8f939a;
        }
      }

      img.delete-icon {
        cursor: pointer;
      }
    }

    .uploaded-files-bottom {
      display: flex;
      gap: 8px;
      justify-content: space-between;
      align-items: center;

      hr {
        width: 100%;
        height: 8px;
        background: linear-gradient(167.07deg,
            #ee7830 -15.49%,
            #ee762f 149.41%);
        border-radius: 5px;
        border: 0;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #25272d;
      }
    }
  }
}

.main-section-training-checkbox {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.input-ceckbox {
  position: relative;

  input {
    display: none;

    &+label {
      border: 1px solid #e0e0e0;
      border-radius: 12px;
      display: block;

      img {
        width: 100%;
        max-width: 174px;
      }

      .heading {
        padding: 8px 12px;
        padding-top: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 125%;
        color: #25272d;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: 11px;
          background-image: url("../img/checkmark-circle-gray.svg");
          height: 24px;
          width: 24px;
        }
      }
    }

    &:checked+label {
      background: linear-gradient(167.07deg,
          rgba(238, 120, 48, 0.08) -15.49%,
          rgba(238, 118, 47, 0.08) 149.41%);
      border-color: #ee7830;

      .heading {
        color: #ee762f;

        &::after {
          background-image: url("../img/checkmark-circle.svg");
        }
      }
    }
  }
}

.disabled {
  background: #d9d9d9;
  color: #ffffff;
  pointer-events: none;
}

.form_group {
  &.textarea-label {
    .input_box {
      span.input_label {
        line-height: 30px;
        top: 0px;
        width: max-content;
        left: 1px;
        top: 1px;
        text-align: left;
        padding-left: 12px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background: #fff;
      }
    }

    img {
      top: 30px;
    }
  }

  &.with-button {
    position: relative;
    display: flex;
    gap: 8px;

    .input_box {
      width: 100%;

      .corss-icon {
        position: absolute;
        top: 20px;
        right: 18px;
        z-index: 5;
        cursor: pointer;
      }
    }

    button {
      background: linear-gradient(167.07deg, #ee7830 -15.49%, #ee762f 149.41%);
      border-radius: 12px;
      padding: 16px;
      border: 0;
      display: grid;
    }
  }
}

.loder-part-scro {
  min-height: 630px;

  &.traning-expere {
    min-height: 900px;
  }
}

.mt-36 {
  margin-top: 36px;
}

.mb-36 {
  margin-bottom: 36px;
}

.csv-modal-wrap {
  position: relative;

  .notnow {
    background: #f3f3f3 !important;
    width: 100%;
    color: var(--SteelBlue, #25272d);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .close-btn-csv {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 1px solid #f3f3f3;
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.account-info-box {
  display: flex;
  align-items: center;
  gap: 8px;

  .upgrade-member-link {
    padding-top: 4px;
    font-size: 10px;
    font-weight: 600;

    a {
      color: #ee7830;
    }
  }
}

.basic_btn {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: right;
  color: #ee7830;
  border: 0;
  padding: 6px 12px;
  background: transparent;
  display: inline-flex;
  gap: 4px;
  align-items: center;

  &:hover {
    background: #ee79301f;
    border-radius: 6px;

    // color: #EE7830;
  }
}

.booking_details_wrapper {
  min-height: calc(100vh - 1.5rem);

  .booking_details_container {
    margin-left: auto;
    margin-right: auto;
    border-radius: 12px;
    background: rgba(255, 255, 255, 1);
    max-width: 1112px;
    padding: 60px 94px;
    width: 100%;
    margin-bottom: 70px;

    @media (max-width: 1112px) {
      border-radius: 0;
      max-width: 100%;
      // padding: 6px 40px;
    }

    @media (max-width: 992px) {
      padding: 60px 40px;
    }

    @media (max-width: 767px) {
      padding: 24px;
      margin-bottom: 0px;
    }
  }

  .booking_header {
    @media (max-width: 767px) {
      border-color: #8f939a;
      border-bottom: 1px;
    }
  }

  .booking_header_text {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
  }

  .details_container {
    margin-top: 50px;
    padding-left: 60px;

    @media (max-width: 767px) {
      margin-top: 32px;
      padding-left: 0px;
    }

    .sub_heading_container {
      font-size: 28px;
      font-weight: 700;
      line-height: 28px;
      text-align: left;
      color: #25272d;
      display: inline;

      @media screen and (max-width: 767px) {
        font-size: 20px;
        line-height: 28px;
      }
    }

    .container_session {
      margin-left: 12px;
      width: fit-content;
      font-size: 14px;
      font-weight: 500;
      line-height: 26px;
      text-align: right;
      color: #ee7830;
      display: inline-flex;
      align-items: center;
      gap: 4px;
      padding: 4px 8px;
      border-radius: 8px;
      background: #f3f3f3;
    }

    .details_description {
      padding-right: 60px;
      // margin-top: 20px;

      @media (max-width: 767px) {
        padding-right: 0px;
      }

      .date_container {
        font-size: 24px;
        font-weight: 600;
        line-height: 28px;
        text-align: left;
        color: #25272d;
        display: flex;
        align-items: center;
        gap: 72px;

        @media (max-width: 767px) {
          gap: 8px;
          flex-direction: column;
          align-items: flex-start;
          font-size: 14.41px;
          font-weight: 600;
          line-height: 14.41px;
        }
      }

      .time_container {
        display: flex;
        align-items: center;
        gap: 16px;
        font-size: 22px;
        font-weight: 500;
        line-height: 16px;
        text-align: left;

        @media (max-width: 767px) {
          font-size: 11.32px;
          font-weight: 500;
          line-height: 8.24px;
          text-align: left;
          gap: 8px;
          color: #8f939a;

          svg {
            width: 10px;
            height: 10px;

            path {
              fill: #8f939a;
            }
          }
        }
      }

      .summary_container {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        @media (max-width: 767px) {
          gap: 12px;
        }

        .summary_heading {
          font-size: 18px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
          color: #25272d;

          @media (max-width: 767px) {
            font-size: 16px;
          }
        }

        .summary_underline {
          width: 100%;
          border: 1px solid #dddddd;
        }

        .summary_desc {
          display: flex;
          justify-content: space-between;
          gap: 4px;
          align-items: center;

          .summary_details {
            width: 50%;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color: #25272d;

            @media (max-width: 767px) {
              width: max-content;
            }
          }

          .summary_count {
            width: 50%;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            text-align: left;
            color: #25272d;

            @media (max-width: 767px) {
              width: max-content;
              text-align: right;
            }
          }
        }
      }

      //btn_container
      .down_btn_container {
        margin-top: 28px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .info_text {
          display: flex;
          max-width: 586px;
          padding: 10px 12px;
          gap: 10px;
          border-radius: 10px;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          text-align: left;
          color: #8f939a;
          background: #f3f3f3;
        }
      }

      // attendees_container

      .attendees_container {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        position: relative;

        .attendees_header {
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
          color: #25272d;
        }

        .list_heading {
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          color: #25272d;
          margin-bottom: 4px;
        }

        .attendees_list {
          background: #ffffff;
          gap: 24px;
          border-radius: 12px;
          border: 1px solid #f3f3f3;
          padding: 16px 26px;


        }
      }
    }

    //team attendees
    .team_attendees_tab_container {
      margin-top: 24px;
      margin-left: 43px;
      margin-right: 43px;
      border-bottom: 1px solid #d9d9d9;
      display: flex;
      justify-content: center;

      .team_attendees_tab_wrapper {
        display: flex;
        justify-content: center;
        color: #8f939a;

        .team_tab {
          /* Payment details */
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
          padding: 8px 16px;
          cursor: pointer;
        }

        .active {
          color: #ee762f;
          border-bottom: 1px solid #ee762f;
        }
      }

      @media screen and (max-width: "768px") {
        margin-left: 0px;
        margin-right: 0px;
        display: block;
        border-bottom: none;

        .team_attendees_tab_wrapper {
          .team_tab {
            text-align: center;
            flex-grow: 1;
          }
        }
      }
    }

    .payment_details_wrapper {
      // border: 5px solid green;
      // max-height: 250px;
      // overflow: scroll;
      max-width: 546px;
      margin: 26px auto;
      padding: 10px;

      .transactions-info-wrap {
        padding: 10px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          display: flex;
          align-items: center;
          gap: 8px;
          color: var(--SecondarySteel, #8f939a);
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-align: start;

          .date {
            color: var(--SteelBlue, #25272d);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }

        .right {
          color: var(--SteelBlue, #25272d);
          text-align: right;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      .divider {
        max-width: 546px;
        height: 1px;
        background: #f3f3f3;
        margin: auto;
      }
    }

    .view_all_bookings {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #ee7830;
      text-decoration: underline;
      margin-top: 26px;
      margin-bottom: 26px;
      cursor: pointer;
    }

    .button_wrapper {
      max-width: 546px;
      margin-right: auto;
      margin-left: auto;

      .auto-paycut-btn {
        color: #eb5757;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        border-radius: 12px;
        background: #ffe2e2;
        height: 56px;
        padding: 11px 0;
      }

      .auto-paycut-btn.disable {
        background-color: #f3f3f3;
        color: #8f939a;
      }
    }
  }
}

.custom_table {
  display: table;
  width: 100%;
  border-collapse: collapse;

  .custom_table-row {
    display: table-row;
  }

  .custom_table-header {
    display: table-cell;
    padding: 6px;
  }

  .custom_table-cell {
    display: table-cell;
    padding: 8px 6px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }

  .heading {
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    color: #8f939a;
  }

  .highlight {
    color: #000000;
    font-weight: 500;
    word-wrap: break-word;
    word-break: break-all;
  }
}

.responsive_table {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 14px;

  .container_data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;

    .separator {
      width: 5px;
      height: 5px;
      background: #d9d9d9;
      border-radius: 50%;
    }

    .data_name {
      font-size: 12px;
      font-weight: 500;
      line-height: 22px;
      text-align: center;
      color: #000000;
    }

    .text_light {
      font-size: 10px;
      font-weight: 400;
      line-height: 22px;
      text-align: center;
      color: #8f939a;
    }
  }
}

.all_bookings_modal {
  min-width: 848px;
  max-height: calc(100dvh - 200px);

  .all_bookings_modal_body {
    padding: 36px 64px;
  }

  .meet_link_navigate {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #25272D;
    text-decoration: underline;
    cursor: pointer;
  }

  .btn_ticket_download {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color: #FFFFFF;
    border: 0;
    background: #EE7830;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8.5px 18px;
    gap: 12px;
    border-radius: 12px;

    &:hover {
      background: #ee7930e7;
    }
  }


  .max_modal_container {
    max-height: calc(100dvh - 400px);
    overflow-y: scroll;
    margin-right: -60px;
    padding-right: 52px;
  }

  .bookings_stamp {
    margin-top: 20px;
    margin-bottom: 24px;

    .booking_text {
      font-weight: 500;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;

      .highlight {
        font-weight: 600;
      }
    }
  }

  .booking_sections {
    border: 2px solid #6C6D6F;
    border-radius: 12px;
    padding: 14px 0px;
    margin-bottom: 10px;
  }

  .booking_section_header {
    border-bottom: 1px solid #f3f3f3;
    padding: 0 16px;

    .booking_date {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #25272d;
    }

    .booking_time_stamp {
      display: flex;
      justify-content: space-between;
      gap: 3;
      margin-bottom: 10px;
    }

    .booking_attendee {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #ee7830;
    }

    .time_container {
      display: flex;
      align-items: center;
      gap: 9px;
      font-size: 12.01px;
      font-weight: 500;
      line-height: 9.37px;
      text-align: left;
      color: #8f939a;

      svg {
        width: 10px;
        height: 10px;

        path {
          fill: #8f939a;
        }
      }
    }

    .location_container {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 12.01px;
      font-weight: 500;
      line-height: 9.37px;
      text-align: left;
      color: #8f939a;

      .location_container_add {
        white-space: nowrap;
        width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .location_link {
        font-size: 12.51px;
        font-weight: 500;
        line-height: 17.86px;
        text-align: left;
        color: #ee7830;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .booking_section_footer {
    padding: 0 16px;
  }

  .heading_bookings_container {
    display: flex;
  }

  .dismiss_container {
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #F3F3F3;
    border-radius: 50%;
    cursor: pointer;

    svg {
      cursor: pointer;
    }
  }

  .heading_bookings {
    margin-left: 10%;
    width: 80%;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    text-align: center;
    color: #25272d;
  }

  .sub_heading_bookings {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #8f939a;
  }

  .handle_link_container {
    box-sizing: border-box;
    width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (max-width:'768px') {
      width: 20%;
    }

    @media screen and (max-width:'390px') {
      width: 15%;
    }
  }

  .button_wrapper_modal {
    max-width: 350px;
    margin-right: auto;
    margin-left: auto;
  }

  .booking_text_ {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }

  .booking_attendee_heading {
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #000000;
    padding: 8px 0px;
  }

  @media screen and (max-width: 900px) {
    .emodal_wrapper {
      min-height: auto;
    }

    min-width: calc(100% - 40px);

    .all_bookings_modal_body {
      padding: 24px 20px;
    }

    .max_modal_container {
      max-height: calc(100dvh - 400px);
      overflow-y: auto;
      overflow-x: hidden;
      margin-right: 0px;
      padding-right: 0px;
    }

    .bookings_stamp {
      flex-direction: column-reverse;

      .booking_text {
        font-weight: 500;

        .highlight {
          font-weight: 600;
        }
      }

    }

    .booking_attendee {
      display: flex;
      gap: 4px;
      align-items: center;
      color: #25272d;
    }

    .booking_sections {
      border: 1px solid #f3f3f3;
    }
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #e4e3e3;
    cursor: grab;
  }
}

.booking_sessions_modal {
  .sessions_list_container {
    border: 1px solid #dbdbdb;
    border-radius: 12px;
    padding: 8px 16px;
    display: flex;

    .date_wrapper {
      border-right: 2px solid #ebebeb;
    }

    .list_heading {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #25272d;
      margin-bottom: 12px;

      @media screen and (max-width: 767px) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100% - 16px);
      }
    }

    .time_container {
      display: flex;
      align-items: center;
      gap: 9px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #8f939a;

      svg {
        width: 14px;
        height: 14px;

        path {
          fill: #8f939a;
        }
      }
    }

    .location_container {
      display: flex;
      align-items: center;
      gap: 4px;
      text-align: left;
      color: #8f939a;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      .location_link {
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #ee7830;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .details_wrapper {
      padding: 8px 16px;
    }

    .date_wrapper {
      padding: 8px 8px 8px 0px;
    }

    .date_month,
    .date_day {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #8f939a;
    }

    .date_ {
      font-size: 24px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #25272d;
      margin-top: 8px;
      margin-bottom: 6px;
    }
  }
}

.inner-polcy-bg {
  .manage_profile {
    background-color: white;
  }
}

//new ui booking details

.booking_details_wrapper_new {
  margin-top: 60px;

  .booking_details_container {
    margin-left: auto;
    margin-right: auto;
    border-radius: 12px;
    background: rgba(255, 255, 255, 1);
    max-width: 1136px;
    padding: 0px 16px;
    width: 100%;
    margin-bottom: 70px;

    @media (max-width: 1112px) {
      border-radius: 0;
      max-width: 100%;
    }

    @media (max-width: 767px) {
      margin-bottom: 0px;
    }
  }

  .booking_header {
    @media (max-width: 767px) {
      border-color: #8f939a;
      border-bottom: 1px;
    }
  }

  .booking_header_text {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
  }

  .details_container {
    @media (max-width: 767px) {
      margin-top: 0px;
      padding-left: 0px;
    }

    .sub_heading_container {
      font-size: 28px;
      font-weight: 700;
      line-height: 28px;
      text-align: left;
      color: #25272d;
      display: inline-flex;
      align-items: center;

      @media screen and (max-width: 767px) {
        font-size: 20px;
        line-height: 28px;

        @media screen and (max-width: 767px) {
          flex-direction: column;
          align-items: flex-start;
          font-size: 20px;
          line-height: 28px;
          margin-left: 0px;
        }
      }
    }

    .container_session {
      margin-left: 12px;
      width: fit-content;
      font-size: 14px;
      font-weight: 500;
      line-height: 26px;
      text-align: right;
      color: #ee7830;
      display: inline-flex;
      align-items: center;
      gap: 12px;
      padding: 4px 8px;
      border-radius: 8px;
    }

    hr {
      color: #dddddd;
      opacity: 1;
      max-width: 803px;
    }

    .details_description {
      padding-right: 60px;
      // margin-top: 20px;

      @media (max-width: 767px) {
        padding-right: 0px;
      }

      .date_container {
        font-size: 24px;
        font-weight: 600;
        line-height: 28px;
        text-align: left;
        color: #25272d;
        display: flex;
        align-items: center;
        gap: 72px;

        @media (max-width: 767px) {
          gap: 8px;
          flex-direction: column;
          align-items: flex-start;
          font-size: 14.41px;
          font-weight: 600;
          line-height: 14.41px;
        }
      }

      .time_container {
        display: flex;
        align-items: center;
        gap: 16px;
        font-size: 22px;
        font-weight: 500;
        line-height: 16px;
        text-align: left;

        @media (max-width: 767px) {
          font-size: 11.32px;
          font-weight: 500;
          line-height: 8.24px;
          text-align: left;
          gap: 8px;
          color: #8f939a;

          svg {
            width: 10px;
            height: 10px;

            path {
              fill: #8f939a;
            }
          }
        }
      }

      .summary_container {
        // margin-top: 32px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: 800px;

        @media (max-width: 767px) {
          gap: 12px;
        }

        .summary_heading {
          font-size: 18px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
          color: #25272d;

          @media (max-width: 767px) {
            font-size: 16px;
          }
        }

        .summary_underline {
          width: 100%;
          border: 1px solid #dddddd;
        }

        .summary_desc {
          display: flex;
          justify-content: space-between;
          gap: 4px;
          align-items: center;

          .summary_details {
            width: 50%;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color: #25272d;

            @media (max-width: 767px) {
              width: max-content;
            }
          }

          .summary_count {
            width: 50%;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            text-align: left;
            color: #25272d;

            @media (max-width: 767px) {
              width: max-content;
              text-align: right;
            }
          }
        }
      }

      //btn_container
      .down_btn_container {
        margin-top: 28px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .info_text {
          display: flex;
          max-width: 586px;
          padding: 10px 12px;
          gap: 10px;
          border-radius: 10px;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          text-align: left;
          color: #8f939a;
          background: #f3f3f3;
        }
      }

      // attendees_container

      .attendees_container {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .list_heading {
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          color: #25272d;
          margin-bottom: 4px;
        }

        .attendees_header {
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
          color: #25272d;
        }

        .attendees_list {
          background: #ffffff;
          gap: 24px;
          border-radius: 12px;
          border: 1px solid #f3f3f3;
          padding: 16px 26px;


        }
      }
    }

    //team attendees
    .team_attendees_tab_container {
      margin-top: 24px;
      margin-left: 43px;
      margin-right: 43px;
      border-bottom: 1px solid #d9d9d9;
      display: flex;
      justify-content: center;

      .team_attendees_tab_wrapper {
        display: flex;
        justify-content: center;
        color: #8f939a;

        .team_tab {
          /* Payment details */
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
          padding: 8px 16px;
          cursor: pointer;
        }

        .active {
          color: #ee762f;
          border-bottom: 1px solid #ee762f;
        }
      }

      @media screen and (max-width: "768px") {
        margin-left: 0px;
        margin-right: 0px;
        display: block;
        border-bottom: none;

        .team_attendees_tab_wrapper {
          .team_tab {
            text-align: center;
            flex-grow: 1;
          }
        }
      }
    }

    .payment_details_wrapper {
      // border: 5px solid green;
      // max-height: 250px;
      // overflow: scroll;
      max-width: 546px;
      margin: 26px auto;
      padding: 10px;

      .transactions-info-wrap {
        padding: 10px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          display: flex;
          align-items: center;
          gap: 8px;
          color: var(--SecondarySteel, #8f939a);
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-align: start;

          .date {
            color: var(--SteelBlue, #25272d);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }

        .right {
          color: var(--SteelBlue, #25272d);
          text-align: right;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      .divider {
        max-width: 546px;
        height: 1px;
        background: #f3f3f3;
        margin: auto;
      }
    }

    .view_all_bookings {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #ee7830;
      text-decoration: underline;
      margin-top: 26px;
      margin-bottom: 26px;
      cursor: pointer;
    }

    .button_wrapper {
      max-width: 546px;
      margin-right: auto;
      margin-left: auto;

      .auto-paycut-btn {
        color: #eb5757;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        border-radius: 12px;
        background: #ffe2e2;
        height: 56px;
        padding: 11px 0;
      }

      .auto-paycut-btn.disable {
        background-color: #f3f3f3;
        color: #8f939a;
      }
    }
  }

  .btn_footer {
    width: 100%;
    background: linear-gradient(180deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 76.04%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 0px 24px 0px;
    box-sizing: border-box;
    position: sticky;
    bottom: 0;
    margin-top: auto;
    justify-content: center;

    .info-box-booking-head {
      margin-top: 8px;
      background: #ffffff;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
      gap: 16px;

      .info-box-booking-head {
        background: #f3f3f3;
      }
    }
  }

  @media screen and (max-width: 900px) {
    margin-top: 24px;
  }
}

.overflow_web_schedule {
  @media screen and (min-width: 601px) {
    max-height: calc(100% - 80px);
    overflow-y: auto;
  }
}