.tabs-container-wrap {
  margin: 42px -45px 0;
  height: 100%;

  .tabs-container {
    display: flex;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.14);
    padding: 0px 12.5%;

    .tab-button {
      flex-grow: 1;
      padding: 20px;
      border: none;
      border-bottom: 2px solid transparent;
      background-color: transparent;
      cursor: pointer;
      transition:
        border-bottom-color 0.3s,
        background-color 0.3s;
      color: #8f939a;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .tab-button:hover {
      background-color: #f0f0f0;
    }

    .tab-button.active {
      font-weight: 600;
      color: #25272d;
      border-bottom-color: #ee7830;
    }
  }

  .tab-content {
    margin-top: 16px;
    height: 100%;

    .infinite-scroll-component__outerdiv {
      height: 100%;

      .infinite-scroll-component {
        min-height: 600px;
      }
    }

    .month-title {
      color: #000000;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      margin-top: 16px;
    }

    .empty-data {
      margin-top: 150px;
    }
  }

  .booking_wrapper_profile {
    padding: 0px 45px;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    gap: 16px;

    @media screen and (max-width:'768px') {
      padding: 0px 20px;
    }
  }

  .upcoming-schedule-row-outer {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    padding: 16px;
    background: #ffffff;
    border: 1px solid #F2F2F2;
    box-shadow: 0px 4px 32px 0px #0000000A;
    border-radius: 12px;


    .upcoming-schedule-wrap {
      // padding: 12px;
      // max-width: 668px;
      // flex-grow: 1;
      width: calc(100% - 110px);
      box-sizing: border-box;

      @media screen and (max-width:'768px') {
        width: calc(100% - 90px);
      }

      .listing_detail_content {
        @media screen and (max-width:'768px') {
          display: none;
        }

        p {
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
          color: #25272d;
          text-align: left;
          margin-bottom: 0px;

          span {
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            color: #0099ff;
          }
        }
      }

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      .card-content {
        display: flex;
        align-items: center;
        gap: 8px;
        box-sizing: border-box;
        width: 100%;
        justify-content: space-between;

        .left {
          width: calc(100% - 210px);

          @media screen and (max-width:'768px') {
            width: 100%;
          }

          .cancel_date {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color: #8F939A;
            padding-top: 8px;
          }


          .title {
            width: calc(100%);
            // max-width: 405px;
            // overflow: hidden;
            color: var(--FitnessMates-Pitch-Deck-Black, #25272d);
            // text-overflow: ellipsis;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            gap: 16px;

            .event_desc {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: calc(100% - 10px);
            }

            .virtual_resp {
              display: none;
            }

            @media screen and (max-width:'768px') {
              flex-direction: column;
              gap: 8px;
              margin: 0px;

              .virtual_resp {
                display: flex;
              }
            }
          }

          .content-items {
            display: flex;
            align-items: center;
            gap: 4px;
            color: #25272d;
          }
        }

        .open-maps-box-booking-detail {
          display: flex;
          align-items: center;
          gap: 4px;

          .t-sub-title-box {
            max-width: 73px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #8f939a;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
          }

          .meet-link {
            color: #25272d;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            text-decoration-line: underline;
            cursor: pointer;
          }

          .copy-btn {
            cursor: pointer;
          }
        }
      }

      .left-content {
        display: flex;
        align-items: center;
      }

      .sub-content-wrap {
        display: flex;
        gap: 16px;

        .content-items {
          display: flex;
          align-items: center;
          line-height: 0;
          gap: 8px;
        }
      }

      .day {
        overflow: hidden;
        color: #8f939a;
        text-overflow: ellipsis;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 8px;
      }

      .date {
        color: #25272d;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }

      .title {
        overflow: hidden;
        color: #25272d;
        text-overflow: ellipsis;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 8px;
      }

      .t-sub-title-box {
        width: 8rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #25272d;
      }

      .sub-title {
        color: #25272d;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      .map-link {
        color: #ee7830;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        text-decoration-line: underline;
        cursor: pointer;
      }

      .vertical-divider {
        margin: 8px 24px;
        width: 1px;
        background: #ebebeb;
        min-height: 72px;
      }

      .avatar-participants-container {
        display: flex;
        gap: 1rem;
        justify-content: center;
        align-items: center;

        .avatar-container {
          padding-left: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;

          .avatar-item {
            margin-left: -10px;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 24px;
              height: 24px;
              border-radius: 50%;
            }
          }
        }

        .t-participants {
          text-align: end;
          color: var(--SteelBlue, #25272d);
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 500;
          line-height: 1.25rem;
        }
      }
    }

    .sb-date-cont {
      padding-right: 36px;
      border-right: 2px solid #EBEBEB;
      display: flex;
      gap: 8px;
      flex-direction: column;
      justify-content: center;

      @media screen and (max-width:768px) {
        max-height: 76px;
        padding-right: 24px;
      }

      .sb-day {
        color: #6C6D6F;
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }

      .sb-date {
        color: var(--SteelBlue, #25272d);
        font-size: 24px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
      }
    }

    .refund-status-box {
      width: 105px;
      padding: 4px 12px;
      border-radius: 8px;

      @media screen and (max-width:768px) {
        width: fit-content;
        display: flex;
        gap: 4px;
      }

      .title {
        color: var(--SecondarySteel, #8f939a);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        margin: 0;
      }

      .status {
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
      }

      .ref_amt {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        text-align: center;
        color: #25272D;
      }
    }

    .cancelled-card-wrap {
      justify-content: space-between;
      align-items: flex-start !important;
    }
  }

  .my-booking-container {
    .sb-archives-container {
      .sb-archive {
        display: flex;
        gap: 12px;
        margin-bottom: 32px;

        .sb-date-cont {
          .sb-day {
            color: var(--SecondarySteel, #8f939a);
            text-align: right;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
          }
        }

        .sb-archive-card {
          max-width: 668px;
          width: 100%;
          border-radius: 12px;
          border: 1px solid #f2f2f2;
          background: #fff;
          box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.04);
          padding: 12px;

          .sb-card-title {
            color: #8f939a;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            width: 200px;
            white-space: nowrap;

            p {
              overflow: hidden;
              text-overflow: ellipsis;
              margin-bottom: 12px;
            }
          }

          .sb-card-location {
            display: flex;
            gap: 4px;

            p {
              color: #8f939a;
              font-weight: 400;
              font-size: 14px;
              margin-bottom: 0px;
            }
          }

          .sb-card-time-participants {
            display: flex;
            gap: 24px;

            .sb-time {
              display: flex;
              color: #8f939a;
              font-size: 400;
              align-items: center;
              gap: 4px;

              p {
                margin-bottom: 0px;
              }
            }

            .avatar-participants-container {
              display: flex;
              gap: 4px;
              justify-content: center;
              align-items: center;

              .avatar-container {
                padding-left: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;

                .avatar-item {
                  margin-left: -10px;
                  border-radius: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  img {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                  }
                }
              }

              .t-participants {
                text-align: end;
                color: var(--SteelBlue, #25272d);
                font-size: 0.75rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.25rem;
              }
            }
          }
        }
      }
    }
  }

  .my-booking-container {
    .sb-archives-container {
      .sb-archive {
        .sb-archive-card {
          .sb-card-time-participants {
            flex-direction: column;

            .avatar-participants-container {
              justify-content: start;
            }
          }
        }
      }
    }
  }
}

.diasble-download {
  pointer-events: none;
  opacity: 0.6;
}

@media (max-width: 768px) {
  .tabs-container-wrap {
    margin: 32px -16px;

    .tabs-container {
      padding: 0;
    }
  }

  .tab-button {
    padding: 10px;
  }

  .no-content-wrap {
    img {
      object-fit: cover;
      max-width: 300px;
    }
  }

  .refund-status-box-mob {
    justify-content: space-between;
    align-items: center;
  }

  .upcoming-schedule-row-outer {
    gap: 8px !important;
  }
}