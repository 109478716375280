.manage_profile_wrapper {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 84px);
  box-sizing: border-box;
  padding: 24px 0px 0px 0px;

  @media screen and (max-width: 600px) {
    // padding: 20px 20px 0px 20px;
    padding-top: 20px;
  }

  .mp_header {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;

    @media screen and (max-width: 600px) {
      padding-inline: 20px;
    }
    .mp_heading {
      font-size: 22px;
      font-weight: 600;
      line-height: 28px;
      text-align: center;
      color: #25272d;
      position: relative;

      .back_icon {
        cursor: pointer;
        position: absolute;
        left: 20px;
        top: 0px;
      }

      @media screen and (max-width: 600px) {
        font-size: 20px;
        line-height: 30px;

        .back_icon {
          left: 0px;
          width: 30px;
          height: 30px;
        }
      }
    }

    .mp_description {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: #8f939a;

      @media screen and (max-width: 600px) {
        font-size: 14px;
        line-height: 21px;
      }
    }

    .back_btn {
      position: absolute;
      top: 4px;
      left: 0;
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
  }

  .mp_progress_wrapper {
    margin-top: 18px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    @media screen and (max-width: 600px) {
      padding-inline: 20px;
    }

    .mp_progress_container {
      max-width: 555px;
      width: 100%;
      position: relative;

      .mp_progress {
        height: 4px;
        width: 100%;
        background: #f3f3f3;
        border-radius: 2px;

        .mp_current_progress {
          height: inherit;
          border-radius: 2px;
          background-color: #ee7830;
        }
      }
      .step_counts {
        font-size: 10px;
        color: #6c6d6f;
        font-weight: 500;
        line-height: 12px;
        position: absolute;
        right: 0;
        top: 4;
      }
    }
  }

  .mp_form_wrapper {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    .mp_form_container {
      width: 100%;
      max-width: 555px;

      @media screen and (max-width: 600px) {
        padding-inline: 20px;
      }

      .mp_cover_wrapper {
        position: relative;
        width: 100%;
        margin-bottom: 55px;

        .mp_cover_container {
          .mp_cover_empty {
            border: 1px solid #dcdcdc;
            border-radius: 8px;
            background: #fff4ee;
            width: 100%;
            height: 212px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            cursor: pointer;

            &:hover {
              background: #ededed;
            }

            .add_cover_title {
              font-size: 10px;
              font-weight: 400;
              line-height: 22px;
              text-align: left;
              color: #25272d;
            }
          }

          .cover_media_file {
            border-radius: 8px;
            width: 100%;
            height: 212px;
            object-fit: cover;
            cursor: pointer;
          }
        }

        .mp_profile_wrapper {
          position: absolute;
          left: 0;
          bottom: -39px;
          // overflow: hidden;
          width: 78px;
          height: 78px;
          border-radius: 50%;

          .user_profile_container {
            position: relative;

            .mp_profile_media {
              width: 78px;
              height: 78px;
              border-radius: 50%;
              object-fit: cover;
            }

            .mp_profile_icon {
              position: absolute;
              bottom: 2px;
              right: -10px;
              cursor: pointer;
            }
          }
        }
      }

      .mp_form_fill_container {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 24px;

        .mp_section {
          width: 100%;
          color: #25272d;

          .form_group {
            margin-bottom: 0px;
          }

          .gender_wrapper {
            max-width: auto;
            margin: 0;
            gap: 16px;
            justify-content: flex-start;

            .gender_option {
              margin-bottom: 0;
            }
          }

          .loc_sel_icon {
            margin-bottom: 0px;
          }

          .google_map_view {
            margin-top: 32px;
            border-radius: 12px;
            border: 1px solid #dcdcdc;
          }

          .pt_checkbox {
            background: linear-gradient(167.07deg, rgba(238, 120, 48, 0.12) -15.49%, rgba(238, 118, 47, 0.12) 149.41%);
            border-radius: 12px;
            padding: 18px 12px;
            border: 1px solid #ee7830;
            cursor: pointer;
            width: 100%;
            display: flex;
            gap: 12px;
            justify-content: space-between;

            .pt_label {
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
              text-align: left;

              @media screen and (max-width: "600px") {
                font-size: 14px;
                font-weight: 500;
              }
            }

            .cross-icon {
              display: none;
            }

            .form_group {
              margin-bottom: 0;
            }

            .input_box {
              input {
                left: 0;
                top: 0;
                height: 24px;
                width: 24px;
                background-color: white;
                border-radius: 50%;
                appearance: none;
                -webkit-appearance: none;
                outline: none;
                cursor: pointer;
              }

              input[type="checkbox"] {
                width: 24px;
                height: 24px;
                padding: 0;
                border: 1px solid #ee7830;
              }

              input:checked {
                background: url("../../assets/img/checked.svg");
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                border: none;
              }
            }
          }
        }

        .mp_section_label {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          color: #25272d;
        }

        .mp_section_sub_label {
          font-size: 10px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          color: #25272d;
        }

        .mp_error {
          margin-top: 8px;
        }

        .hr_line {
          width: 100%;
          height: 1px;
          background: #6c6d6f;
        }
      }
    }
  }

  .gender_wrapper_new {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 482px;
    margin: auto;
    position: relative;
    gap: 20px;

    .gender_option {
      position: relative;
      display: flex;
      align-items: center;
      background: #f6f6f7;
      border-radius: 12px;
      padding: 12px;
      padding-right: 30px;
      min-width: fit-content;
      width: fit-content;
      margin-right: 16px;
      cursor: pointer;
      margin-bottom: 30px;

      .form_group {
        margin-bottom: 0;
      }

      .cross-icon {
        display: none;
      }

      &:last-child {
        margin-right: 0px;
      }

      .input_box {
        position: initial;
      }

      > img {
        width: 32px;
        height: 32px;
      }

      .input_box {
        textarea {
          border: 1px solid #8f939a;
          border-radius: 10px;
        }

        input {
          position: absolute;
          opacity: 0;

          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }

        input[type="radio"] {
          cursor: pointer;
        }

        .input_label {
          position: static;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          display: block;
          padding: 0 10px;
          white-space: nowrap;
        }
      }

      .check-icon {
        opacity: 0;
        width: 20px;
        height: 20px;
        // background: url(../img/checked.svg);
        background-size: contain;
        position: absolute;
        right: 12px;
        z-index: -1;
      }
    }

    .text-danger {
      width: max-content;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
    }

    .gender_option.active {
      background: linear-gradient(167.07deg, rgba(238, 120, 48, 0.12) -15.49%, rgba(238, 118, 47, 0.12) 149.41%);
      border: 1px solid #ee7830;

      .check-icon {
        opacity: 1;
        z-index: 2;
      }
    }
  }

  @media screen and (max-width: "768px") {
    .gender_wrapper_new {
      gap: 10px;
      overflow: auto;

      .gender_option {
        > img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  @media screen and (max-width: "457px") {
    .gender_wrapper_new {
      justify-content: start;
    }
  }
}

.business-address-searchbar {
  .input_box {
    .cross-icon {
      display: none;
    }
  }
}

.bi_wrapper {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 1240px) {
    padding-inline: 20px;
  }
  .bi_container {
    max-width: 1240px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    .search_header {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 24px;

      .search_input_container {
        flex-grow: 1;
      }

      .search_view {
        display: flex;
        gap: 12px;
      }

      .search_input_wrapper {
        border: 1px solid rgba(220, 220, 220, 1);
        padding: 10px 24px 10px 12px;
        width: 100%;
        box-sizing: border-box;
        height: fit-content;
        border-radius: 10px;
        position: relative;

        .search_label {
          // margin-left: 4px;
          margin-bottom: 2px;
          font-size: 10px;
          font-weight: 400;
          line-height: 14px;
          text-align: left;
          color: #25272d;
        }

        .input_search_text {
          width: 100%;
          border: none;
          outline: none;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
          color: #25272d;

          &::placeholder {
            color: #8f939a;
          }
        }

        .clear_data {
          position: absolute;
          top: calc(50% - 12px);
          right: 8px;
          cursor: pointer;
        }
      }
    }

    .filter_container {
      margin: 12px 0px;
      width: 100%;
      display: flex;
      gap: 18px;

      .all_filter_btn {
        width: fit-content;
        min-width: 107px;
        padding: 9.5px 24.5px;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        color: #fff;
        box-sizing: border-box;

        @media screen and (max-width: 600px) {
          font-size: 12px;
          line-height: 14px;
          padding: 6.5px 18.5px;
          min-width: 83px;
        }

        &.inactive {
          border: 1px solid #8f939a;
          padding: 8.5px 24.5px;
          background: #fff;
          color: #25272d;

          @media screen and (max-width: 600px) {
            padding: 5.5px 18.5px;
          }
        }
      }
      .btn_new {
        max-width: fit-content;
        min-width: 200px;
        background: transparent;
        padding-left: 20px;
        padding-right: 20px;
        color: #25272d;

        @media screen and (max-width: 600px) {
          min-width: 115px;
          font-size: 14px;
          padding: 10px 0px;
        }

        &:hover {
          background: #d5d6d82f;
        }
      }
    }

    .body_interest {
      min-height: calc(100dvh - 450px);

      @media screen and (max-width: 767px) {
        min-height: calc(100dvh - 456px);
      }
    }

    .interest_data_wrapper {
      width: 100%;
      display: grid;
      gap: 24px;
      margin-bottom: 24px;
      grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));

      @media screen and (max-width: 600px) {
        grid-template-columns: repeat(auto-fill, minmax(106px, 1fr));
        gap: 12px;
      }

      .interest_cards {
        max-width: 132px;
        border-radius: 8px;
        border: 1px solid #e0e0e0;
        cursor: pointer;

        @media screen and (max-width: 600px) {
          min-width: 106px;
        }

        .card_image {
          width: 100%;
          aspect-ratio: 1/1;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
        }

        .card_title {
          border-top: 1px solid #e0e0e0;
          color: #25272d;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          text-align: left;
          padding: 6px 9px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;
          max-width: 100%;
          box-sizing: border-box;
        }

        &.selected,
        &:hover {
          border: 1px solid #ee7830;
          background: rgba(238, 120, 48, 0.08);

          .card_title {
            border-top: 1px solid #ee7830;
            color: #ee7830;
            font-size: 14px;
            font-weight: 500;
            line-height: 17.5px;
            padding: 5px 9px 6px;
          }
        }
      }
    }

    .interest_list_wrapper {
      width: 100%;
      display: grid;
      gap: 16px;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      margin-top: 10px;

      .interest_list {
        min-width: 230px;
        border-radius: 8px;
        border: 1px solid #e0e0e0;
        display: flex;
        width: 100%;
        padding: 3px 22px;
        gap: 16px;
        align-items: center;
        cursor: pointer;
        margin-top: 0px;

        .list_image {
          width: 42px;
          height: 42px;
          aspect-ratio: 1/1;
        }

        .list_title {
          color: #25272d;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          text-align: left;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;
          max-width: 100%;
          box-sizing: border-box;
        }

        &.selected,
        &:hover {
          border: 1px solid #ee7830;
          background: rgba(238, 120, 48, 0.08);

          .list_title {
            color: #ee7830;
          }
        }
      }
    }

    // .btn_footer {
    //   width: 100%;
    //   background: linear-gradient(180deg,
    //       rgba(255, 255, 255, 0) 0%,
    //       #ffffff 76.04%);
    //   display: flex;
    //   flex-direction: row;
    //   gap: 8px;
    //   align-items: center;
    //   padding: 24px 0px 24px 0px;
    //   box-sizing: border-box;
    //   position: sticky;
    //   bottom: 0;
    //   margin-top: auto;
    //   justify-content: center;

    //   @media screen and (max-width: 600px) {
    //     flex-direction: column;
    //     padding: 20px 0px;
    //   }

    //   .btn_continue {
    //     max-width: fit-content;
    //     min-width: 200px;
    //     padding-left: 20px;
    //     padding-right: 20px;

    //     @media screen and (max-width: 600px) {
    //       min-width: 100%;
    //     }

    //     &:disabled {
    //       cursor: not-allowed;
    //       background: #8f939a;
    //       pointer-events: visible;
    //     }
    //   }

    //   .btn_new {
    //     max-width: fit-content;
    //     min-width: 200px;
    //     background: transparent;
    //     padding-left: 20px;
    //     padding-right: 20px;
    //     color: #25272d;

    //     @media screen and (max-width: 600px) {
    //       min-width: 100%;
    //     }

    //     &:hover {
    //       background: #d5d6d82f;
    //     }
    //   }
    // }
  }
  .btn_footer {
    width: 100%;
    // background: linear-gradient(180deg,
    //     rgba(255, 255, 255, 0) 0%,
    //     #ffffff 76.04%);
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    padding: 30px 0px 30px 0px;
    box-sizing: border-box;
    position: sticky;
    bottom: 0;
    margin-top: auto;
    justify-content: center;
    box-shadow: 0px 0px 4px 0px #00000040;

    @media screen and (max-width: 600px) {
      flex-direction: row;
      padding: 25px 23px;
      width: auto;
    }

    .btn_continue {
      max-width: 372px;
      min-width: 200px;
      padding-left: 20px;
      padding-right: 20px;

      @media screen and (max-width: 600px) {
        min-width: 100%;
      }

      &:disabled {
        cursor: not-allowed;
        background: #8f939a;
        pointer-events: visible;
      }
    }
    .btn_interest_Save {
      max-width: 372px;
      padding-left: 20px;
      padding-right: 20px;

      @media screen and (max-width: 600px) {
        width: 192px;
      }

      &:disabled {
        cursor: not-allowed;
        background: #8f939a;
        pointer-events: visible;
      }
    }

    .btn_skip {
      max-width: 372px;
      background: transparent;
      padding-left: 20px;
      padding-right: 20px;
      color: #25272d;

      @media screen and (max-width: 600px) {
        width: 135px;
      }

      &:hover {
        background: #d5d6d82f;
      }
    }
  }
}

.trainer_wrapper {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .trainer_container {
    max-width: 788px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 20px;

    .body_trainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .web_fields {
        display: none;
      }

      .trainer_expert_card {
        // width: 100%;
        border: 1px solid #e0e0e0;
        // border-radius: 0px 12px 12px 0px;
        display: flex;
        box-sizing: border-box;

        @media screen and (max-width: 600px) {
          border-radius: 12px;
        }

        .image_data {
          width: 230px;
          aspect-ratio: 1/1;

          @media screen and (max-width: 600px) {
            display: none;
          }
        }

        .container_data {
          width: calc(100% - 230px);
          padding: 12px 48px 12px 26px;
          // flex-grow: 1;
          box-sizing: border-box;

          .image_data_resp {
            display: none;
          }

          @media screen and (max-width: 600px) {
            width: calc(100%);
            padding: 12px;

            .image_data_resp {
              display: block;
              aspect-ratio: 1/1;
              width: 80px;
              margin-right: 12px;
            }
          }

          .heading_t {
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
            color: #25272d;
          }

          .title_optional {
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
            text-align: right;
            color: #8f939a;
            margin-bottom: 6px;
            box-sizing: border-box;
          }

          .form_input {
            display: flex;
            box-sizing: border-box;
            width: 100%;
            gap: 10px;

            .input_cont {
              background: #f8f8f8;
              border: 1px solid #f3f3f3;
              border-radius: 12px;
              width: 100%;
              display: flex;
              align-items: center;

              .end_text {
                padding: 10px 20px;
                font-size: 12px;
                font-weight: 400;
                line-height: 24px;
                text-align: right;
                color: #25272d;
              }

              .text_input {
                border: none;
                outline: none;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                text-align: center;
                color: #25272d;
                width: calc(100% - 74px);
              }
            }
          }

          .form_textarea {
            margin-top: 12px;
            box-sizing: border-box;
            width: 100%;
            border: 1px solid #f3f3f3;
            background: #f8f8f8;
            border-radius: 12px;
            padding: 10px 20px;

            .area_input {
              padding: 0;
              background: transparent;
              border: none;
              border-radius: 0px;
              width: 100%;
              min-height: auto;
            }

            .area_header {
              display: flex;
              justify-content: space-between;
              gap: 12px;
              align-items: center;
              font-size: 10px;
              font-weight: 400;
              line-height: 14px;
              text-align: left;
              color: #25272d;
              margin-bottom: 8px;

              .header_right_area {
                display: flex;
                align-items: center;
                gap: 12px;

                img {
                  width: 12px;
                  height: 12px;
                  cursor: pointer;
                }
              }
            }
          }

          .add_file_wrapper {
            margin-top: 8px;
            width: 100%;
            display: flex;
            justify-content: flex-end;
          }

          .add_file {
            display: flex;
            gap: 5px;
            align-items: center;
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            color: #ee7830;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }

            img {
              width: 24px;
              height: 24px;
            }
          }
        }
      }

      .qualification_doc_container {
        display: flex;
        width: 100%;
        flex-direction: column;

        @media screen and (max-width: 786px) {
          margin-top: 10px;
          gap: 10px;
          border-radius: 12px;
        }

        .doc_wrap_exp {
          border: 1px solid #ee7830;
          padding: 14px 20px;
          display: flex;
          width: 100%;
          gap: 12px;

          @media screen and (max-width: 786px) {
            gap: 10px;
            border-radius: 12px;
          }

          .doc_name_wrap {
            flex-grow: 1;
            display: flex;
            width: 100%;
            flex-direction: column;

            .qual_doc_name_title {
              display: flex;
              width: 100%;
              gap: 16px;

              .file_name_doc {
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                color: #25272d;
              }

              .file_name_size {
                font-size: 12px;
                font-weight: 400;
                line-height: 24px;
                color: #6c6d6f;
              }
            }
          }
        }
      }
    }
  }
  .btn_footer {
    width: 100%;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    padding: 30px 0px 30px 0px;
    box-sizing: border-box;
    position: sticky;
    bottom: 0;
    margin-top: auto;
    justify-content: center;
    box-shadow: 0px 0px 4px 0px #00000040;

    @media screen and (max-width: 600px) {
      flex-direction: row;
      padding: 25px 23px;
      width: 100%;
    }

    .btn_skip {
      max-width: 372px;
      min-width: 200px;
      background: transparent;
      padding-left: 20px;
      padding-right: 20px;
      color: #25272d;

      @media screen and (max-width: 600px) {
        width: 135px;
      }

      &:hover {
        background: #d5d6d82f;
      }
    }

    .btn_Qualifications_Save {
      max-width: 372px;
      padding-left: 20px;
      padding-right: 20px;

      &:disabled {
        cursor: not-allowed;
        background: #8f939a;
        pointer-events: visible;
      }
    }
  }
}

// Personal Details screen
.personal-details-wrapper {
  background-color: #ffffff;
  border-radius: 12px;
  width: 100%;
  max-width: 875px;
  padding: 36px 40px;
  margin-bottom: 0;

  .profile_box {
    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      text-align: left;
      color: #25272d;
      margin-bottom: 20px;
    }
  }

  .cover-photo-box {
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    background: #f3f3f3;
    width: 100%;
    height: 161px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    margin-bottom: 106px;

    @media screen and (max-width: 768px) {
      margin-bottom: 72px;
    }

    .cover_empty {
      position: relative;
      width: 100%;
      height: 100%;

      label {
        position: absolute;
        inset: 0;
        text-align: center;
      }

      .add_cover_icon {
        position: absolute;
        right: 12px;
        top: 12px;
        z-index: 3;
        cursor: pointer;
      }

      .cover-title {
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        text-align: center;
        color: #25272d;
        flex: 1;
      }

      .add_cover_icon_center {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
        margin-top: 44px;
        cursor: pointer;
      }

      .cover-pic {
        width: 100%;
        height: 159px;
        object-fit: fill;
        position: relative;
        border-radius: 7px;

        &.video {
          overflow: hidden;
          position: relative;

          .videoicon {
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
            top: 50%;
          }

          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer;
          }
        }
      }
    }

    .profile-image-box {
      position: absolute;
      left: 20px;
      top: 50%;
      z-index: 2;

      .profile-image-wrap {
        position: relative;

        .profile-pic {
          width: 153px;
          height: 153px;
          border-radius: 50%;
          border: 4px solid #fff;
          object-fit: cover;
          position: relative;
          cursor: pointer;

          &.video {
            overflow: hidden;
            position: relative;

            .videoicon {
              position: absolute;
              left: 50%;
              transform: translate(-50%, -50%);
              top: 50%;
            }

            video {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .add_image_icon {
          position: absolute;
          right: 12px;
          bottom: 12px;
          z-index: 3;
          cursor: pointer;
        }
      }
    }
  }

  &.inner-policy-pages-setup {
    padding: 0;

    .manage_profile {
      padding-top: 0px !important;

      .margin-top-adjust {
        margin-top: 0px !important;
      }

      &.container {
        padding: 0;

        & > a {
          display: none;
        }

        .profile_box {
          .title {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .input_title_dob {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin-bottom: 16px;
  }

  .details-box {
    max-width: 555px;
    display: flex;
    flex-direction: column;


    .detail-title {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: #000000;
      margin-bottom: 10px;
    }
  }

  .gender-box {
    text-align: left;

    .title {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
      color: #25272d;
      margin-bottom: 10px;
    }

    .gender_wrapper {
      display: flex;
      justify-content: left;
      max-width: 100%;
    }
  }

  @media screen and (max-width: "768px") {
    padding: 24px 16px;

    .profile_box {
      .title {
        font-weight: 600;
        font-size: 20px;
        line-height: normal;
        color: #25272d;
        padding-bottom: 12px;
        position: relative;

        .back_arrow {
          top: -25px;
          cursor: pointer;
          width: 32px;
          height: 32px;
          margin-right: 20px;
        }
      }
    }

    .image-upload-box {
      .cover-title {
        display: none;
      }

      .profile-image-box {
        top: calc(100% - 55px);

        .profile-image-wrap .profile-pic {
          width: 110px;
          height: 110px;
        }
      }
    }

    .gender_wrapper {
      flex-wrap: wrap;

      .gender_option {
        margin-bottom: 12px;
      }
    }
  }

  //payement page
  .payment_wrapper {
    background-color: transparent !important;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .profile_box {
      background: #fff;
      padding: 24px;
      border-radius: 12px;
      max-width: 619px;

      .title {
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        text-align: left;
        color: #25272d;
        margin: 0;
      }

      .sub_title {
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: #52545b;
        margin-bottom: 36px;
      }

      &.available_card {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border: 1px solid #dcdcdc;

        .options {
          position: relative;

          .more {
            cursor: pointer;
          }

          .option_wrapper {
            background: #ffffff;
            border: 1px solid rgba(37, 39, 45, 0.16);
            border-radius: 12px;
            padding: 20px 20px 4px;
            position: absolute;
            width: 210px;
            right: 0px;
            opacity: 0;
            z-index: -2;
            transition: 0.3s;
          }

          &:hover {
            .option_wrapper {
              opacity: 1;
              z-index: 2;
            }
          }
        }
      }

      .left_box {
        display: flex;
        align-items: flex-start;
        gap: 12px;

        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #25272d;
          padding-bottom: 4px;
        }

        small {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #8f939a;
        }
      }

      .delete {
        font-weight: 400;
        font-size: 14px;
        line-height: 0px;
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        padding: 4px;
        margin-bottom: 8px;
        border: 1px solid #dcdcdc;
        border-radius: 8px;

        &:hover {
          background: #dcdcdc;
        }
      }
    }
  }

  .paymet_card {
    .form_group {
      width: 100%;

      .input_box {
        .StripeElement {
          border: 1px solid #f3f3f3;
          border-radius: 10px;
          padding: 0 12px;
          padding-top: 30px;
          padding-bottom: 12px;
        }

        label {
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 14px;
          display: flex;
          align-items: center;
          color: #25272d;
          position: absolute;
          top: 10px;
          left: 12px;
        }
      }
    }

    .two-row {
      display: flex;
      gap: 20px;

      .form_group {
        &:nth-child(2) {
          max-width: 167px;
        }
      }
    }

    .descrip-pay-meth {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      color: #25272d;
      margin-top: 40px;
      margin-bottom: 16px;
    }
  }

  //stripe-bankaccount page
  .stripe-bankaccount {
    .page-title {
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      color: #25272d;
    }

    .container_stripe_payment_listing {
      max-width: 546px;
      position: relative;
      margin: 24px auto;

      .bank-stripe-btn {
        display: flex;
        border: 1px solid #dcdcdc;
        gap: 12px;
        border-radius: 12px;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        color: #ee762f;
        padding: 14px;
        cursor: pointer;
      }

      @media screen and (max-width: "768px") {
        padding: 0px 20px;
        box-sizing: border-box;
      }

      .payment_heading {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        color: #25272d;
        padding-top: 64px;

        .handle_back_arrow_resp {
          display: none;
        }

        .heading_transfer {
          flex-grow: 1;
          text-align: center;
        }

        @media screen and (max-width: "768px") {
          padding-top: 36px;
          display: flex;
          gap: 6px;
          align-items: flex-start;

          .handle_back_arrow_resp {
            display: flex;
          }
        }

        @media screen and (max-width: "600px") {
          text-align: left;
        }
      }

      .payment_subheading {
        margin-top: 16px;
        margin-bottom: 72px;
        color: var(--SecondarySteel, #8f939a);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 23.5px;

        @media screen and (max-width: "600px") {
          text-align: left;
          font-size: 12px;
          line-height: 18px;
        }
      }

      .or_hr {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #8f939a;
        margin-top: 20px;
        margin-bottom: 20px;
      }

      .or_hr:after,
      .or_hr:before {
        content: "";
        display: block;
        background: #f3f3f3;
        width: 50%;
        height: 1px;
        margin: 0 10px;
      }

      .stripe_acc_listing {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 16px;
        padding-top: 16px;

        @media screen and (max-width: "600px") {
          padding-top: 16px;
        }

        .acc_wrapper {
          border: 1px solid #dcdcdc;
          padding: 26px 32px;
          border-radius: 12px;
          display: flex;
          width: 100%;
          gap: 16px;
          justify-content: space-between;
          cursor: pointer;

          @media screen and (max-width: "600px") {
            align-items: center;
            padding: 12px 14px;
          }

          &:hover {
            background: #d5d6d82f;
          }

          .acc_content {
            display: flex;
            flex-direction: column;
            gap: 6px;

            @media screen and (max-width: "600px") {
              font-size: 2px;
            }

            .acc_number {
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              text-align: left;
              color: #25272d;

              @media screen and (max-width: "600px") {
                font-size: 14px;
              }
            }

            .acc_added {
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              text-align: left;
              color: #8f939a;

              @media screen and (max-width: "600px") {
                font-size: 14px;
              }
            }
          }
        }
      }

      .height_listing {
        min-height: 0;
        display: flex;
        width: 100%;
        align-items: baseline;

        @media screen and (max-width: "768px") {
          min-height: calc(100dvh - 62px - 234px - 60px);

          &.bank_transfer {
            min-height: calc(100dvh - 88px - 234px - 84px - 52px);
          }
        }
      }

      .acc_btn_container {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 76.04%);
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        padding: 74px 0px 36px 0px;
        box-sizing: border-box;
        position: sticky;
        bottom: 0;
        margin-top: auto;

        .stripe_acc_continue {
          max-width: 546px;

          &:disabled {
            cursor: not-allowed;
            background: #8f939a;
            pointer-events: visible;
          }
        }

        .stripe_acc_new {
          max-width: 546px;
          background: transparent;
          color: #25272d;

          &:hover {
            background: #d5d6d82f;
          }
        }
      }
    }

    .container_stripe_payment_listing {
      max-width: 619px;
      height: calc(100dvh - 84px);
      margin-top: 24px;

      @media screen and (max-width: "768px") {
        height: calc(100dvh - 62px);
      }

      .bank_info {
        background: #f3f3f3;
        border-radius: 10px;
        padding: 10px 12px;
        display: flex;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        color: #8f939a;
        align-items: flex-start;
        gap: 4px;
        margin-top: 20px;

        img {
          width: 16px;
          height: 16px;
        }
      }

      .no_listing {
        border: 1px dashed #dcdcdc;
        height: 184px;
        border-radius: 12px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .no_listing_desc {
          font-size: 16px;
          font-weight: 400;
          line-height: 23px;
          text-align: center;
          color: #25272d;
        }

        .stripe_acc_add {
          width: 147px;
          padding: 9px 55px;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          margin-top: 8px;
        }
      }
    }
  }

  .gender_wrapper_new {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    gap: 4px;
    margin-bottom: 24px;

    .gender_option {
      position: relative;
      display: inline-flex;
      align-items: center;
      border-radius: 12px;
      min-width: fit-content;
      width: fit-content;
      cursor: pointer;

      .form_group {
        margin-bottom: 0;
      }

      .cross-icon {
        display: none;
      }

      &:last-child {
        margin-right: 0px;
      }

      .input_box {
        position: initial;
      }

      > img {
        width: 24px;
        height: 24px;
      }

      .input_box {
        textarea {
          border: 1px solid #8f939a;
          border-radius: 10px;
        }

        input {
          position: absolute;
          opacity: 0;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }

        input[type="radio"] {
          cursor: pointer;
        }

        .input_label {
          position: static;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          display: block;
          padding: 0 10px;
          white-space: nowrap;
          color: #25272d;
        }
      }

      .check-icon {
        opacity: 0;
        width: 20px;
        height: 20px;
        background-size: contain;
        position: absolute;
        right: 12px;
        z-index: -1;
      }
    }

    .text-danger {
      width: max-content;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
    }

    .gender_option.active {
      .check-icon {
        opacity: 1;
        z-index: 2;
      }
    }
  }

  @media screen and (max-width: "768px") {
    .gender_wrapper_new {
      gap: 10px;
      overflow: auto;

      .gender_option {
        > img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  @media screen and (max-width: "457px") {
    .gender_wrapper_new {
      justify-content: start;
    }
  }

  //manage

  .subscription-plan-right {
    text-align: center;
    background-color: #ffffff;
    border-radius: 12px;
    max-width: 619px;
    width: 100%;
    padding: 0;

    .subscription-box {
      .s-no-content-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      .s-title {
        color: #25272d;
        text-align: left;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        margin-bottom: 32px;

        @media screen and (max-width: "768px") {
          text-align: center;
        }
      }
    }

    .s-block-details-card-wrap {
      margin: 0;

      .info-box-cancel-wrap {
        margin-top: 20px;

        .info-box-cancel {
          display: inline-flex;
          align-items: center;
          gap: 8px;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          text-align: left;
          color: #6c6d6f;
        }
      }
    }
  }

  .tabs-container-wrap {
    margin: 24px -40px 0;

    .tabs-container {
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.14);
    }

    @media (max-width: 768px) {
      margin: 32px -12px 0;
    }
  }

  //favourite page
  .favourite-new-contenet {
    .page-title {
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      color: #25272d;
    }

    .favourite-results-wrapper {
      max-width: 660px;
      position: relative;
      margin: 0 auto;
      display: grid !important;
      padding: 16px 0;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      gap: 20px;

      .favourite-card-wrapper {
        .listing_box {
          border-radius: 12px;
          position: relative;

          .crousel-container {
            max-width: 320px;
            width: 100%;
            position: relative;

            .dots-nav-container,
            .image-carousel-button {
              display: none !important;
            }

            .event_img {
              min-width: 320px;
              max-width: unset;
            }
          }

          .crousel-container:hover {
            .dots-nav-container,
            .image-carousel-button {
              display: flex !important;
            }
          }

          .videoicon {
            position: absolute;
            z-index: 2;
            left: 50%;
            transform: translateX(-50%);
            top: 65px;
          }

          .tag {
            position: absolute;
            left: -7px;
            top: 24px;
          }

          .event_img {
            height: 320px;
            width: 100%;
            border-radius: 12px 12px 0 0;
            object-fit: cover;
          }

          .span_box {
            position: relative;

            .distance {
              background: rgba(0, 0, 0, 0.42);
              // backdrop-filter: blur(16px);
              padding: 4px 16px 4px 16px;
              border-radius: 8px;
              font-size: 12px;
              font-weight: 500;
              line-height: 24px;
              letter-spacing: 0em;
              text-align: right;
              color: #fff;
              position: absolute;
              top: 52px;
              left: 10px;
              z-index: 10;
            }

            .event_type {
              // background: #EE7830;
              padding: 4px 16px 4px 16px;
              border-radius: 8px;
              font-size: 12px;
              font-weight: 500;
              line-height: 24px;
              letter-spacing: 0em;
              text-align: right;
              color: #fff;
              position: absolute;
              top: 12px;
              left: 0px;
              z-index: 10;
            }

            .price {
              background: linear-gradient(167.07deg, #ee7830 -15.49%, #ee762f 149.41%);
              border-radius: 8px;
              padding: 4px 16px 4px 16px;
              font-size: 14px;
              font-weight: 700;
              line-height: 24px;
              letter-spacing: 0em;
              color: #fff;
              position: absolute;
              right: 12px;
              top: -50px;
            }

            .share {
              border-radius: 8px;
              padding: 4px 16px 4px 16px;
              font-size: 14px;
              font-weight: 700;
              line-height: 24px;
              letter-spacing: 0em;
              color: #fff;
              position: absolute;
              right: 0px;
              top: 12px;
              z-index: 10;
            }

            .heart {
              border-radius: 8px;
              padding: 4px 16px 4px 16px;
              font-size: 14px;
              font-weight: 700;
              line-height: 24px;
              letter-spacing: 0em;
              color: #fff;
              position: absolute;
              right: 0px;
              top: 55px;
              z-index: 10;

              img {
                margin-bottom: 8px;
              }
            }
          }

          .limited-event-info {
            .title {
              width: 100%;
              white-space: nowrap;
              overflow: hidden !important;
              text-overflow: ellipsis;

              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 20px;
              color: #25272d;
            }

            margin-top: 16px;
            padding-left: 0px;
            padding-right: 12px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;

            color: #25272d;

            .item {
              margin-bottom: 8px;
              width: 100%;
              white-space: nowrap;
              overflow: hidden !important;
              text-overflow: ellipsis;

              span {
                color: black;
              }

              .event-type-blue {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;

                color: #0099ff;
              }

              .event-type-orange {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;

                color: #ee762f;
              }
            }
          }

          .name {
            background: #f3f3f3;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            padding: 10px 6px;
            margin-top: 14px;
            border-radius: 24px;
            display: flex;
            align-items: center;
            gap: 2px;

            span {
              white-space: nowrap;
              width: 300px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: block;
            }

            img,
            video {
              width: 20px;
              height: 20px;
              object-fit: cover;
              margin-right: 5px;
              vertical-align: middle;
              border-radius: 100%;
              box-shadow: 0px 3.3333332538604736px 10px 0px #0000001f;
            }
          }

          .name-special {
            background-color: #ee7830;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px;

            img {
              width: unset;
              height: unset;
              object-fit: unset;
              margin-right: unset;
              vertical-align: unset;
              border-radius: unset;
              box-shadow: unset;
            }
          }
        }
      }

      @media screen and (max-width: "768px") {
        .favourite-card-wrapper {
          margin: 0 auto;
          width: 320px;
        }

        .individual-image-video-block-2 {
          img {
            max-width: 100%;
          }
        }
      }
    }
  }

  // people card
  .favourite-people-card-wrap {
    border: 1px solid #dcdcdc;
    border-radius: 12px;
    max-width: 320px;
    width: 100%;
    position: relative;
    box-shadow: 0px 9px 25px 0px #0000001a;

    .cover-photo-wrap {
      cursor: pointer;
    }

    .profile-image-user-type-box {
      position: relative;
      height: 70px;

      img {
        cursor: pointer;
      }

      .trainer-chip-outer {
        position: absolute;
        right: 16px;
        top: 16px;

        .trainer-chip {
          background: #feefe7;
          padding: 1px 12px;
          font-size: 14px;
          font-weight: 600;
          line-height: 21px;
          text-align: left;
          color: #ee762f;
          border-radius: 8px;
        }
      }
    }

    .details-box {
      padding: 12px;

      .username {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        text-align: left;
        cursor: pointer;
      }

      .age {
        font-size: 14px;
        font-weight: 600;
        line-height: 28px;
        text-align: left;
        color: #666666;
      }

      .location {
        display: inline-flex;
        align-items: center;
        gap: 4px;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-align: center;
        color: #6c6d6f;
      }

      .interest-tag-section {
        display: flex;
        flex-wrap: wrap;
        margin-top: 12px;
        margin-bottom: 56px;

        .interest-tag {
          border: 1px solid #f3f3f3;
          border-radius: 8px;
          padding: 2px 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 8px;
          margin-bottom: 8px;
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
        }
      }

      .action-buttons {
        position: absolute;
        margin-top: 16px;
        left: 16px;
        right: 16px;
        bottom: 16px;
        margin-top: auto;
        display: flex;
        justify-content: space-between;
        gap: 12px;

        .like-people {
          cursor: pointer;
          border: 1px solid rgb(108, 109, 111);
          border-radius: 50%;
        }

        .connect {
          display: flex;
          max-width: 220px;
          height: 40px;
          padding: 16px 4px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: 8px;
          background: var(--Orange-Gradient, linear-gradient(167deg, #ee7830 -15.49%, #ee762f 149.41%));
          color: var(--White, #fff);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          cursor: pointer;
        }

        .disabled-connect {
          display: flex;
          max-width: 220px;
          height: 40px;
          padding: 16px 4px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: 8px;
          background: #f2f3f3;
          color: #8f939a;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          cursor: text;
        }
      }
    }

    @media screen and (max-width: "768px") {
      max-width: calc(100% - 80px);
      margin: 0 auto;
      padding: 0 20px;
    }
  }

  //my team membership
  .team-membership-new-page {
    padding: 0px 16px;

    .no-content-wrap {
      padding: 100px 0px;
      display: flex;
      align-items: center;
      justify-content: center;

      .title {
        margin-top: 20px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        color: #8f939a;
      }

      .subtitle {
        margin-top: 4px;
        width: 265px;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        color: #8f939a;

        a {
          color: #ee7830;
          font-weight: 500;
        }
      }
    }

    .team-membership-card-listing {
      width: 100%;
      max-width: 752px;
      position: relative;
      margin: 0 auto;
      display: grid !important;
      padding: 24px 16px 16px 16px;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      gap: 20px;

      //my team listing card
      .my-teamlisting-card-new-wrap {
        max-width: 350px;
        height: 100%;

        .card-wrapper {
          border: 1px solid #dcdcdc;
          border-radius: 12px;
          overflow: hidden;
          display: flex;
          width: 100%;
          height: 100%;
          flex-direction: column;
          justify-content: space-between;

          .top-section {
            display: flex;

            .left {
              .my-memberships-img {
                width: 130px;
                height: 116px;
                border-top-left-radius: 12px;
                object-fit: cover;
              }
            }

            .right {
              padding: 12px;

              .title {
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
                text-align: left;
                max-width: 194px;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                color: #25272d;
              }

              .sub-title {
                margin-top: 4px;
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
                color: #6c6d6f;
              }

              .action-box {
                margin-top: 4px;
                display: flex;
                justify-content: space-between;
                width: 194px;

                .event-type-box {
                  img {
                    height: 26px;
                  }
                }

                .view-all {
                  display: inline-flex;
                  align-items: center;
                  gap: 4px;
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 16px;
                  cursor: pointer;
                  color: #ee762f;
                  padding: 4px 14px;

                  svg {
                    width: 16px;
                    height: 16px;
                  }

                  &:hover {
                    background: #ee79301f;
                    border-radius: 6px;
                  }
                }
              }
            }
          }

          .auto-paycut-btn-box {
            padding: 16px 12px;

            .auto-paycut-btn {
              color: #eb5757;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              border-radius: 12px;
              background: #ffe2e2;
              height: 44px;
              padding: 11px 0;
            }

            .accept-pending {
              color: #ee762f;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              border-radius: 12px;
              background: #f3f3f3;
              height: 44px;
              padding: 11px 0;
              opacity: 1;
            }

            .auto-paycut-btn.disable {
              background-color: #f3f3f3;
              color: #8f939a;
            }
          }
        }

        .info-box-cancel-wrap {
          padding: 0px 16px 16px 24px;

          .info-box-cancel {
            display: inline-flex;
            align-items: center;
            gap: 4px;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            text-align: left;
            color: #6c6d6f;
          }
        }
      }

      @media screen and (max-width: "768px") {
        padding: 24px 0 0 0;
      }
    }
  }

  .bi_wrapper {
    .interest_data_wrapper {
      max-width: 757px;
    }

    .filter_container {
      margin: 28px auto;
    }
  }
}

.qualification-page {
  // qulification card
  .details-title {
    display: flex;
    justify-content: space-between;
    max-width: 788px;
    margin: 0 auto;
    margin-bottom: 24px;

    .title {
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      text-align: center;
      color: #25272d;
    }

    .add-btns {
      display: flex;
      align-items: center;
      gap: 16px;

      .save {
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        color: #ee762f;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }

      .clear {
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        color: #ff3b30;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }

      img {
        cursor: pointer;
      }
    }
  }

  .qualification-card-wrap {
    border: 1px solid #dcdcdc;
    margin-bottom: 16px;
    max-width: 788px;
    margin: 0 auto;
    margin-bottom: 16px;

    .card-top {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .content-left {
        display: flex;
        gap: 16px;

        .training-info-box {
          margin: 8px;

          .training-title {
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
            color: #ee762f;
            padding-bottom: 5px;
          }

          .exp {
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
            color: #000000;
          }
        }

        .training-img {
          height: 58px;
          width: 94px;
          object-fit: cover;

          &.empty_icon {
            padding: 4px;
            height: 48px;
            width: 48px;
          }
        }
      }

      .content-right {
        display: flex;
        padding: 12px;
        gap: 20px;

        img {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }
    }

    //no document card
    .no-doc-card {
      display: flex;
      justify-content: space-between;
      padding: 0px 20px;

      .left {
        display: flex;
        align-items: center;
        gap: 20px;

        .training-img {
          height: 50px;
          width: 50px;
          object-fit: cover;

          &.empty_icon {
            padding: 4px;
            height: 48px;
            width: 48px;
          }
        }

        .title {
          font-size: 16px;
          font-weight: 600;
          line-height: 21.87px;
          text-align: center;
          color: #ee762f;
        }
      }

      .right {
        display: flex;
        align-items: center;
        gap: 16px;

        .add-qualification {
          font-size: 14px;
          font-weight: 600;
          line-height: 24px;
          text-align: left;
          color: #ee762f;
          cursor: pointer;
          text-decoration: underline;
        }

        img {
          cursor: pointer;
        }
      }
    }

    .card-bottom {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 12px 24px;

      .desc {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        max-width: calc(100% - 85px);
      }

      .descfull {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
        max-width: calc(100% - 85px);
      }

      .show-more {
        width: 85px;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        text-align: right;
        color: #ee762f;
        text-decoration: underline;
        cursor: pointer;
      }

      @media screen and (max-width: "768px") {
        flex-direction: column;
        justify-content: center;
        flex: 1 1 0;
        // .show-more {
        //   display: none;
        // }
      }
    }

    .document-box {
      .filenamebox {
        padding-bottom: 12px;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: #25272d;
        display: flex;
        gap: 16px;
        align-items: left;
        display: flex;
        flex-direction: column;

        .flie-card {
          border-top: 1px solid #dcdcdc;
          border-bottom: 1px solid #dcdcdc;
          padding: 12px 24px;

          &:last-child {
            border-bottom: none;
            padding-bottom: 0;
          }
        }
      }
    }

    .card-top-edit {
      display: flex;
      align-items: start;

      .expertise-img {
        width: 280px;
        min-height: 265px;
        object-fit: cover;
        display: flex;
      }

      .training-info-box-edit {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 20px;

        .edit-title {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          flex: 1 1 0;
        }

        .edit-btns {
          display: flex;
          align-items: center;
          gap: 16px;

          .save {
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            text-align: left;
            color: #ee762f;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }

          .clear {
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            text-align: left;
            color: #ff3b30;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }

          img {
            cursor: pointer;
          }
        }
      }

      .text-fields-box {
        display: flex;
        padding-left: 20px;

        align-items: center;
      }

      .additional-notes-wrap {
        padding: 0 33px 0 20px;
      }

      .attach-document {
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: flex-end;
        padding-right: 20px;

        span {
          color: #ee762f;
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
          cursor: pointer;
          text-decoration: underline;
        }

        img {
          cursor: pointer;
        }
      }

      .form_group {
        position: relative;
        margin-right: 10px;
        margin-bottom: 12px;

        .modify_exp_box {
          position: relative;
          display: flex;

          input {
            background: #f8f8f8;
            border: 1px solid #f3f3f3;
            padding: 10px 60px 10px 20px;
            text-align: center;
          }

          .traning-label {
            position: absolute;
            top: 10px;
            right: 20px;
          }

          .new_training_label {
            position: absolute;
            top: 10px;
            right: 20px;
            font-size: 12px;
            font-weight: 400;
            line-height: 24px;
          }
        }

        textarea {
          min-height: 82px;
          background: #f8f8f8;
          border: 1px solid #f3f3f3;
        }

        .textarea-label {
          background: #f8f8f8;
        }

        .traning-label {
          font-size: 12px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: right;
          color: #25272d;
        }

        .textarea-label {
          font-size: 12px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          color: #25272d;
          position: absolute;
          top: 1px;
          left: 12px;
          z-index: 2;
          background: #f8f8f8;
          max-width: calc(100% - 12px) !important;
        }
        .char-counting-text{
          // font-family: Poppins;
          font-size: 12px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          color: #25272d;
          position: absolute;
          top: 1px;
          right: 40px;
          background: #f8f8f8;
        }
        .cross-icon-btn{
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          color: #25272d;
          position: absolute;
          top: 1px;
          right: 12px;
          cursor: pointer;
          background: #f8f8f8;
        }
        .count {
          top: 4px;
          right: 40px;
          z-index: 2;
        }

        .cross-icon {
          position: absolute !important;
          top: 1px !important;
          right: 12px;
          z-index: 5;
          width: 24px;
          height: 24px;
          cursor: pointer;
          border: none;
        }
      }

      h4 {
        background: #fff;
        border-radius: 0px 0px 12px 12px;
        text-align: left;
        font-weight: 500;
        font-size: 16px;
        line-height: 125%;
        padding: 8px 12px;
        margin-top: 0px;
        border: 1px solid #e0e0e0;
        border-top: 0px;
      }

      .txtb {
        border: 1px solid #e0e0e0;
        border-radius: 0px 12px 12px 0;
        border-left: none;
        padding: 0 12px;
        position: relative;
        width: 100%;

        .form_group {
          margin-bottom: 0px;
        }
      }

      .optional_txt {
        font-size: 8px;
        font-weight: 400;
        line-height: 14px;
        text-align: left;
        padding-right: 4px;
        margin-top: -20px;
      }

      .tell-us-more-box {
        justify-self: end;
        text-align: end;
        cursor: pointer;
        padding: 4px;
      }

      .tell-us-more {
        font-size: 14px;
        font-weight: 500;
        color: #ee7830;
      }

      .mobile-card {
        .expertise-img {
          min-width: 76px !important;
          max-width: 76px;
          height: 91px;
          object-fit: cover;
          border-bottom: none;
          border-radius: 12px;
          display: inherit;
        }
      }
    }

    .loder-part-scro {
      min-height: 0px;
      position: relative;

      ul,
      li {
        position: relative;
        margin: 0;
        padding: 0;
      }

      .file_preview {
        border-radius: 0px;
        padding: 12px 16px;
      }

      .name {
        padding-right: 40px;
      }

      .file-status {
        position: absolute;
        bottom: 11px;
        right: 24px;
        display: flex;

        img {
          width: 16px;
          height: 16px;
        }
      }

      .cancel-delete {
        position: absolute;
        top: 4px;
        right: 12px;
        cursor: pointer;
      }
    }
  }

  .uploded-file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 20px;
    border: 1px solid #ee762f;
    margin-bottom: 12px;

    .left {
      display: flex;
      gap: 8px;
    }

    img {
      cursor: pointer;
    }
  }
}

.no-event-outer-new {
  margin: 0 auto;

  .no_fav_list {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      max-width: 156px;
      height: 172px;
    }
  }
}

.auto-paycut-btn-global {
  color: #eb5757 !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-radius: 12px;
  background: #ffe2e2 !important;
  height: 56px;
  padding: 11px 0;
}
