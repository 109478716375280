.stripe_acc_pop {
    width: 100%;
    max-width: 398px;
    background: #ffffff;
    border-radius: 12px;
    padding: 24px 20px 40px 20px;
    display: grid;

    @media screen and (max-width:400px) {
        width: 90%;
    }

    .header_container {
        display: flex;
        width: 100%;
        justify-content: flex-end;

        img {
            cursor: pointer;
        }
    }

    .bank_details_form {
        margin-top: 20px;
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;

        .form_group {
            margin-bottom: 0px;
        }
    }

    .modal_stripe_heading {
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        text-align: center;
        color: #25272D;
        padding-top: 16px;
    }

    .modal_stripe_subheading {
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        text-align: center;
        color: #8F939A;
        padding-top: 12px;
        padding-bottom: 26px;
    }

    .stripe_existing {
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
    }

    .stripe_new {
        margin-top: 16px;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
        background: #DCDCDC;
        color: #25272D;
    }

    .stripe_waiting_text {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        text-align: center;
        margin-top: 16px;
        margin-bottom: 16px;
    }

}

.wrap_stripe_payment {
    position: relative;

    .handle_back_arrow {
        position: absolute;
        top: 52px;
        left: 100px;
        cursor: pointer;

        @media screen and (max-width: "768px") {
            display: none;
        }
    }

}

.container_stripe_payment,
.container_stripe_payment_listing {
    max-width: 546px;
    position: relative;
    margin: 0 auto;

    @media screen and (max-width: "768px") {
        padding: 0px 20px;
        box-sizing: border-box;
    }



    .payment_heading {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: center;
        color: #25272D;
        padding-top: 64px;

        .handle_back_arrow_resp {
            display: none;
        }

        .heading_transfer {
            flex-grow: 1;
            text-align: center;
        }

        @media screen and (max-width: "768px") {
            padding-top: 36px;
            display: flex;
            gap: 6px;
            align-items: flex-start;

            .handle_back_arrow_resp {
                display: flex;
            }
        }

        @media screen and (max-width: "600px") {
            text-align: left;
        }
    }

    .payment_subheading {
        margin-top: 16px;
        margin-bottom: 72px;
        color: var(--SecondarySteel, #8f939a);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 23.5px;

        @media screen and (max-width: "600px") {
            text-align: left;
            font-size: 12px;
            line-height: 18px;
        }
    }

    .or_hr {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #8f939a;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .or_hr:after,
    .or_hr:before {
        content: "";
        display: block;
        background: #f3f3f3;
        width: 50%;
        height: 1px;
        margin: 0 10px;
    }

    .stripe_acc_listing {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 16px;
        padding-top: 42px;

        @media screen and (max-width: "600px") {
            padding-top: 24px;
        }

        .acc_wrapper {
            border: 1px solid #DCDCDC;
            padding: 26px 32px;
            border-radius: 12px;
            display: flex;
            width: 100%;
            gap: 16px;
            justify-content: space-between;
            cursor: pointer;

            @media screen and (max-width: "600px") {
                align-items: center;
                padding: 12px 14px;
            }

            &:hover {
                background: #d5d6d82f;
            }

            .acc_content {
                display: flex;
                flex-direction: column;
                gap: 6px;

                @media screen and (max-width: "600px") {
                    font-size: 2px;
                }

                .acc_number {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                    text-align: left;
                    color: #25272D;

                    @media screen and (max-width: "600px") {
                        font-size: 14px;
                    }

                }

                .acc_added {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: left;
                    color: #8F939A;

                    @media screen and (max-width: "600px") {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .height_listing {
        min-height: calc(100dvh - 88px - 234px - 84px);
        display: flex;
        width: 100%;
        align-items: center;

        &.bank_transfer {
            min-height: calc(100dvh - 88px - 234px - 84px - 72px);
        }

        @media screen and (max-width: "768px") {
            min-height: calc(100dvh - 62px - 234px - 60px);

            &.bank_transfer {
                min-height: calc(100dvh - 88px - 234px - 84px - 52px);
            }
        }
    }


    .acc_btn_container {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 76.04%);
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        padding: 74px 0px 36px 0px;
        box-sizing: border-box;
        position: sticky;
        bottom: 0;
        margin-top: auto;



        .stripe_acc_continue {
            max-width: 546px;

            &:disabled {
                cursor: not-allowed;
                background: #8F939A;
                pointer-events: visible;
            }
        }

        .stripe_acc_new {
            max-width: 546px;
            background: transparent;
            color: #25272D;

            &:hover {
                background: #d5d6d82f;
            }

        }
    }

}

.container_stripe_payment_listing {
    max-width: 619px;
    height: calc(100dvh - 84px);

    @media screen and (max-width: "768px") {
        height: calc(100dvh - 62px);
    }

    .bank_info {
        background: #F3F3F3;
        border-radius: 10px;
        padding: 10px 12px;
        display: flex;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        color: #8F939A;
        align-items: flex-start;
        gap: 4px;
        margin-top: 20px;

        img {
            width: 16px;
            height: 16px;
        }
    }

    .no_listing {
        border: 1px dashed #DCDCDC;
        height: 184px;
        border-radius: 12px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;


        .no_listing_desc {
            font-size: 16px;
            font-weight: 400;
            line-height: 23px;
            text-align: center;
            color: #25272D;
        }

        .stripe_acc_add {
            width: 147px;
            padding: 9px 55px;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            margin-top: 8px;
        }

    }
}