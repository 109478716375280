.loader_wrapper_layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-width: 100vw;
    min-height: 100dvh;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.42);
    backdrop-filter: blur(4px);

    .inner_wrap {
        display: flex;

        .spinner_img {
            width: 40px;
            height: 40px;
        }
    }
}