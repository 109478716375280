.page-members {
  width: 100%;
  height: 100%;
  padding: 30px;

  .select-heading {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: #25272d;
  }
  .select-sub-heading {
    margin-top: 4px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: #6c6d6f;
  }

  .users-container {
    display: flex;
    justify-content: center;
    gap: 42px;
    margin-top: 92px;

    .user-type-wraps {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 11px;

      .img-user-wrap {
        height: 142px;
        width: 142px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &.selected-color {
          background-color: #fff4ee;
          border: 1.12px solid #ee7830;
        }
        &.not-selected {
          background-color: #f3f3f3;
          border: 0px;
        }
        .icon-user-style {
          height: 63.14px;
          width: 63.14px;
        }
      }

      .user-label-text {
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 20px;
    padding-top: 36px;
    .select-sub-heading {
      margin-top: 6px;
      font-size: 14px;
      line-height: 21px;
    }

    .users-container {
      flex-direction: column;
      align-items: center;
      margin-top: 35px;
    }
  }
}
