.short-card-wrapper {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 16px 16px 19px 16px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 9px 25px 0px #0000001A;
    gap: 16px;
    max-width:391px;
  
    @media (max-width: 767px) {
      margin: 0;
    }
    .user-detail-wraper {
      display: flex;
  
      .user-img-wrapper {
        position: relative;
  
        .profile-img-style {
          border-radius: 100%;
          object-fit: cover;
          border: 4px solid white;
          height: 80px;
          width: 80px;
        }
        .flag-style {
          position: absolute;
          top: 2px;
          right: -8px;
        }
      }
  
      .name-interest-wraper {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin: 11px 5px 13px 8px;
  
        .text-style {
          color: #6c6d6f;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          display: flex;
          gap: 4px;
        }
      }
    }
  
    .btn-wrap {
      display: flex;
      justify-content: center;
      .unflag-btn-class {
        border-radius: 8px;
        border: 0px;
        background-color: #ee7830;
        font-size: 14px;
        font-weight: 500;
        color: white;
        line-height: 24px;
        width: 100%;
        height: 40px;
        max-width: 211px;
      }
    }
    .request-btn-wrapper {
      display: flex;
      
      .accept-btn-class {
        border-radius: 8px;
        border: 0px;
        background-color: #ee7830;
        font-size: 14px;
        font-weight: 500;
        color: white;
        line-height: 24px;
        // max-width: 123px;
        width: 100%;
        height: 40px;
      }
      .reject-btn-class {
        border-radius: 8px;
        border: 0px;
        background-color: #feefe7;
        font-size: 14px;
        font-weight: 500;
        color: #25272d;
        line-height: 24px;
        // max-width: 123px;
        width: 100%;
        height: 40px;
        // margin-left: 8px;
      }
    }

    .connect-btn-class{
      border-radius: 8px;
      border: 0px;
      background-color: #ee7830;
      font-size: 14px;
      font-weight: 500;
      color: white;
      line-height: 24px;
      width: 100%;
      height: 40px;
      // margin-left: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap:8px
    }

    .confirm-btn-class {
      border-radius: 8px;
      border: 0px;
      background-color: #ee7830;
      font-size: 18px;
      font-weight: 500;
      color: white;
      line-height: 24px;
      padding: 28px 0px;
      width: 100%;
      height: 40px;
      // margin-left: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap:8px
    }
  }
  