.chat-managment-wrap {
  padding: 16px 55px 55px;
  background: #f6f6f6;
  min-height: calc(100vh - 114px);
  margin-top: 92px;

  .chat-main-section {
    display: flex;
    gap: 24px;
    justify-content: center;
    flex-wrap: wrap;

    .main-section-left {
      background-color: #fff;
      border-radius: 12px;
      width: 100%;
      max-width: 390px;

      @media screen and (max-width: "768px") {
        max-width: 100%;
      }

      .group-details-heading {
        font-size: 16px;
        font-weight: 500;
        position: relative;
        text-align: center;
        padding-top: 38px;
      }

      .profile-image {
        border-radius: 100%;
      }

      .group-details-name {
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
        padding: 20px 0px;
      }

      .group-details-chat-wrap {
        width: 90px;
        height: 90px;
        background: #f3f3f3;
        border-radius: 50%;
        position: relative;

        .user-img {
          width: 90px;
          height: 90px;
          object-fit: cover;
          border-radius: 50%;
        }

        .user-img-1 {
          width: 50px;
          height: 50px;
          object-fit: cover;
          border-radius: 50%;
          position: absolute;
          top: 7.5px;
          left: 7.5px;
          z-index: 2;
          border: 3px solid #f3f3f3;
        }

        .user-img-2 {
          width: 50px;
          height: 50px;
          object-fit: cover;
          border-radius: 50%;
          position: absolute;
          top: 32.5px;
          left: 32.5px;
          z-index: 4;
          border: 3px solid #f3f3f3;
        }
      }

      .member-count {
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0em;
        color: #8f939a;
      }

      .group-details-members {
        padding: 16px 20px;
        border-top: 4px Solid #f1f1f1;
        border-bottom: 4px Solid #f1f1f1;

        .heading {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 16px;
        }

        .group-details-member-item {
          align-items: center;
          display: flex;
          justify-content: space-between;
          margin: 10px 0px;

          .user-img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            object-fit: cover;
            cursor: pointer;
          }

          .name {
            font-size: 14px;
            font-weight: 500;
          }

          .more-popup-item-wrap {
            width: 138px;
            padding: 6px 6px 6px 12px;
            border-radius: 12px;
            background: var(--White, #fff);
            box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.08);
            position: absolute;

            .title {
              color: var(--SteelBlue, #25272d);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              margin: 8px 0;
              cursor: pointer;
            }
          }
        }
      }

      .button-container {
        padding: 16px 20px;

        .option {
          color: #eb5757;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 10px 0px;
          cursor: pointer;
        }
      }

      .top-section {
        padding: 24px 20px;

        .search-box-wrap {
          display: flex;
          justify-content: space-between;
          gap: 12px;
          align-items: center;

          .form-group {
            position: relative;
            width: 100%;

            input {
              background: #f2f2f2;
              border-radius: 12px;
              width: 100%;
              height: 48px;
              padding: 10px 40px;
              border: 0;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #25272d;

              &::-webkit-input-placeholder {
                /* Edge */
                color: #8f939a;
              }

              &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: #8f939a;
              }

              &::placeholder {
                color: #8f939a;
              }

              &+label {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 12px;
                display: grid;

                img {
                  &.black-search {
                    display: none;
                  }
                }

                &+img.corss-icon {
                  position: absolute;
                  right: 12px;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }

              &:focus,
              &:focus-visible,
              &:focus-within {
                &::-webkit-input-placeholder {
                  /* Edge */
                  color: #25272d;
                }

                &:-ms-input-placeholder {
                  /* Internet Explorer 10-11 */
                  color: #25272d;
                }

                &::placeholder {
                  color: #25272d;
                }

                &+label {
                  img {
                    &.gray-search {
                      display: none;
                    }

                    &.black-search {
                      display: inherit;
                    }
                  }
                }
              }
            }
          }
        }

        .connection-req-box {
          margin-top: 32px;
          margin-bottom: 12px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;

          .left {
            color: #25272d;
          }

          .right {
            color: #ee7830;
            cursor: pointer;
          }
        }

        .users-img-box-wrap {
          position: relative;
          height: 50px;

          .content {
            position: absolute;
            display: flex;
            overflow-x: hidden;
            gap: 3px;
            max-width: 366px;
            overflow-x: scroll;

            &::-webkit-scrollbar {
              display: none;
            }

            .user-img {
              width: 48px;
              height: 48px;
              border-radius: 50%;
              object-fit: cover;
              cursor: pointer;
            }
          }
        }

        .divider {
          position: absolute;
          max-width: 390px;
          width: 100%;
          height: 4px;
          flex-shrink: 0;
          background: #f1f1f1;
          margin-left: -20px;
          margin-top: 20px;
        }
      }

      .users-chat-list-box {
        padding: 12px 0px;
        min-height: calc(100vh - 322px);
        max-height: calc(100vh - 322px);
        overflow-y: scroll;

        &::-webkit-scrollbar {
          display: none;
          width: 0px;
        }

        .chat-listing-card-wrap {
          .chat-listing-card {
            padding: 8px 20px 12px;

            .content {
              display: grid;
              grid-template-columns: 0fr 3fr 0.6fr;
              gap: 8px;
              align-items: center;
              position: relative;

              .user-img {
                width: 48px;
                height: 48px;
                object-fit: cover;
                border-radius: 50%;
                object-fit: cover;
              }

              .title {
                color: var(--FitnessMates-Pitch-Deck-Black, #25272d);
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                // width: 250px;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                word-break: break-word;
              }

              .desc {
                overflow: hidden;
                color: var(--SecondarySteel, #8f939a);
                text-overflow: ellipsis;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                display: -webkit-box;
                // width: 250px;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
              }
            }

            .time {
              color: var(--SecondarySteel, #8f939a);
              text-align: right;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              // position: absolute;
              // right: 0px;
              // top: 5px;
            }

            .active-dot {
              position: absolute;
              right: 0;
              top: 16px;
            }

            .group-chat-wrap {
              width: 48px;
              height: 48px;
              background: #f3f3f3;
              border-radius: 50%;
              position: relative;

              .user-img-1 {
                width: 26px;
                height: 26px;
                object-fit: cover;
                border-radius: 50%;
                position: absolute;
                top: 8px;
                left: 5px;
                z-index: 2;
              }

              .user-img-2 {
                width: 26px;
                height: 26px;
                object-fit: cover;
                border-radius: 50%;
                position: absolute;
                top: 14px;
                left: 18px;
                z-index: 4;
              }
            }
          }

          .active {
            background-color: #f3f3f3;
          }

          .divider:last-child {
            border-bottom: 1px solid #f3f3f3;
            margin: 0 20px;
          }
        }
      }

      .connection-req-popup {
        padding: 20px;

        .top-section {
          display: flex;
          justify-content: center;
          position: relative;
          color: var(--SteelBlue, #25272d);
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;

          .back-arrow {
            position: absolute;
            left: 0;
          }
        }

        .form-group {
          position: relative;
          width: 100%;

          input {
            background: #f2f2f2;
            border-radius: 12px;
            width: 100%;
            height: 48px;
            padding: 10px 40px;
            border: 0;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #25272d;

            &::-webkit-input-placeholder {
              /* Edge */
              color: #8f939a;
            }

            &:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: #8f939a;
            }

            &::placeholder {
              color: #8f939a;
            }

            &+label {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: 12px;
              display: grid;

              img {
                &.black-search {
                  display: none;
                }
              }

              &+img.corss-icon {
                position: absolute;
                right: 12px;
                top: 50%;
                transform: translateY(-50%);
              }
            }

            &:focus,
            &:focus-visible,
            &:focus-within {
              &::-webkit-input-placeholder {
                /* Edge */
                color: #25272d;
              }

              &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: #25272d;
              }

              &::placeholder {
                color: #25272d;
              }

              &+label {
                img {
                  &.gray-search {
                    display: none;
                  }

                  &.black-search {
                    display: inherit;
                  }
                }
              }
            }
          }
        }

        .request-list-box {
          padding: 12px 0px;
          min-height: calc(100vh - 200px);
          max-height: 632px;
          overflow-y: scroll;

          &::-webkit-scrollbar {
            display: none;
            width: 0px;
          }

          .request-card-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 16px 0px;

            .info-box,
            .button-box {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 12px;

              .user-img {
                width: 44px;
                height: 44px;
                object-fit: cover;
                border-radius: 50%;
              }

              .name {
                overflow: hidden;
                color: var(--SteelBlue, #25272d);
                text-overflow: ellipsis;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
              }

              .card-btn {
                cursor: pointer;
              }
            }
          }
        }
      }

      //new chat section
      .new-chat-wrap {
        padding-top: 20px;
        height: 100%;

        .create-group-button-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 4px 32px 0px #0000003d;
          height: 100px;
        }

        .group-name-field {
          padding: 20px 20px;
          display: flex;
          flex-direction: row;
          border-bottom: 2px Solid #f2f2f2;
          margin-bottom: 10px;

          input {
            margin-left: 20px;
            width: 100%;
            border: none;
          }
        }

        .top-section {
          display: flex;
          justify-content: center;
          position: relative;
          color: var(--SteelBlue, #25272d);
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;

          .back-arrow {
            position: absolute;
            left: 0;
          }
        }

        .search-box {
          padding: 0px 20px;

          .form-group {
            position: relative;
            width: 100%;

            input {
              background: #f2f2f2;
              border-radius: 12px;
              width: 100%;
              height: 48px;
              padding: 10px 40px;
              border: 0;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #25272d;

              &::-webkit-input-placeholder {
                /* Edge */
                color: #8f939a;
              }

              &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: #8f939a;
              }

              &::placeholder {
                color: #8f939a;
              }

              &+label {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 12px;
                display: grid;

                img {
                  &.black-search {
                    display: none;
                  }
                }

                &+img.corss-icon {
                  position: absolute;
                  right: 12px;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }

              &:focus,
              &:focus-visible,
              &:focus-within {
                &::-webkit-input-placeholder {
                  /* Edge */
                  color: #25272d;
                }

                &:-ms-input-placeholder {
                  /* Internet Explorer 10-11 */
                  color: #25272d;
                }

                &::placeholder {
                  color: #25272d;
                }

                &+label {
                  img {
                    &.gray-search {
                      display: none;
                    }

                    &.black-search {
                      display: inherit;
                    }
                  }
                }
              }
            }
          }
        }

        .new-chat-box {
          margin: 24px 0px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          padding-left: 20px;

          .left {
            display: flex;
            align-items: center;
            gap: 12px;
            color: var(--SecondarySteel, #8f939a);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;

            .icon {
              width: 48px;
              height: 48px;
              border-radius: 50%;
              background: #f3f3f3;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          .right {
            padding: 4px;

            .open-group-chat {
              cursor: pointer;
            }
          }
        }

        .my-connections-wrap {
          margin-bottom: 24px;
          padding: 0px 20px;
          min-height: calc(100vh - 450px);

          .heading {
            color: var(--SteelBlue, #25272d);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            margin-bottom: 24px;
            margin-top: 16px;
          }

          .user-list-box {
            .user-card {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 16px;
              cursor: pointer;

              img,
              video {
                width: 48px;
                height: 48px;
                border-radius: 50%;
                margin-right: 12px;
                object-fit: cover;
                vertical-align: middle;
              }

              .check-img {
                margin-right: 0px;
              }

              color: var(--FitnessMates-Pitch-Deck-Black, #25272d);
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
            }
          }
        }
      }
    }

    .main-section-left-hidden {
      display: none;
    }

    .main-section-right {
      background-color: #fff;
      border-radius: 12px;
      width: 100%;
      max-width: 690px;
      margin-bottom: 0;

      .nochat-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;

        .title {
          color: #000;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
          margin-top: 32px;
        }

        .desc {
          max-width: 380px;
          color: var(--SecondarySteel, #8f939a);
          text-align: center;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          margin-top: 12px;
        }
      }

      .listing-screen {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .chat-btn-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;

          .chatl-btn {
            max-width: 390px;
            margin: 16px 0px;
          }
        }

        .nochat-wrap-listing {
          align-self: center;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          .title {
            color: #000;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            margin-top: 32px;
          }

          .desc {
            max-width: 380px;
            color: var(--SecondarySteel, #8f939a);
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            margin-top: 12px;
          }
        }

        .typing-box {
          border-top: 1px solid #eeeeee;
          padding: 20px;

          .typing-box-outer {
            display: flex;
            justify-content: space-between;
            gap: 12px;
            align-items: center;

            .form-group {
              position: relative;
              width: 100%;

              input {
                background: #f2f2f2;
                border-radius: 12px;
                width: 100%;
                height: 48px;
                padding: 10px;
                border: 0;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #25272d;

                &::-webkit-input-placeholder {
                  /* Edge */
                  color: #8f939a;
                }

                &:-ms-input-placeholder {
                  /* Internet Explorer 10-11 */
                  color: #8f939a;
                }

                &::placeholder {
                  color: #8f939a;
                }

                &+label {
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  left: 12px;
                  display: grid;

                  img {
                    &.black-search {
                      display: none;
                    }
                  }

                  &+img.corss-icon {
                    position: absolute;
                    right: 12px;
                    top: 50%;
                    transform: translateY(-50%);
                  }
                }

                &:focus,
                &:focus-visible,
                &:focus-within {
                  &::-webkit-input-placeholder {
                    /* Edge */
                    color: #25272d;
                  }

                  &:-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: #25272d;
                  }

                  &::placeholder {
                    color: #25272d;
                  }

                  &+label {
                    img {
                      &.gray-search {
                        display: none;
                      }

                      &.black-search {
                        display: inherit;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      //chatting-box
      .chatting-screen {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100vh - 112px);

        .top-section {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 16px 20px;
          border-bottom: 4px solid #f3f3f3;

          .left {
            display: flex;
            align-items: center;
            gap: 8px;

            .group-chat-wrap {
              width: 48px;
              height: 48px;
              background: #f3f3f3;
              border-radius: 50%;
              position: relative;

              .user-img-1 {
                width: 26px;
                height: 26px;
                object-fit: cover;
                border-radius: 50%;
                position: absolute;
                top: 8px;
                left: 5px;
                z-index: 2;
              }

              .user-img-2 {
                width: 26px;
                height: 26px;
                object-fit: cover;
                border-radius: 50%;
                position: absolute;
                top: 14px;
                left: 18px;
                z-index: 4;
              }
            }

            .user-img {
              width: 48px;
              height: 48px;
              border-radius: 50%;
              object-fit: cover;
            }

            .name {
              overflow: hidden;
              color: var(--SteelBlue, #25272d);
              text-overflow: ellipsis;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;
              /* 171.429% */
            }
          }

          .right {
            position: relative;

            .more-icon {
              cursor: pointer;
              padding: 4px;
            }

            .more-popup-item-wrap {
              width: 138px;
              padding: 6px 6px 6px 12px;
              border-radius: 12px;
              background: var(--White, #fff);
              box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.08);
              position: absolute;
              right: 0;

              .title {
                color: var(--SteelBlue, #25272d);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin: 8px 0;
                cursor: pointer;
              }
            }
          }
        }

        .message-box {
          overflow: scroll;
          margin-top: auto;

          .recived-message-box-wrap {
            float: left;
            margin-bottom: 24px;
            width: 100%;

            .recived-message-box {
              border-radius: 12px 12px 12px 0px;
              background: var(--FitnessNonSelected, #f3f3f3);
              box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.04);
              color: var(--SecondarySteel, #8f939a);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              width: fit-content;
              max-width: 500px;
              padding: 16px;
              word-break: break-all;
              display: flex;
            }

            .msg-info {
              display: flex;
              gap: 6px;
              align-items: center;
              margin-top: 16px;
              margin-bottom: 16px;

              .seen-img {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                object-fit: cover;
              }
            }
            .initial-name-wrap{
              background: lightgrey;
              border-radius: 100%;
              width: 24px;
              height: 24px;
              font-size: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          .sent-message-box-wrap {
            margin-bottom: 24px;
            float: right;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .sent-message-box {
              border-radius: 12px 12px 12px 0px;
              background: var(--SteelBlue, #25272d);
              box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.04);
              color: var(--White, #fff);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              max-width: 500px;
              padding: 16px;
              word-break: break-all;
            }
          }

          .msg-time {
            color: var(--SecondarySteel, #8f939a);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        .typing-box {
          border-top: 1px solid #eeeeee;
          padding: 20px;

          .typing-box-outer {
            display: flex;
            justify-content: space-between;
            gap: 12px;
            align-items: center;

            .form-group {
              position: relative;
              width: 100%;

              input {
                background: #f2f2f2;
                border-radius: 12px;
                width: 100%;
                height: 48px;
                padding: 10px;
                border: 0;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #25272d;

                &::-webkit-input-placeholder {
                  /* Edge */
                  color: #8f939a;
                }

                &:-ms-input-placeholder {
                  /* Internet Explorer 10-11 */
                  color: #8f939a;
                }

                &::placeholder {
                  color: #8f939a;
                }

                &+label {
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  left: 12px;
                  display: grid;

                  img {
                    &.black-search {
                      display: none;
                    }
                  }

                  &+img.corss-icon {
                    position: absolute;
                    right: 12px;
                    top: 50%;
                    transform: translateY(-50%);
                  }
                }

                &:focus,
                &:focus-visible,
                &:focus-within {
                  &::-webkit-input-placeholder {
                    /* Edge */
                    color: #25272d;
                  }

                  &:-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: #25272d;
                  }

                  &::placeholder {
                    color: #25272d;
                  }

                  &+label {
                    img {
                      &.gray-search {
                        display: none;
                      }

                      &.black-search {
                        display: inherit;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .exit-group {
          background-color: #f6f6f6;
          display: flex;
          justify-content: center;
          padding: 20px 0px;

          p {
            max-width: 302px;
            text-align: center;
            color: #8f939a;
          }
        }
      }
    }

    .main-section-right-hidden {
      display: none;
    }
  }
}

@media screen and (max-width: "768px") {
  .chat-managment-wrap {
    padding: 16px;

    .users-img-box-wrap {
      .content {
        max-width: 337px !important;
        overflow-x: hidden !important;
      }
    }

    .divider {
      max-width: 350px !important;
    }
  }
}