.my-team-bookings-wrapper {
  margin-top: 60px;
  margin-bottom: 40px;

  .head-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .title-container-wrap {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .title-container {
        display: flex;
        align-items: center;
        gap: 19px;
        .title {
          font-size: 32px;
          font-weight: 500;
          line-height: 24px;
          color: #000000;
        }
        .team-icon-wrapper {
          background: linear-gradient(
            167.07deg,
            #ee7830 -15.49%,
            #ee762f 149.41%
          );
          padding: 11px 16px 11px 11px;
          border-radius: 8px;
          border: none;
          color: #ffffff;
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
          display: flex;
          align-items: center;
          gap: 4px;
        }

        .event-type-wrapper {
          color: #25272d;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          border: 0px;
          border-radius: 12px;
          display: flex;
          gap: 6px;
          padding: 8px 12px 8px 12px;
          align-items: center;
        }
      }
    }

    .team-booking-text {
      font-size: 22px;
      font-weight: 500;
      line-height: 20px;
      color: #25272d;
      display: flex;
      gap: 6px;
    }
    .location-wrap {
      display: flex;
      height: 64px;
      align-items: center;

      .location-type-text {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #25272d;
        padding: 20px 26px;
        background-color: #feefe7;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
      }
      .location-copy-wrap {
        border-radius: 0px 12px 12px 0px;
        border: 1px dashed #dcdcdc;
        height: 100%;
        display: flex;
        align-items: center;
        gap: 50px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        padding: 20px 18px;
        color: #25272d;
      }
    }
  }

  .tabs-wrapper {
    margin-top: 36px;

    .tabs-container {
      display: flex;
      // box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.14);
      border-bottom: 0.5px solid #dcdcdc;
      // padding: 0px 12.5%;

      .tab-button {
        //   flex-grow: 1;
        padding: 0px 50px 10px 40px;
        border: none;
        border-bottom: 2px solid transparent;
        background-color: transparent;
        cursor: pointer;
        transition: border-bottom-color 0.3s, background-color 0.3s;
        color: #8f939a;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }

      .tab-button:hover {
        background-color: #f0f0f0;
      }

      .tab-button.active {
        font-weight: 600;
        color: #ee7830;
        border-bottom-color: #ee7830;
      }
    }

    .tab-content {
      .card-wrapper {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 28px;

        .request-card-Wrapper {
          border: 1px solid #dcdcdc;
          border-radius: 12px;
          width: 100%;

          .card-heading-wrap {
            padding: 16px 32px 16px 26px;
            display: flex;
            justify-content: space-between;
            width: 100%;

            .card-heading-start {
              display: flex;
              align-items: center;
              gap: 16px;

              .booki-img-style {
                border-radius: 100%;
                object-fit: cover;
                // border: 4px solid white;
                height: 60px;
                width: 60px;
                // box-shadow: 0px 4px 20px 0px #0000001f;
              }

              .name-text {
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                color: #25272d;
              }

              .email-text {
                font-size: 16px;
                font-weight: 400;
                line-height: 22px;
                color: #6c6d6f;
              }
            }
            .card-heading-end {
              display: flex;
              align-items: center;
              gap: 16px;

              .accpet-btn-class {
                padding: 8px 51px;
                color: #ffffff;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                background-color: #29cc6a;
                border-radius: 12px;
                box-shadow: 0px 4px 32px 0px #00000014;
                border: 0px;
              }

              .reject-btn-class {
                padding: 8px 51px;
                color: #ffffff;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                background-color: #fc5555;
                border-radius: 12px;
                box-shadow: 0px 4px 32px 0px #00000014;
                border: 0px;
              }
            }
          }
          .horizontal-break {
            border: 1px solid #f3f3f3;
            width: 100%;
          }
          .table-wrapper {
            padding: 16px 32px 16px 26px;

            .attende-text {
              color: #ee7830;
              font-size: 16px;
              font-weight: 600;
              line-height: 22px;
            }

            .table-container{
              width: 100%;
              margin-top: 12px;
              display: grid;
              grid-template-columns: 15% 15% 15% 7.5% 7.5% 40%;
              gap:16px; 
              // table{
              //   width: 100%;
              //   border-collapse: separate; /* Allows spacing between rows */
              //   border-spacing: 0 16px; 

              //   thead{
              //     font-size: 12px ;
              //     font-weight: 400;
              //     line-height: 22px;
              //     color: #6C6D6F;
              //     margin-bottom: 15px;
              //   }

              //   tbody{

              //     tr{
              //        gap:16px;

              //       .attende-details-text{
              //         font-size: 16px ;
              //         font-weight: 400;
              //         line-height: 22px;
              //         color: #000000;
              //       }
              //     }
                  
              //   }
              // }
            }
          }
        }
      }
    }
    .no-data-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .content-wrapper {
        // padding-top: 161px;
        text-align: center;
        height: 60vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
             
        .shadow-wrap {
          display: flex;
          justify-content: center;

          .shadow {
            width: 156px;
            height: 14px;
            background-color: #f3f3f3;
            border-radius: 50%;
            margin-top: 11px;
          }
        }

        .title-text {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #6c6d6f;
        }

        .desc-text {
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          color: #6c6d6f;
          margin-top: 12px;

          .click-text {
            color: #ee7830;
            font-weight: 500;
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }
  }
  
  @media screen and (max-width: 768px) {
    margin-top: 20px;

    .back-icon{
        height: 24px ;
        width: 24px ;
        cursor: pointer ;
        
    }
    .heading-mobile-bookings{
          font-size: 20px ;
          font-weight: 600;
          line-height: 30px;
          color: #25272D ;
    }

    .head-container {
      gap: 16px;
      margin-top: 24px;

      .title-container-wrap {
        display: flex;
        justify-content: start;
        width: 100%;
        .title-container {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 14px;
          .title {
            font-size: 20px;
            font-weight: 700;
            line-height: 28px;
            color: #000000;
          }
          .team-icon-wrapper {
            padding: 4.5px 16px 4.5px 11px;
          }
  
          .event-type-wrapper {
            padding: 5px 10px 5px 10px;
          }
        }
      }
  
      .team-booking-text {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: #25272d;
        display: flex;
        gap: 6px;
      }
      .location-wrap {
        display: flex;
        height: 64px;
        align-items: center;
  
        .location-type-text {
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          color: #25272d;
          padding: 12px 19px;
          background-color: #feefe7;
          border-top-left-radius: 12px;
          border-bottom-left-radius: 12px;
        }
        .location-copy-wrap {
          border-radius: 0px 12px 12px 0px;
          border: 1px dashed #dcdcdc;
          height: 48px;
          display: flex;
          align-items: center;
          gap: 2px;
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          padding: 12px 16px;
          color: #25272d;
        }
      }
    }
    .tabs-wrapper {
      margin-top: 22px;
  
      .tabs-container {
        display: flex;
        border-bottom: 0.5px solid #dcdcdc;
  
        .tab-button {
          //   flex-grow: 1;
          padding: 0px 20px 10px 17px;
          border: none;
          border-bottom: 2px solid transparent;
          background-color: transparent;
          cursor: pointer;
          transition: border-bottom-color 0.3s, background-color 0.3s;
          color: #8f939a;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
  
        .tab-button:hover {
          background-color: #f0f0f0;
        }
  
        .tab-button.active {
          font-weight: 600;
          color: #ee7830;
          border-bottom-color: #ee7830;
        }
      }
  
      .tab-content {
        .card-wrapper {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          gap: 28px;
  
          .request-card-Wrapper {
            border: 1px solid #dcdcdc;
            border-radius: 12px;
            width: 100%;
  
            .card-heading-wrap {
              padding: 16px 16px 16px 12px;
              display: flex;
              justify-content: space-between;
              width: 100%;
  
              .card-heading-start {
                display: flex;
                align-items: center;
                gap: 7px;
  
                .booki-img-style {
                  border-radius: 100%;
                  object-fit: cover;
                  // border: 4px solid white;
                  height: 39px;
                  width: 39px;
                  // box-shadow: 0px 4px 20px 0px #0000001f;
                }
  
                .name-text {
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 24px;
                  color: #25272d;
                }
  
                .email-text {
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 22px;
                  color: #6c6d6f;
                }
              }
              .card-heading-end {
                display: flex;
                align-items: center;
                gap: 12px;
  
                .accepted-txt {
                  color: #29cc6a;
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 24px;
                  border: 0px;
                }
  
                .rejected-txt {
                  color: #fc5555;
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 24px;
                  border: 0px;
                }
              }
            }
            .horizontal-break {
              border: 1px solid #f3f3f3;
              width: 100%;
            }
            .table-wrapper {
              padding: 12px 16px 12px 12px;
  
              .attende-text {
                color: #ee7830;
                font-size: 12px;
                font-weight: 500;
                line-height: 22px;
              }
  
              .table-container{
                width: 100%;
                margin-top: 12px;
                display: grid;
                grid-template-columns: 15% 15% 15% 7.5% 7.5% 40%;
                gap:16px; 
              }

              .attende-card-wrapper{
                   display: flex;
                   flex-direction: column;
                   font-size: 10px;
                   font-weight: 400;
                   line-height: 22px;
                   padding-right: 7px;
                   color: #6C6D6F;

                   .name-age-wrapper{
                   display: flex;
                   justify-content: space-between;   

                   .name-container{
                     font-size: 12px;
                     font-weight: 500;
                     line-height: 22px;
                     color: #000000;

                   }

                   .age-gender-container{
                     display: flex;
                     gap:7px;
                     align-items: center;
                   }

                  }

                   .contact-wrapper{
                    display: flex;
                    justify-content: space-between;   

                    .email-wrapper{
                      display: flex;
                      gap:4px;
                      align-items: center;
                    }

                    .phone-wrapper{
                      display: flex;
                      gap:4px;
                      align-items: center;
                    }
                   }

                   .desc-wrapper{
                    display: flex;
                    gap:4px; 
                    align-items: start;
                   }
              }
            }
          }
        }
      }
    }
    .no-data-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .content-wrapper {
        padding-top: 161px;
        padding-bottom: 161px;
        text-align: center;

        .shadow-wrap {
          display: flex;
          justify-content: center;

          .shadow {
            width: 156px;
            height: 14px;
            background-color: #f3f3f3;
            border-radius: 50%;
            margin-top: 11px;
          }
        }

        .title-text {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #6c6d6f;
        }

        .desc-text {
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          color: #6c6d6f;
          margin-top: 12px;

          .click-text {
            color: #ee7830;
            font-weight: 500;
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }
  }
 


}
