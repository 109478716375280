.team-membership-page {
  background-color: #ffffff;
  border-radius: 12px;
  width: 100%;
  max-width: 875px;
  padding: 24px;
  margin-bottom: 0;

  .team-membership-box {
    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      color: #25272d;
      margin-bottom: 50px;
    }
  }

  .membership-card-listing {
    max-width: 732px;
    display: grid;
    gap: 32px;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 auto;

    .my-membership-card-wrap {
      max-width: 350px;
      border-radius: 12px;
      border: 1px solid #f2f2f2;
      background: #fff;
      height: fit-content;

      .top-section {
        display: flex;
        gap: 12px;

        .right {
          margin-top: 12px;

          .title {
            color: var(--FitnessMates-Pitch-Deck-Black, #25272d);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
          }

          .date {
            margin-top: 8px;
            color: var(--SecondarySteel, #8f939a);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }

      .divider {
        height: 1px;
        background: #f3f3f3;
        margin: 16px 12px;
      }

      .price-detail-box-wrap {
        margin-top: 16px;
        padding: 0 12px;

        .price-detail-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 8px;

          .left {
            display: inline-flex;
            align-items: center;
            gap: 4px;
            color: #8f939a;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
          }

          .right {
            color: #25272d;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }
      }

      .transaction-btn-box {
        padding: 0 12px;
      }

      .auto-paycut-btn-box {
        padding: 16px 12px;

        .auto-paycut-btn {
          color: #eb5757;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          border-radius: 12px;
          background: #ffe2e2;
          height: 42px;
          padding: 11px 0;
        }

        .auto-paycut-btn.disable {
          background-color: #f3f3f3;
          color: #8f939a;
        }
        .info-box-cancel-wrap {
          padding: 4px 8px;
          .info-box-cancel {
            display: flex;
            gap: 12px;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
          }
        }
      }

      .accordion-item {
        border-radius: 12px;
      }

      .accordion-button {
        background: #f3f3f3 !important;
        color: #8f939a !important;
        font-size: 12px !important;
        font-style: normal;
        font-weight: 500 !important;
        line-height: normal;
        justify-content: center;
        height: 42px;
        outline: none;

        &::after {
          rotate: -90deg;
          background-image: url("../img/chevron-down.svg") !important;
          background-size: cover;
        }

        &:focus {
          z-index: 0;
          border: none;
          box-shadow: none;
        }
      }

      .accordion-item:not(:first-of-type) {
        border: 1px solid #dee2e6;
      }

      .accordion-button:not(.collapsed) {
        background: transparent;
      }

      .accordion-button:not(.collapsed)::after {
        rotate: 0deg;
      }

      .accordion-body {
        background: var(--FitnessNonSelected, #f3f3f3);
      }

      .transactions-info-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          display: flex;
          align-items: center;
          gap: 8px;
          color: var(--SecondarySteel, #8f939a);
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-align: start;

          .date {
            color: var(--SteelBlue, #25272d);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }

        .right {
          color: var(--SteelBlue, #25272d);
          text-align: right;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
  }

  .no-content-wrap {
    padding: 100px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
      margin-top: 20px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      color: #8f939a;
    }
    .subtitle {
      margin-top: 4px;
      width: 265px;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      color: #8f939a;
      a {
        color: #ee7830;
        font-weight: 500;
      }
    }
  }

  @media screen and (max-width: "768px") {
    padding: 20px;

    .team-membership-box {
      .title {
        margin-bottom: 24px;
        display: inline-flex;
        gap: 38px;
      }
    }

    .membership-card-listing {
      grid-template-columns: repeat(1, 1fr);
      gap: 16px;
    }
  }

  .my-memberships-img {
    width: 130px;
    height: 90px;
    border-radius: 12px 0px;
    object-fit: cover;
  }
}

.gobackmodalbtn {
  background: #f3f3f3;
  color: #8f939a;
}

.modalconfirmpara {
  color: var(--SecondarySteel, #8f939a);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 24px;
}

.info-box-wrap {
  position: relative;

  .info_box {
    position: relative;
    color: var(--SecondarySteel, #8f939a);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }
}

@media screen and (max-width: "768px") {
  .modalconfirmpara {
    font-size: 14px;
  }

  .modal-top-part {
    .info-box-wrap {
      margin: 0;

      .info_box {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .purchase-popup-links-wrap {
    margin-bottom: 24px;
    font-size: 11px;
    padding: 0px;
  }

  .gobackmodalbtn {
    margin-top: 16px;
  }

  .modal-section {
    padding: 20px !important;
  }
}
