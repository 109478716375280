.flagged-card-wrapper {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 16px 16px 16px 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 9px 25px 0px #0000001A;
  gap: 16px;
  width:390px;

  @media (max-width: 767px) {
    margin: 0;
    max-width: 390px;
  }
  .user-detail-wraper {
    display: flex;

    .user-img-wrapper {
      position: relative;

      .profile-img-style {
        border-radius: 100%;
        object-fit: cover;
        border: 4px solid white;
        height: 80px;
        width: 80px;
      }
      .flag-style {
        position: absolute;
        top: 2px;
        right: -8px;
      }
    }

    .name-interest-wraper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin: 11px 5px 13px 24px;
      width: 100%;

      .name-wrap{
      //  width: 187px;
      }
      @media (max-width: 767px) {
        .name-wrap{
          // width: 140px;
         }
      }
      @media (max-width: 390px) {
        .name-wrap{
          // max-width: 140px;
         }
      }

      .text-style {
        color: #6c6d6f;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        display: flex;
        gap: 4px;
      }
    }

    .role-wrapper {
      .user-role {
        // width: 65px;
        height: 20px;
        border: 1px solid #ee762f;
        border-radius: 14px;
        padding: 5px 8px 5px 8px;
        color: #ee762f;
        font-size: 10px;
        font-weight: 500;
        display: flex;
        justify-content: start;
      }
    }
  }
}
