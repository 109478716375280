body.disable_scroll {
  overflow: hidden;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

@font-face {
  font-family: 'Futura Condensed';
  src: url('./assets/fonts/FuturaCondensedExtraBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Futura';
  src: url('./assets/fonts/FuturaBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.font_fc{
  font-family: 'Futura Condensed', sans-serif !important;
}

.font_f{
  font-family: 'Open Sans', sans-serif !important;

}

.font_heading{
  font-family: 'Futura', sans-serif !important;
}

.font_os{
  font-family: 'Open Sans', sans-serif !important;
}

body {
  font-family: 'Open Sans', sans-serif !important;

}


