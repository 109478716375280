.loader_wrapper_splash {

    width: 100%;
    height: 100%;
    min-width: 100dvw;
    min-height: 100dvh;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(255, 255, 255);

    .inner_wrap {
        display: flex;

        .spinner_img {
            width: 40px;
            height: 40px;
        }
    }
}