.my-created-page {
  background: #f9f9f9;
  padding: 60px 130px 60px 130px;

  .dominating-css{
    flex-wrap: wrap  !important;
    overflow-X: unset !important;
    padding: 0px !important;
  }
  .my-created-content-box {
    width: 100%;
    border-radius: 12px;

    .top-section {
      display: flex;
      flex-direction: column;
      gap:16px;
      .title-wrapper{
        .back-icon-style{
          display: none;
        }
        .page-title {
          font-size: 28px;
          font-weight: 600;
          line-height: 20px;
          color: #25272D;
        }
      }
      

      
      .page-desc {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #6C6D6F;
      }
    }

    .active-inactive-box {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #d9d9d9;

      .item {
        display: flex;
        flex-direction: column;
        gap: 4px;
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        width: 115px;
        height: 56px;
      }

      .active {
        border-bottom: 2px solid #25272d;
        font-size: 12px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        padding-bottom: 2.5px;
      }
    }
  }

  .more-popup-listing {
    background: rgba(0, 0, 0, 0.32);
    position: absolute;
    top: 12px;
    right: 12px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .more-popup-item-wrap {
    background: #fff;
    padding: 16px;
    position: absolute;
    top: 54px;
    right: 9px;
    width: 230px;
    max-height: 90px;
    border-radius: 12px;
  }

  .active-card-listings-box {
    // margin: 32px auto;
    // max-width: 952px;
    margin-top: 24px;

    .listing-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
    }
  }

  .no_data {
    display: flex;
    justify-content: center;
    padding-top: 60px;
  }
}

@media screen and (max-width: "768px") {
  .my-created-page {
    padding: 23px 20px 23px 20px;

    .content-box {
      padding: 16px;
    }
    .modal-imp{
      max-width: 350px;
    }
    .my-created-content-box {
      .top-section {
        padding: 16px;

        .title-wrapper{
          display: flex;
          gap:20px;

          .back-icon-style{
            display: block;
          }
          .page-title {
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
            color: #25272D;
          }
          
        }
        .page-desc {
          display: none;
        }
      }

      .active-inactive-box {
        margin-top: 0px;
      }
    }

    .active-card-listings-box {
      .listing-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
      }
    }
  }
}

@media screen and (max-width: "400px") {
  .my-created-page {
   
    .dominating-css{
      justify-content: center;
    }
  }
}
.active-inactive-listing-box {
  display: flex;
  align-items: center;
  margin-left: -32px;
  height: 33px;
  border-bottom: 1px solid #f3f3f3;
  margin-bottom: 24px;

  .item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    width: 115px;
    height: 32px;
  }

  .active {
    border-bottom: 2px solid #ee762f;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #ee762f;
    height: 32px;
  }
}

.carousel-card-see-all {
  width: 287px;
  background-color: #ffffff;
  height: 311px;
  border-radius: 12px;
  border: 1px solid #f2f2f2;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.see-all-listing-button-new {
  position: absolute;
  right: 240px;
  top: 15px;
}

.see-all-button-mobile {
  display: none;
}

@media screen and (max-width: "768px") {
  .active-inactive-listing-box {
    .item {
      width: 50%;
    }
  }

  .see-all-button-mobile {
    display: flex;
    justify-content: center;
    padding-top: 16px;
  }
}