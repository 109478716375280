.welcome-popup-container {
  // min-width: 800px !important;

  .welcome-modal-content {
    // min-height: 100vh !important;
    height: 550px;
    max-height: calc(100dvh - 60px);
    max-height: 874px;
    max-width: 818px;
    border-radius: 12px;
    // overflow-y: auto;
    background-color: white;
    // display: flex;
    // flex-direction: column;
    // align-items: center;

    @media screen and (max-width: "768px") {
      // min-height: 100vh !important;
      max-height: auto;
      width: 350px;
      height: 365px;
      display: flex;
      flex-direction: column;
      align-items: normal !important;
    }

    .welcome-modal-body {
      // border: 1px solid green;
      // padding: 51px;
      position: relative;
      color: #000000;

      .img-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;

        .gif-class {
          height: 220px;
          width: 220px;

          @media screen and (max-width: "768px") {
            width: 169px;
            height: 169px;
          }
        }
      }

      .welcome-title {
        text-align: center;
        font-size: 32px;
        font-weight: 600;
        line-height: 48px;
        @media screen and (max-width: "768px") {
          font-size: 18px;
          line-height: 27px;
          font-weight: 700;
        }
      }

      .sub-title {
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        text-align: center;
        margin: 0px 99px;
        @media screen and (max-width: "768px") {
          font-size: 11px;
        font-weight: 500;
        line-height: 16.5px;
        text-align: center;
        margin: 0px 15px;
        }
      }
      .btn-wrapper {
        margin: 56px 251px 0px 251px;
        display: flex;
        justify-content: center;
        .btn-continue {
          border: 0px;
          border-radius: 12px;
          padding: 16px 144px;
          background-color: #ee7830;
          color: #ffffff;
          font-size: 18px;
          font-weight: 500;
          line-height: 24px;
        }
        @media screen and (max-width: "768px") {
          margin: 15px 15px 24px 15px;

          .btn-continue {
            border: 0px;
            border-radius: 12px;
            padding: 14px 121px;
            background-color: #ee7830;
            color: #ffffff;
            font-size: 16.5px;
            font-weight: 500;
            line-height: 22px;
          }
        }
      }
      @media screen and (max-width: "768px") {
        padding: 0;
        margin-bottom: 102px;
        box-sizing: border-box;
      }
    }
  }
}
