@import "~react-image-gallery/styles/scss/image-gallery.scss";

.listing_two_layout {
  // margin: 76px auto;
  margin: 40px auto;
  position: relative;
  max-width: 1180px;
}

.listing_detail_back_arrow {
  position: absolute;
  left: -38px;
  top: 6px;

  @media screen and (max-width: 1200px) {
  left: 12px;
  }

}

.mid_screen_handling{
  @media screen and (min-width: 768px) and (max-width: 1200px) {
      padding: 0px 60px;
    }
}

.listing_detail_title {
  display: flex;
  align-items: center;
  gap: 21px;
  max-width: 900px;
  //   h4 {
  //     font-size: 32px;
  //     font-weight: 500;
  //     line-height: 24px;
  //   }
}

.session_btn_listing,
.team_btn_listing {
  display: flex;
  gap: 6px;
  align-items: center;
  background-color: color-mix(in srgb, #ee7830 100%, #ee762f 100%);
  border: 1px solid color-mix(in srgb, #ee7830 100%, #ee762f 100%);
  color: white;
  padding: 6px 22px 6px 14px;
  border-radius: 12px;
}

.image-carousel-button:hover {
  background: linear-gradient(167.07deg,
      #ee7830 -15.49%,
      #ee762f 149.41%) !important;
  height: 26px !important;
  width: 26px !important;
}

.event-type {
  border: 1px #EE7830 solid;
  border-radius: 21px;
  padding: 6px 15px;
  margin: 0px;
  width: 126px;
  display: flex;
  gap:6px;
  // height: 39px;

  @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    height: 39px;
    padding: 4px 15px 4px 15px;
    gap: 6px;
    border-radius: 21px;
    border: 1px #EE7830 solid;
    background: linear-gradient(167.07deg, rgba(238, 120, 48, 0.1) -15.49%, rgba(238, 118, 47, 0.1) 149.41%);
  }

  //   @media screen and (max-width: 768px) {
  // height:39px;
  // padding: 4px 15px 4px 15px!important;
  // gap: 6px;
  // border-radius: 21px!important;
  // border: 1px;
  // background: linear-gradient(167.07deg, rgba(238, 120, 48, 0.1) -15.49%, rgba(238, 118, 47, 0.1) 149.41%);

  //   }
}


.design-text {
  font-size: 14px !important;
  color: #25272D !important;
  font-weight: 400 !important;
  margin: 0px !important;
  margin-bottom: 3px !important;
}

.session_btn_popup,
.team_btn_popup {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5.64px 22.56px 5.64px 14.1px;
  gap: 5.64px;
  border-radius: 11.28px;
  // opacity: 0px;
  background: linear-gradient(167.07deg, #EE7830 -15.49%, #EE762F 149.41%);
  border: 1.41px solid #F3F3F3;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 500;
  line-height: 22.56px;
  text-align: center;

  @media (max-width: 768px) {

    height: 40px;
    padding: 5.64px 22.56px 5.64px 14.1px;
    gap: 5.64px;
    border-radius: 11.28px;
    background: linear-gradient(167.07deg, #EE7830 -15.49%, #EE762F 149.41%);
    border: 1.41px solid #F3F3F3;
  }

}

.package_btn_listing {
  display: flex;
  gap: 6px;
  align-items: center;
  background-color: #313638;
  border: 1px solid #f3f3f3;
  color: white;
  padding: 6px 22px 6px 14px;
  border-radius: 8px;
}

.package_btn_popup {
  display: inline-flex;
  gap: 6px;
  align-items: center;
  background-color: #313638;
  border: 1px solid #f3f3f3;
  color: white;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 0px;
  padding: 0px 4px;
  margin-right: 16px;
}

.listing_detail_buttons {
  display: flex;
  gap: 20px;

  button {
    display: flex;
    gap: 4px;
    align-items: center;
    background-color: transparent;
    border: 0px;
    text-decoration: underline;
  }
}

.listing_img_container {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  // gap: 10px;
  position: relative;
  max-height: 450px;
}

.listing_detail_sec_img {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 290px);
  gap: 8px;
}

.listing_img {
  // background-color: #d9d9d9;
  // border: 1px solid #d9d9d9;
  position: relative;
  max-height: 450px;
  // overflow-x: auto;
  overflow: hidden;
}

.listing_gallery_content {
  .list_five {
    position: relative;
    max-height: 450px;
    // overflow-x: auto;
    overflow: hidden;

  }

  .list_five img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    position: relative;
  }

  .list_five img:hover {
    opacity: 0.6;
  }

  .list_five video:hover {
    opacity: 0.6;
  }

  .list_five:nth-child(1) {
    grid-area: 1 / 1 / 3 / 2;
    /* Span two rows, column 1 */
  }

  .list_five:nth-child(2) {
    grid-area: 1 / 2 / 2 / 3;
    /* Span two rows, column 2 */
  }

  .list_five:nth-child(3) {
    grid-area: 1 / 3 / 2 / 4;
    /* Row 1, column 3 */
  }

  .list_five:nth-child(4) {
    grid-area: 2 / 2 / 3 / 2;
    /* Row 2, column 3 */
  }

  .list_five:nth-child(5) {
    grid-area: 2 / 3 / 3 / 3
      /* Row 2, column 3 */
  }

  .list_img {
    // background-color: #d9d9d9;
    // border: 1px solid #d9d9d9;
    position: relative;
    max-height: 450px;
    // overflow-x: hidden;
  }

  .listing_img {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
      cursor: pointer;
    }

    video :hover {
      opacity: 0.6;
    }

    img :hover {
      opacity: 0.6;
    }
  }
}

.listing_detail_buttons {
  @media screen and (max-width: 768px) {
    flex-direction: row !important;
    justify-content: center !important;
  }

}

.list_five {
  position: relative;
  max-height: 450px;
  // overflow-x: auto;

}

// .list_five :hover {
//   opacity: 0.5;
// }

.list_five img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  // cursor: pointer;
  position: relative;
}

// .list_five img:hover {
//   opacity: 0.6;
// }

// .list_five video:hover {
//   opacity: 0.6;
// }

.list_five:nth-child(1) {
  grid-area: 1 / 1 / 3 / 2;
  /* Span two rows, column 1 */
}

.list_five:nth-child(2) {
  grid-area: 1 / 2 / 2 / 3;
  /* Span two rows, column 2 */
}

.list_five:nth-child(3) {
  grid-area: 1 / 3 / 2 / 4;
  /* Row 1, column 3 */
}

.list_five:nth-child(4) {
  grid-area: 2 / 2 / 3 / 2;
  /* Row 2, column 3 */
}

.list_five:nth-child(5) {
  grid-area: 2 / 3 / 3 / 3
    /* Row 2, column 3 */
}

.list_img {
  // background-color: #d9d9d9;
  // border: 1px solid #d9d9d9;
  position: relative;
  max-height: 450px;
  // overflow-x: hidden;
}

.list_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}

.list_img:nth-child(1) {
  grid-area: 1 / 1 / 3 / 2;
  /* Span two rows, column 1 */
}

.list_img:nth-child(2) {
  grid-area: 1 / 2 / 3 / 3;
  /* Span two rows, column 2 */
}

.list_img:nth-child(3) {
  grid-area: 1 / 3 / 2 / 4;
  /* Row 1, column 3 */
}

.list_img:nth-child(4) {
  grid-area: 2 / 3 / 3 / 4;
  /* Row 2, column 3 */
}

.listing_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}

.show_gallery_btn {
  position: absolute;
  bottom: 23px;
  right: 22px;
  background-color: #25272dc2;
  border: 1px solid #dcdcdcc2;
  border-radius: 12px;
  padding: 13px 21px;
  color: white;
  display: flex;
  align-items: center;
  gap: 9px;
}

.show_gallery_btn img {
  width: 24px;
  height: 24px;
}

.video_icon {
  position: absolute;
  top: 42%;
  right: 38%;
}

.listing_detail_information {
  display: flex;
  justify-content: space-between;
}

.listing_detail_content {
  width: 58%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: #6C6D6F;
    margin-bottom: 27px;

    span {
      font-size: 20px;
      font-weight: 500;
      line-height: 16px;
      color: #0099ff;
    }
  }

  div {
    // margin-bottom: 22px;

    h4 {
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 16px;

      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
    }

    button {
      border: 1px solid #DCDCDC;
      border-radius: 12px;
      padding: 10px 32px 10px 10px;
      background-color: white;
      color: #25272d;
      margin-right: 12px;
      text-align: left;
      font-weight: 500;

      img {
        margin-right: 4px;
      }
    }
  }
}

.button-gender {
  border: 1px solid #DCDCDC;
  border-radius: 12px;
  padding: 10px 32px 7px 10px;
  background-color: white;
  color: #25272d;
  margin-right: 12px;
  text-align: left;
  font-weight: 500;

  @media screen and (max-width: 768px) {
    padding: 10px 10px 10px 10px;
    margin-right: 5px;
    margin-bottom: 10px;
    justify-content: left !important;
  }

  img {
    margin-right: 4px;
  }

  button {
    @media screen and (max-width: 768px) {
      margin-bottom: 0px !important;
    }
  }
}

.read-more {
  color: #EE7830 !important;
  background: none !important;
  border: none !important;
  text-decoration: underline;
  margin-left: 5px;
}

.scroll-img {
  width: 32px;
  height: 32px;
  cursor: pointer;
  // left: 55%;
  // position: absolute;
}

.carousal-item-custom div:nth-child(2) {
  border-radius: 12px !important;
}

.slider-item {
  background-color: pink;
  width: 200px;
  height: 200px;

  h3 {
    background-color: red;
    color: blue;
  }
}

.slick-event {
  margin-top: 15px;

  .slick-slider {
    .slick-list {
      .slick-track {
        display: block !important;

        .slick-slide {
          div {
            height: 300px !important;

            img {
              height: 300px;
              object-fit: cover;
            }

            video {
              height: 300px;
            }
          }
        }
      }
    }
  }
}

.overflow-outer {
  width: auto;
  height: 75px;
  left: 56%;
  position: absolute;
  transition: filter 0.3s ease-in-out;
  z-index: 0;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, 0.2);
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    left: 90% !important;
    height: 125px;
    display: none !important;
  }
}

.aval-datesss {
  @media screen and (max-width: 768px) {
    justify-content: space-between !important;
    display: contents !important;
  }

  .span {
    @media screen and (max-width: 768px) {
      padding: 6px !important;
    }
  }
}

.disabled-date-card{
  background: #d9d9d9;
}

.individual-image-video-block {
  display: flex;
  flex-flow: wrap;
  box-sizing: border-box;
  justify-content: center;
  width: 100%;
}

.individual-image-video-block-2 {
  max-width: 100%;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-flow: wrap;
  padding: 0px;
  overflow: hidden;
  max-width: 320px;
  border-radius: 12px !important;

  img {
    height: 300px;
  }
}

.scroll-img-2 {
  width: 32px;
  height: 32px;
  cursor: pointer;
  // left: 55%;
  // position: absolute;
  -moz-transform: scale(-1, -1);
  -o-transform: scale(-1, -1);
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
}

.overflow-outer-2 {
  width: auto;
  height: 75px;
  left: -3px;
  position: absolute;
  transition: filter 0.3s ease-in-out;
  z-index: 0;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, 0.2);
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    height: 125px;
    display: none !important;
  }

}

.overflow-div {
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
  padding: 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-left: 0px;
  padding-right: 35px;
  border-bottom: 1px solid rgb(220, 220, 220);

  @media screen and (max-width: 768px) {
    padding: 10px !important;
    display: inline-flex !important;
    justify-content: left !important;
    border-bottom: none;
  }
}

.aval-box {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 30.5px 8px 30.5px;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  width: auto;
  max-width: 30%;
  justify-content: center;
  align-items: center;
  color: #EE7830;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 0px !important;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    border: none;
  }
}

.team_listing_data {
  min-width: 466px;

  @media screen and (max-width: 1000px) {
    min-width: 400px;
  }

  @media screen and (max-width: 900px) {
    min-width: 300px;
  }

  @media screen and (max-width: 900px) {
    min-width: 300px;
  }

  @media screen and (max-width: 900px) {
    min-width: 250px;
  }
}

.listing_booking_card_bottom {
  box-shadow: 0px 4px 4px 0px #0000000d;
  background-color: #FEF7F3;
  border-radius: 12px;
  padding: 22px 20px 30px 20px;
  width: 100%;
  max-width: 615px;
  max-height: 355px;
  position: fixed;
  bottom: 20px;
  border: 1px #EE7830 solid;
  left: 30%;
  z-index: 5;

  .bottom_btn_section {
    display: flex;
    width: 100%;
    gap: 24px;
    align-items: center;

    .price_section_sticky {
      font-size: 32px;
      font-weight: 500;
      line-height: 28px;
      width: fit-content;
      color: #25272D;

      span {
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
      }
    }

    .btn_section_sticky {
      flex-grow: 1;

      .action_sticky {
        width: 100% !important;
        margin: 0 !important;
      }
    }
  }

  .info_event_data {
    .cancel-img {
      width: 16px;
      height: 16px;
    }

    display: flex;
    gap: 4px;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    background: #FFFFFF;
    padding: 10px 12px;
    border-radius: 10px;
    margin-bottom: 10px;

    .cancel_text_data {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      color: #25272D;
      box-sizing: border-box;
      display: inline;
      width: 100%;

      span {
        font-size: 12px;
        font-weight: 600;
        line-height: 24px;
        color: #EE7830;
        text-decoration: underline;
      }
    }

  }

  @media screen and (max-width: 768px) {
    left: 0px;
    width: 100%;
    padding: 22px 20px;
    max-width: 100%;
    background-color: #FEF7F3;
    box-shadow: 0px 4px 50px 0px #0000003D;
    border: none;
    bottom: 0px;
  }

  .cancel-img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .cancel-text {
    font-size: 12px;
    font-weight: 400;
    width: 100%;
    padding-top: 4px;

    span {
      color: #EE7830;
      font-size: 12px;
      margin: 0px 4px;
      line-height: 24px;
      text-decoration: underline;
      font-weight: 500;
    }
  }

  .align-center {
    align-items: center;

    @media screen and (max-width: 768px) {
      align-items: center;
      flex-direction: initial;
    }
  }

  p {
    font-size: 32px;
    font-weight: 500;
    line-height: 28px;
    color: #25272d;
    margin-bottom: 0px;

    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      color: #25272d;
    }
  }

  .booking_card_sessions,
  .booking_card_team {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: color-mix(in srgb, #ee7830 100%, #ee762f 100%);
  }

  button {
    width: 60%;
    background-color: color-mix(in srgb, #ee7830 100%, #ee762f 100%);
    border: 1px solid color-mix(in srgb, #ee7830 100%, #ee762f 100%);
    border-radius: 12px;
    color: white;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    padding: 16px 0px;
    margin: 5px 0px 10px 0px;

    @media screen and (max-width: 768px) {
      padding: 5px 0px;
      width: 45%;
      margin: 5px 0px 0px 0px;
      margin-right: 0px;
      margin-left: 0px;
      font-size: 12px;
      margin-left: auto;
      margin-right: 14px;
    }
  }

  div {
    // padding-top: 24px;
    // border-top: 4px solid white;

    h4 {
      color: #25272d;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 4px;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: #25272d;
      margin-bottom: 0px;
      display: flex;
      width: 40%;
      font-size: 32px;
      font-weight: 500;

      @media screen and (max-width: 768px) {
        font-size: 16px;
        font-weight: 500;
        flex-wrap: wrap;
      }

      span {
        font-weight: 400;
        font-size: 16px;

        @media screen and (max-width: 768px) {
          font-size: 10px;
          font-weight: 500;
        }

        // text-decoration: underline;
      }
    }
  }

  .button-shrinker {
    width: 28%;
    font-size: 13px;
    padding: 10px 0px;
    margin: 2px;
  }
}

.listing_booking_card {
  box-shadow: 0px 4px 4px 0px #0000000d;
  background-color: #ee762f0f;
  border-radius: 12px;
  padding: 30px 36px 30px 32px;
  width: 100%;
  max-width: 466px;
  // max-height: 275px;
  position: sticky;
  top: 75px;

  p {
    font-size: 32px;
    font-weight: 500;
    line-height: 28px;
    color: #25272d;
    margin-bottom: 0px;


    @media screen and (max-width: 768px) {
      font-size: 18px;
      font-weight: 400;
      line-height: 10px;
    }

    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      color: #25272d;
    }
  }

  .list_five {
    img {
      cursor: pointer;
    }


    img :hover {
      opacity: 0.7;
    }
  }

  .booking_card_sessions,
  .booking_card_team {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: color-mix(in srgb, #ee7830 100%, #ee762f 100%);
  }

  button {
    width: 100%;
    background-color: color-mix(in srgb, #ee7830 100%, #ee762f 100%);
    border: 1px solid color-mix(in srgb, #ee7830 100%, #ee762f 100%);
    border-radius: 12px;
    color: white;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    padding: 16px 0px;
    // margin-top: 24px;
    // margin-bottom: 24px;

    @media screen and (max-width: 768px) {
      padding: 10px 0px;
      margin: 10px 0px;
    }
  }

  div {
    padding-top: 10px;
    border-top: 4px solid white;

    h4 {
      color: #25272d;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 4px;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: #25272d;
      margin-bottom: 0px;

      span {
        font-weight: 600;
        text-decoration: underline;


        @media screen and (max-width: 768px) {
          font-size: 12px;
          margin: 0px 3px !important;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .width-for-interest {
    max-height: 200px !important;
    margin: auto;
    margin-top: 0px;
    // max-width: 400px !important;
    text-align: center;
    left: 35% !important;
  }

  .width-for-interest p {
    width: 50%;
    margin: auto;
  }

  .width-for-interest button {
    margin: auto;
  }
}



.listing_detail_description {
  padding: 8px 0px 36px 0px;
  border-bottom: 1px solid #dcdcdc;
  // width: 60%;

  h4 {
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    margin-bottom: 12px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 21.87px;
    text-align: left;
    color: #25272d;
    margin-bottom: 0px;
  }
}

.listing_detail_interest {
  padding: 30px 0px;
  border-bottom: 1px solid #dcdcdc;
  // width: 60%;

  h4 {
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    margin-bottom: 28px;
  }

  div {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    span {
      padding: 8px 8px 8px 12px;
      border: 1px solid #dcdcdc;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #25272d;
    }
  }
}

.listing_detail_dates {
  padding: 15px 0px;
  border-bottom: 1px solid #dcdcdc;

  h4 {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    text-align: left;
    margin-bottom: 30px;
  }

  div {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;

    span {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 8px 10px 8px 10px;
      border: 1px solid #dcdcdc;
      border-radius: 8px;

      h5 {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        color: #25272d;
        margin-bottom: 0px;
      }

      p {
        display: flex;
        gap: 5px;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: center;
        color: #6C6D6F;
        margin-bottom: 0px;

        b {
          color: #25272d;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
        }
      }
    }

    .active-date {
      border: 1px solid #ee7830;
    }
  }

  button {
    margin-top: 32px;
    padding: 16px 65px;
    border: 1px solid #8f939a;
    border-radius: 12px;
    box-shadow: 0px 4px 32px 0px #00000014;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #25272d;
    background-color: white;

    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
}

.virtual-icon {
  width: 115px;
}

.session_detail_dates {
  padding: 30px 0px;
  border-bottom: 1px solid #dcdcdc;

  h4 {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    text-align: left;
    margin-bottom: 30px;
  }

  div {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;

    span {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 12px;
      border: 1px solid #dcdcdc;
      border-radius: 8px;
      width: 45%;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      h5 {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: #25272D;
        margin-bottom: 0;
      }

      p {
        display: flex;
        gap: 5px;
        justify-content: flex-start;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        color: #6C6D6F;
        margin-bottom: 0px;
      }

      .open-map {
        color: #ee7830 !important;
        font-size: 13px !important;
        line-height: 20px !important;
        margin-bottom: 0 !important;
        text-decoration: underline !important;
      }

      .number-circle {
        background-image: linear-gradient(to right, #ee78303b, #ee762f3b);
        width: 24px;
        height: 24px;
        border-radius: 20px;
        justify-content: center;
        align-items: center;
        font-size: 12px;
      }
    }

    .active-date {
      border: 1px solid #ee7830;
    }
  }

  button {
    margin-top: 32px;
    padding: 16px 65px;
    border: 1px solid #8f939a;
    border-radius: 12px;
    box-shadow: 0px 4px 32px 0px #00000014;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #25272d;
    background-color: white;
  }
}

.listing_detail_instruction {
  padding: 30px 0px 50px 0px;
  border-bottom: 1px solid #dcdcdc;
  width: 55%;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 20px 0px 20px 0px;
  }

  h4 {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    text-align: left;
    margin-bottom: 6px;

    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
  }

  ul {
    display: grid;
    grid-template-columns: auto;
    margin-bottom: 0px;

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    li {
      margin-top: 24px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      padding-right: 20px;

      @media screen and (max-width: 768px) {
        margin-top: 5px;
        font-size: 14px;
        font-weight: 400;
      }
    }

    li:nth-child(odd) {
      padding-right: 20px;
      // margin-right: 115px;
    }
  }
}

.listing_detail_map {
  padding: 30px 0px 6px 0px;
  border-bottom: 1px solid #dcdcdc;

  @media screen and (max-width: 768px) {
    padding: 20px 0px 6px 0px;
  }

  .details_address_container{
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    width: calc(100% - 120px);
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  h4 {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    text-align: left;
    margin-bottom: 16px;

    @media screen and (max-width: 768px) {
      font-size: 16px;
      margin-bottom: 5px;
    }
  }

  div {
    // display: flex;
    justify-content: space-between;
    margin-bottom: 22px;

    h5 {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;

      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
    }

    span {
      text-decoration: underline;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      color: #0099ff;

      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
}

.listing_detail_map {
  padding: 30px 0px 6px 0px;
  border-bottom: 1px solid #dcdcdc;

  @media screen and (max-width: 768px) {
    padding: 20px 0px 6px 0px;
  }

  h4 {
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    text-align: left;
    margin-bottom: 16px;

    @media screen and (max-width: 768px) {
      font-size: 16px;
      margin-bottom: 5px;
    }
  }

  div {
    // display: flex;
    justify-content: space-between;
    margin-bottom: 22px;

    h5 {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;

      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
    }

    span {
      text-decoration: underline;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      color: #0099ff;

      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
}

.listing_detail_reviews {
  padding: 30px 0px;
  border-bottom: 1px solid #dcdcdc;

  @media screen and (max-width: 768px) {
    padding: 15px 0px;
  }

  h4 {
    font-weight: 500;
    font-size: 24px;
    line-height: 43px;
    color: #25272d;
    margin-bottom: 30px;

    @media screen and (max-width: 768px) {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }

  div {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    gap: 40px 150px;

    .review_box {
      display: flex;
      flex-direction: column;
      gap: 0px;

      div {
        display: flex;
        flex-direction: row;
        gap: 12px;
        margin-bottom: 13px;

        @media screen and (max-width: 768px) {
          flex-direction: column !important;
        }

        div {
          display: flex;
          flex-direction: column;
          gap: 0px;
          margin-bottom: 0px;

          h5 {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: #25272d;
          }

          p {
            font-size: 12px;
            font-weight: 500;
            line-height: 12px;
            color: #25272d;
            margin-bottom: 0px;

            span {
              font-size: 12px;
              font-weight: 400;
              line-height: 12px;
              color: #bdbdbd;
            }
          }
        }
      }

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #25272d;
      }
    }
  }

  button {
    margin-top: 30px;
    padding: 16px 100px;
    border: 1px solid #8f939a;
    border-radius: 12px;
    box-shadow: 0px 4px 32px 0px #00000014;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #25272d;
    background-color: white;

    @media screen and (max-width: 768px) {
      margin-top: 10px;
      border: none;
      border-radius: 12px;
      box-shadow: none;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: #25272d;
      background-color: white;
      width: 100%;
      color: #EE7830;
      text-decoration: underline;
      padding: 0px;
    }
  }
}

.listing_detail_listner {
  padding: 35px 0px 8px 0px;

  @media screen and (max-width: 768px) {
    padding: 10px 0px 10px 0px;
  }

  h4 {
    font-weight: 500;
    font-size: 24px;
    line-height: 43px;
    color: #25272d;
    margin-bottom: 30px;
  }

  .listner_box {
    background-color: #f3f3f3;
    padding: 26px 12px 20px 44px;

    div {
      display: flex;
      margin-bottom: 18px;
      gap: 18px;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;

      div {
        flex-direction: column;
        align-items: start;
        gap: 0px;
        margin-bottom: 0px;

        h5 {
          font-size: 24px;
          font-weight: 500;
          line-height: 36px;
          color: #25272d;
          margin-bottom: 0px;
        }

        p {
          font-size: 24px;
          font-weight: 400;
          line-height: 36px;
          color: #25272d;
          margin-bottom: 0px;
        }
      }
    }
  }
}

.listing_gallery_layout {
  padding: 44px 50px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: wrap;

  img {
    // width: 100%;
    width: auto;
    max-width: 100%;
  }
}

.dates_modal_layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .modal_close_btn {
    position: absolute;
    right: 32px;
    top: 32px;
  }

  h4 {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: #25272d;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #6C6D6F;
  }

  .modal_availabel_dates {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    position: relative;

    span {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 8px 23.5px 8px 17.5px;
      border: 1px solid #dcdcdc;
      border-radius: 8px;

      h5 {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        color: #25272d;
        margin-bottom: 0px;
      }

      p {
        display: flex;
        gap: 5px;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: center;
        color: #6C6D6F;
        margin-bottom: 0px;

        b {
          color: #25272d;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
        }
      }
    }
  }

  .modal_availabel_sessions {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    position: relative;

    span {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 12px;
      border: 1px solid #dcdcdc;
      border-radius: 8px;
      width: 33%;

      h5 {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        color: #25272d;
        margin-bottom: 0px;
      }

      p {
        display: flex;
        gap: 5px;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        color: #6C6D6F;
        margin-bottom: 0px;
      }

      .open-map {
        color: #ee7830 !important;
        font-size: 12px !important;
        line-height: 20px !important;
        margin-bottom: 0 !important;
        text-decoration: underline !important;
      }

      .number-circle {
        background-image: linear-gradient(to right, #ee78303b, #ee762f3b);
        width: 24px;
        height: 24px;
        border-radius: 20px;
        justify-content: center;
        align-items: center;
        font-size: 12px;
      }

      div {
        display: flex;
        gap: 20px;
      }
    }
  }

  button {
    border-radius: 12px;
    background-color: color-mix(in srgb, #ee7830 100%, #ee762f 100%);
    border: 1px solid color-mix(in srgb, #ee7830 100%, #ee762f 100%);
    box-shadow: 0px 4px 32px 0px #00000014;
    padding: 16px 152.5px;
    color: white;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
  }
}

.share_options span {
  cursor: pointer;
}

.share_modal_layout {
  .share_modal_close_btn {
    position: absolute;
    right: 26px;
    top: 24px;
  }

  h4 {
    @media screen and (min-width: 768px) {
      padding-top: 20px;
    }

    font-size: 26px;

    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #25272d;
  }

  .share_modal_details {
    display: flex;
    gap: 15px;
    margin-top: 42px;
    margin-bottom: 36px;
    padding: 0px 32px;
    align-items: flex-start;

    @media screen and (max-width: 768px) {
      padding: 0px 8px;
    }

    .title {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: #000000;

      flex-grow: 1;
    }

    .sub-title {
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #000000;
    }
  }

  .share_options {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    padding: 0px 24px 32px;

    @media screen and (max-width: 768px) {
      padding: 0px 8px 16px 8px;
    }

    span {
      display: flex;
      justify-content: start;
      gap: 10px;
      align-items: center;
      border: 1px solid #8f939a;
      border-radius: 12px;
      padding: 12px 137px 12px 12px;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
      color: #25272d;
      max-width: 48%;

      @media screen and (max-width: 768px) {
        max-width: 100%;
        font-size: 14px;
      }
    }


  }


  @media screen and (min-width: 235px) and (max-width:700px) {
    .back-btn {
      display: none;
    }
  }



  // button {
  //   border-radius: 12px;
  //   // background-color: color-mix(in srgb, #ee7830 100%, #ee762f 100%);
  //   // border: 1px solid color-mix(in srgb, #ee7830 100%, #ee762f 100%);
  //   box-shadow: 0px 4px 32px 0px #00000014;
  //   padding: 16px 152.5px;
  //   color: white;
  //   font-size: 18px;
  //   font-weight: 500;
  //   line-height: 24px;
  //   text-align: center;
  // }
}




.head-class {
  width: 100% !important;
  margin: auto !important;
  flex-direction: initial !important;
  align-items: center !important;
}

@media screen and (max-width: 768px) {
  .listing_detail_instruction {
    ul {
      padding-left: 15px !important;
    }
  }

  .listner_box p {
    font-size: 14px;
  }

  .listing_two_layout {
    margin: 39px 20px;
    display: flex;
    flex-direction: column;

    .listing_detail_back_arrow {
      position: absolute;
      left: 0px;
      top: 8px;
      z-index: 2;
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: left;

      .listing_detail_head_content {
        order: 1;
        // margin: 0px 0px 33px 0px;
        position: relative;

        .listing_detail_conatiner {
          padding: 10px;
          margin-top: 2.3rem;
          flex-direction: column;
          gap: 4px;

          h4 {
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
          }
        }

        .listing_detail_buttons {
          flex-direction: row;
          justify-content: end;
          gap: 15px;
          position: absolute;
          top: 6px;
          right: 0px;

          button {
            background-color: #25272d1a;
            border-radius: 100px;
            padding: 6px;

            img {
              width: 13px;
              height: 15px;
            }

            span {
              display: none;
            }
          }
        }

        .listing_detail_buttons_left {
          flex-direction: row;
          justify-content: end;
          gap: 15px;
          position: absolute;
          top: 6px;
          left: 0px;

          button {
            background-color: #25272d1a;
            border-radius: 100px;
            padding: 6px;

            img {
              width: 13px;
              height: 15px;
            }

            span {
              display: none;
            }
          }
        }
      }

      .listing_img_container {
        order: 2;
        margin-top: 0px;
        grid-template-columns: 1fr;

        .listing_detail_sec_img {
          grid-template-columns: 1fr;
          grid-template-rows: repeat(3, 160px);
        }
      }
    }
  }

  .listing_detail_information {
    order: 3;

    .listing_detail_content {
      order: 2;
      border-top: 1px solid #dcdcdc;
      padding: 20px;

      @media screen and (max-width: 768px) {
        padding: 0px;
        padding-top: 15px;
      }

      div {
        flex-direction: row;
        flex-wrap: wrap;
        // margin-bottom: 10px;

        h4 {
          width: 100%;
        }

        button {
          padding: 8px 10px;
          font-size: 14px;
          margin-bottom: 10px;

          @media screen and (max-width: 768px) {
            padding: 0px;
          }
        }
      }
    }

    .listing_booking_card {
      order: 1;
      margin-bottom: 20px;
      padding: 20px;
    }
  }

  .listing_detail_description {
    padding: 0px;
    order: 2;
    border: 0px;

    h4 {
      font-size: 16px;
      font-weight: 500;
      // display: none;
    }
  }

  .listing_detail_interest {
    margin: 0px 0px;
    padding: 10px;
    padding-bottom: 20px;

    h4 {
      font-size: 14px;
      margin-bottom: 10px;
    }

    .interent_types {
      flex-direction: row;
      gap: 12px;

      @media screen and (max-width: 768px) {
        justify-content: left;
      }

      span {
        font-size: 14px;
      }
    }
  }

  .listing_detail_dates {
    margin: 0px 0px;
    justify-content: center;

    div {
      flex-direction: row;
      gap: 12px;

      span {
        padding: 6px 3px;
        margin-right: 6px;
        margin-bottom: 9px;
        border-color: #DCDCDC;
      }
    }

    h4 {
      font-size: 14px;
      margin-bottom: 15px;
    }

    button {
      padding: 16px;
    }
  }

  .listing_detail_dates_new {
    div {
      span {
        padding: 6px 0px;
        margin-right: 4px;
        margin-bottom: 9px;
        border-color: #DCDCDC;
      }
    }
  }

  .listing_detail_map,
  .listing_detail_reviews {
    margin: 0px 0px;
  }

  .listing_detail_listner {
    margin: 0px 0px;
    padding: 20px 0px 20px 0px;

    h4 {
      font-size: 16px;
      margin-bottom: 16px;
    }

    .listner_box {
      padding: 16px;
      border-radius: 12px;

      div {
        flex-direction: row;
        font-size: 14px;
        line-height: 24px;

        div {
          img {
            width: 85px;
            height: 85px;
          }

          h5 {
            font-size: 16px;
            line-height: 33px;
          }

          p {
            font-size: 12px;
            line-height: 24px;
          }
        }
      }
    }
  }

  .dates_modal_layout {
    .modal_availabel_dates {
      span {
        padding: 11.5px 24.5px 11.5px 18.5px;
      }
    }

    button {
      padding: 16px 88px;
      width: 100%;
    }
  }

  .share_modal_layout {
    padding-top: 20px;

    .share_modal_details {
      // flex-wrap: wrap;
      gap: 10px;

      img {
        width: 40px !important;
        height: 40px !important;
        border-radius: 12px;
      }

      video {
        width: 50px !important;
        height: 50px !important;
        border-radius: 12px;
      }

      .session_btn_popup {
        width: 50%;

      }

      .team_btn_popup {
        width: 50%;

        img {
          width: 30px !important;
          height: 30px !important;
        }
      }

      .package_btn_popup {
        width: 50%;
        max-height: 50px;

      }

      div {
        img {
          width: 40px;
          height: 40px;
          border-radius: 12px;
        }

        h5 {
          font-size: 16px;
          line-height: 24px;
        }

        p {
          font-size: 12px;
          line-height: 24px;
        }

        button {
          padding: 6px 22px 6px 15px;

          img {
            width: 22px;
            height: 22px;
          }
        }
      }
    }

    button {
      padding: 16px 88px;
      width: 100%;
    }
  }

  .listing_detail_dates_new div {
    justify-content: center !important;
  }
}

@media screen and (max-width: 375px) {
  .listing_detail_dates_new div span {
    margin-right: 0px !important;
  }
}

@media screen and (max-width: 768px) {
  .dynamic-content-slick img {
    max-width: 100%;
  }

  .dynamic-content-slick video {
    max-width: 100%;
  }

  .listing_img {
    // overflow-x: inherit !important;
    overflow: auto;
    background: white;
  }

  .listing_img img {
    flex: 33%;
    padding: 10px;
    border-radius: 25px;
    //width: 50% !important;
  }

  .list_five {
    overflow-x: inherit !important;
    background: white;
  }

  .list_five img {
    flex: 33%;
    padding: 10px;
    border-radius: 25px;
    //width: 50% !important;
  }

  .list_img {
    overflow-x: inherit !important;
    background: white;
  }

  .list_img img {
    flex: 33%;
    padding: 10px;
    border-radius: 25px;
    //width: 50% !important;
  }

  .listing_detail_description {
    margin-top: 15px;
  }

  .listing_img {
    border: none !important;
  }

  .show_gallery_btn {
    padding: 0px !important;
    right: 12px;
    gap: 0px;
    font-size: 10px !important;
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 aspect ratio */
  height: inherit;
  overflow: hidden;
  border-radius: 12px;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 12px;
}

.box-layout {

  height: 400px;

  width: auto;

  background-image: radial-gradient(circle at 56% 18%, #ee7730, rgb(238, 119, 48) 37%, rgba(0, 0, 0, 0))
}

.box-new-shadow {
  background-image: radial-gradient(circle at 56% 18%, #ee7730, rgb(238, 119, 48) 37%, rgba(0, 0, 0, 0))
}

.video-grid-special {
  max-height: 450px !important;
  width: 100%;
  object-fit: cover;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 40px;
  width: 40px;
}

.fullscreen .image-gallery-slide img {
  max-height: 100vh;
}

.fullscreen .image-gallery-slide video {
  max-height: 100vh;
}

.p-l-0 {
  padding-left: 0px !important;
}

// .image-gallery {
//   width: 40%;
//   height: auto;
// }

// .image-gallery-slide img {
//   width: 100%;
//   height: auto;
//   max-height: 80vh;
//   object-fit: cover;
//   overflow: hidden;
//   object-position: center center;
// }

// .image-gallery-slide video {
//   width: 100%;
//   height: auto;
//   max-height: 80vh;
//   object-fit: cover;
//   overflow: hidden;
//   object-position: center center;
//   max-height: calc(100vh - 80px) !important;
// }

// .fullscreen .image-gallery-slide img {
//   max-height: 100vh;
// }

.fullscreen .image-gallery-slide video {
  max-height: 100vh;
}

.image-gallery-div {
  position: absolute;
  width: 100%;
  height: 100% !important;
  max-height: 100%;
  background: rgb(0, 0, 0) !important;
  left: 0px !important;
  transition: opacity 0.2s 0.2s !important;

  .image-carousel-button {
    display: inline !important;

    &.next_arrow {
      right: 20px !important;
    }

    &.prev_arrow {
      left: 20px !important;
    }

  }
}

.image-gallery-div img {
  // width: 100%;
  // height: 100%;
  // position: absolute;
  // object-fit: contain;
  // display: inline-block;
}

.list_img {
  cursor: pointer;
}

.list_five {
  cursor: pointer;
}

.list_five :hover {
  opacity: 0.6;
}

.list_img :hover {
  opacity: 0.6;
}

.listing_img :hover {
  opacity: 0.6;
  cursor: pointer;
}

.image-gallery-div .slick-dots li.slick-active button::before {
  color: white !important;
}

.image-gallery-div .slick-dots li button::before {
  color: white !important;
}

.slick-slide {
  height: auto; // ← that must not be ignored
}

.slick-track {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
}

.dynamic-content-slick {
  margin-top: 100px;
}

.dynamic-content-slick img {
  max-height: 500px !important;
  max-width: auto;
}

.dynamic-content-slick video {
  max-height: 500px !important;
  max-width: auto;
}

.list_five .image-style-1 {
  max-height: 225px !important;
}

// .image-style-1 {
//   max-height: 225px !important;
// }

.list_five .image-style-2 {
  max-height: 225px !important;
}

// .image-style-2 {
//   max-height: 225px !important;
// }

.list_five .image-style-3 {
  max-height: 225px !important;
}

// .image-style-3 {
//   max-height: 225px !important;
// }

.list_five .image-style-4 {
  max-height: 225px !important;
}

// .image-style-4 {
//   max-height: 225px !important;
// }

@media screen and (max-width: 768px) {


  .custom-card-position {
    position: absolute;
    top: 15% !important;
    left: 7% !important;
  }

  .mobile-time-card {
    width: 160px !important;
    // margin-right: 0px !important;
  }

  .mobile-time-card h5 {
    font-size: 12px !important;
  }

  .mobile-time-card p {
    font-size: 10px !important;
  }

  .listing_detail_content div button {
    margin-right: 3px;
  }

  .button-gender {
    width: 100%;
  }

  .listing_detail_book_now {
    margin-top: 0px;
    box-shadow: 0px 4px 4px 0px #0000000d;
    background-color: #ee762f0f;
    border-radius: 12px;
    padding: 20px 25px 20px 25px;
    width: 100%;
    max-width: 466px;
    max-height: 275px;
    // position: sticky;
    top: 75px;
  }

  .listing_detail_book_now p {
    font-size: 32px;
    font-weight: 500;
    line-height: 28px;
    color: #25272d;
    margin-bottom: 0px;
  }

  .listing_detail_book_now button {
    width: 328px !important;
    height: 56px !important;
    background-color: color-mix(in srgb, #ee7830 100%, #ee762f 100%) !important;
    border: 1px solid color-mix(in srgb, #ee7830 100%, #ee762f 100%) !important;
    border-radius: 12px !important;
    color: white !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
    text-align: center !important;
    padding: 16px 0px !important;
    margin-bottom: 24px!important;
    margin-top: 24px;
  }

  .listing_detail_book_now .grey-button {
    margin-top: 0px !important;
    margin-bottom: 24px !important;
    background: #ebebeb !important;
    color: #8f939a !important;
    border: none !important;
  }

  .listing_detail_book_now .cancel_button_mobile {
    background-color: rgb(255, 255, 255) !important;
    color: rgb(37, 39, 45) !important;
    border: none !important;
  }

  .listing_detail_book_now div h4 {
    color: #25272d;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 4px;
  }

  .listing_detail_book_now div p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #25272d;
    margin-bottom: 0px;
  }

  .listing_detail_book_now .booking_card_sessions {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: color-mix(in srgb, #ee7830 100%, #ee762f 100%);
  }

  .listing_booking_card {
    position: inherit;
  }

  .listing_detail_dates {
    justify-content: center !important;
  }
}

@media screen and (max-width: 380px) {
  .mobile-time-card {
    width: 150px !important;
    // margin-right: 0px !important;
  }
}

.special-div-detail {
  top: 80px;
  position: absolute;
  z-index: 10;
}

.special-div-detail img {

  width: 250px;
  height: auto;
}

.see-all-buttons {
  width: 380px;
  max-width: 100%;
  height: 56px;
  font-size: 18px !important;
  font-weight: 500 !important;
  text-align: center !important;
}