.listing-block-page-wrap {
  background: #f9f9f9;
  padding: 32px 100px;
  margin: 0 auto;
  min-height: calc(100vh - 90px);
  .listing-block-white {
    max-width: 1112px;
    background-color: #fff;
    border-radius: 12px;
    padding: 52px 100px 40px;
    margin: 0 auto;
    margin-bottom: 32px;
    overflow-x: scroll;
  }
  .star-title-box {
    margin: 0 auto;
    max-width: 346px;
    .star-img {
      display: flex;
      justify-content: center;
      margin: 16px auto 24px;
    }
    .title {
      color: var(--SteelBlue, #25272d);
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }
    .sub-title {
      color: var(--SecondarySteel, #8f939a);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-top: 12px;
      margin-bottom: 40px;
    }
  }

  .accordion-item {
    margin-bottom: 24px;
  }
  .accordion-button {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 26.5px;
    color: #ee762f !important;
    &::after {
      rotate: 270deg;
    }
    &:focus {
      z-index: 0;
      border: none;
      box-shadow: none;
    }
  }
  .accordion-item:not(:first-of-type) {
    border: 1px solid #dee2e6;
  }
  .accordion-button:not(.collapsed) {
    background: transparent;
  }

  .accordion-button:not(.collapsed)::after {
    rotate: 0deg;
  }

  .accordion-body {
    .desc {
      color: var(--SteelBlue, #25272d);
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    ul {
      margin-top: 48px;
      li {
        color: var(--SteelBlue, #25272d);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
  .listingblock_btn {
    max-width: 546px;
    width: 100%;
    margin: 40px auto;
    margin-bottom: 16px;
    padding: 0 16px;
  }
  .back_arrow {
    margin-top: 24px;
    position: absolute;
  }
  .cancel-box {
    color: #000;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    .cancel-btn {
      padding: 6px 8px;
      cursor: pointer;
    }
  }
}

.listing_block_radio_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 482px;
  margin: auto;
  position: relative;
  gap: 20px;

  .listing_block_radio_option {
    position: relative;
    .input_label {
      border-radius: 8px 8px 0px 0px;
      border: 1px solid var(--Orange-Gradient, #ee7830);
      background: linear-gradient(
        167deg,
        rgba(238, 120, 48, 0.1) -15.49%,
        rgba(238, 118, 47, 0.1) 149.41%
      );
      font-size: 20px !important;
      font-style: normal;
      font-weight: 600 !important;
      line-height: 23.5px;
      letter-spacing: 0.4px;
      padding: 17px 14px !important;
      min-width: 192px !important;
      color: #ee762f;
      cursor: pointer;
    }

    .form_group {
      margin-bottom: 0;
    }

    .cross-icon {
      display: none;
    }

    &:last-child {
      margin-right: 0px;
    }

    .input_box {
      position: initial;
    }

    > img {
      width: 32px;
      height: 32px;
    }

    .input_box {
      textarea {
        border: 1px solid #f3f3f3;
        border-radius: 10px;
      }

      input {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }

      input[type="radio"] {
        cursor: pointer;
      }

      .input_label {
        position: static;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        display: block;
        padding: 0 10px;
        white-space: nowrap;
      }
    }

    .check-icon {
      opacity: 0;
      width: 24px;
      height: 24px;
      background: url(../img/radio-check.svg);
      background-size: contain;
      position: absolute;
      right: 12px;
      top: 16px;
      z-index: -1;
    }
    .default-icon {
      opacity: 1;
      width: 20px;
      height: 20px;
      background: url(../img/radio-default.svg);
      background-size: contain;
      position: absolute;
      right: 14px;
      top: 18px;
      z-index: 2;
      pointer-events: none;
    }
    .radio-content {
      height: 184px;
      flex-shrink: 0;
      border-radius: 0px 0px 8px 8px;
      border: 1px solid var(--SecondarySteel, #8f939a);
      border-top: none;
      padding: 32px 8px;

      .price {
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 23.5px;
        letter-spacing: 0.56px;
        color: #ee762f;
        span {
          color: var(--SteelBlue, #25272d);
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: 23.5px;
        }
      }
      .divider {
        height: 0.3px;
        background: #8f939a;
        margin-top: 10px;
        margin-bottom: 16px;
        padding: 0 4px;
      }
      .listing-info {
        ul {
          padding-left: 1rem;
          li {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 23.5px;
            letter-spacing: 0.32px;
          }
        }
      }
    }
  }

  .text-danger {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }

  .listing_block_radio_option.active {
    background: linear-gradient(
      167.07deg,
      rgba(238, 120, 48, 0.12) -15.49%,
      rgba(238, 118, 47, 0.12) 149.41%
    );

    .check-icon {
      opacity: 1;
      z-index: 2;
    }
    .default-icon {
      opacity: 0;
      z-index: -1;
    }
    .radio-content {
      background: #ee7830;
    }
    .price {
      span {
        color: #fff;
      }
      color: #fff;
    }
    .divider {
      background: #fff;
    }
    .listing-info {
      ul {
        li {
          color: #fff;
        }
      }
    }
  }
  .block_label {
    label {
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 23.5px;
      letter-spacing: 0.4px;
    }
  }
}

@media screen and (max-width: "768px") {
  .listing-block-page-wrap {
    padding: 16px;
    .listing-block-white {
      padding: 16px;
    }
  }

  .listing_block_radio_wrapper {
    gap: 10px;
    overflow: auto;
    padding: 10px;

    .listing_block_radio_option {
      > img {
        width: 24px;
        height: 24px;
      }
    }
  }
}

@media screen and (max-width: "457px") {
  .listing_block_radio_wrapper {
    justify-content: start;
  }
}

.modal-cancel-event {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #25272dcc;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  z-index: 1000;

  &.show {
    display: flex;
  }

  .modal-section {
    width: 100%;
    max-width: 405px;
    background: #ffffff;
    border-radius: 12px;
    padding: 32px 27px;
    display: grid;
    gap: 20px;
    .modal-top-part {
      text-align: center;
      .info-box-wrap {
        position: relative;
        .info_box {
          position: relative;
          margin: 0px;
          padding: 10px 6px;
          color: var(--SecondarySteel, #8f939a);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
        }
      }
      button {
        background: transparent;
        padding: 0;
        text-align: right;
        border: 0;
        width: 100%;

        img {
          float: right;
          cursor: pointer;
        }
      }

      h5 {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: #25272d;
        margin-bottom: 12px;
      }
    }
    .goback {
      background: #f3f3f3;
      color: #8f939a;
    }
    .terms {
      text-align: center;
      color: var(--SecondarySteel, #8f939a);
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      .yellow-text {
        color: #ee762f;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: "768px") {
  .modal {
    .modal-section {
      margin: 1rem;
      display: block;
      
      .modal-top-part {
        h5 {
          color: var(--SteelBlue, #25272d);
          text-align: center;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
  }
}
.listing-chips {
  display: flex;
  gap: 24px;
  padding-left: 0px;
  .items {
    display: inline-flex;
    padding: 7px 12px;
    gap: 6px;
    padding: 7px 12px;
    border-radius: 16px;
    border: 1px solid var(--FitnessNonSelected, #f3f3f3);
    background: var(--FitnessWhite, #fff);
    color: var(--SteelBlue, #25272d);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;

    img {
      width: 16px;
      height: 16px;
    }
  }
  .active {
    background-color: #ee7830;
    color: #fff;
  }
}
@media screen and (max-width: 768px) {
  .listing-chips {
    overflow-x: scroll;
    gap: 16px;
    &::-webkit-scrollbar {
      display: none;
    }
    .items {
      flex-shrink: 0;
    }
  }
}
.slick-track {
  margin-left: 0px;
}
