.dropdown-container {
  position: relative;
  display: inline-block;

  .dropdown-content {
    display: flex;
    align-items: center;
    padding: 7px 19.5px;
    border: 1px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    background: #F3F3F3;
  }

  .dropdown-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-right: 6px; /* Space between text and icon */
  }

  .dropdown-icon {
    display: flex;
    align-items: center;
  }

  .dropdown-icon svg {
    fill: #25272d;
  }

  .dropdown-menu {
    position: absolute;
    // top: 100%;
    left: 0;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: gray;
    color: #25272d;
    z-index: 1000;

    .dropdown-item {
      padding: 8px 21px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      cursor: pointer;
    }

    .dropdown-item:hover {
      background: #f0f0f0;
    }
  }
}
