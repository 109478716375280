.share_common_modal_layout {
    .share_modal_close_btn {
        position: absolute;
        right: 26px;
        top: 24px;
    }

    h4 {
        @media screen and (min-width: 768px) {
            padding-top: 20px;
        }

        font-size: 26px;

        font-weight: 600;
        line-height: 24px;
        text-align: center;
        color: #25272d;
    }

    .share_modal_details {
        display: flex;
        gap: 15px;
        margin-top: 42px;
        margin-bottom: 36px;
        padding: 0px 32px;
        align-items: flex-start;

        .custom_share_media {
            width: 150px;
            border-radius: 8px;
            aspect-ratio: 5/3;
            object-fit: cover;
        }

        @media screen and (max-width: 768px) {
            padding: 0px 8px;
        }


        .title {
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            color: #000000;

            flex-grow: 1;
        }

        .sub-title {
            font-size: 12px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color: #000000;
        }
    }


    .share_options {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
        padding: 0px 24px 32px;

        @media screen and (max-width: 768px) {
            padding: 0px 8px 16px 8px;
        }

        span {
            display: flex;
            justify-content: start;
            gap: 10px;
            align-items: center;
            border: 1px solid #8f939a;
            border-radius: 12px;
            padding: 12px 137px 12px 12px;
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
            color: #25272d;
            max-width: 48%;

            @media screen and (max-width: 768px) {
                max-width: 100%;
                font-size: 14px;
            }
        }


    }


    @media screen and (min-width: 235px) and (max-width:700px) {
        .back-btn {
            display: none;
        }
    }
}


.otp_Modal {
    .modal-content {
        width: 600px;
        margin: auto;
        text-align: center;

        @media screen and (max-width: 768px) {
            width: 80%;
        }

        @media screen and (max-width: 480px) {
            width: 100%;
        }

        .modal-heading {
            @media screen and (max-width: 480px) {
                font-size: 20px;
            }
        }

        .otp_modal_details {
            flex-direction: column;
            align-items: center;
            gap: unset;
            margin-top: 80px;
            margin-bottom: 10px;

            .resendBtn {
                color: #EE7830;
                text-decoration: underline;
                cursor: pointer;
            }

            .heading_verify_modal {
                font-size: 20px;
                font-weight: 700;
                line-height: 30px;
                text-align: center;
            }

            .sub_heading_verify_modal {
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0.6998084783554077px;

                span {
                    font-weight: 600;
                }
            }
        }

        .verifyBtn {
            width: 100%;
            background-color: #EE7830;
            border: none;
            box-shadow: 0px 4px 32px 0px #00000014;
            border: 1px solid #E0E0E014;
            border-radius: 12px;
            font-family: Poppins;
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
            text-align: center;
            padding: 16px;
            color: #fff;

            &:hover {
                background-color: #ee7930e9;
            }

            &:disabled {
                background: #DCDCDC;
                color: #25272D;
                pointer-events: all;
                cursor: not-allowed;
            }
        }
    }
}