/* autoprefixer: off */
.page-event-booking {

  .desktop-date{
    display: block;
    @media screen and (max-width: "480px") {
      display: none;
    }
  }
  .mobile-date{
    display: none;
    @media screen and (max-width: "480px") {
      display: block;
    }
  }

  // background: #f6f6f6;
  .outer-box {
    min-height: calc(100vh - 121px);
    margin: 60px auto 0px;
    max-width: 1112px;
    background: #ffffff;
    // border-radius: 12px;
    position: relative;

    @media screen and (max-width: "900px") {
      margin: 24px auto;
    }

    .back-arrow {
      position: absolute;
      // top: 46px;
      left: 65px;
      cursor: pointer;
    }

    .content-box {
      // padding-top: 46px;
      text-align: center;

      .loc-res {
        text-align: start;
      }

      .page-title {
        font-size: 22px;
        font-weight: 500;
        line-height: 32px;
        color: #25272d;
        margin-bottom: 8px;
      }

      .page-sub-title {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #6c6d6f;
      }

      .step-show-res {
        margin: 22px auto 0px;
      }

      .step-text {
        font-size: 18px;
        font-weight: 400;
        line-height: 40px;
        text-align: center;
        color: #000000;

        @media screen and (max-width: "676px") {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }

  .hor-sep {
    background-color: #f3f3f3;
    height: 1px;
    margin: 24px 0px;
  }

  .info-box {
    background-color: #f3f3f3;
    border-radius: 10px;
    padding: 10px 10px 10px 28px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #25272d;
    position: relative;

    img {
      position: absolute;
      left: 10px;
      top: 12px;
    }

    .orange {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      text-align: left;
      color: #ee762f;
    }
  }

  .detail-question {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color: #000000;
    margin-top: 24px;
    margin-bottom: 30px;
    text-align: center;

    @media screen and (max-width: "900px") {
      margin-bottom: 12px;
      text-align: left;
    }
  }

  .detail-button-box {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    justify-content: center;

    .tab-button {
      padding: 8px 10px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      border-radius: 12px;
      border: 1px solid #000000;
      color: #25272d;
    }

    .tab-button-active {
      padding: 8px 10px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      border-radius: 12px;
      border: 1px solid #ee762f;
      color: #25272d;
      background: linear-gradient(167deg,
          rgba(238, 120, 48, 0.12) -15.49%,
          rgba(238, 118, 47, 0.12) 149.41%),
        #fff;
    }
  }

  .attendee-detail-accrodian {
    .accordion-item {
      border-radius: 12px;
      margin-bottom: 24px;

      .accordion-button {
        background: #FFF4EE;
        color: black;
        border: none !important;
      }
    }

    .accordion-button {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      color: #000000;
      @media screen and (max-width: "480px") {
        font-size: 14px;
      }

      &:focus {
        z-index: 0;
        border: none;
        box-shadow: none;
      }

      &::after {
        background-image: url("../img/chevron-wh.svg") !important;
        background-size: cover;
        rotate: 180deg;
        filter: invert(1);
      }
    }

    .accordion-button.collapsed {
      border: none;
    }

    .accordion-item:not(:first-of-type) {
      border: 1px solid #dee2e6;
    }

    .accordion-button:not(.collapsed) {
      border: none;
      box-shadow: none;
      // background: #ee762f;
      // color: #ffffff;
    }
  }

  .bookiie-detail-wrap {
    padding: 0px 32px;

    .bookie-detail-title {
      margin-top: 20px;
      margin-bottom: 16px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      display: flex;
      width: 100%;
      align-items: center;
      gap: 8px;
    }

    .customNumberInput {
      .label{
        top: 8px;
      }
      .base-NumberInput-root{
        padding: 6px 0px;
        width: auto;
        .base-NumberInput-input{
          padding: 18px 12px;
          padding-bottom: 1px;
        }
      }
    }
  }

  .checkbox-ticket {
    display: flex;
    font-size: 12px;
    font-weight: 400;
    color: #8f939a;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    .form-check-input {
      margin-top: 0;
      width: 20px;
      height: 20px;
    }

    .form-check-input:checked {
      background-color: #ee762f;
      border: none;
    }

    .form-check-input:focus {
      border-color: #ee762f;
      box-shadow: none;
    }
  }

  .save-attendies {
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: end;
    text-decoration: underline;
    // cursor: pointer;
    margin-top: -30px;
  }

  .save-attendies:active {
    color: #ee762f;
  }

  .bookiie-detail-title-box {
    display: flex;
    justify-content: space-between;

    .myself-box {
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 16px;
      font-weight: 500;
      text-decoration: underline;
      color: #ee762f;
      cursor: pointer;
    }
  }

  .p-0 {
    padding: 0px;
  }

  .booking-session-card {
    display: flex;
    border-radius: 12px;
    border: 1px solid #ddd;
    padding: 12px;
    flex-grow: 1;
    margin-bottom: 20px;

    .ep_date_first {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .ep_dt {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;

        text-decoration-line: underline;
        color: #ee762f;
      }

      .ep_tm {
        color: var(--SteelBlue, #25272d);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }

      .ep_first {
        display: flex;
        align-items: end;
        align-items: center;
        gap: 2px;

        p {
          color: var(--SteelBlue, #25272d);
          text-align: right;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
      }

      .ep_date_second {
        align-self: self-end;
        color: var(--SteelBlue, #25272d);
        text-align: right;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }

  .flex-gap-24 {
    gap: 24px;
  }

  .my-6 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

.btn_footer_listing_booking {
  width: 100%;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 76.04%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0px 24px 0px;
  box-sizing: border-box;
  position: sticky;
  bottom: 0;
  margin-top: auto;
  justify-content: center;
  z-index: 1;
}

.myself-box {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
  color: #ee762f;
  cursor: pointer;
  right: 16px;
  margin-top: 20px;
  margin-bottom: 16px;
}

.up_gender {
  border: 1px solid #dcd9d9;
  border-radius: 10px;
  padding: 14px 8px 12px 9px;
  position: relative;

  .ep_input_label {
    position: absolute;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    background-color: #fff;
    left: 12px;
    color: #25272d;
    top: -7px;
    z-index: 2;
    padding-inline: 2px;
  }

  .option_cont {
    background: transparent;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #25272d;
  }
}

@media screen and (max-width: "576px") {
  .save-btn-b-wrapper {
    padding: 16px;
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    margin-bottom: 16px;
  }

  .active-inactive-box-res {
    // margin: 16px 0px;
    margin-top: 16px !important;
    // border: 5px solid green;
  }

  .save-btn-b {
    max-width: 350px;
    width: 100% !important;
    position: absolute;
    left: 0;
    right: 0;
    // bottom: -30px;
    bottom: 0px;
    margin: auto;
  }

  .page-event-booking {
    .outer-box {
      .back-arrow {
        position: absolute;
        top: 2px;
        left: 16px;
        cursor: pointer;
      }

      .res-container {
        padding: 16px;
      }

      .schedule_event_dates_res {
        margin: 0px 16px;
      }

      .step-show-res {
        margin: 16px auto;
        max-width: 300px;
      }

      .info-box-wrapper-res {
        padding: 0px 16px !important;

        .tab-button {
          flex-grow: 1;
        }

        .tab-button-active {
          flex-grow: 1;
        }
      }

      .loc-res {
        max-width: 100% !important;
        text-align: start;
      }

      .attendee-detail-accrodian-res {
        padding: 16px;
      }
    }

    .bookiie-detail-wrap-res {
      padding: 0px 16px !important;
    }

    .booking_event_res {
      padding-top: 0px !important;
      min-height: unset !important;
    }

    .booking_container_res {
      min-height: unset !important;
    }

    .all-button-d-res {
      padding: 0px 0px !important;
    }

    .mb-res {
      margin-bottom: 16px;
    }
  }

  .page-event-booking .checkbox-ticket {
    padding-bottom: 12px;
  }

  .sp_date_container_new .sp_info {
    width: 100%;
  }

  .btn_footer_listing_booking {
    bottom: 20px;
  }
}

.event-booking-accordion .accordion-button {
  height: 17px !important;
}

.calc-height {
  min-height: calc(100vh - 272px);

  @media screen and (max-width: "876px") {
    min-height: calc(100vh - 215px);
  }
}

.ep_dt_min_width {
  min-width: 25%;
  text-align: left;

  @media screen and (max-width: "700px") {
    min-width: 35%;
  }
}

@media screen and (max-width: "768px") {
  .booking-session-card {
    height: 70px;
  }

  .close-button-leave-popup {
    width: 25px !important;
    height: 25px !important;
  }
}