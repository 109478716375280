.welcome-container {
    display: flex;
    flex-direction: row;
}

.right-container {
    padding: 24px 112px 0 62px
}

.welcome-heading {
    font-size: 34px;
    font-weight: 600;
    color: #25272D;
}

.welcome-sub-heading {
    color: #8F939A;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 12px;
}

.member-card-active {
    border: 1px solid #ee7830;
    border-radius: 12px;
    padding: 20px 20px;
    margin: 20px 0;
    cursor: pointer;

    .outline-button-orange {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 17px 20px;
        border-radius: 10px;
        border: 1px solid #ee7830;
        color: #ee7830;
        background: #fff;
        cursor: pointer;
        transition: background-color 0.3s ease;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        position: relative;
    }

    .description {
        margin-top: 20px;
        font-size: 12px;
        color: #8F939A;
        font-weight: 400;
    }

    .bullet-point-heading {
        font-size: 14px;
        font-weight: 500;
        margin-top: 10px;

    }

    .orange-bullet-points {
        font-size: 14px;
        font-weight: 500;
        color: #ee7830;
        margin-top: 5px;
    }
}

.member-card {
    box-shadow: 0px 4px 32px 0px #00000014;
    border-radius: 12px;
    padding: 20px 20px;
    margin: 24px 0;
    cursor: pointer;

    .outline-button-gray {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 17px 20px;
        border-radius: 10px;
        border: 1px solid #8F939A;
        color: #8F939A;
        background: #fff;
        cursor: pointer;
        transition: background-color 0.3s ease;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        position: relative;
    }

    .description {
        margin-top: 20px;
        font-size: 12px;
        color: #8F939A;
        font-weight: 400;
    }

    .bullet-point-heading {
        display: none;
    }

    .orange-bullet-points {
        display: none;
    }
}

.center-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.center-container-new {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background-color: white;
    width: calc(100vw - 540px);
    bottom: 0px;
    margin-left: -62px;
    padding-bottom: 24px;
    gap: 16px;
    padding: 16px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

}

.join-as-member {
    flex: 1;
}



.link {
    font-weight: 500;
    font-size: 18px;
    // margin: 24px 0 0 0;

    flex: 1;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    padding: 15px 0;
    color: #8F939A !important;
    border-radius: 10px;
    border: 1px solid #8F939A;
    text-decoration: none;
}

@media only screen and (max-width: 768px) {
    .welcome-container {
        background-color: #f6f6f6;
    }

    .left-container {
        display: none;
    }

    .right-container {
        margin-top: calc(73.33px + 16px);
        padding: 24px 16px 80px 16px;
        background-color: #ffffff;
        // margin-bottom: 80px;
    }

    .welcome-heading {
        font-size: 24px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .welcome-sub-heading {
        color: #8F939A;
        font-size: 14px;
        text-align: center;
    }

    .orange-bullet-points {
        font-size: 12px;
        display: flex;
        gap: 8px;
        align-items: center;
        margin-bottom: 8px;

        p {
            margin-bottom: 0px;
        }
    }

    .member-card-active {
        border: 1px solid #ee7830;
        border-radius: 12px;
        padding: 28px 30px;

        .description {
            margin-top: 20px;
            font-size: 12px;
            color: #8F939A;
            font-weight: 400;
        }
    }

    .center-container-new {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        background-color: white;
        margin-left: -16px;
        width: 100%;
        bottom: 0px;
        gap: 10px;
        padding: 10px;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

    }

    .join-as-member {
        flex: 1;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
    }

    .link {
        font-weight: 500;

        // margin: 24px 0 0 0;
        color: #8F939A !important;
        flex: 1;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
    }

}