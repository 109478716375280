.connect-user-card-wrapper {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 12px;
  height: 398px;
  width: 295px;
  box-shadow: 0px 9px 25px 0px #0000001a;
  cursor: pointer;

  // /* for not scrollable part */
  // .non-scrollable-width {
  //   width: 295px;
  //   @media (max-width: 767px) {
  //     width: 100%;
  //   }
  // }

  // .scrollabale-width {
  //   width: 295px;
  // }

  .cover-media {
    height: 150px;
    @media (max-width: 768) {
      height: 130px;
    }
  }

  .mx {
    margin-inline: 16px;
  }
  .profile-img-style {
    border-radius: 100%;
    object-fit: cover;
    border: 4px solid white;
    height: 140px;
    width: 140px;
    position: absolute;
    top: 42px;
    left: 16px;

    @media (max-width: 768) {
      height: 100px;
      width: 100px;
      top: 42px;
      left: 16px;
    }
  }
  .role-text {
    position: absolute;
    top: 163px;
    right: 10px;
  }

  .name-wrap {
    margin-top: 48px;
  }

  .interest-wrapper {
    padding-top: 12px;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }

  .connect-fav-wrapper {
    margin-top: 12px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    bottom: 20px;
    right: 16px;
    left: 16px;
  }

  .accpt-reject-fav-wrapper {
    margin-top: 12px;
    display: flex;
    // justify-content: space-around;
    align-items: center;
    position: absolute;
    bottom: 20px;
    right: 16px;
    gap:11px;
    left: 16px;
  }
  .connect-btn-class {
    border-radius: 8px;
    border: 0px;
    background-color: #ee7830;
    font-size: 14px;
    font-weight: 500;
    color: white;
    line-height: 24px;
    width: 100%;
    height: 40px;
    margin-left: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:8px
  }
  .accept-btn-class {
    border-radius: 8px;
    border: 0px;
    background-color: #ee7830;
    font-size: 14px;
    font-weight: 500;
    color: white;
    line-height: 24px;
    width: 100px;
    height: 40px;
    // margin-left: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    // gap:8px
  }
  .confirm-btn-class {
    border-radius: 8px;
    border: 0px;
    background-color: #ee7830;
    font-size: 18px;
    font-weight: 500;
    color: white;
    line-height: 24px;
    padding: 28px 0px;
    width: 100%;
    height: 40px;
    // margin-left: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:8px
  }
  .reject-btn-class {
    border-radius: 8px;
    border: 0px;
    background-color: #feefe7;
    font-size: 14px;
    font-weight: 500;
    color: #25272d;
    line-height: 24px;
    width: 100px;
    height: 40px;
    // margin-left: 12px;
  }
}
