/* autoprefixer: off */
.booking_event {
  // min-height: 100vh;
  // background: #f6f6f6;
  padding: 0 !important;

  .nav_bar {
    .nav_content {
      max-width: 1112px;
      margin-left: auto;
      margin-right: auto;
      padding: 20px 0px;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        left: 0px;
      }
    }
  }

  .booking_container {
    max-width: 1112px;
    margin-top: 27px;
    margin-left: auto;
    margin-right: auto;
    // min-height: 100vh;
    padding: 32px;
    padding-bottom: 0px;
    background-color: white;
    border-radius: 12px;

    .left_container {
      padding-left: 0px;
      padding-right: 36px;

      .img_cont {
        width: 100%;
        border-radius: 12px;
        margin: 0 auto;
        display: flex;
        justify-content: start;
        align-items: center;
        flex-direction: column;

        img {
          border-radius: 12px;
          width: 100%;
          object-fit: cover;
        }
      }

      .left_title {
        color: var(--SteelBlue, #25272d);
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
      }

      .left_terms {
        color: var(--SecondarySteel, #8f939a);
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;

        span {
          background: var(--Orange-Gradient,
              linear-gradient(167deg, #ee7830 -15.49%, #ee762f 149.41%));
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;
          text-decoration-line: underline;
        }
      }

      .all-button {
        padding: 0 0px;

        width: 100%;
        margin-top: 1rem;

        .all-button-top {
          display: flex;
          gap: 15px;

          .event_price {
            color: var(--SteelBlue, #25272d);
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
          }

          .event_class {
            color: var(--SteelBlue, #25272d);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
          }

          .event_btn_price {
            max-width: 200px;
            width: 100%;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #25272d;
            justify-content: start;
            background: transparent;
            border: 0;
            flex: 1;

            .common_btn {
              width: 100%;
              flex: 3;
            }
          }

          .common_btn {
            width: 100%;
            flex: 1;
          }

          .p_txt_below {
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            color: #ee7830;
          }
        }

        .c-cancellation-policy {
          .c-first {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            margin-bottom: 0px;
          }

          .c-second {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
    }

    .right_container {
      border-left: 2px solid #f0f0f0;
      padding-left: 36px;
      padding-right: 36px;

      .info_wrapper {
        .p_title {
          color: var(--SteelBlue, #25272d);
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
        }

        .ep_session {
          display: flex;
          justify-content: space-between;
          align-items: center;

          p {
            color: var(--SteelBlue, #25272d);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
          }
        }

        .ep_date_container {
          display: flex;
          gap: 10px;
          align-items: center;

          .ep_date {
            display: flex;
            border-radius: 12px;
            border: 1px solid #ddd;
            padding: 12px;
            justify-content: space-between;
            flex-grow: 1;

            max-width: 188px;
            max-width: 188px;

            .ep_date_first {
              width: 100%;

              .ep_dt {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;

                text-decoration-line: underline;
                color: #ee762f;
              }

              .ep_tm {
                color: var(--SteelBlue, #25272d);
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
              }

              .ep_how {
                display: flex;

                .ep_first {
                  display: flex;
                  align-items: end;

                  p {
                    color: var(--SteelBlue, #25272d);
                    text-align: right;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                  }
                }

                .ep_date_second {
                  align-self: self-end;
                  color: var(--SteelBlue, #25272d);
                  text-align: right;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 24px;
                }
              }
            }

            .ep_date_second {
              align-self: self-end;
              color: var(--SteelBlue, #25272d);
              text-align: right;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;
            }
          }
        }

        .session_p_selected_info {
          .sp_first {
            color: var(--SteelBlue, #25272d);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;

            align-items: center;
          }

          .sp_second {
            margin-top: 1rem;
            display: flex;
            justify-content: space-between;

            .s_p_counter {
              display: inline-flex;
              padding: 5px;
              justify-content: center;
              align-items: center;
              gap: 36px;

              border-radius: 16px;
              border: 1px solid var(--FitnessNonSelected, #f3f3f3);
              background: #fff;
              box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.04);
            }
          }
        }

        .ep_sess_edit {
          width: 100%;
          max-width: 320px;
          height: 56px;
          padding: 10px 12px;
          align-items: center;
          gap: 10px;
          flex-shrink: 0;

          border-radius: 12px;
          background: var(--FitnessNonSelected, #f3f3f3);

          .edit_txt {
            color: var(--SteelBlue, #25272d);
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }

        .session_p {
          display: flex;
          width: 300px;
          padding: 12px 12px 12px 12px;
          flex-direction: column;

          gap: 8px;

          border-radius: 12px;
          border: 1px solid #ddd;
          background: var(--FitnessWhite, #fff);

          .sp_title {
            color: var(--SteelBlue, #25272d);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
          }

          .sp_address {
            display: flex;
            align-items: center;
            gap: 5px;
            width: 240px;

            p {
              color: var(--SecondarySteel, #8f939a);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
              white-space: nowrap;
              /* Prevents the text from wrapping to the next line */
              overflow: hidden;
              /* Hides any content that overflows the container */
              text-overflow: ellipsis;
            }
          }

          .sp_date_time {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: var(--SecondarySteel, #8f939a);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;

            div {
              display: flex;

              align-items: center;
              gap: 2px;
            }
          }
        }

        .ep_contact {
          color: var(--SteelBlue, #25272d);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          margin-top: 36px;
        }

        .ep_contact_info {
          width: 100%;
          border-radius: 12px;
          border: 1px solid #ddd;
          padding: 1rem;
          margin-bottom: 36px;

          .ep_user_info {
            .ep_first {
              color: var(--SteelBlue, #25272d);
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
            }

            .ep_second {
              display: flex;
              color: var(--SecondarySteel, #8f939a);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;

              align-items: center;
              gap: 8px;

              .ep_email {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }

              p {
                margin-bottom: 0px;
              }

              .ep_dot {
                color: var(--SecondarySteel, #8f939a);
                width: 2px;
                height: 2px;
                border: 2px solid #8f939a;
                border-radius: 100%;
              }

              .ep_active {
                color: rgba(238, 120, 48, 1);
              }
            }
          }
        }

        .ep_amount {
          .ep_am_title {
            color: var(--SteelBlue, #25272d);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
          }

          .ep_am_subtotal {
            display: flex;
            justify-content: space-between;

            .first,
            .second {
              color: var(--SteelBlue, #25272d);
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
            }
          }

          .ep_fee_content {
            display: flex;
            justify-content: space-between;

            .first,
            .second {
              color: var(--SteelBlue, #25272d);
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;

              padding-bottom: 0px;
            }
          }

          .ep_am_total {
            display: flex;
            justify-content: space-between;

            .first,
            .second {
              color: var(--SteelBlue, #25272d);
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
            }
          }
        }

        .ep_note {
          color: var(--SteelBlue, #25272d);
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px;

          span {
            color: #8f939a;
            font-weight: 400;
          }
        }

        .ep_pay_info {
          margin-top: 24px;

          .pay_title {
            color: var(--SteelBlue, #25272d);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            text-align: left;
            margin-bottom: 12px;
          }

          .ep_card_container {
            border-radius: 12px;
            border: 1px solid #ddd;
            padding: 1rem;

            .ep_card {
              border-bottom: 1px solid #ddd;
              padding-bottom: 1rem;

              .ep_pay_card {
                border-radius: 10px;
                border: 1px solid #ddd;
                background: #fff;
                padding: 1rem;
                margin: 16px 0px;

                .first {
                  color: var(--SteelBlue, #25272d);
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px;

                  .card_val {
                    color: var(--SecondarySteel, #8f939a);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                  }
                }

                .second {
                  color: var(--SecondarySteel, #8f939a);
                  text-align: right;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 20px;

                  text-decoration-line: underline;
                }
              }

              .pay-text {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
              }

              .cursor-not-allowed {
                cursor: not-allowed;
              }
            }

            .ep_card:last-child {
              border-bottom: 0px solid #ddd;
              padding-bottom: 0rem;
            }

            .ep_added_card {
              cursor: pointer;
              border-radius: 10px;
              border: 1px solid #ddd;
              background: #fff;
              padding: 1rem;
              margin: 16px 0px;
            }
          }
        }

        .ep_req_info {
          display: flex;
          gap: 10px;

          p {
            color: var(--SteelBlue, #25272d);
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
          }
        }

        .ep_req {
          justify-content: space-between;
          border-radius: 12px;
          border: 1px solid var(--FitnessNonSelected, #f3f3f3);
          padding: 1rem;

          .first {
            color: var(--SteelBlue, #25272d);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
          }

          .second {
            color: var(--SecondarySteel, #8f939a);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
          }
        }
      }
    }
  }
}

.info-box-booking-head {
  background-color: #f3f3f3;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  width: fit-content;
  margin-top: 20px;
  padding: 10px;
  border-radius: 10px;
  gap: 4px;
}

.dob_wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .input_box {
    width: 100%;
    margin-right: 12px;

    &:last-child {
      margin-right: 0px;
    }
  }

  .day,
  .year {
    max-width: 120px;
  }

  select:focus {
    outline: none;
    border: none;
    border: 1px solid #DCDCDC;
  }

  select {
    width: 100%;
    border: 1px solid #DCDCDC;
    border-radius: 10px;
    min-height: 60px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 24px 12px 10px;
    font-style: normal;
    color: #25272d;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url("../img/downarrow.svg");
    background-repeat: no-repeat;
    background-position: 93% center;
  }

  .rsd__react-select-datepicker {
    width: 100%;
  }
}

.ep_dot {
  color: var(--SecondarySteel, #8f939a);
  width: 2px;
  height: 2px;
  border: 2px solid #8f939a;
  border-radius: 100%;
}

@media screen and (max-width: "768px") {
  .booking_event {
    padding-top: 73.33px !important;

    .nav_bar {
      .nav_content {
        span {
          left: 0px;
        }
      }
    }

    .booking_container {
      padding: 1rem;
      margin-top: 16px;

      .left_container {
        padding-left: 0px;
        padding-right: 0px;

        .img_cont {
          img {
            height: 223px;
          }
        }

        .mob-heading {
          h3 {
            color: var(--SteelBlue, #25272d);
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }

          span {
            left: 0px;
          }
        }
      }

      .right_container {
        border: 0px;
        padding-left: 0px;
        padding-right: 0px;

        .info_wrapper {
          .ep_date_container {
            overflow: auto;

            .ep_date {
              min-width: 201px;
            }
          }

          .ep_contact_info {
            .ep_user_info {
              .ep_second {
                display: flex;
                color: var(--SecondarySteel, #8f939a);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;

                align-items: center;
                flex-wrap: wrap;
                gap: 8px;

                p {
                  margin-bottom: 0px;
                }

                .ep_dot {
                  color: var(--SecondarySteel, #8f939a);
                  width: 2px;
                  height: 2px;
                  border: 2px solid #8f939a;
                  border-radius: 100%;
                }
              }
            }
          }
        }

        .all-button {
          padding: 0 20px;

          width: 100%;
          margin-top: 1rem;

          .all-button-top {
            display: flex;
            gap: 15px;

            .event_price {
              color: var(--SteelBlue, #25272d);
              font-size: 24px;
              font-style: normal;
              font-weight: 500;
              line-height: 28px;
            }

            .event_class {
              color: var(--SteelBlue, #25272d);
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 28px;
            }

            .event_btn_price {
              max-width: 200px;
              width: 100%;
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 24px;
              display: flex;
              align-items: center;
              text-align: center;
              color: #25272d;
              justify-content: start;
              background: transparent;
              border: 0;
              flex: 2;

              .common_btn {
                width: 100%;
                flex: 3;
              }
            }
          }

          .c-cancellation-policy {
            .c-first {
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
              margin-bottom: 0px;
            }

            .c-second {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}

.session_p_modal {
  padding: 40px;

  .sp_c_icon {
    display: flex;
    justify-content: center;

    span {
      background-color: #f2f2f2;
      padding: 10px;
      border-radius: 100%;
    }
  }

  .sp_txt {
    .sp_title {
      color: var(--SteelBlue, #25272d);
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

      @media screen and (max-width: "768px") {
        .sp_title {
          font-size: 14px;
        }
      }
    }

    .sp_subtitle {
      color: var(--SecondarySteel, #8f939a);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24p;
    }
  }

  .sp_date_container {
    width: 505px;
    border-radius: 12px;
    border: 1px solid #dbdbdb;
    background: #fff;
    padding: 1rem;

    display: flex;
    gap: 10px;

    hr {
      width: 2px;

      background: #ebebeb;
      display: flex;
      flex-shrink: 0;
    }

    .sp_only_date {
      overflow: hidden;
      color: var(--SecondarySteel, #8f939a);
      text-overflow: ellipsis;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      display: flex;
      flex-direction: column;
      gap: 8px;
      flex-shrink: 0;

      .sp_date_date {
        color: var(--SteelBlue, #25272d);
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }

    .sp_info {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .sp_title {
        overflow: hidden;
        color: var(--fitness-mates-pitch-deck-black, #25272d);
        text-overflow: ellipsis;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }

      .sp_loc {
        display: flex;
        gap: 6px;
        align-items: center;

        .loc {
          color: var(--SecondarySteel, #8f939a);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;

          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 200px;
        }

        .sp_map {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;

          text-decoration-line: underline;
          color: #ee7830;
          display: flex;
          flex-shrink: 0;
        }
      }

      .sp_time {
        color: var(--SecondarySteel, #8f939a);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
}

.openMap {
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  text-decoration-line: underline;
  color: #ee7830;
  display: flex;
  flex-shrink: 0;
}

.sp_date_container_new {
  border-radius: 12px;
  border: 1px solid #dbdbdb;
  background: #fff;
  padding: 1rem;

  display: flex;
  flex-grow: 1 !important;

  hr {
    width: 2px;

    background: #ebebeb;
    display: flex;
    flex-shrink: 0;
  }

  .sp_only_date {
    overflow: hidden;
    color: var(--SecondarySteel, #8f939a);
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-shrink: 0;
    border-right: 2px solid #ebebeb;

    .sp_date_date {
      color: var(--SteelBlue, #25272d);
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }

  .sp_info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: start;

    .sp_title {
      overflow: hidden;
      color: var(--fitness-mates-pitch-deck-black, #25272d);
      text-overflow: ellipsis;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    .sp_loc {
      display: flex;
      gap: 6px;
      align-items: center;

      .loc {
        color: var(--SecondarySteel, #8f939a);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 200px;
      }

      .sp_map {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;

        text-decoration-line: underline;
        color: #ee7830;
        display: flex;
        flex-shrink: 0;
      }
    }

    .sp_time {
      color: var(--SecondarySteel, #8f939a);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

@media screen and (max-width: "768px") {
  .session_p_modal {
    min-height: calc(100vh - 190px);
    margin-bottom: 75px;
    padding: 0px;

    .sp_date_container {
      width: 100%;

      .sp_info {
        .sp_loc {
          .loc {
            width: 100px;
          }
        }
      }
    }

    .package_wrapper {
      position: absolute;
      bottom: 54px;
      width: 100%;
    }
  }
}

//earn booking page
.earn-booking-wrap {
  max-width: 1256px;
  // padding: 0 16px;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 12px;
  // border: 1px solid var(--FitnessNonSelected, #f3f3f3);
  // padding: 62px 64px 64px 89px;
  min-height: calc(100vh - 120px);

  .booking-title-box {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    // max-width: 1152px;

    .main-title {
      color: var(--SteelBlue, #25272d);
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }

    hr {
      width: 2px;

      background: #ebebeb;
      display: flex;
      flex-shrink: 0px;
    }

    .sp_only_date {
      overflow: hidden;
      color: var(--SecondarySteel, #8f939a);
      text-overflow: ellipsis;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      gap: 8px;

      .sp_date_date {
        color: var(--SteelBlue, #25272d);
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }

    .sp_info {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 70%;

      .sp_title {
        overflow: hidden;
        color: var(--fitness-mates-pitch-deck-black, #25272d);
        text-overflow: ellipsis;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }

      .sp_loc {
        display: flex;
        gap: 6px;
        align-items: center;

        .loc {
          color: var(--SecondarySteel, #8f939a);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          width: 200px;

          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .sp_map {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;

          text-decoration-line: underline;
          color: #ee7830;
          display: flex;
          flex-shrink: 0;
        }
      }

      .sp_time {
        color: var(--SecondarySteel, #8f939a);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }

    .back_arrow,
    .document-download {
      cursor: pointer;
      padding: 2px 8px;
    }
  }

  .booking-details-box {
    margin-top: 60px;
    position: relative;
    // text-align: center;
    // padding-right: 48px;

    .title-wrap {
      display: inline-flex;
      gap: 24px;
      
      @media screen and (max-width:768px) {
        gap:16px;
      }

      .chat-btn {
        position: absolute;
        right: 0;
        cursor: pointer;
      }
    }

    .title {
      color: var(--SteelBlue, #25272d);
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }

    .list-chip {
      display: inline-flex;
      padding: 4px 8px;
      align-items: center;
      gap: 4px;
      border-radius: 8px;
      background: #f3f3f3;
      text-align: right;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
      color: #ee762f;
      min-width: 73px;
    }

    .sub-title-wrap {
      display: flex;
      // justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 48px;
      margin-top: 20px;

      .subtitle {
        color: var(--SteelBlue, #25272d);
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        display: inline-flex;
        gap: 8px;
        align-items: center;
      }

      .spot-text {
        color: var(--SecondarySteel, #8f939a);
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }

      .yellow-money {
        color: #ee762f;
      }
    }
  }
}

.booking-list-wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  // max-width: 726px;
  margin: 40px auto;
  gap: 24px;
  // max-height: 550px;
  overflow-y: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .booking-card-wrap {
    // flex: 1 1 350px;
    flex: 0 0 calc(33.33% - 16px);

    @media screen and (max-width:1000px) {
      flex: 0 0 calc(50% - 12px);
    }

    @media screen and (max-width:700px) {
      flex: 0 0 calc(100%);
    }

    border-radius: 12px;
    border: 1px solid #ddd;
    background: var(--FitnessWhite, #fff);
    padding: 16px 18px;
    position: relative;

    .img-content {
      display: flex;
      align-items: center;
      gap: 6px;

      .avatar-participants-container {
        display: flex;
        gap: 1rem;
        justify-content: center;
        align-items: center;

        .avatar-container {
          padding-left: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;

          .avatar-item {
            margin-left: -10px;
            border-radius: 100%;
            display: flex;
            align-items: center;

            img {
              width: 24px;
              height: 24px;
              border-radius: 50%;
            }
          }
        }
      }
    }

    .date {
      display: flex;
      gap: 2px;
      flex-direction: column;
      color: var(--SteelBlue, #25272d);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 10px;
      .em_slots{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #6C6D6F;
        display: flex;
        gap: 2px;
        align-items: center;
      }
    }

    .spot {
      color: var(--SecondarySteel, #8f939a);
      text-align: right;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    .total_spot {
      color: var(--SteelBlue, #25272d);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    .earning-btn {
      cursor: pointer;
      display: inline-flex;
      display: inline-flex;
      padding: 4px 12px 4px 16px;
      align-items: flex-start;
      gap: 4px;
      border-radius: 8px;
      background: var(--Orange-Gradient,
          linear-gradient(167deg, #ee7830 -15.49%, #ee762f 149.41%));
      color: var(--FitnessWhite, #fff);
      text-align: right;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      margin-top: 14px;
    }

    .zero-earning-btn {
      // cursor: pointer;
      display: inline-flex;
      display: inline-flex;
      padding: 4px 12px 4px 16px;
      align-items: flex-start;
      gap: 4px;
      border-radius: 8px;
      background: #ffffff;
      color: #25272d;
      text-align: right;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      margin-top: 14px;
      border: 1px solid #25272d;
    }

    .chat-btn {
      position: absolute;
      top: 16px;
      right: 18px;
      cursor: pointer;
    }

    .chat-btn-no-event {
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: unset;
    }
  }
}

.pagination_container_single_earning {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 20px;
  position: sticky;
  bottom: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.6) 0%, #FFFFFF 76.04%);
}

.slot-list-wrap {
  // max-width: 693px;
  margin: 40px auto;
  position: relative;

  .cancelled-on {
    position: absolute;
    right: 12px;
    top: -12px;
    border-radius: 6px;
    background: #ffe2e2;
    display: inline-flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #eb5757;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .slot-card-wrap {
    // max-width: 693px;
    border-radius: 12px;
    border: 1px solid var(--FitnessNonSelected, #f3f3f3);
    background: var(--FitnessWhite, #fff);
    margin-bottom: 16px;

    .slot-card-content {
      padding: 12px 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid var(--FitnessNonSelected, #f3f3f3);
    }

    .img-content {
      display: flex;
      align-items: center;
      gap: 12px;

      .user-media {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .money {
      color: var(--SteelBlue, #25272d);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
    }

    .date {
      color: var(--SecondarySteel, #8f939a);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .slort-card-accordian {
      .accordion-item {
        border-radius: 12px;

        button {
          border-radius: 12px !important;
        }

        .attendee-list-wrap {
          .title-box {
            display: flex;
            color: #8f939a;
            font-size: 12px;
            font-weight: 400 !important;
            line-height: 22px;
            text-align: left;
            width: 100%;
          }

          .list-item {
            display: flex;
            padding-right: 8px;
            margin: 10px 0px;
            text-align: left;

            .name {
              font-size: 16px;
              font-weight: 500;
              line-height: 22px;
              color: #000000;
              text-align: left;
              // width: 170px;
              // flex: 1;
              text-overflow: ellipsis;
              padding-left: 8px;
              overflow: hidden;
              white-space: nowrap;
            }

            .gender {
              font-size: 16px;
              font-weight: 500;
              line-height: 22px;
              color: #000000;
              // max-width: 70px;
              // flex: 1;
              text-overflow: ellipsis;
              // text-align: center;
              padding-left: 16px;
              text-align: left;
            }

            .age {
              font-size: 16px;
              font-weight: 500;
              line-height: 22px;
              color: #000000;
              // max-width: 70px;
              // flex: 1;
              text-overflow: ellipsis;
              // text-align: center;
              // padding-left: 8px;
              text-align: left;
            }

            .email {
              font-size: 16px;
              font-weight: 400;
              line-height: 22px;
              color: #000000;
              // max-width: 200px;
              overflow: hidden;
              flex: 1;
              text-overflow: ellipsis;
              text-align: left;
            }

            .phone {
              font-size: 16px;
              font-weight: 400;
              line-height: 22px;
              color: #000000;
              text-align: left;
            }
          }
        }

        .list-item-mob {
          display: none;
          margin: 10px 0px;
          padding-left: 8px;
          text-align: left;

          .name {
            font-size: 12px;
            font-weight: 500;
            line-height: 22px;
            color: #000000;
            max-width: 250px;
            text-overflow: ellipsis;
          }

          .dot {
            width: 6px;
            height: 6px;
            background-color: #d9d9d9;
            border-radius: 50%;
            text-align: justify;
            /* Creates a circular dot */
          }

          .g-age {
            display: flex;
            align-items: center;
            gap: 4px;
            font-size: 10px;
            font-weight: 400;
            line-height: 22px;
            text-align: center;
            color: #8f939a;
            max-width: 140px;
            white-space: no-wrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .info-box {
            display: flex;
            justify-content: space-between;
            width: 270px;

            .email {
              display: flex;
              gap: 4px;
              font-size: 10px;
              font-weight: 400;
              line-height: 22px;
              text-align: center;
              color: #000000;
              max-width: 140px;
              white-space: no-wrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .em-value {
              max-width: 140px;
              white-space: no-wrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .phone {
              display: flex;
              gap: 4px;
              font-size: 10px;
              font-weight: 400;
              line-height: 22px;
              text-align: center;
              color: #000000;
              max-width: 90px;
              text-overflow: ellipsis;
            }
          }
        }
      }

      .accordion-button {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
        color: #000000;

        &:focus {
          z-index: 0;
          border: none;
          box-shadow: none;
        }

        &::after {
          background-image: url("../img/chevron-down-black.svg") !important;
          background-size: cover;
          rotate: 180deg;
        }
      }

      .accordion-item:not(:first-of-type) {
        border: 1px solid #dee2e6;
      }

      .accordion-button:not(.collapsed) {
        background: transparent;
        box-shadow: none;
      }
    }
  }

  @media screen and (max-width: 767px) {

    .list-item,
    .title-box {
      display: none !important;
    }

    .list-item-mob {
      display: block !important;
    }
  }
}

//single booking event
.event-booking-detail-wrap {
  margin: 0 auto;
  margin-top: 60px;
  position: relative;
  padding-right: 48px;
  text-align: left;
  // max-width: 693px;

  .title-wrap {
    display: inline-flex;
    gap: 24px;
    flex-wrap: wrap;

    .chat-btn {
      cursor: pointer;
      position: absolute;
      right: 0;
    }

    .title {
      color: var(--SecondarySteel, #25272d);
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }

    .list-chip {
      max-height: 40px;
      display: inline-flex;
      padding: 4px 8px;
      align-items: center;
      gap: 4px;
      border-radius: 8px;
      background: #f3f3f3;
      text-align: right;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
      color: #ee762f;
      min-width: 73px;
    }
  }

  .total-desc {
    margin-top: 16px;
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;

    span {
      color: #ee762f;
    }
  }

  .date {
    margin-top: 24px;
    color: var(--SteelBlue, #25272d);
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }

  .content-items {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 4px;

    .sub-title {
      color: var(--SecondarySteel, #8f939a);
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
  }

  .sub-title-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 48px;
    margin-top: 16px;

    .subtitle {
      color: var(--SteelBlue, #25272d);
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      display: inline-flex;
      gap: 8px;
      align-items: center;
    }

    .spot-text {
      color: var(--SecondarySteel, #8f939a);
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }

    .yellow-money {
      color: #ee762f;
    }
  }

  @media screen and (max-width: "568px") {
    margin-top: 24px;
    padding-right: 0;

    .title-wrap {
      gap: 16px;
      padding: 0;

      .title {
        font-size: 20px;
      }
    }

    .date {
      margin-top: 12px;
      font-size: 20px;
    }

    .sub-title-wrap {
      gap: 16px;

      .subtitle {
        font-size: 16px;
      }
    }

    .content-items {
      margin-top: 12px;

      .sub-title {
        font-size: 16px !important;
      }
    }
  }
}

.total-list-wrap {
  margin: 32px auto;
}

.review-screen-container {
  width: 100%;
  background: #f6f6f6;
  padding-top: 1.6rem;
  margin-top: 94px;
}

.review-main-container {
  margin: auto;
  margin-top: 10px;
  padding: 2rem;

  max-width: 1112px;
  border-radius: 12px;
  background: var(--FitnessWhite, #fff);

  gap: 1.5rem;
  padding-left: 104px;
  padding-right: 104px;

  .rv-title {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    span {
      position: absolute;
      left: 0px;
      cursor: pointer;
    }
  }

  .rhr {
    height: 4px;
    margin-left: -104px;
    margin-right: -104px;
    background-color: #fcfcfc;
  }

  .all-user-r-container {
    .user-r-wrapper {
      .user-r-info {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .user-info {
          display: flex;
          gap: 12px;
          align-items: center;

          img {
            width: 44px;
            height: 44px;
            border-radius: 100px;
            border: 3px solid #f3f3f3;
          }

          .user-p-info {
            .user-p-name {
              font-size: 16px;
              font-weight: 500;
            }

            .user-p-date {
              color: #505155;

              font-size: 12px;

              font-weight: 500;
              line-height: 15px;
            }
          }
        }

        .user-r-star {
          display: flex;
          gap: 5px;

          p {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 12px;
          }

          span {
            color: #bdbdbd;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 12px;
          }
        }
      }

      .user-r-desc {
        margin-top: 14px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        display: flex;

        span {
          color: #ee7830;
        }
      }
    }
  }
}

@media screen and (max-width: "768px") {
  .review-screen-container {
    padding-top: 1rem;
    margin-top: 73.33px;
  }

  .review-main-container {
    margin-top: 0px;
    padding-top: 0px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 0px;
  }

  .rv-title {
    padding: 1rem;

    p {
      margin-bottom: 0px;
    }
  }

  .all-user-r-container {
    padding: 0px !important;

    .user-r-wrapper {
      margin-top: 10px;
    }
  }
}

.review-modal-wrapper {

  .r-title-wrapper {
    margin-bottom: 22px;
    display: flex;
    align-items: center;
    justify-content: center;

    .r-title {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      text-align: center;
      margin-bottom: 0px;
    }

    img {
      top: -4px;
      right: 0px;
      cursor: pointer;
    }
  }

  .r-btn-container {
    display: flex;
    justify-content: center;

    .r-btn {
      width: 390px;
    }
  }

  .r-event-info-wrapper {
    .r-event-img-cont {
      display: flex;
      align-items: center;
      height: 103px;
    }

    .r-event-info {
      display: flex;
      justify-content: center;
      flex-direction: column;

      h4 {
        font-size: 20px;
        width: "100%";
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
      }

      p {
        color: #505155;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 0px;
      }
    }
  }

  .r-main-container {
    .r-rate {
      font-size: 18px;
      font-weight: 600;
    }

    .r-shared {
      font-size: 14px;
      font-weight: 400;
    }

    .r-write {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px;
    }

    .rating-container {
      display: flex;
      gap: 16px;
      width: fit-content;

      .box-1,
      .box-2,
      .box-3,
      .box-4,
      .box-5 {
        border: 4px solid #ee762f;
        display: flex;
        align-items: center;
        border-radius: 100%;
        padding: 1rem;
      }

      .bg-active {
        background-color: #ee762f;
      }

      .bg-hover {
        background-color: #ee762f;
      }
    }

    .r-input-container {
      padding: 10px;
      border-radius: 10px;
      border: 1px solid var(--FitnessNonSelected, #f3f3f3);
      background: #fff;

      .r-label {
        display: flex;
        justify-content: space-between;

        p {
          font-size: 10px;
          margin-bottom: 0px;
        }

        .rt-color-red {
          color: red;
        }
      }

      textarea {
        box-shadow: 0 0 0 0;
      }
    }
  }
}

@media screen and (max-width: "768px") {
  .onlyForResponsive {
    background-color: #f3f3f3;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .review-modal-wrapper {
      min-height: 100%;

      .r-title-wrapper {
        margin: 1rem !important;

        .r-title {
          font-size: 20px;
        }

        .r-mob-back-btn {
          left: 0px;
          bottom: 3px;
        }
      }

      .r-event-info-wrapper {
        padding: 1rem !important;
        gap: 0px !important;

        .r-event-info {
          padding-left: 0px !important;

          h4 {
            font-size: 14px;
          }

          p {
            font-size: 10px;
          }
        }

        .r-event-img-cont {
          height: 62px;
          max-height: 62px;

          img {
            height: 100%;
          }
        }
      }

      .r-main-container {
        padding: 1rem !important;

        .r-rate {
          padding: 0rem !important;
        }

        .r-shared {
          font-size: 10px;
        }

        .rating-container {

          .box-1,
          .box-2,
          .box-3,
          .box-4,
          .box-5 {
            padding: 12px;
          }
        }
      }

      .r-btn-container {
        width: 100%;
        margin-top: 1rem !important;
        position: relative;
        bottom: 0px;
      }
    }
  }

  .review-width {
    min-height: 100vh;
  }

  .slot-list-wrap {
    .slot-card-wrap {
      .date-res {
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .s_p_counter {
    width: 82px;
    height: 28px;
    gap: 15px !important;
  }
}

.s_p_counter {
  display: inline-flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
  gap: 36px;

  border-radius: 16px;
  border: 1px solid var(--FitnessNonSelected, #f3f3f3);
  background: #fff;
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.04);
}

.open-maps-box-booking-detail-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .open-maps-box-booking-detail {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 14px;

    .t-sub-title-box {
      max-width: 73px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #8f939a;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
    }

    .map-link {
      color: #ee7830;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      text-decoration-line: underline;
      cursor: pointer;
    }

    .meet-link {
      color: #25272d;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      text-decoration-line: underline;
      cursor: pointer;
    }

    .copy-btn {
      cursor: pointer;
    }
  }
}

.orange-text-number {
  color: #ee762f;
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;

  .underline-view-all {
    margin-left: 12px;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.copy-link-box-booking-details {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  margin-top: 24px;

  @media screen and (max-width: "768px") {
    width: 100%;
    font-size: 14px;
    line-height: 24px;
  }

  .orange-box {
    background: #feefe7;
    border-radius: 12px 0px 0px 12px;
    text-align: center;
    padding: 24px;
    border: 1px solid transparent;
    text-align: center;
    min-width: auto;

    @media screen and (max-width: "768px") {
      padding: 12px;
      display: inline;
      word-break: keep-all;
      min-width: 125px;
    }
  }

  .link-box {
    display: flex;
    padding: 24px;
    border: 1px dashed #dcdcdc;
    border-left: none;
    gap: 24px;
    border-radius: 0px 12px 12px 0px;
    // box-sizing: border-box;
    width: fit-content;
    max-width: 500px;

    @media screen and (max-width: "768px") {
      width: calc(100% - 125px);
      padding: 12px;
      gap: 12px;
    }

    .link_text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .copy-btn {
      color: #0099ff;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
    }
  }
}

.session-card-earning-details {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(4, 1fr);

  .session-card-earning-wrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px;
    border: 1px solid #dddddd;
    border-radius: 8px;

    .title-box {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      h5 {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #25272d;
        margin-bottom: 0;
        width: 80%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .number-circle {
        background-image: linear-gradient(to right, #ee78303b, #ee762f3b);
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
      }
    }

    .address-box {
      display: inline-flex;
      gap: 5px;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      color: #8f939a;

      p {
        margin-bottom: 0px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .date-time-box {
      display: inline-flex;
      align-items: center;
      gap: 12px;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      color: #8f939a;
      margin-top: -4px;

      span {
        display: inline-flex;
        align-items: center;
        gap: 2px;
      }
    }

    .open-map {
      color: #ee7830;
      font-size: 13px;
      line-height: 20px;
      margin-bottom: 0;
      text-decoration: underline;
    }
  }

  @media screen and (max-width: "1024px") {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: "768px") {
    grid-template-columns: repeat(1, 1fr);
  }
}

.info-box-booking-head-earning {
  background-color: #f3f3f3;
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  margin-top: 20px;
  padding: 10px;
  padding-right: 20px;
  border-radius: 10px;
  gap: 6px;
  margin-bottom: 2px;
  color: #25272D;
  width: fit-content;

  a {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
    color: #ee762f;
  }
}

.sp_info_new {
  flex: auto !important;
}