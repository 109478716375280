/* autoprefixer: off */
.manage_profile {
  padding: 25px 55px;
  background: #f6f6f6;

  .profile_box {
    background: #fff;
    text-align: center;
    padding: 30px 70px 0;
    max-width: 730px;
    margin: 0 auto;

    .document-upload {
      min-height: calc(100vh - 358px);
    }

    .title {
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #25272d;
      padding-bottom: 12px;
      position: relative;

      .back_arrow {
        position: absolute;
        left: -60px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
      }
    }

    @media screen and (max-width: "768px") {
      .title {
        .back_arrow {
          top: 40%;
          left: 0px;
          width: 32px;
          height: 32px;
        }
      }
    }

    .sub_title {
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      color: #8f939a;
      padding-bottom: 20px;
      min-height: 64px;

      &.pb-40 {
        padding-bottom: 40px;
      }
    }

    .basic_details {
      label {
        cursor: pointer;
        display: inline-block;

        .dummy_img img {
          width: 155px;
          height: 155px;
          border-radius: 100%;
          object-fit: cover;
        }

        &.add_photo_label {
          margin-bottom: 30px;
        }

        @media screen and (max-width: "768px") {
          .dummy_img img {
            width: 110px;
            height: 110px;
          }
        }
      }

      .remove_image {
        position: relative;
        display: flex;
        padding-bottom: 20px;
        justify-content: center;

        .text {
          margin-top: -30px;
          cursor: pointer;
          display: flex;
          align-items: center;

          &:hover {
            background: linear-gradient(0deg,
                rgba(0, 0, 0, 0.08),
                rgba(0, 0, 0, 0.08)),
              linear-gradient(167.07deg, #ee7830 -15.49%, #ee762f 149.41%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }
        }
      }

      .gender_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        position: relative;
        gap: 20px;

        .gender_option {
          position: relative;
          display: flex;
          align-items: center;
          background: #f6f6f7;
          border-radius: 12px;
          padding: 12px;
          padding-right: 30px;
          min-width: fit-content;
          width: fit-content;
          margin-right: 16px;
          cursor: pointer;

          .form_group {
            margin-bottom: 0;
          }

          .cross-icon {
            display: none;
          }

          &:last-child {
            margin-right: 0px;
          }

          .input_box {
            position: initial;
          }

          >img {
            width: 32px;
            height: 32px;
          }

          .input_box {
            textarea {
              border: 1px solid #f3f3f3;
              border-radius: 10px;
            }

            input {
              position: absolute;

              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
            }

            input[type="radio"] {
              cursor: pointer;
            }

            .input_label {
              position: static;
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
              display: block;
              padding: 0 10px;
              white-space: nowrap;
            }
          }

          .check-icon {
            opacity: 0;
            width: 20px;
            height: 20px;
            background: url(../img/checked.svg);
            background-size: contain;
            position: absolute;
            right: 12px;
            z-index: -1;
          }
        }

        .text-danger {
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
        }

        .gender_option.active {
          background: linear-gradient(167.07deg,
              rgba(238, 120, 48, 0.12) -15.49%,
              rgba(238, 118, 47, 0.12) 149.41%);

          .check-icon {
            opacity: 1;
            z-index: 2;
          }
        }
      }

      @media screen and (max-width: "768px") {
        .gender_wrapper {
          gap: 10px;
          overflow: auto;

          .gen-opt-cont {
            flex-shrink: 1;

            .gender_option {
              >img {
                width: 24px;
                height: 24px;
              }

              .input_box {}
            }
          }
        }

        .submit_btn {
          margin-top: 40px;
        }
      }

      @media screen and (max-width: "457px") {
        .gender_wrapper {
          justify-content: start;
        }
      }

      .submit_btn {
        margin-top: 65px;
      }

      @media screen and (max-width: "768px") {
        .submit_btn {
          margin-top: 30px;
        }
      }

      .input_box {
        ::placeholder {
          color: #8f939a;
        }

        :-ms-input-placeholder {
          color: #8f939a;
        }

        ::-ms-input-placeholder {
          color: #8f939a;
        }

        input {
          color: #25272d;
        }

        textarea {
          border: 1px solid #f3f3f3;
          border-radius: 10px;
          width: 100%;
          padding: 26px 55px 12px 12px;
          resize: none;
          min-height: 122px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #25272d;
        }
      }

      .input_title {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: left;
        margin: 40px 0 16px;
        padding-bottom: 0;

        &.profile_input_title {
          text-align: center;
          margin: 6px 0 16px;
        }

        &.mt-10px {
          margin-top: 10px;
        }
      }

      .text {
        background: linear-gradient(167.07deg,
            #ee7830 -15.49%,
            #ee762f 149.41%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        display: inline-flex;
        align-items: center;
        margin-top: 8px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .user_img_input {
        display: none;
      }

      .note {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        text-align: center;

        color: #8f939a;
        margin-top: 12px;
      }

      .horizontal_line {
        border: 1px solid #f3f3f3;
        margin: 24px 0;
      }

      .personal_trainer_checkbox {
        background: linear-gradient(167.07deg,
            rgba(238, 120, 48, 0.12) -15.49%,
            rgba(238, 118, 47, 0.12) 149.41%);
        border-radius: 12px;
        padding: 18px 12px;
        margin-bottom: 100px;

        label {
          margin-bottom: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .cross-icon {
          display: none;
        }

        .form_group {
          margin-bottom: 0;
        }

        .input_box {
          display: flex;

          .input_label {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #25272d;
            position: static;
            width: 100%;
          }

          input {
            left: 0;
            top: 0;
            height: 20px;
            width: 32px;
            background-color: white;
            border-radius: 50%;

            appearance: none;
            -webkit-appearance: none;
            outline: none;
            cursor: pointer;
          }

          input[type="checkbox"] {
            width: 32px;
            height: 32px;
            padding: 0;
            border: 2px solid #ffddc6;
          }

          input:checked {
            background: url(../img/checked.svg);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
      }

      @media screen and (max-width: "478px") {
        .personal_trainer_checkbox {
          label {
            color: var(--SteelBlue, #25272d);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            display: flex;
            text-align: start;
          }

          .input_box {
            input[type="checkbox"] {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }

    .input_title {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #25272d;
      padding-bottom: 15px;
    }

    .business_intrest {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: flex-start;
      gap: 16px;
      list-style: none;
      padding: 40px 0;
      min-height: calc(100vh - 400px);

      &.pt-0 {
        padding-top: 0;
      }

      .lds-ring {
        min-height: calc(100vh - 300px);
      }

      li {
        width: 175px;
        display: inline-block;
        margin: 0 20px 30px 0;

        &:nth-child(3) {
          margin: 0 0px 30px 0;
        }

        label {
          position: relative;
          display: block;
          cursor: pointer;
        }

        input {
          display: none;
        }

        img {
          width: 175px;
          height: 175px;
          object-fit: cover;
          border-bottom: none;
          border: 1px solid #e0e0e0;
          border-radius: 12px 12px 0 0;
          display: inherit;
        }

        h4 {
          background: #fff;
          border-radius: 0px 0px 12px 12px;
          text-align: left;
          font-weight: 500;
          font-size: 16px;
          line-height: 125%;
          padding: 8px 34px 8px 12px;
          margin-top: 0px;
          border: 1px solid #e0e0e0;
          border-top: 0px;
          word-break: break-word;

          div {
            width: 85%;
          }
        }

        svg {
          position: absolute;
          bottom: 10px;
          right: 12px;
        }

        .active {
          img {
            border-bottom: none;
            border-color: #ee772f;
          }

          h4 {
            border: 1px solid #ee772f;
            background: linear-gradient(167.07deg,
                #ee7830 -15.49%,
                #ee762f 149.41%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }

          svg {
            path {
              fill: #ee772f;
            }
          }
        }
      }

      &.exp_wrapper {
        li {
          margin: 0 0px 0px 0;
        }
      }

      .expertise_web {
        li {
          margin: 0 20px 30px 0;

          .expertise-img {
            min-width: 215px;
            max-height: 242px;
            object-fit: cover;
            border-bottom: none;
            border-radius: 12px 0 0 12px;
            display: inherit;
          }

          .form_group {
            position: relative;

            .traning-label {
              font-size: 12px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0em;
              text-align: right;
              color: #25272d;
            }

            .textarea-label {
              font-size: 12px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0em;
              text-align: left;
              color: #25272d;
              position: absolute;
              top: 1px;
              left: 12px;
              z-index: 2;
              background: #fff;
              width: 100%;
            }

            .count {
              top: 4px;
              right: 40px;
              z-index: 2;
            }

            .cross-icon {
              position: absolute !important;
              top: 1px !important;
              right: 12px;
              z-index: 5;
              width: 24px;
              height: 24px;
              cursor: pointer;
              border: none;
            }
          }

          h4 {
            background: #fff;
            border-radius: 0px 0px 12px 12px;
            text-align: left;
            font-weight: 500;
            font-size: 16px;
            line-height: 125%;
            padding: 8px 12px;
            margin-top: 0px;
            border: 1px solid #e0e0e0;
            border-top: 0px;
          }

          .txtb {
            border: 1px solid #e0e0e0;
            border-radius: 0px 12px 12px 0;
            border-left: none;
            padding: 0 12px;
            position: relative;
            width: 100%;

            .form_group {
              margin-bottom: 0px;
            }
          }

          .optional_txt {
            position: absolute;
            top: -16px;
            right: 8px;
            font-weight: 400;
            font-size: 10px;
            line-height: 14px;
          }

          .tell-us-more-box {
            justify-self: end;
            text-align: end;
            cursor: pointer;
            padding: 4px;
          }

          .tell-us-more {
            font-size: 14px;
            font-weight: 500;
            color: #ee7830;
          }
        }

        .mobile-card {
          .expertise-img {
            min-width: 76px !important;
            max-width: 76px;
            height: 91px;
            object-fit: cover;
            border-bottom: none;
            border-radius: 12px;
            display: inherit;
          }
        }
      }
    }
  }

  @media screen and (max-width: "768px") {
    .profile_box {
      padding: 1rem;

      .search-interest {
        .search-image-icon {
          position: absolute;
          top: 8px;
          left: 10px;
        }

        .input-with-image {
          padding: 8px 12px 8px 40px;
        }
      }

      .title {
        font-size: 20px;
        line-height: normal;
      }

      .sub_title {
        font-size: 14px;
        line-height: 21px;
      }

      .business_intrest {
        .infinite_scroll_styles {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          gap: 16px;

          li {
            margin-right: 0px;
            width: 163px;

            img {
              width: 163px;
              height: 174px;
            }

            h4 {
              div {
                width: 85%;
              }
            }
          }
        }

        .expertise_web {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          gap: 16px;

          li {
            margin-right: 0px;

            border: 1px solid #f3f3f3;
            border-radius: 12px;
            padding: 12px 12px 0 12px;

            .txtb {
              border: none;

              .optional_txt {
                top: -24px !important;
                right: 48px;
              }
            }

            img {
              width: 250px;
              height: 250px;
            }
          }
        }

        .mobile-card {
          .form_group {
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  @media screen and (max-width: "550px") {
    .profile_box {
      .business_intrest {
        .expertise_web {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          gap: 16px;

          li {
            margin-right: 0px;
            min-width: 163px;
            border: 1px solid #f3f3f3;
            border-radius: 12px;
            padding: 12px 12px 0 12px;

            .txtb {
              border: none;

              .optional_txt {
                top: -24px;
                right: 48px;
              }
            }

            img {
              width: 163px;
              height: 174px;
            }
          }
        }

        .mobile-card {
          .form_group {
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  &.expertise_wrapper {
    .business_intrest {
      li {
        width: auto;

        img {
          width: 270px;
          height: 265px;
          object-position: top;
        }

        .txtb {
          border: 1px solid #e0e0e0;
          border-radius: 0px 0px 12px 12px;
          padding: 0 12px 24px;
          position: relative;

          h4 {
            background: #fff;
            border: none;
            border-radius: 0;
            text-align: left;
            font-weight: 500;
            font-size: 16px;
            line-height: 125%;
            padding: 8px 0;
            margin-top: 0;
            word-break: break-word;
          }

          .optional_txt {
            position: absolute;
            bottom: 13px;
            right: 12px;
            font-weight: 400;
            font-size: 10px;
            line-height: 14px;
            color: #8f939a;
          }

          .form_group {
            margin-bottom: 10px;

            .input_label {
              right: 12px;
              top: 50%;
              transform: translateY(-50%);
              left: inherit;
            }

            input {
              display: block;
              height: 44px;
              border: 1px solid #f3f3f3;
              border-radius: 12px;
              background: #f8f8f8;
              padding: 10px 12px;
              text-align: center;
            }
          }
        }
      }
    }
  }

  &.training_experience {
    .profile_box {
      .title {
        margin-bottom: 32px;
        padding-bottom: 0;
      }
    }
  }

  &.update-interests {
    padding: 0;
    padding-top: 6px;

    .profile_box {
      .title {
        margin-bottom: 12px;
        padding-bottom: 0;
      }

      .sub_title {
        margin-bottom: 36px;
        padding-bottom: 0;
        min-height: auto;
      }
    }
  }
}

.margin-top-adjust {
  margin-top: 94px !important;
}

.padding-top-adjust {
  padding-top: 94px;
}

@media screen and (max-width: "768px") {
  .manage_profile {
    width: 100%;
    padding: 1rem 0 0 0;
  }

  .margin-top-adjust {
    margin-top: 73.33px !important;
  }

  .padding-top-adjust {
    padding-top: 73.33px;
  }
}

.input_box.mobile {
  &::before {
    content: "+61";
    position: absolute;
    bottom: 13px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    left: 12px;
  }

  input {
    padding-left: 45px !important;
  }
}

.business_interest {
  .search-interest {
    position: relative;

    .filter-outer {
      position: absolute;
      top: 6px;
      right: 4px;
      height: 48px;

      .filter-icon {
        width: auto;
        cursor: pointer;
        height: 48px;
      }

      .filter-dropdown {
        background: #fafafa;
        border: 1px solid rgba(143, 147, 154, 0.08);
        border-radius: 12px;
        min-width: 247px;
        right: 0;

        list-style: none;
        padding: 25px;
        position: absolute;
        z-index: 4;
        top: 60px;

        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;
          width: 100%;
          position: relative;
          cursor: pointer;

          &:last-child {
            margin-bottom: 0px;
          }

          div {
            display: flex;
          }

          img {
            width: 35px;
            height: 20px;
            margin-right: 17px;
          }

          .select_img {
            position: absolute;
            right: -5px;
            margin-right: 0 !important;
          }

          span {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #25272d;
          }

          input {
            width: 20px;
            height: 20px;
          }
        }

        li.active {
          span {
            color: #ee762f;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.continue_btn {
  position: sticky;
  bottom: 0;
  padding-bottom: 40px;
  background: #fff;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    background: linear-gradient(180deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 76.04%);
    width: 100%;
    height: 110px;
    bottom: 30px;
    left: 0;
  }

  button {
    position: relative;
  }
}

.dzu-dropzone {
  border: none;
  display: block;
  min-height: initial;

  .dzu-inputLabel {
    border: 2px dashed #e0e0e0;
    border-radius: 12px;
    position: initial;
    background: #fafafa;
    min-height: 255px;
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    color: #25272d;
    margin-bottom: 30px;
  }

  .file_preview {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 8px;
    padding: 15px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
    border: 1px solid #ee7830;
    border-radius: 12px;
    margin-bottom: 20px;

    .name {
      flex: 1;

      .progressbar {
        margin: 10px 0 0;
      }
    }

    .status {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      img {
        width: 20px;
      }
    }
  }
}

.update-interests {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #8f939a;
  margin-bottom: 36px;
  max-width: 555px;

  &.left-sdie {
    margin-left: 0;
  }
}

.api_response {
  background: url(../img/dummymap.png);
  border: 1px solid #f3f3f3;
  border-radius: 12px;
  padding: 2px;
  background-size: cover;

  .wrapper {
    border: 1px solid #f3f3f3;
    border-radius: 10px;
    background: #ffffff;
    padding: 12px;
    max-width: 50%;
    text-align: left;

    label {
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      color: #25272d;
    }

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #25272d;
      padding-bottom: 16px;
    }

    span {
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      border: 1px solid #f3f3f3;
      border-radius: 8px;
      padding: 8px;
    }
  }
}

@media screen and (max-width: "768px") {
  .api_response {
    .wrapper {
      max-width: 70%;

      p {
        font-size: 14px;
        padding-bottom: 8px;
      }

      span {
        margin-top: 14px;
      }
    }
  }
}

.dob_wrapper {
  width: 100%;
  display: inherit;

  .rsd__react-select-datepicker {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;

    .rsd__select-container-day,
    .rsd__select-container-year {
      position: relative;
      width: 100%;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
      max-width: 120px;


      label {
        position: absolute;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        left: 12px;
        color: #25272d;
        top: 10px;
        margin-bottom: 0 !important;
      }
    }

    .rsd__select-container-month {
      position: relative;
      width: 100%;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
      max-width: auto;

      label {
        position: absolute;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        left: 12px;
        color: #25272d;
        top: 10px;
        margin-bottom: 0 !important;
      }

    }

  }
}

.upload_doc_label {
  border: 2px dashed #e0e0e0;
  border-radius: 12px;
  position: initial;
  background: #fafafa;
  min-height: 255px;
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;
  color: #25272d;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;

  &.no_func {
    pointer-events: none;
  }

  h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    color: #25272d;
  }

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 125%;
    color: #8f939a;
  }
}

@media screen and (max-width: "478px") {
  .upload_doc_label {
    margin-top: 32px;
    min-height: 175px;
  }
}

.file_preview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
  padding: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
  border: 1px solid #ee7830;
  border-radius: 12px;
  margin-bottom: 20px;

  .name {
    flex: 1;

    .size {
      font-size: 12px;
      line-height: 24px;
      color: #8f939a;
      margin-left: 12px;
    }

    .progressbar {
      margin: 10px 0 0;
    }
  }

  .status {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    img {
      width: 20px;
    }
  }
}

@media screen and (max-width: "768px") {
  .file-cont {
    padding-left: 0;

    .file_preview {
      align-items: start;
      padding-bottom: 16px;

      img {
        width: 32px;
        height: 32px;
      }

      .delete_cont {
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.no-data-found {
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  img {
    margin-bottom: 32px;
  }

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #8f939a;
    margin-bottom: 8px;

    b {
      font-weight: 500;
      color: #25272d;
    }
  }

  small {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #8f939a;
  }
}

.payment_wrapper {
  background-color: transparent !important;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .profile_box {
    background: #fff;

    border-radius: 12px;

    &.available_card {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .options {
        position: relative;

        .more {
          cursor: pointer;
        }

        .option_wrapper {
          background: #ffffff;
          border: 1px solid rgba(37, 39, 45, 0.16);
          border-radius: 12px;
          padding: 20px 20px 4px;
          position: absolute;
          width: 210px;
          right: 0px;
          opacity: 0;
          z-index: -2;
          transition: 0.3s;
        }

        &:hover {
          .option_wrapper {
            opacity: 1;
            z-index: 2;
          }
        }
      }
    }

    .left_box {
      display: flex;
      align-items: flex-start;
      gap: 12px;

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #25272d;
        padding-bottom: 4px;
      }

      small {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #8f939a;
      }
    }

    .delete {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
      padding-bottom: 16px;
    }
  }
}

.paymet_card {
  .form_group {
    width: 100%;

    .input_box {
      .StripeElement {
        border: 1px solid #f3f3f3;
        border-radius: 10px;
        padding: 0 12px;
        padding-top: 30px;
        padding-bottom: 12px;
      }

      label {
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        display: flex;
        align-items: center;
        color: #25272d;
        position: absolute;
        top: 10px;
        left: 12px;
      }
    }
  }

  .two-row {
    display: flex;
    gap: 20px;

    .form_group {
      &:nth-child(2) {
        max-width: 167px;
      }
    }
  }

  .descrip-pay-meth {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: #25272d;
    margin-top: 40px;
    margin-bottom: 16px;
  }
}

.remove_image {
  position: relative;
  display: flex;
  padding-bottom: 20px;
  justify-content: center;

  .text {
    margin-top: -30px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      background: linear-gradient(0deg,
          rgba(0, 0, 0, 0.08),
          rgba(0, 0, 0, 0.08)),
        linear-gradient(167.07deg, #ee7830 -15.49%, #ee762f 149.41%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
}

.fav_list {
  padding-bottom: 55px;
}

.fav_item {
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid rgba(143, 147, 154, 0.04);
  align-items: center;

  &:last-child {
    border-bottom: none;
  }

  .left_txtb {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #25272d;
    column-gap: 14px;

    img,
    .video_box {
      width: 44px;
      height: 44px;
      border-radius: 100%;
      overflow: hidden;
    }

    h5 {
      white-space: nowrap;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 500;
    }

    span {
      display: flex;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      align-items: center;
      column-gap: 5px;

      img {
        width: 14px;
        height: 14px;
      }
    }
  }

  .remove_btn {
    background: #f2f3f3;
    border-radius: 8px;
    color: #25272d;
    width: auto;
    padding: 12px 16px;
  }
}

.search_input {
  background-color: #f2f2f2;
  border-radius: 12px;
  padding: 20px 20px 20px 40px;
  width: 100%;
  border: none;
  background-image: url(../img/search-gray.svg);
  background-repeat: no-repeat;
  background-position: 10px center;
  margin-bottom: 20px;
}

@media screen and (max-width: "768px") {
  .fav_list {
    padding-left: 0px;
  }

  .fav_item {
    &:last-child {}

    .left_txtb {
      h5 {
        color: var(--SteelBlue, #25272d);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin-bottom: 0px;
        max-width: 175px;
      }

      span {
        img {
          width: 14px;
          height: 14px;
        }
      }
    }

    .remove_btn {
      background: #f2f3f3;
      border-radius: 8px;
      color: #25272d;
      width: auto;
      padding: 8px 12px;
      font-size: 14px;
    }
  }

  .search_input {
    padding: 10px 10px 10px 40px;
    font-size: 16px;
  }
}

.remove_all {
  display: inline-block;
  position: absolute;
  right: 0;
  background: linear-gradient(167.07deg, #ee7830 -15.49%, #ee762f 149.41%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 400;
  font-size: 14px;
  line-height: 36px;
  cursor: pointer;
}

.filter-outer {
  position: absolute;
  top: 6px;
  right: 4px;
  height: 48px;

  .filter-icon {
    width: auto;
    cursor: pointer;
    height: 48px;
  }

  .filter-dropdown {
    background: #fafafa;
    border: 1px solid rgba(143, 147, 154, 0.08);
    border-radius: 12px;
    min-width: 247px;
    right: 0;

    list-style: none;
    padding: 25px;
    position: absolute;
    z-index: 10;
    top: 60px;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      width: 100%;
      position: relative;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0px;
      }

      div {
        display: flex;
      }

      img {
        width: 35px;
        height: 20px;
        margin-right: 17px;
      }

      .select_img {
        position: absolute;
        right: -5px;
        margin-right: 0 !important;
      }

      span {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #25272d;
      }

      input {
        width: 20px;
        height: 20px;
      }
    }

    li.active {
      span {
        color: #ee762f;
        font-weight: 600;
      }
    }
  }
}

@media screen and (max-width: "768px") {
  .filter-outer {
    top: 12px;
    right: 6px;
    height: 34px;

    .filter-icon {
      height: 34px;
    }
  }
}

//earning page start
.earning-page-wrap {
  max-width: 875px !important;
  min-height: calc(100vh - 120px);

  .earning_box {
    padding: 30px 45px;
    max-width: 875px !important;
  }

  position: relative;

  .document-download {
    position: absolute;
    right: 54px;
    top: 36px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #000;

    span {
      display: none;
      margin-right: 10px;

      @media screen and (min-width:'800px') {
        display: flex;

      }
    }
  }

  .earning-title-box {
    display: flex;
    justify-content: center;
    gap: 54px;
    margin-top: 39px;
    margin-bottom: 12px;

    .content {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      color: #ee762f;
    }

    .number {
      color: #25272d;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      margin-top: 6px;
    }
  }

  .no-content-wrap {
    padding: 100px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  //earning card
  .myearning-card-wrap {
    border-radius: 12px;
    border: 1px solid #ddd;
    background: #fff;
    padding: 18px 16px 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;

    .left {
      display: flex;
      align-items: center;
      gap: 18px;

      .user-media {
        width: 53px;
        height: 53px;
        border-radius: 50%;
        object-fit: cover;
      }

      .title {
        color: var(--SteelBlue, #25272d);
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        max-width: 370px;
        text-overflow: ellipsis;
        margin-bottom: 6px;
        text-align: left;
      }

      .date {
        color: #6c6d6f;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        margin-bottom: 6px;

        span {
          font-weight: 500;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 16px;
      min-width: 240px;
      justify-content: space-between;

      .list-chip {
        display: inline-flex;
        padding: 4px 8px;
        align-items: center;
        gap: 4px;
        border-radius: 8px;
        background: #f3f3f3;
        text-align: right;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        color: #ee762f;
        min-width: 73px;
      }

      .earning-btn {
        cursor: pointer;
        display: inline-flex;
        padding: 4px 12px 4px 16px;
        align-items: flex-start;
        gap: 4px;
        border-radius: 8px;
        min-width: 125px;
        background: var(--Orange-Gradient,
            linear-gradient(167deg, #ee7830 -15.49%, #ee762f 149.41%));
        color: #fff;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
      }
    }

    .listing_detail_content_earning {
      div {
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        color: #6c6d6f;
        text-align: left;

        span {
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
          color: #0099ff;
        }
      }
    }

    @media screen and (max-width: "768px") {
      flex-direction: column;
    }
  }
}

@media screen and (max-width: "768px") {
  .earning-page-wrap {
    .earning_box {
      padding: 1rem;

      .document-download {
        top: 24px;
        right: 32px;
      }
    }

    .earning-title-box {
      .content {
        font-size: 12px;
      }
    }
  }

  .myearning-card-wrap {
    padding: 12px !important;

    .left {
      gap: 16px !important;
    }

    .right {
      justify-content: flex-end !important;
      flex-direction: row-reverse !important;
      justify-content: space-between;
      align-items: center !important;
      padding-left: 64px;
      width: 300px !important;

      img {
        height: 24px;
      }

      .earning-btn,
      .list-chip {
        margin-top: 6px;
        padding: 0px 8px !important;
      }
    }

    .listing_detail_content {
      text-align: left;

      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        color: #8f939a;
        text-align: left;

        span {
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
          color: #0099ff;
        }
      }
    }
  }

  .earn-booking-wrap {
    padding: 16px !important;
  }

  .booking-details-box {
    padding-right: 0px !important;
    margin-top: 24px !important;

    .list-chip {
      height: 40px;
    }

    .sub-title-wrap {
      margin-top: 16px !important;
      gap: 8px !important;
      justify-content: start !important;

      .subtitle,
      .spot-text {
        font-size: 16px !important;
      }
    }
  }

  .title-wrap {
    padding-right: 40px;
    display: flex !important;

    .title {
      font-size: 20px !important;
      align-items: flex-start;
    }
  }

  .booking-list-wrap {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

//earning page end

//purchase listing page start
.purchase-listing-right {
  text-align: center;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 0;
  max-width: 619px;
  width: 100%;

  .listing_box {
    margin-top: 40px;

    .title {
      color: #25272d;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
      margin-bottom: 80px;
    }

    .no-content-wrap {
      margin-bottom: 55px;
    }

    .purchase-btn-box {
      margin: 0 auto;
      margin-bottom: 32px;
      max-width: 484px;
    }
  }

  .goback {
    background: #f3f3f3;
    color: #8f939a;
  }

  @media screen and (max-width: "768px") {
    .listing_box {
      margin-top: 0;

      .title {
        margin-bottom: 30px;
        display: inline-flex;
        gap: 30px;
        align-items: center;
        font-size: 20px;
      }
    }
  }
}

.purchase-popup-links-wrap {
  margin-top: 24px;
  color: var(--SecondarySteel, #8f939a);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  padding: 0 44px;

  a {
    color: #ee762f;
  }
}

.block-details-card-wrap {
  margin: 0 auto;
  margin-bottom: 30px;
  max-width: 484px;
  border-radius: 12px;
  border: 1px solid #f2f2f2;
  background: #fff;

  .card-top-content {
    border-radius: 12px 12px 0px 0px;
    background: #f3f3f3;
    padding: 24px 40px;
    text-align: start;

    .boxtitle {
      color: #8f939a;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 23.5px;
      letter-spacing: 0.32px;
    }

    .listings-count {
      margin-top: 5px;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 23.5px;
      letter-spacing: 0.4px;
      color: #ee762f;
    }
  }

  .price-detail-box-wrap {
    margin-top: 16px;
    padding: 0 36px;

    .price-detail-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;

      .left {
        display: inline-flex;
        align-items: center;
        gap: 4px;
        color: #8f939a;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }

      .right {
        color: #25272d;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }

  .transaction-btn-box {
    padding: 0 36px;
  }

  .auto-paycut-btn-box {
    padding: 16px 36px;

    .auto-paycut-btn {
      color: #eb5757;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      border-radius: 12px;
      background: #ffe2e2;
      height: 42px;
      padding: 11px 0;
    }

    .auto-paycut-btn-disabled {
      color: #8f939a;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      background: #f3f3f3;
      height: 42px;
      padding: 11px 0;
    }
  }

  .accordion-item {
    border-radius: 12px;
  }

  .accordion-button {
    background: #f3f3f3 !important;
    color: #8f939a !important;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: normal;
    justify-content: center;
    height: 42px;
    outline: none;

    &::after {
      rotate: -90deg;
      margin-left: 4px;
      margin-top: 1px;
      background-image: url("../img/chevron-down.svg") !important;
      background-size: cover;
    }

    &:focus {
      z-index: 0;
      border: none;
      box-shadow: none;
    }
  }

  .accordion-item:not(:first-of-type) {
    border: 1px solid #dee2e6;
  }

  .accordion-button:not(.collapsed) {
    background: transparent;
  }

  .accordion-button:not(.collapsed)::after {
    rotate: 0deg;
  }

  .accordion-body {
    background: var(--FitnessNonSelected, #f3f3f3);
  }

  .transactions-info-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--SecondarySteel, #8f939a);
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: start;

      .date {
        color: var(--SteelBlue, #25272d);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .right {
      color: var(--SteelBlue, #25272d);
      text-align: right;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .divider {
    max-width: 100%;
    margin: 10px 0px 10px 32px;
    height: 1px;
    background: rgba(143, 147, 154, 0.1);
  }
}

.purchase-listing-right-blocks {
  text-align: center;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 0;
  max-width: 909px;
  width: 100%;

  .listing-block-page-wrap {
    padding: 0px;
  }

  .star-title-box {
    margin: 0 auto;
    max-width: 346px;

    .star-img {
      display: flex;
      justify-content: center;
      margin: 16px auto 24px;
    }

    .title {
      color: var(--SteelBlue, #25272d);
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }

    .sub-title {
      color: var(--SecondarySteel, #8f939a);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-top: 12px;
      margin-bottom: 40px;
    }
  }

  .listing_block_radio_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 482px;
    margin: auto;
    position: relative;
    gap: 20px;

    .listing_block_radio_option {
      position: relative;

      .input_label {
        border-radius: 8px 8px 0px 0px;
        border: 1px solid var(--Orange-Gradient, #ee7830);
        background: linear-gradient(167deg,
            rgba(238, 120, 48, 0.1) -15.49%,
            rgba(238, 118, 47, 0.1) 149.41%);
        font-size: 20px !important;
        font-style: normal;
        font-weight: 600 !important;
        line-height: 23.5px;
        letter-spacing: 0.4px;
        padding: 17px 14px !important;
        min-width: 192px !important;
        color: #ee762f;
        cursor: pointer;
      }

      .form_group {
        margin-bottom: 0;
      }

      .cross-icon {
        display: none;
      }

      &:last-child {
        margin-right: 0px;
      }

      .input_box {
        position: initial;
      }

      >img {
        width: 32px;
        height: 32px;
      }

      .input_box {
        textarea {
          border: 1px solid #f3f3f3;
          border-radius: 10px;
        }

        input {
          position: absolute;
          opacity: 0;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }

        input[type="radio"] {
          cursor: pointer;
        }

        .input_label {
          position: static;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          display: block;
          padding: 0 10px;
          white-space: nowrap;
        }
      }

      .check-icon {
        opacity: 0;
        width: 24px;
        height: 24px;
        background: url(../img/radio-check.svg);
        background-size: contain;
        position: absolute;
        right: 12px;
        top: 16px;
        z-index: -1;
      }

      .default-icon {
        opacity: 1;
        width: 20px;
        height: 20px;
        background: url(../img/radio-default.svg);
        background-size: contain;
        position: absolute;
        right: 14px;
        top: 18px;
        z-index: 2;
        pointer-events: none;
      }

      .radio-content {
        height: 184px;
        flex-shrink: 0;
        border-radius: 0px 0px 8px 8px;
        border: 1px solid var(--SecondarySteel, #8f939a);
        border-top: none;
        padding: 32px 8px;

        .price {
          font-size: 28px;
          font-style: normal;
          font-weight: 600;
          line-height: 23.5px;
          letter-spacing: 0.56px;
          color: #ee762f;

          span {
            color: var(--SteelBlue, #25272d);
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 23.5px;
          }
        }

        .divider {
          height: 0.3px;
          background: #8f939a;
          margin-top: 10px;
          margin-bottom: 16px;
          padding: 0 4px;
        }

        .listing-info {
          ul {
            padding-left: 1rem;

            li {
              color: #000;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 23.5px;
              letter-spacing: 0.32px;
            }
          }
        }
      }
    }

    .text-danger {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
    }

    .listing_block_radio_option.active {
      background: linear-gradient(167.07deg,
          rgba(238, 120, 48, 0.12) -15.49%,
          rgba(238, 118, 47, 0.12) 149.41%);

      .check-icon {
        opacity: 1;
        z-index: 2;
      }

      .default-icon {
        opacity: 0;
        z-index: -1;
      }

      .radio-content {
        background: #ee7830;
      }

      .price {
        span {
          color: #fff;
        }

        color: #fff;
      }

      .divider {
        background: #fff;
      }

      .listing-info {
        ul {
          li {
            color: #fff;
          }
        }
      }
    }

    .block_label {
      label {
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 23.5px;
        letter-spacing: 0.4px;
      }
    }
  }

  .listingblock_btn {
    max-width: 546px;
    width: 100%;
    margin: 40px auto;
    margin-bottom: 16px;
    padding: 0 16px;
  }

  .listing-card-box-outer {
    max-width: 520px;
    margin: 32px auto;
    padding: 16px;
    border: 1px solid #ddd;
    border-radius: 12px;
  }

  @media screen and (max-width: "768px") {
    .star-img {
      display: none !important;
    }

    .listing-block-page-wrap {
      padding: 0;

      .listing-block-white {
        padding: 0;
      }
    }

    .listing_block_radio_wrapper {
      gap: 10px;
      overflow: auto;
      padding: 10px;
      display: grid;
      grid-template-columns: repeat(1, 1fr);

      .listing_block_radio_option {
        .form_group {
          width: 300px;
        }

        .radio-content {
          padding: 18px 24px;
          text-align: left;
        }

        >img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  @media screen and (max-width: "457px") {
    .star-img {
      display: none;
    }

    .listing_block_radio_wrapper {
      justify-content: start;
    }

    .listing-card-box-outer {
      border: none;
      padding: 0px 4px;
    }
  }
}

//purchase listing page end

//User-Profile

.user-profile-layout {
  width: 100%;
  background: #f3f3f380;
  display: flex;
  justify-content: center;
  overflow: auto;

  .user-profile-container {
    display: flex;
    justify-content: center;
    height: 100vh;
    color: #25272d;
    padding-top: 24px;
    width: 80%;
    margin-top: 94px;

    @media screen and (max-width: "768px") {
      width: 100%;
      flex-direction: column;
      overflow-y: scroll;
      height: 100%;
    }

    .flex-row {
      display: flex;
      flex-direction: row;
    }

    .personal-info-card {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-around;
      border-radius: 12px;
      padding: 10px 0px;
      margin: 20px 0px;
      background-color: #f3f3f380;
    }

    .left-container {
      height: 740px;
      border-radius: 12px 12px 0px 0px;
      display: flex;
      flex-direction: column;
      width: 35%;
      position: relative;

      @media screen and (max-width: "768px") {
        width: 100%;
      }

      .grey-round-button {
        cursor: pointer;
        position: absolute;
        height: 40px;
        width: 40px;
        background-color: #00000052;
        border-radius: 100%;
        right: 12;
        top: 12;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
      }

      .orange-round-button {
        cursor: pointer;
        position: absolute;
        height: 40px;
        width: 40px;
        background-color: #ee7830;
        border-radius: 100%;
        right: 12;
        top: 12;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
      }

      .profile-image-big {
        border-radius: 12px 12px 0px 0px;
        filter: blur(2px);
        background-size: cover;
        height: 220px;

        &img {
          border-radius: 12px 12px 0px 0px;
        }
      }

      .profile-information {
        padding: 67.5px 24px 24px 24px;
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        position: relative;
        border-radius: 0px 0px 12px 12px;

        .profile-image {
          position: absolute;
          top: 100 !important;
          border-radius: 100%;
        }

        .sub-text {
          color: #8f939a;
          font-size: 12px;
        }

        .gray-card {
          width: 100%;
          background: #f3f3f380;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 46px;
          align-items: center;
        }

        .user-name {
          font-size: 20px;
          font-weight: 600;
        }
      }

      .user-profile-description {
        padding-top: 20px;
        margin-top: 20px;
        border-top: 1px solid #8f939a;
        font-size: 12px;
        color: #25272d;

        .bio-section-title {
          font-size: 16px;
          font-weight: 600px;
          margin-bottom: 8px;
        }

        .interest-section {
          font-size: 16px;
          font-weight: 600px;
          padding-top: 20px;

          .interest-tag-section {
            display: flex;
            font-size: 14px;
            flex-wrap: wrap;
            margin-top: 12px;

            .interest-tag {
              border: 1px solid #f3f3f3;
              border-radius: 8px;
              padding: 4px 16px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 8px;
              margin-bottom: 8px;
            }
          }
        }
      }
    }

    .profile-right-container {
      padding-left: 32px;
      width: 65%;

      @media screen and (max-width: "768px") {
        padding-left: 0px;
        width: 100%;
      }

      .listing-container {
        padding: 24px 30px;
        background: #ffffff;
        height: 100%;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        .more-option {
          justify-self: end;
          text-align: end;
          cursor: pointer;
          color: #ee7830;
        }

        .listing {
          margin-top: 16px;

          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(298px, 1fr));
          gap: 32px;

          @media screen and (max-width: "768px") {
            gap: 24px;
          }

          .no-listing-found-container {
            margin-top: 30px;
            display: flex;
            justify-content: center;

            .no-listing-found {
              display: flex;
              flex-direction: column;
              // justify-content: center;
              align-items: center;
              color: #8f939a;
              font-size: 16px;
            }
          }

          .profile-listing-card {
            position: relative;
            max-height: 311px;
            max-width: 287px;

            .listing-options {
              position: absolute;
              height: 40px;
              width: 40px;
              background-color: #00000052;
              border-radius: 100%;
              right: 12;
              top: 12;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .listing-image {
              border-radius: 12px 12px 0px 0px;
              background: #d9d9d9;
              height: 161px;
              width: 311px;

              @media screen and (max-width: "768px") {
                width: 100%;
              }
            }

            .card-body {
              background-color: #f2f2f2;
              border-radius: 0px 0px 12px 12px;
              position: relative;
              // padding: 0px 12px;

              .heading {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: #25272d;
                padding: 20px 12px;
                width: calc(100% - 12px);
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;
              }

              .calendar {
                margin-bottom: 12px;
                padding: 0px 12px;
                display: flex !important;
                flex-direction: row;
                justify-content: space-between;

                .date {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 16px;
                  color: #8f939a;
                  display: flex;
                  align-items: center;
                  gap: 4px;

                  .target {
                    font-weight: 500;
                    display: flex;
                    flex-direction: row;
                    color: #8f939a;
                  }

                  .set-target {
                    color: #2c407e;
                  }
                }
              }

              .session-count {
                padding: 0px 12px;
                font-size: 12px;
                font-weight: 500;
                color: #25272d;
              }

              .status-button {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px 0px;
                background-color: #f1fff7;
                color: #40b67d;
                font-size: 14px;
                border-radius: 0px 0px 12px 12px;
                margin-top: 8px;
              }

              .status-button-red {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px 0px;
                background-color: #ffe2e2;
                color: #eb5757;
                font-size: 14px;
                border-radius: 0px 0px 12px 12px;
                margin-top: 8px;
              }

              .km-data {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: -16px;
                padding: 0px 12px;
                position: absolute;
                width: 100%;

                .distance {
                  background: rgba(0, 0, 0, 0.32);
                  backdrop-filter: blur(16px);
                  border-radius: 8px;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  color: #ffffff;
                  padding: 4px 16px;
                }

                .pricing {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  color: #ffffff;
                  padding: 4px 12px;
                  background: linear-gradient(167.07deg,
                      #ee7830 -15.49%,
                      #ee762f 149.41%);
                  border-radius: 8px;
                }
              }
            }
          }
        }

        .listing-heading {
          font-size: 16px;
          font-weight: 600;
          border-radius: 8px;
        }

        .listing-info {
          background: #f3f3f3;
          color: #8f939a;
          padding: 8px 12px;
          border-radius: 8px;
          margin-top: 16px;
          display: flex;
          align-items: center;
          gap: 10px;

          .listing-info-text {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;

            color: #8f939a;

            span {
              color: #25272d;
            }
          }
        }
      }
    }
  }
}

//User_profile Section Ends

//Flag User Modal
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #25272dcc;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  z-index: 100;

  &.show {
    display: flex;
  }

  .flag_user_modal {
    width: 100%;
    max-width: 619px;
    background: #ffffff;
    border-radius: 12px;
    padding: 36px 32px 36px;
    display: grid;

    .title {
      font-weight: 600;
      font-size: 24px;
      text-align: center;
    }

    .description {
      font-weight: 600;
      font-size: 18px;
    }

    .sub-text {
      font-size: 12px;
      font-weight: 400;
      color: #8f939a;
    }

    .radio-container {
      padding: 20px 0px;
    }

    .fl-textarea {
      border-radius: 10px;
      border: 1px solid var(--FitnessNonSelected, #f3f3f3);
      background: #fff;

      label {
        color: var(--SteelBlue, #25272d);
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
      }

      .fl-words {
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
      }

      textarea.form-control:focus {
        box-shadow: 0 0 0 0 !important;
      }
    }
  }
}

//purchase listing page end

//subscription page ui start

.subscription-plan-right {
  text-align: center;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 0;
  max-width: 619px;
  width: 100%;

  .subscription-box {

    // margin-top: 32px;
    .s-no-content-wrap {
      // margin-bottom: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .s-title {
      color: #25272d;
      text-align: left;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
      margin-bottom: 80px;
    }
  }
}

.subscription-w-plan-right {
  text-align: center;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 24px 62px 24px 62px;
  margin-bottom: 0;
  max-width: 909px;
  width: 100%;

  .s-welcome-container {
    margin-top: 40px;

    .sw-title {
      text-align: start;
      font-size: 44px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .sw-desc {
      color: var(--SecondarySteel, #8f939a);
      text-align: start;
      font-size: 20px;
      // max-width: 704px;
      font-weight: 400;
      line-height: 32px;
    }

    .s-card-wrapper {
      max-width: 735px;
      width: 100%;
      padding: 28px;
      border-radius: 12px;

      background: #fff;
      box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.08);
      margin-bottom: 24px;

      .subs-option {
        width: 148px;

        display: flex;
        gap: 8px;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 8px;
        border: 1px solid #8f939a;
        margin-bottom: 1rem;

        p {
          margin-bottom: 0px;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          color: #8f939a;
        }
      }

      .member-info {
        color: #8f939a;

        font-size: 12px;

        font-weight: 500;
        line-height: 23px;

        letter-spacing: -0.408px;
        text-align: start;
      }

      .guest-info {
        text-align: start;
        color: #8f939a;
        font-size: 14px;
        font-weight: 400;
        line-height: 21.5px;
      }

      .benefit-title {
        font-size: 14px;
        text-align: start;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: -0.408px;
        margin-bottom: 0;
        display: none;
      }

      .benefit-container {
        display: none;
        gap: 8px;
        align-items: center;

        .benefit {
          margin-bottom: 0px;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 34.5px;
          letter-spacing: -0.408px;
          color: #ee7830;
        }
      }
    }

    .s-card-wrapper.Active {
      border: 1px solid #ee7830;

      .subs-option {
        border: 1px solid #ee7830;

        p {
          color: #ee7830;
        }
      }

      .benefit-title {
        display: block;
      }

      .benefit-container {
        display: flex;
      }
    }

    .s-card-wrapper.guest {
      border: 0;

      .subs-option {
        border: 1px solid #8f939a;

        p {
          color: var(--SecondarySteel, #8f939a);
          text-align: center;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }

    .btn-member {
      margin-bottom: 24px;
      max-width: 555px;
    }

    .btn-guest {
      background: #fff;
      color: black;
      max-width: 555px;
    }
  }
}

.s-block-details-card-wrap {
  margin: 0 auto;
  margin-bottom: 30px;
  max-width: 484px;
  border-radius: 12px;
  border: 1px solid #f2f2f2;
  background: #fff;

  .s-card-top-content {
    border-radius: 12px 12px 0px 0px;
    background: #f3f3f3;
    padding: 19px 36px;
    text-align: start;
    display: flex;
    align-items: center;
    gap: 18px;

    .subs-img-container {
      display: flex;
      background-color: #fff;
      align-items: center;
      border-radius: 100%;
      padding: 16px;
      border: 1px solid #ee7830;
    }

    .s-boxtitle {
      color: #ee7830;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
    }

    .s-subtitle {
      margin-top: 1px;
      color: #6c6d6f;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }

  .s-price-detail-box-wrap {
    margin-top: 16px;
    padding: 0 36px;

    .s-price-detail-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;

      .s-left {
        display: inline-flex;
        align-items: center;
        gap: 4px;
        color: #6c6d6f;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
      }

      .s-right {
        color: #25272d;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: right;
      }
    }
  }

  .s-transaction-btn-box {
    padding: 0 36px;
  }

  .s-auto-paycut-btn-box {
    padding: 16px 36px;

    .s-auto-paycut-btn {
      color: #eb5757;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      border-radius: 12px;
      background: #ffe2e2;
      height: 42px;
      padding: 11px 0;
    }

    .s-auto-paycut-btn.disable {
      background-color: #f3f3f3;
      color: #8f939a;
    }

    .info-box-cancel-wrap {
      margin-top: 16px;

      .info-box-cancel {
        display: flex;
        gap: 12px;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }

  .accordion-item {
    border-radius: 12px;
    border: none;
  }

  .accordion-button {
    background: #f3f3f3 !important;
    color: #6c6d6f !important;
    font-size: 12px !important;
    line-height: 18px !important;
    font-style: normal;
    font-weight: 500 !important;
    line-height: normal;
    justify-content: center;
    height: 42px;
    outline: none;
    border: none;
    outline: none !important;

    &::after {
      rotate: -90deg;
      margin-left: 4px;
      margin-top: 1px;
      background-image: url("../img/chevron-down.svg") !important;
      background-size: cover;
    }

    &:focus {
      z-index: 0;
      border: none;
      box-shadow: none;
    }
  }

  .accordion-item:not(:first-of-type) {
    border: 1px solid #dee2e6;
  }

  .accordion-button:not(.collapsed) {
    background: transparent;
  }

  .accordion-button:not(.collapsed)::after {
    rotate: 0deg;
  }

  .accordion-body {
    background: var(--FitnessNonSelected, #f3f3f3);
  }

  .s-transactions-info-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .s-left {
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--SecondarySteel, #8f939a);
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: start;

      .s-date {
        color: var(--SteelBlue, #25272d);
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .s-right {
      color: var(--SteelBlue, #25272d);
      text-align: right;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .s-divider {
    max-width: 100%;
    margin: 10px 0px 10px 32px;
    height: 1px;
    background: rgba(143, 147, 154, 0.1);
  }
}

.s-modal-container {
  max-width: 619px !important;

  .s-modal-wrapper {
    padding: 32px;

    .close {
      text-align: end;

      img {
        cursor: pointer;
      }
    }

    .s-title {
      margin-top: 12px;
      text-align: center;

      font-size: 24px;

      font-weight: 600;
      line-height: 36px;
    }

    .s-sub-title {
      color: #8f939a;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    .s-warning {
      color: var(--SecondarySteel, #8f939a);
      text-align: center;

      font-size: 16px;

      font-weight: 600;
      line-height: 20px;
    }

    .s-refund-info {
      display: flex;

      padding: 8px 12px;
      align-items: center;
      gap: 8px;
      border-radius: 12px;
      background-color: #f3f3f3;
      max-width: 465px;
      margin: auto;

      p {
        color: #8f939a;
        margin-bottom: 0px;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
      }
    }

    .s-purchase-popup-links-wrap {
      margin-top: 14px;
      margin-bottom: 14px;
      color: var(--SecondarySteel, #8f939a);
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      max-width: 480px;

      a {
        color: #ee762f;
      }
    }

    .goback {
      color: #8f939a;
      background: #f3f3f3;
      margin-top: 24px;
    }
  }
}

.payment-subs-plan-right {
  // max-width: 700px;
  background-color: #fff;
  padding: 3rem;
  border-radius: 12px;

  .ps-title {
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .ps-subtitle {
    color: #8f939a;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }

  .p-plan-container {
    display: flex;
    justify-content: center;

    .inner-p-plan-cont {
      display: flex;
      gap: 8px;
      margin: 50px 0px;

      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 0.897px;
      }

      .switch-button-container {
        border: 2px solid #ee7830;

        background-color: #f3f3f3;
        width: 50px;
        height: 26px;
        padding: 1px;
        display: flex;
        justify-content: start;

        .switch-circle {
          width: 21px;
          height: 100%;
          background-color: #ee7830;
          border-radius: 100%;
        }
      }

      .switch-button-container.active {
        border: 2px solid #ee7830;
        justify-content: end;
        background-color: #f3f3f3;
        transition: background-position 0.15s ease-in-out;

        .switch-circle {
          background-color: #ee7830;
        }
      }
    }
  }

  .subs-plan-info {
    border-radius: 12px;
    border: 1px solid #ee7830;
    background: linear-gradient(167deg,
        rgba(238, 120, 48, 0.12) -15.49%,
        rgba(238, 118, 47, 0.12) 149.41%);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 60px;
    max-width: 555px;
    margin: 0 auto;

    .sp-offer {
      border-radius: 9px;
      background: #ee7830;
      min-width: 168px;

      position: relative;
      top: -16px;
      padding: 4px;
      color: #fff;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }

    .s-plan-info {
      .s-plan-data {
        font-size: 25px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        text-align: center;
      }

      .sp-amount {
        text-align: center;
        color: #ee762f;
        font-size: 38px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }

      .sp-small-amount {
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: #8f939a;
      }
    }
  }

  .sp-note {
    color: #8f939a;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.5px;
    margin-top: 20px;

    b {
      color: #000;
    }
  }

  .feature-item {
    font-size: 14px;
    font-weight: 500;
    line-height: 34.5px;
    letter-spacing: -0.4px;
    text-align: left;
    color: #ee762f;
  }
}

@media screen and (max-width: "578px") {
  .subscription-plan-right {
    border-radius: 0px;

    .subscription-box {
      margin-top: 0px;

      .s-title {
        font-size: 20px;
        margin-bottom: 30px;
      }

      .s-block-details-card-wrap {
        .s-card-top-content {
          padding: 20px 16px;
          gap: 8px;

          .subs-img-container {
            padding: 12px;
          }

          .s-subtitle {
            margin-top: 0px;
          }
        }

        .s-price-detail-box-wrap {
          padding: 0 16px;
        }

        .s-transaction-btn-box {
          padding: 0 16px;
        }

        .s-auto-paycut-btn-box {
          padding: 16px 16px;
        }
      }
    }
  }

  .s-modal-container {
    max-width: 619px !important;

    .s-modal-wrapper {
      padding: 0px;

      .s-title {
        margin-top: 0px;
        font-size: 20px;
      }

      .s-sub-title {
        font-size: 14px;
      }

      .s-warning {
        font-size: 14px;
      }

      .s-refund-info p {
        font-size: 12px;
      }
    }
  }

  .subscription-w-plan-right {
    border-radius: 0px;
    padding: 1rem;

    .s-welcome-container {
      margin-top: 0px;

      .sw-title {
        font-size: 20px;
        text-align: center;

        p {
          max-width: 250px;
        }
      }

      .sw-desc {
        font-size: 14px;
        text-align: center;
        font-style: normal;
        line-height: 24px;
      }

      .s-card-wrapper {
        padding: 16px;

        .subs-option {
          margin-bottom: 8px;
        }

        .benefit-container {
          margin-bottom: 8px;

          .benefit {
            line-height: normal;
            text-align: start;
          }
        }
      }
    }
  }

  .payment-subs-plan-right {
    padding: 1rem;
    border-radius: 0px;

    .ps-title {
      font-size: 20px;

      display: flex;
      justify-content: center;
      text-align: center;

      p {
        position: absolute;
        left: 16px;
        cursor: pointer;
      }
    }

    .ps-subtitle {
      font-size: 16px;
      line-height: 24px;
    }

    .p-plan-container {
      max-width: 555px;
      margin: 0;

      .inner-p-plan-cont {
        margin: 0 0;
        margin-bottom: 16px;
      }
    }

    .subs-plan-info {
      margin-bottom: 24px;

      .s-plan-info {
        .s-plan-data {
          font-size: 20px;
        }

        .sp-amount {
          font-size: 24px;
        }

        .sp-small-amount {
          font-size: 14px;
        }
      }
    }
  }
}

//subscription page ui end
.radius-selector {
  display: flex;
  align-items: center;
  border-radius: 12px;
  background: #fff;
  margin-top: 15%;
  margin-right: 5%;
  padding-right: 8px;

  span {
    border-radius: 12px 0px 0px 12px;
    background: #ee762f;
    width: 44px;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .radiusSelect {
    border: none;
    outline: none;
    color: var(--SteelBlue, #25272d);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding-left: 4px;
  }
}

//trainer's page start

.trainer-layout {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #f2f2f2;
}

.trainer-skill-container {
  background-color: #ffffff;
  padding: 25px 88px;
  width: 65%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;

  .flex-row {
    display: flex;
    flex-direction: row;
    padding: 20px 0px;
  }

  .heading {
    font-size: 24px;
    font-weight: 600;
    color: #25272d;
    text-align: center;
  }

  .subtext {
    color: #8f939a;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
  }

  .tell-us-more {
    font-size: 14px;
    font-weight: 500;
    color: #ee7830;
    justify-self: end;
    text-align: end;
    cursor: pointer;
  }

  .progress-bar {
    background-color: #f3f3f3;
    height: 4px;
    width: 33%;
    cursor: pointer;
  }

  .progress-bar-orange {
    background-color: #ee7830;
    height: 4px;
    width: 33%;
  }

  .skill-card-container {
    display: flex;
    flex-direction: column;
  }

  .skill-card {
    margin: 10px 0px;
    display: flex;
    flex-direction: row;

    .skill-info {
      width: 62%;
      border: 1px solid #e0e0e0;
      border-radius: 0px 8px 8px 0px;
      padding: 10px 16px;

      .duration-input {
        display: flex;
        background-color: #f3f3f3;
        border-radius: 8px;
        padding: 10px 16px;
        margin: 0px 4px;

        input {
          border: none;
          width: 50%;
        }
      }

      .skill-heading {
        font-size: 16px;
        font-weight: 500;
        color: #25272d;
        text-transform: capitalize;
      }
    }
  }
}

.info-box-welcome-wrap {
  margin: 20px 0;
  border-radius: 12px;
  background: #f3f3f3;

  .info-box-welcome {
    padding: 8px 12px 8px 12px;
    display: flex;
    gap: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #8f939a;
  }
}

.info-box-reacc-payment {
  margin: 20px 0;
  border-radius: 12px;
  background: #f3f3f3;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  padding: 8px;
  color: #8f939a;
}

.modal-section.cropper_modal_wrap {
  max-width: fit-content;
  max-height: 100%;
  width: 100%;
  padding: 0 !important;
  margin: 0 !important;

  .image-cropper {
    display: flex;
    justify-content: center;

    .ReactCrop__child-wrapper {
      img {
        width: 100%;
        max-width: 1000px;
        max-height: 70dvh;
        object-fit: contain;

        @media screen and (max-width:600px) {
          width: calc(100vw - 40px);
        }

      }
    }
  }
}

.croper-wrap {
  max-width: 90vw;
  width: 100%;

  @media screen and (max-width:600px) {
    max-width: 100%;
  }

  .crop-box {
    padding: 24px;

    @media screen and (max-width:600px) {
      padding: 20px;
    }
  }

  .crop-button {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 24px;

    @media screen and (max-width:600px) {
      padding: 20px;
    }
  }

  .result-image {
    display: flex;
    justify-content: center;
    object-fit: cover;

    .preview-image {
      width: 550px;
      max-width: 1000px;
      max-height: 70dvh;
      object-fit: contain;

      @media screen and (max-width:600px) {
        width: calc(100vw - 40px);
      }

    }
  }



  .action-buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 24px;
    padding: 0px 24px 24px;

    .goback {
      background: #f3f3f3;
      color: #8f939a;
    }
  }
}

.all-checkbox-button_with-check {
  display: flex;
  gap: 8px;
  position: relative;
  margin-bottom: 24px;

  .form_checkbox_button_with_check {
    width: 100%;
    position: relative;
  }

  .checkbox-gender-label {
    background: #f6f6f7;
    border: 2px solid #f6f6f7;
    border-radius: 12px;
    padding: 12px;
    position: relative;
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    width: 100%;

    span {
      font-size: 12px;
      line-height: 24px;
      color: #25272d;
      font-weight: 500;
      line-height: 16px;
    }
  }

  .form_group {
    display: flex;
    position: relative;

    @media screen and (max-width: 600px) {
      margin-bottom: 10px;
    }

    .input_box {
      position: initial;
    }

    input {
      position: absolute;
      width: 100%;
      top: 0;
      height: 100%;
      z-index: 2;
      opacity: 0;
      cursor: pointer;
      visibility: hidden;
    }
  }

  .checkmark-circle {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }

  .checked {
    background: linear-gradient(167.07deg,
        rgba(238, 120, 48, 0.12) -15.49%,
        rgba(238, 118, 47, 0.12) 149.41%);
    border-radius: 12px;
    border: 2px solid #ee7830;
    padding: 12px;
    position: relative;
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    width: 100%;

    span {
      font-size: 12px;
      line-height: 24px;
      color: #25272d;
      font-weight: 500;
      line-height: 16px;
    }
  }
}

@media screen and (max-width: "768px") {
  .all-checkbox-button_with-check {
    flex-direction: column;
  }
}

.all-checkbox-button_with-check {
  display: flex;
  gap: 8px;
  position: relative;
  margin-bottom: 24px;

  .form_checkbox_button_with_check {
    width: 100%;
    position: relative;
  }

  .checkbox-gender-label {
    background: #f6f6f7;
    border: 2px solid #f6f6f7;
    border-radius: 12px;
    padding: 12px;
    position: relative;
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    width: 100%;

    span {
      font-size: 12px;
      line-height: 24px;
      color: #25272d;
      font-weight: 500;
      line-height: 16px;
    }
  }

  .form_group {
    display: flex;
    position: relative;

    .input_box {
      position: initial;
    }

    input {
      position: absolute;
      width: 100%;
      top: 0;
      height: 100%;
      z-index: 2;
      opacity: 0;
      cursor: pointer;
      visibility: hidden;
    }
  }

  .checkmark-circle {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }

  .checked {
    background: linear-gradient(167.07deg,
        rgba(238, 120, 48, 0.12) -15.49%,
        rgba(238, 118, 47, 0.12) 149.41%);
    border-radius: 12px;
    border: 2px solid #ee7830;
    padding: 12px;
    position: relative;
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    width: 100%;

    span {
      font-size: 12px;
      line-height: 24px;
      color: #25272d;
      font-weight: 500;
      line-height: 16px;
    }
  }
}

@media screen and (max-width: "768px") {
  .all-checkbox-button_with-check {
    flex-direction: column;
  }
}

.compare_table {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 45px;

  .compare_table_row {
    width: 100%;
    display: flex;
    z-index: 2;
    position: relative;
  }

  .compare_table_heading .compare_table_cell {
    padding: 28px 0px 16px 0px;
  }

  .horizontal_line {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #f3f3f3;
  }

  .compare_table_row:last-child .horizontal_line {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 0px solid #f3f3f3;
  }

  .compare_table_cell {
    padding: 4px 0px;
  }

  .first_cell {
    width: calc(100% - 460px);
  }

  .compare_table_cell:not(.first_cell) {
    width: 230px;
    text-align: center;
  }

  .highlight {
    border-left: 1px solid #ee7830;
    border-right: 1px solid #ee7830;
    background: linear-gradient(177.62deg,
        rgba(238, 120, 48, 0.08) -3.39%,
        rgba(238, 118, 47, 0.08) 32.22%);
    z-index: 3;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.40799999237060547px;
    text-align: right;
    color: #25272d;
  }

  .guest_data {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #25272d;
  }

  .free {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: #25272d;
  }

  .compare_table_row .first_cell {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #8f939a;
  }

  .compare_table_row:first-child .highlight {
    border-top: 1px solid #ee7830;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .compare_table_row:last-child .highlight {
    border-bottom: 1px solid #ee7830;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .special {
    position: relative;

    .pop_style {
      width: 56px;
      height: 28px;
      border-top-left-radius: 28px;
      border-top-right-radius: 28px;
      position: absolute;
      top: -28px;
      left: calc(50% - 28px);
      background: #fff;
    }
  }

  .member_heading {
    font-size: 26px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #ee7830;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .guest_heading {
    font-size: 26px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #25272d;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.info_wrap {
  width: fit-content;
  top: 84px;
  left: 60px;
  padding: 10px 12px 10px 12px;
  gap: 10px;
  border-radius: 10px;
  opacity: 0px;
  background: #F3F3F3;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #25272d;

  .icon_info {
    width: 16px;
    height: 16px;
  }

  .click_action {
    text-decoration: underline;
    font-weight: 500;
    cursor: pointer;

  }
}