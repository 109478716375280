.page-draft {
  padding: 24px;
  background: #f6f6f6;
  min-height: calc(100vh - 1px);

  .wrap-box {
    margin: 0px auto;
    max-width: 1112px;
    padding: 80px 106px;
    background: #ffffff;

    .title-box {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;

      .back_arrow {
        position: absolute;
        left: 0;
        cursor: pointer;
      }
    }

    .select_listing_type {
      display: flex;
      justify-content: center;
      list-style: none;
      gap: 32px;
      padding-top: 24px;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0em;
      text-align: center;
      border-bottom: 1px solid #d9d9d9;
      margin-top: 24px;

      li {
        cursor: pointer;
        color: #4e5158;
        padding-bottom: 9px;

        &.active {
          border-bottom: 1px solid #25272d;
        }

        img {
          width: 24px;
        }
      }
    }
  }

  .card-list-results {
    margin: 32px auto;
  }

  .draft-card-wrap {
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #f3f3f3;
    background: #fff;
    max-width: 647px;
    margin: 0 auto;
    margin-bottom: 24px;
    cursor: pointer;

    .content-box {
      display: flex;
      gap: 25px;
      position: relative;

      .card-img {
        width: 91px;
        height: 91px;
        border-radius: 4px;
      }

      .content {
        width: 100%;
        padding-right: 48px;

        .title {
          color: #25272d;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          margin-bottom: 8px;
          margin-top: 15px;
        }

        .sub-content {
          align-items: center;
          display: flex;
          gap: 4px;
          color: #8f939a;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 0;
          margin-bottom: 10px;
        }
      }

      .delete-icon {
        position: absolute;
        right: 8px;
        top: 4px;
        cursor: pointer;
      }
    }
  }

  .tooltip-box {
    margin: 0 auto;
    max-width: 647px;
    padding-top: 8px;
    margin-top: 32px;

    .info_box {
      box-shadow: none;
      padding: 10px 12px;
      border-radius: 10px;
      background: #ececec;
      position: static;
      z-index: 4;
      display: flex;
      gap: 10px;
      align-items: flex-start;

      .tip-title {
        color: #8f939a;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 12px;
        margin-bottom: 12px;
      }

      .tip-subtitle {
        color: #8f939a;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        margin-bottom: 8px;
      }
    }
  }
}

.or-divider-wrap {
  align-items: center;
  justify-content: center;
  gap: 10px;
  display: flex;
  color: #8f939a;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.12px;
  padding: 28px 0px;

  .divide-line {
    height: 1px;
    opacity: 0.16;
    width: 100%;
    background-color: #8f939a;
  }
}

.Notavailabledata-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.Notavailableimg-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.description-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #6C6D6F;

}

.description-details {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #6C6D6F;

}

.Notavailableimg {
  width: 130px;
  height: 130px;
  margin-top: 15px;
}

@media screen and (max-width: "768px") {
  .page-draft {
    padding: 0px;
    padding-top: 16px;

    .wrap-box {
      padding: 1rem;
      height: 100vh;
    }

    .draft-card-wrap {
      padding: 0px;
      border: 0px;

      .content-box {
        display: flex;
        gap: 25px;
        position: relative;
        align-items: center;
        margin-bottom: 1rem;

        .content {
          width: 100%;
          padding-right: 48px;
          display: flex;
          align-items: center;

          .title {
            margin-bottom: 0px;
            margin-top: 0px;
            color: var(--SteelBlue, #25272d);
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
          }

          .sub-content {
            align-items: center;
          }
        }

        .delete-icon {
          top: 9px;
        }

        .card-img {
          width: 50px;
          height: 50px;
          border-radius: 4px;
        }
      }

      .sub-content {
        align-items: center;
        display: flex;
        gap: 4px;
        color: #8f939a;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 0;
        margin-bottom: 10px;

        h6 {
          font-size: 14px;
          font-weight: 400;
          max-width: 400px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

//payment mode start
.payment-mode-wrapper {
  .payment-sub-title {
    margin-top: 16px;
    margin-bottom: 72px;
    color: var(--SecondarySteel, #8f939a);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 23.5px;
  }

  .payment-type-buttons-wrap {
    max-width: 546px;
    margin: 0 auto;

    .or {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #8f939a;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .or:after,
    .or:before {
      content: "";
      display: block;
      background: #f3f3f3;
      width: 50%;
      height: 1px;
      margin: 0 10px;
    }

    .continue-btn-box {
      margin-top: 48px;
    }
  }

  .bank-form-wrap {
    margin-top: 38px;

    .info-box {
      margin-top: 32px;
      padding: 10px 12px;
      border-radius: 10px;
      background: #ececec;
      display: flex;
      gap: 4px;
      align-items: flex-start;
      color: var(--SecondarySteel, #8f939a);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;

      img {
        margin-top: 2.5px;
      }
    }
  }
}

.common_btn_payment {
  width: 100%;
  cursor: pointer;
  border-radius: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  padding: 15px 0;
  border-radius: 12px;
  border: 1px solid #8f939a;
  height: 56px;
  color: #25272d;
  position: relative;
  background: #fff;

  img {
    margin-right: 8px;
    position: absolute;
    left: 24px;
    -webkit-filter: grayscale(100%) !important;
    filter: grayscale(100%) !important;
  }

  .isRecommend {
    display: inline-flex;
    padding: 0px 11px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: var(--Orange-Gradient,
        linear-gradient(167deg, #ee7830 -15.49%, #ee762f 149.41%));
    position: absolute;
    right: 36px;
    top: -12px;
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  &.active,
  &:active,
  &:focus {
    border: 1px solid var(--Orange-Gradient, #ee7830);
    background: linear-gradient(167deg,
        rgba(238, 120, 48, 0.12) -15.49%,
        rgba(238, 118, 47, 0.12) 149.41%),
      #fff;
    font-weight: 600;
    color: #ee762f;

    img {
      -webkit-filter: grayscale(0) !important;
      filter: grayscale(0) !important;
    }
  }
}

//payment mode end
.radio-recommended {
  display: inline-flex;
  padding: 0px 11px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: var(--Orange-Gradient,
      linear-gradient(167deg, #ee7830 -15.49%, #ee762f 149.41%));
  color: var(--White, #fff);
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.terms-cancel-allow {
  color: var(--SteelBlue, #25272d);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  a {
    color: var(--SteelBlue, #25272d);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    text-decoration-line: underline;
  }
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner__container {
  width: 56px;
  height: 56px;
  position: relative;
}

.text__container {
  height: 56px;
  margin-left: 12px;
  display: flex;
  align-items: center;
}

.text__container>span {
  font-size: 16px;
  color: #474f54;
  font-weight: 400;
  font-family: lato, sans-serif;
}

.spinner--fill {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 100%;
  animation: spinner-fill-scaleout 2.4s infinite ease-in-out;
}

.spinner--line {
  width: 54px;
  height: 54px;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 100%;
  animation: spinner-line-scaleout 1.2s infinite ease-in-out;
}

@keyframes spinner-fill-scaleout {
  0% {
    transform: scale(0);
    border: 18px solid #1e7e98;
    background-color: #1e7e98;
  }

  100% {
    transform: scale(1);
    border: 18px solid #1e7e98;
    background-color: none;
    opacity: 0;
  }
}

@keyframes spinner-line-scaleout {
  0% {
    transform: scale(0);
    border: 1px solid #820068;
    opacity: 1;
  }

  100% {
    transform: scale(1);
    border: 1px solid #820068;
    opacity: 0;
  }
}