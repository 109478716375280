 .custom_dialog_content {
     padding: 0px !important;

     .event_wrap_review {
         padding: 15px 56px 0px 56px;

         @media screen and (max-width:768px) {
             padding: 16px 20px 0px 20px;
         }

         .event_review_border {
             border-bottom: 1px solid #6C6D6F;
             padding-bottom: 16px;
             width: 100%;
             display: flex;
             gap: 22px;

             @media screen and (max-width:768px) {
                 padding-bottom: 16px;
             }

             .event_review_media {
                 width: 179px;
                 height: 103px;
                 border-radius: 8px;

                 img {
                     width: 179px;
                     height: 103px;
                     border-radius: 8px;
                     object-fit: cover;
                 }

                 @media screen and (max-width:768px) {
                     width: 108px;
                     height: 62px;

                     img {
                         width: 108px;
                         height: 62px;
                     }
                 }
             }

             .event_details_wrap {
                 width: calc(100% - 201px);

                 @media screen and (max-width:768px) {
                     width: calc(100% - 128px);
                 }

                 .event_title_review {
                     font-size: 20px;
                     font-weight: 500;
                     line-height: 30px;
                     color: #25272D;
                     display: -webkit-box;
                     -webkit-box-orient: vertical;
                     -webkit-line-clamp: 2;
                     overflow: hidden;

                     @media screen and (max-width:768px) {
                         font-size: 14px;
                         line-height: 20px;
                     }
                 }

                 .event_date_review {
                     margin-top: 5px;
                     font-size: 16px;
                     font-weight: 400;
                     line-height: 20px;
                     color: #505155;
                     -webkit-box-orient: vertical;
                     -webkit-line-clamp: 1;
                     overflow: hidden;

                     @media screen and (max-width:768px) {
                         font-size: 10px;
                         margin-top: 2px;
                     }
                 }
             }
         }
     }

     .add_review_section {
         padding: 10px 56px 0px 56px;

         @media screen and (max-width:768px) {
             padding: 10px 20px 10px 20px;
         }

         .add_review_title {
             color: #25272D;
             font-size: 18px;
             font-weight: 600;
             line-height: 26px;
         }

         .add_review_subtitle {
             font-size: 14px;
             font-weight: 400;
             line-height: 20px;
             color: #505155;
         }
     }
 }