.manage-subscription-container {
    .manage-subscription-card {
        max-width: 550px;
        border-radius: 12px;
        border: 1px solid #dcdcdc;

        .manage-subscription-header {
            display: flex;
            align-items: center;
            background-color: #ffead9;
            border-radius: 12px 12px 0 0;
            padding: 23px 28px;

            .manage-subscription-icon {
                margin-right: 10px;
                img {
                    width: 56px;
                    height: 56px;
                }
            }

            .manage-subscription-title {
                text-align: left;
                .title {
                    color: #ee7830;
                    font-size: 20px;
                    line-height: 24px;
                    font-weight: 600;
                }

                .description {
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 500;
                    color: #6c6d6f;
                    margin-top: 0px;
                }
            }
        }

        .manage-subscription-details {
            padding: 16px 32px 19px 28px;
            .detail-item {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                justify-content: space-between;
                .detail-icon {

                    .img-box{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 20px;
                        width: 20px;
                    }
                    margin-right: 10px;
                    display: flex;
                    i {
                        font-size: 16px;
                        color: #666;
                    }
                }

                .detail-label {
                    flex: 1;
                    font-size: 16px;
                    color: #6c6d6f;
                    line-height: 20px;
                    font-weight: 500;
                    padding-left: 8px;
                }

                .detail-value {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 24px;
                    color: #25272d;
                }
            }
        }
    }
}
.manage-subscription-bottom {
    margin-top: 24px;
    .bottom-content {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        .bottom-icon {
            margin-right: 11px;
            display: flex;
            align-items: center;
        }
        .bottom-description {
            font-size: 12px;
            color: #6c6d6f;
            font-weight: 400;
            line-height: 19px;
            padding-top: 2px;
        }
    }

    .bottom-buttons {
        display: flex;
        justify-content: space-between;
        @media (max-width: 767px) {
            flex-direction: column;
        }
        .bottom-btn {
            flex: 1;
            margin: 0 5px;
            font-size: 14px;
            line-height: 24px;
            font-weight: 500;
            padding: 16px 18px;
            color: #25272d !important;
            border: 1px solid #dcdcdc;
            border-radius: 12px;
            text-transform: capitalize;
            &:hover {
                background-color: #ff7d42;
                color: #fff !important;
            }
            @media (max-width: 767px) {
                margin-bottom: 15px;
            }
        }
    }
}
