.join-page-wrap {
  background-image: url("../../assets/img/joinbg.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  position: relative;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ee762fe3;
    .outer-box {
      max-width: 795px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100vh;
      .title {
        font-size: 44px;
        line-height: 66px;
        color: #ffffff;
        font-weight: 700;
        text-align: center;
      }
      .sub-title {
        font-size: 20px;
        line-height: 32px;
        color: #ffffff;
        font-weight: 400;
        text-align: center;
        padding: 0px 44px;
      }
    }
    .button-box {
      margin-top: 54px;
      text-align: center;
      .continue-btn {
        max-width: 553px;
        height: 54px;
        background: #ffffff;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ee762fe3;
        margin: 0 auto;
        font-size: 18px;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }
}
@media (max-width: 768px) {
  .join-page-wrap {
    .outer-box {
      padding: 32px;
      .title {
        font-size: 29px !important;
        line-height: 43px !important;
      }
      .sub-title {
        font-size: 14px !important;
        line-height: 21px !important;
        padding: 0px !important;
      }
    }
  }
}
