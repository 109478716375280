.header-container {
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #25272d;
  display: flex;
  justify-content: center;

  .new-header-container {
    width: 1290px;
    padding: 0px 16px;

    .new-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 6px 0px;

      ul {
        list-style: none;
        padding-left: 0;
        margin: 0;
        display: flex;
      }

      .middle-bar {
        display: flex;
        gap: 66px;
        align-items: center;

        .menus {
          position: relative;

          ul {
            gap: 48px;

            @media screen and (max-width: "1200px") {
              gap: 20px;
            }

            li {
              a {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                color: #ffffff;
                text-decoration: none;
                min-width: 80px;

                &.active {
                  color: #ee772f;
                  font-weight: 500;
                }
              }

              a:hover {
                color: #ff7800;
              }
            }
          }
        }
      }

      .right-bar {
        display: flex;
        gap: 16px;
        align-items: center;

        .login-signup-wrapper{
            display: flex;
            gap: 12px;
            align-items: center;
            font-weight: 500 ;
            line-height: 16px;
            font-size: 14px ;

            .login-btn{
                padding: 7px 12px ; 
                color: #FFFFFF ;
                cursor: pointer;
                background-color: #25272D;
                border-radius: 8px;
                border: 1px solid #FFFFFF ; 
            }
            .sign-up-btn{
              padding: 7px 12px ; 
              color: #25272D ;
              background-color: #FFFFFF ;
              cursor: pointer;
              border-radius: 8px;
              border:0px;
              box-shadow: 0px 4px 32px 0px #0000000A;

            }
        }
        .join-as-a-member {
          padding: 7px 12px ; 
          color: #25272D ;
          background-color: #FFFFFF ;
          cursor: pointer;
          border-radius: 8px;
          border:0px;
          box-shadow: 0px 4px 32px 0px #0000000A;
          cursor: pointer;
          // font-family: "Poppins";
          // font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
        }

        .join-as-a-member:hover {
          // color: #ee772f;
        }

        .icon-menu {
          ul {
            gap: 16px;
            align-items: center;

            li {
              img {
                cursor: pointer;
              }

              &.menu-des {
                .inner {
                  display: flex;
                  border: 1px solid #d9d9d9;
                  border-radius: 100px;
                  padding: 4px;
                  padding-left: 14px;
                  align-items: center;
                  gap: 8px;

                  .menu-icon {
                    width: 17px;
                    height: 17px;
                  }

                  .profile {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    border: 2.21538px solid #ffffff;
                    filter: drop-shadow(0px 0px 6.64615px rgba(0, 0, 0, 0.12));
                    object-fit: cover;
                  }
                }

                .inner:hover {
                  display: flex;
                  border: 1px solid #ee762f;
                  background: linear-gradient(167.07deg,
                      rgba(238, 120, 48, 0.1) -15.49%,
                      rgba(238, 118, 47, 0.1) 149.41%);

                  border-radius: 100px;
                  padding: 4px;
                  padding-left: 14px;
                  align-items: center;
                  gap: 8px;

                  .menu-icon {
                    width: 20px;
                    height: 20px;
                  }

                  .profile {
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                    border: 2.21538px solid #ffffff;
                    filter: drop-shadow(0px 0px 6.64615px rgba(0, 0, 0, 0.12));
                    object-fit: cover;
                  }
                }

                @media screen and (max-width: "768px") {
                  .inner {
                    .profile {
                      width: 28px;
                      height: 28px;
                    }

                    .menu-icon {
                      width: 18px;
                      height: 18px;
                    }
                  }
                }
              }
            }

            li.header-notify-icon {
              position: relative;

              .notify-badge {
                position: absolute;
                background: #ee762f;
                border-radius: 100%;
                display: flex;
                top: -7px;
                right: -7px;

                .nine-plus {
                  font-size: 10px;
                  color: #ffffff;
                  // padding: 2px 4px;
                  border-radius: 100%;
                  width: 16px;
                  height: 16px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }

                .no-plus {
                  font-size: 10px;
                  color: #ffffff;
                  // padding: 2px 4px;
                  border-radius: 100%;
                  width: 15px;
                  height: 15px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
            }
          }
        }
      }
    }
  }
}

.global-footer {
  background-color: #25272d;

  a {
    text-decoration: none;
    color: inherit;
  }

  .footer-container {
    max-width: 1290px;
    margin: auto auto;

    .footer-first-part {
      padding-top: 60px;
      padding-bottom: 60px;
      color: #ffffff;

      .links-box {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        padding-left: 16px;
        padding-right: 16px;

        // width: fit-content;

        h4 {
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
        }

        ul {
          margin-top: 22px;

          li {
            margin-bottom: 16px;
            width: fit-content;
            cursor: pointer;
          }

          li:hover {
            color: #ff7800;
          }
        }
      }

      .follow-us {
        .follow-icon-container {
          display: flex;
          gap: 24px;
          margin-top: 20px;
          margin-bottom: 20px;

          .img-container {
            cursor: pointer;
            width: 20px;
            height: 20px;

            img {
              width: 100%;
            }

            img:nth-child(2) {
              display: none;
            }
          }

          .img-container:hover {
            img:nth-child(1) {
              display: none;
            }

            img:nth-child(2) {
              display: block;
            }
          }
        }

        .follow-store-container {
          display: flex;
          gap: 10px;

          .download-image {
            position: relative;

            .coming-soon {
              position: absolute;
              bottom: -12px;
              right: 21px;
              display: inline-flex;
              padding: 2px 8px;
              align-items: flex-start;
              gap: 4px;
              border-radius: 100px;
              background: #25272d;
              text-align: center;
              font-size: 10px;
              font-style: normal;
              font-weight: 500;
              line-height: 16px;
              color: #ffffff;
              border: 1px solid #f4f4f4;
              cursor: pointer;
            }
          }
        }
      }

      .locate-us {
        .locate-item-container {
          margin-top: 22px;
          margin-bottom: 22px;
          display: flex;
          flex-direction: column;
          gap: 20px;

          .locate-item {
            display: flex;
            gap: 10px;
            align-items: center;

            p {
              margin-bottom: 0px;
              word-wrap: break-word;
              word-break: break-all;
              cursor: pointer;
            }

            p:hover {
              color: #ff7800;
            }
          }
        }
      }

      @media screen and (max-width: "900px") {
        padding-bottom: 30px;
      }
    }

    .footer-second-part {
      border-top: 1px solid #f3f3f3;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 14px;
      color: #ffffff;
      padding-top: 20px;
      padding-bottom: 28px;
      padding-left: 16px;
      padding-right: 16px;

      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .footer-terms {
        display: flex;
        gap: 16px;

        p {
          margin-bottom: 16px;
        }

        p:hover {
          color: #ff7800;
        }
      }

      @media screen and (max-width: "900px") {
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 0.3px;
        text-align: left;
        border: none;

        .footer-terms {
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
          text-align: right;
          color: #ffffff;
        }
      }
    }
  }
}

.blur-class {
  background: #25272d;
  opacity: 0.8;
}

.events-page {
  background-color: #ffffff;
  position: relative;
  z-index: 0;

  .selected-listing-type-container {
    border-bottom: 1px solid #dcdcdc;
    display: flex;
    justify-content: center;

    .selected-listing-type-wrapper {
      width: 1290px;
      // border: 1px solid blue;
      padding: 18px 16px;
      display: flex;
      justify-content: space-between;

      .listing-type-left {
        display: flex;
        gap: 26px;

        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #25272d;

        .selected-type {
          display: flex;
          gap: 6px;
          align-items: center;
          border-right: 1px solid #dcdcdc;
          padding: 0px 26px 0px 0px;
          cursor: pointer;

          img {
            width: 22px;
            height: 22px;
          }

          img:nth-child(2) {
            display: none;
          }

          p {
            white-space: nowrap;
          }
        }

        .selected-type:hover {
          color: #ff7800;

          img:nth-child(1) {
            display: none;
          }

          img:nth-child(2) {
            display: block;
          }
        }

        .selected-type.active {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          color: #ee762f;
        }

        .selected-type:last-child {
          border-right: none;
        }
      }

      .listing-type-right {
        display: flex;
        gap: 10px;
        align-items: center;
        padding: 10px 22px 10px 17px;
        border: 1px solid #8f939a;
        border-radius: 39px;
        z-index: 1000;
        position: relative;
        background: #ffffff;

        img {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }

        input {
          width: 350px;
          border: none;
          outline: none;
          white-space: nowrap;
          margin-bottom: 0px;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #25272d;
        }
      }
    }
  }

  // .seracinput {
  //   width: "350px"
  // }

  .filter-row-container {
    // border: 5px solid green;

    padding-top: 16px;
    padding-bottom: 16px;

    .filter-row-wrapper {
      display: flex;
      gap: 30px;
      max-width: 1258px;
      margin-left: auto;
      margin-right: auto;

      .filter-first {
        max-width: 58%;

        // .filter-list {
        //   border: 2px solid green;
        .slider-container {
          .slick-slider {
            display: flex;

            .slick-slide {
              width: fit-content !important;
              // padding-left: 16px;
              // padding-right: 16px;
            }

            .prev-arrow-btn-container {
              display: flex !important;
              align-items: center;
              // width: 10px;
              // padding-left: 16px;
              margin-right: 16px;
              cursor: pointer;

              /* Auto layout */
              .prev-arrow-btn {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                width: 32px;
                height: 32px;

                /* White */
                background: #ffffff;
                /* SecondarySteel */
                border: 1px solid #8f939a;
                border-radius: 28px;
                transform: matrix(-1, 0, 0, 1, 0, 0);

                img {
                  width: 16px;
                  height: 16px;
                }
              }
            }

            .next-arrow-btn-container {
              display: flex !important;
              align-items: center;
              margin-left: 24px;
              // width: 10px;
              cursor: pointer;

              /* Auto layout */
              .next-arrow-btn {
                display: flex;
                justify-content: center;
                align-items: center;

                width: 32px;
                height: 32px;

                /* White */
                background: #ffffff;
                /* SecondarySteel */
                border: 1px solid #8f939a;
                border-radius: 28px;
                transform: matrix(-1, 0, 0, 1, 0, 0);

                img {
                  width: 16px;
                  height: 16px;
                }
              }
            }
          }
        }

        .filter-item {
          // width: fit-content;
          // max-width: 968px;
          // border: 5px solid violet;
          display: flex;
          padding-left: 20px;
          padding-right: 20px;
          cursor: pointer;

          img {
            margin: auto;
            margin-bottom: 5px;
            display: flex;
          }

          p {
            margin-bottom: 0px;
            text-align: center;
          }
        }

        // }
      }

      .filter-second {
        display: flex;
        gap: 10px;
        // border: 1px solid black;
        // width: fit-content;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #25272d;

        .filter-sort-btn {
          display: flex;
          padding: 12px 9px;
          // width: 139px;
          // flex: 1;
          height: 48px;
          border: 1px solid #25272d;
          border-radius: 12px;
          flex-shrink: 1;
          cursor: pointer;

          .inner-info {
            display: flex;
            align-items: center;
            gap: 7px;
            margin: auto;

            p {
              margin-bottom: 0px;
              white-space: nowrap;
            }
          }
        }
        .active-filter-sort {
          display: flex;
          padding: 12px 9px;
          // width: 139px;
          // flex: 1;
          height: 48px;
          border: 1px solid #EE7830;
          background: linear-gradient(167.07deg,
              rgba(238, 120, 48, 0.1) -15.49%,
              rgba(238, 118, 47, 0.1) 149.41%);
          border-radius: 12px;
          flex-shrink: 1;
          cursor: pointer;

          .inner-info {
            display: flex;
            align-items: center;
            gap: 7px;
            margin: auto;

            p {
              margin-bottom: 0px;
              white-space: nowrap;
            }
          }
        }
        .filter-count-wrap{
              height:16px ;
              width:16px ;
              border-radius: 50% ;
              background-color: #EE7830 ; 
              position: absolute ;
              top:-4px;
              right:0px;
              color:#FFFFFF;
              font-size: 8px;
              font-weight: 600;
              line-height: 13px;
              display: flex;
              justify-content: center;
              align-items: center;
        }
        .filter-sort-btn:hover {
          display: flex;
          background: linear-gradient(167.07deg,
              rgba(238, 120, 48, 0.1) -15.49%,
              rgba(238, 118, 47, 0.1) 149.41%);
          border-radius: 12px;
          border: 1px solid #ee762f;
          height: 48px;
          border-radius: 12px;
          flex-shrink: 1;
          cursor: pointer;

          .inner-info {
            display: flex;
            align-items: center;
            gap: 7px;
            margin: auto;

            p {
              margin-bottom: 0px;
              white-space: nowrap;
            }
          }
        }

        .show-map-btn {
          cursor: pointer;
          padding: 12px 12px;
          gap: 10px;
          // width: 150px;
          flex: 1;
          height: 48px;
          background: linear-gradient(167.07deg,
              rgba(238, 120, 48, 0.1) -15.49%,
              rgba(238, 118, 47, 0.1) 149.41%);
          border-radius: 12px;
          border: 1px solid #ee762f;

          .inner-info {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 7px;
            margin: auto;

            p {
              margin-bottom: 0px;
              white-space: nowrap;
            }
          }
        }

        .show-map-btn-inactive {
          cursor: pointer;
          padding: 12px 12px;
          gap: 10px;
          // width: 150px;
          flex: 1;
          height: 48px;
          border-radius: 12px;
          border: 1px solid;

          .inner-info {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 7px;
            margin: auto;

            p {
              margin-bottom: 0px;
              white-space: nowrap;
            }
          }
        }

        .show-map-btn-inactive:hover {
          cursor: pointer;
          padding: 12px 12px;
          gap: 10px;
          // width: 150px;
          flex: 1;
          height: 48px;
          background: linear-gradient(167.07deg,
              rgba(238, 120, 48, 0.1) -15.49%,
              rgba(238, 118, 47, 0.1) 149.41%);
          border-radius: 12px;
          border: 1px solid #ee762f;

          .inner-info {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 7px;
            margin: auto;

            p {
              margin-bottom: 0px;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  .filter-row-container-responsive {
    padding-bottom: 12px;

    .filter-row-wrapper-responsive {
      justify-content: center;
      align-items: center;
      display: flex;
      gap: 16px;
      margin-left: auto;
      margin-right: auto;
      overflow-y: auto;



      .filter-first {
        max-width: 58%;

        // .filter-list {
        //   border: 2px solid green;
        .slider-container {
          .slick-slider {
            display: flex;

            .slick-slide {
              width: fit-content !important;
              // padding-left: 16px;
              // padding-right: 16px;
            }

            .prev-arrow-btn-container {
              display: flex !important;
              align-items: center;
              // width: 10px;
              // padding-left: 16px;
              margin-right: 16px;
              cursor: pointer;

              /* Auto layout */
              .prev-arrow-btn {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                width: 32px;
                height: 32px;

                /* White */
                background: #ffffff;
                /* SecondarySteel */
                border: 1px solid #8f939a;
                border-radius: 28px;
                transform: matrix(-1, 0, 0, 1, 0, 0);

                img {
                  width: 16px;
                  height: 16px;
                }
              }
            }

            .next-arrow-btn-container {
              display: flex !important;
              align-items: center;
              margin-left: 24px;
              // width: 10px;
              cursor: pointer;

              /* Auto layout */
              .next-arrow-btn {
                display: flex;
                justify-content: center;
                align-items: center;

                width: 32px;
                height: 32px;

                /* White */
                background: #ffffff;
                /* SecondarySteel */
                border: 1px solid #8f939a;
                border-radius: 28px;
                transform: matrix(-1, 0, 0, 1, 0, 0);

                img {
                  width: 16px;
                  height: 16px;
                }
              }
            }
          }
        }

        .filter-item {
          // width: fit-content;
          // max-width: 968px;
          // border: 5px solid violet;
          display: flex;
          padding-left: 15px;
          padding-right: 20px;
          cursor: pointer;

          img {
            margin: auto;
            margin-bottom: 5px;
            display: flex;
          }

          p {
            margin-bottom: 0px;
            text-align: center;
          }
        }

        // }
      }

      .filter-second-res {
        display: flex;
        align-items: center;
        gap: 5px !important;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #25272d;




        .filter-sort-btn-res {
          display: flex;
          padding: 8px 9px !important;
          height: 38px !important;
          border: 1px solid #EE7830;
          border-radius: 12px;
          flex-shrink: 1;
          cursor: pointer;


          .inner-info {
            display: flex;
            align-items: center;
            gap: 7px;
            margin: auto;

            p {
              margin-bottom: 0px;
              white-space: nowrap;
            }
          }
        }

        .sort-btn {
          position: relative;
          display: flex;
          padding: 8px 9px;
          height: 38px;
          border: 1px solid #25272d;

          // background: linear-gradient(167.07deg, rgba(238, 120, 48, 0.1) -15.49%, rgba(238, 118, 47, 0.1) 149.41%);

          border-radius: 12px;
          flex-shrink: 1;
          cursor: pointer;


          .inner-info-res {
            display: flex;
            align-items: center;
            gap: 7px;
            margin: auto;

            p {
              margin-bottom: 0px;
              white-space: nowrap;
            }
          }
        }

        .active-sort-btn {
          position: relative;
          display: flex;
          padding: 8px 9px;
          height: 38px;
          border: 1px solid #EE7830;
          background: linear-gradient(167.07deg,
          rgba(238, 120, 48, 0.1) -15.49%,
          rgba(238, 118, 47, 0.1) 149.41%);
          // background: linear-gradient(167.07deg, rgba(238, 120, 48, 0.1) -15.49%, rgba(238, 118, 47, 0.1) 149.41%);

          border-radius: 12px;
          flex-shrink: 1;
          cursor: pointer;


          .inner-info-res {
            display: flex;
            align-items: center;
            gap: 7px;
            margin: auto;

            p {
              margin-bottom: 0px;
              white-space: nowrap;
            }
          }
        }

        .filter-sort-btn:hover {
          display: flex;
          background: linear-gradient(167.07deg,
              rgba(238, 120, 48, 0.1) -15.49%,
              rgba(238, 118, 47, 0.1) 149.41%);
          border-radius: 12px;
          border: 1px solid #ee762f;
          height: 48px;
          border-radius: 12px;
          flex-shrink: 1;
          cursor: pointer;

          .inner-info {
            display: flex;
            align-items: center;
            gap: 7px;
            margin: auto;

            p {
              margin-bottom: 0px;
              white-space: nowrap;
            }
          }
        }

        .show-map-btn {
          cursor: pointer;
          padding: 12px 12px;
          gap: 10px;
          // width: 150px;
          flex: 1;
          height: 48px;
          background: linear-gradient(167.07deg,
              rgba(238, 120, 48, 0.1) -15.49%,
              rgba(238, 118, 47, 0.1) 149.41%);
          border-radius: 12px;
          border: 1px solid #ee762f;

          .inner-info {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 7px;
            margin: auto;

            p {
              margin-bottom: 0px;
              white-space: nowrap;
            }
          }
        }

        .show-map-btn-inactive {
          cursor: pointer;
          padding: 8px 12px;
          gap: 10px;
          // width: 150px;
          flex: 1;
          height: 38px;
          border-radius: 12px;
          border: 1px solid;

          .inner-info {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 7px;
            margin: auto;

            p {
              margin-bottom: 0px;
              white-space: nowrap;
            }
          }
        }

        .show-map-btn-inactive:hover {
          cursor: pointer;
          // padding: 12px 12px;
          // gap: 10px;
          // width: 150px;
          flex: 1;
          // height: 48px;
          height: 38px;
          background: linear-gradient(167.07deg,
              rgba(238, 120, 48, 0.1) -15.49%,
              rgba(238, 118, 47, 0.1) 149.41%);
          border-radius: 12px;
          border: 1px solid #ee762f;

          .inner-info {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 7px;
            margin: auto;

            p {
              margin-bottom: 0px;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  .total-filter-conatiner {
    // top: -1rem;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 16px;
    height: 16px;
    background: #EE762F;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-top: -18px;
    position: absolute;
    top:-4px;
    right: -7px;
  }

  .total-filter-number {
    font-size: 8px;
    font-weight: 600;
    line-height: 13px;
    text-align: center;
    color: #FFFFFF;
  }

  .filter-sort-btn {
    display: flex;
    padding: 12px 9px;
    height: 48px;
    border: 1px solid #EE7830;
    border-radius: 12px;
    flex-shrink: 1;
    cursor: pointer;
  }

  .filter-row-wrapper-responsive::-webkit-scrollbar {
    display: none;
  }

  .listing-container {
    display: flex;
    justify-content: center;

    .listing-inner-wrapper {
      width: 1290px;
      padding: 0px 16px;
      position: relative;

      .listings {
        display: grid !important;
        padding: 16px 0;
        grid-template-columns: repeat(auto-fill, minmax(295px, 1fr));
        gap: 14px;


        .people-item {
          height: 2rem;
          overflow-y: auto;
          overflow-x: hidden;
          /* Optional: Hides horizontal overflow if not needed */
        }

        .people-item::-webkit-scrollbar {
          display: none;
        }

        .listing_box {
          // border: 1px solid #f2f2f2;
          border-radius: 12px;

          position: relative;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
    

          .crousel-container {
            width: 100%;
            position: relative;

            .dots-nav-container,
            .image-carousel-button {
              // display: none !important;
            }

            .event_img {
              min-width: 320px;
              max-width: unset;
            }
          }

          .crousel-container:hover {

            .dots-nav-container,
            .image-carousel-button {
              display: flex !important;
            }
          }

          .videoicon {
            position: absolute;
            z-index: 2;
            left: 50%;
            transform: translateX(-50%);
            top: 65px;
          }

          .tag {
            position: absolute;
            left: -7px;
            top: 24px;
          }

          .event_img {
            height: 320px;
            width: 100%;
            border-radius: 12px 12px 0 0;
            object-fit: cover;
          }

          .span_box {
            position: relative;

            .distance {
              background: rgba(0, 0, 0, 0.30);
              -webkit-backdrop-filter: blur(43px);
              backdrop-filter: blur(43px);
              // backdrop-filter: blur(16px);
              padding: 4px 16px 4px 16px;
              border-radius: 8px;
              font-size: 12px;
              font-weight: 500;
              line-height: 24px;
              letter-spacing: 0em;
              text-align: right;
              color: #fff;
              position: absolute;
              top: 52px;
              left: 10px;
              z-index: 10;

              &.dist_only {
                top: 12px;
                left: 12px;
              }

              @media only screen and (max-width: 600px) {
                display: flex !important;
                align-items: center !important;
                height: 24px;
                padding: 0px 8px 0px 8px;
                gap: 4px;
                border-radius: 6px;
                opacity: 0px;
                top: 38px;
                left: 18px;

                &.dist_only {
                    top: 12px;
                    left: 12px;
                }
              }
            }

            .event_type {
              // background: #EE7830;
              padding: 4px 16px 4px 16px;
              border-radius: 8px;
              font-size: 12px;
              font-weight: 500;
              line-height: 24px;
              letter-spacing: 0em;
              text-align: right;
              color: #fff;
              position: absolute;
              top: 12px;
              left: 0px;
              z-index: 10;

              @media only screen and (max-width: 600px) {
                height: 26px;
                left: 12px;
                gap: 4px;
                opacity: 0px;

                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                text-align: left;
              }
            }

            .price {
              background: linear-gradient(167.07deg,
                  #ee7830 -15.49%,
                  #ee762f 149.41%);
              border-radius: 8px;
              padding: 4px 16px 4px 16px;
              font-size: 14px;
              font-weight: 700;
              line-height: 24px;
              letter-spacing: 0em;
              color: #fff;
              position: absolute;
              right: 12px;
              top: -50px;
            }

            .share {
              border-radius: 8px;
              padding: 4px 16px 4px 16px;
              font-size: 14px;
              font-weight: 700;
              line-height: 24px;
              letter-spacing: 0em;
              color: #fff;
              position: absolute;
              right: 0px;
              // top: 47px;
              top: 12px;
              z-index: 10;
            }

            .heart {
              border-radius: 8px;
              padding: 4px 16px 4px 16px;
              font-size: 14px;
              font-weight: 700;
              line-height: 24px;
              letter-spacing: 0em;
              color: #fff;
              position: absolute;
              right: 0px;
              // top: 12px;
              top: 47px;
              z-index: 10;
            }
          }

          .limited-event-info {
            .title {
              width: 100%;
              white-space: nowrap;
              overflow: hidden !important;
              text-overflow: ellipsis;

              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 20px;
              color: #25272d;
            }

            margin-top: 16px;
            padding-left: 0px;
            padding-right: 12px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;

            color: #25272d;

            .item {
              margin-bottom: 8px;
              width: 100%;
              white-space: nowrap;
              overflow: hidden !important;
              text-overflow: ellipsis;

              span {
                color: black;
              }

              .event-type-blue {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;

                color: #0099ff;
              }

              .event-type-orange {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;

                color: #ee762f;
              }
            }
          }

          .name {
            background: #DCDCDC;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            padding: 10px 6px;
            margin-top: 14px;
            border-radius: 24px;
            display: flex;
            align-items: center;
            gap: 2px;
            height: 32px;

            span {
              white-space: nowrap;
              width: 300px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: block;
            }

            img,
            video {
              width: 20px;
              height: 20px;
              object-fit: cover;
              margin-right: 5px;
              vertical-align: middle;
              border-radius: 100%;
              box-shadow: 0px 3.3333332538604736px 10px 0px #0000001f;
            }
          }

          .name-special {
            background-color: #ee7830;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px;

            img {
              width: unset;
              height: unset;
              object-fit: unset;
              margin-right: unset;
              vertical-align: unset;
              border-radius: unset;
              box-shadow: unset;
            }
          }
        }


      }

      .create-listing-btn {
        position: fixed;
        bottom: 90px;
        // width: 1258px;
        display: flex;
        justify-content: flex-end;
        right: 30px;
      }

      @media screen and (max-width: 768px) {
        .create-listing-btn {
          width: 90%;
          bottom: 10px;
        }
      }
    }
  }

  .icon-date-container {}

  .special-box {
    position: absolute;
    z-index: 9;
    width: 150px;
    height: 30px;
    background-color: #ee7830bf;
    bottom: 20px;
    left: 10px;
    border-radius: 12px;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

  .exc-text {
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    // line-height: 20px;
    color: #fff;
  }

  .heading-and-map-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

    .heading {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;

      color: #25272d;
    }

    .headingclick {

      display: none;
    }

    .listing-map-container {
      display: flex;
      align-items: center;
      gap: 14px;

      img {
        cursor: pointer;
      }
    }
  }

  .heading-and-map-container-res {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 28px;
  }

  .res-search-and-sorting-container {
    width: 105px;
    max-width: 90%;
    position: absolute;
    // margin: 16px 0;
    // padding: 0 16px;
    display: flex;
    justify-content: space-between;
    gap: 16px;
    right: 15px;
    height: 36px;
    transition: width 0.3s ease; // Transition effect for smooth resizing

    .search-input {
      display: flex;
      gap: 10px;
      align-items: center;
      padding: 8px 14px 8px 14px;
      border: 1px solid #8f939a;
      border-radius: 24px;
      flex-grow: 1;
      transition: width 0.3s ease; // Transition effect for smooth resizing

      img {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }

      input {
        border: none;
        outline: none;
        white-space: nowrap;
        margin-bottom: 0;
        text-overflow: ellipsis;
        width: 81%;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #25272D;
      }
    }

    .filter-sort-btn-first {
      width: 79px;
      height: 38px;
      // top: -2px;
      // left: 74px;
      // padding: 8px 18px 6px 20px;
      // gap: 7px;
      // border-radius: 12px 0px 0px 0px;
      // opacity: 0px;

      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 38px;
      background: linear-gradient(167.07deg, #ee7830 -15.49%, #ee762f 149.41%);
      border-radius: 12px;
      flex-shrink: 0;
      cursor: pointer;

      img {
        width: 24px;
        height: 24px;
      }
    }

    &.expanded {
      width: 100%;
    }
  }


  .res-listing-container {
    display: flex;
    overflow-x: auto;
    
    .res-selected-type {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      flex-grow: 1;
      cursor: pointer;

      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;

      color: #4e5158;
      border-bottom: 1px solid #f3f3f3;
      padding-bottom: 16px;
      min-width: 120px;

      img {
        width: 16px;
        height: 16px;
      }
    }

    .res-selected-type.active {
      color: #ee762f;
      border-bottom: 2px solid #ee762f;
    }
  }

  .res-listing-container::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .res-filter-list-container {
    height: 55px;
    // margin-top: 3rem !important;
    display: flex;
    margin: 16px 16px;
    overflow-x: scroll;
    overflow-y: hidden;

    gap: 8px;
    white-space: nowrap !important;

    scrollbar-width: none;

    .filter-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 6px;
      cursor: pointer;

      padding: 7px 12px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;

      color: #25272d;

      // border: 1px solid #f3f3f3;
      // border-radius: 16px;

      @media (max-width: 750px) {
        height: 30px;
        padding: 7px;
        gap: 6px;
        border-radius: 16px;
        border: 1px;
        // opacity: 0px;
        // background: #ffffff;
        // border: 1px solid #dcdcdc;
      }

      p {
        margin-bottom: 0px;
      }
    }
  }

  @media (max-width: 750px) {
    .filter-item-img {
      width: 24px;
      height: 24px !important;
    }

    .filter-text-active {
      font-size: 10px;
      font-weight: 500;
      line-height: 15px;
      text-align: left;
      color: #25272D;
    }

    .filter-text {
      font-size: 13px;
      font-weight: 500;
      line-height: 15px;
      text-align: left;
      color: #6C6D6F;
    }
  }

  .active-class {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 12px;
    gap: 7px;
    border-radius: 4px;
    opacity: 0px;
    background: #25272d;
    border: 1px solid #25272d;
  }

  .active-class-text {
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
  }

  .normal-class {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 12px;
    gap: 7px;
    border-radius: 4px;
    border: 1px;
    opacity: 0px;
    border: 1px solid #25272d;
  }

  .normal-class-text {
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: #25272d;
  }

  .resposnive-tab {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
  }

  .resposnive-tab::-webkit-scrollbar {
    display: none;
  }

  // .

  @media (max-width: 750px) {
    .resposnive-tab {
      padding: 0 17px;
    }
  }

  .pages-container {
    display: flex;
    justify-content: center;

    .pages-inner-container {
      max-width: 1258px;
      display: flex;
      justify-content: center;

      .pagination-box {
        .pagination-custom {
          display: flex;
          align-items: center;
          list-style: none;
          padding-left: 0px;

          .page-item {
            border: none;
            // padding: 0px 16px;
            min-width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .page-number {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            color: #8f939a;
          }

          .active-page {
            background-color: black !important;
            color: white !important;
            width: 30px;
            height: 30px;

            background: linear-gradient(167.07deg,
                #ee7830 -15.49%,
                #ee762f 149.41%);
            border-radius: 5px;
          }
        }
      }
    }
  }

  .events-map-container {
    .listing_box {
      // border: 1px solid #f2f2f2;
      border-radius: 12px;
      background-color: white;
      max-width: 295px;
      position: relative;

      .crousel-container {
        width: 100%;
        position: relative;

        .event_img {
          min-width: 320px;
          max-width: unset;
        }
      }

      .videoicon {
        position: absolute;
        z-index: 2;
        left: 50%;
        transform: translateX(-50%);
        top: 65px;
      }

      .tag {
        position: absolute;
        left: -7px;
        top: 24px;
      }

      .event_img {
        height: 320px;
        width: 100%;
        border-radius: 12px 12px 0 0;
        object-fit: cover;
      }

      .span_box {
        position: relative;

        .distance {
          background: rgba(0, 0, 0, 0.30);
          -webkit-backdrop-filter: blur(43px);
          backdrop-filter: blur(43px);
          // backdrop-filter: blur(16px);
          padding: 4px 16px 4px 16px;
          border-radius: 8px;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: right;
          color: #fff;
          position: absolute;
          top: 20px;
          left: 12px;
        }

        .event_type {
          // background: #EE7830;
          padding: 4px 16px 4px 16px;
          border-radius: 8px;
          font-size: 12px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: right;
          color: #fff;
          position: absolute;
          top: 12px;
          left: 0px;
          z-index: 10;
        }

        .price {
          background: linear-gradient(167.07deg,
              #ee7830 -15.49%,
              #ee762f 149.41%);
          border-radius: 8px;
          padding: 4px 16px 4px 16px;
          font-size: 14px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0em;
          color: #fff;
          position: absolute;
          right: 12px;
          top: -50px;
        }

        .share {
          border-radius: 8px;
          padding: 4px 16px 4px 16px;
          font-size: 14px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0em;
          color: #fff;
          position: absolute;
          right: 0px;
          // top: 47px;
          top: 12px;
          z-index: 10;
        }

        .heart {
          border-radius: 8px;
          padding: 4px 16px 4px 16px;
          font-size: 14px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0em;
          color: #fff;
          position: absolute;
          right: 0px;
          // top: 12px;
          top: 47px;
          z-index: 10;
        }
      }

      .limited-event-info {
        .title {
          width: 100%;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;

          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #25272d;
        }

        margin-top: 16px;
        padding-left: 0px;
        padding-right: 12px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;

        color: #8f939a;

        .item {
          margin-bottom: 8px;
          width: 100%;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;
          display: flex;

          span {
            color: black;
          }
        }
      }

      .name {
        background: #DCDCDC;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        padding: 10px 6px;
        margin-top: 14px;
        border-radius: 0 0 12px 12px;
        display: flex;
        align-items: center;
        gap: 2px;
        height: 32px;

        span {
          white-space: nowrap;
          width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }

        img,
        video {
          width: 20px;
          height: 20px;
          object-fit: cover;
          margin-right: 5px;
          vertical-align: middle;
          border-radius: 100%;
          box-shadow: 0px 3.3333332538604736px 10px 0px #0000001f;
        }
      }

      .name-special {
        background-color: #ee7830;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: unset;
          height: unset;
          object-fit: unset;
          margin-right: unset;
          vertical-align: unset;
          border-radius: unset;
          box-shadow: unset;
        }
      }
    }
  }
}



@media screen and (min-width: 235px) and (max-width: 700px) {
  .back-btn {
    display: none;
  }
}

// .events-page .automodal {
//   background: #25272D;
//   opacity: 0.8;
// }

.res-listing-container {
  position: relative;
}

.selected-listing-type-wrapper {
  position: relative;
}

.listing-type-right {
  display: flex;
  align-items: center;
  position: relative;
  /* Added this to make it the reference point */
}

.selected-listing-type-wrapper {
  position: relative;
}

.listing-type-right {
  display: flex;
  align-items: center;
  position: relative;
  /* Added this to make it the reference point */
}

.tabcontent {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}

// .eventsstyle {
//   background: transparent;
//   font-size: 16px;
//   font-weight: 600;
//   line-height: 24px;
//   color: #EE7830;
//   border-style: none;
// }

.eventsstyle,
.Peopleeventstyle {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  padding: 10px;
  color: #25272d;
  font-weight: 600;
  /* Default color for inactive tabs */
}

.clearstyle {
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #52545b;
  cursor: pointer;
}

.eventsstyle.active,
.Peopleeventstyle.active {
  // border: 2px solid;
  color: #ee772f;
  /* Color for active tab */
}

// .Peopleeventstyle {
//   background: transparent;
//   font-size: 16px;
//   font-weight: 600;
//   line-height: 24px;
//   color: #EE7830;

// }

.eventsstyle,
.Peopleeventstyle {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  padding: 10px;
}

.eventbottom,
.peoplebottom {
  // width: 150px;
  border: none;
  border-top: 2px solid transparent;
  margin: 0;
  opacity: unset !important;
}

.eventbottom.active {
  // width: 215px;
  height: 0px;
  // Left217px
  // Border2px
  // Rotation-180°
  color: #ee762f;
  border-bottom: 2px solid #ee762f;
  opacity: unset !important;
}

.peoplebottom.active {
  // width: 215px;
  height: 0px;
  gap: 0px;
  color: #ee762f;
  border-bottom: 2px solid #ee762f;
  opacity: unset !important;
}

.main-divider {
  margin-top: -1px;
}

.automodel {
  z-index: 999;
  position: absolute;
  width: 465px;
  max-height: 550px;
  // overflow-y: auto;
  border-radius: 0;
  background: #ffffff;
  border: 1px solid #8f939a;
  // top: calc(100% + 0.1rem);
  border-top: none;
  left: 1px;
  margin-top: -0.9rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.conatinerview-scroll {
  overflow-y: auto;
  max-height: 465px;
  height: 100%;
  overflow-x: hidden;
}

.conatinerview-scroll-res {
  overflow-y: auto;
  max-height: 465px;
  height: 100%;
  overflow-x: hidden;
}

.secondautomodel {
  z-index: 999;
  position: absolute;
  width: 100%;
  max-height: 550px;
  overflow-y: auto;
  border-radius: 16px;
  background: #ffffff;
  border: 1px solid #8f939a;
  left: 1px;
  margin-top: 1.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.main-section-search {
  display: flex;
  gap: 24px;
  align-items: stretch;
  font-style: normal;
}

.card-title-section {
  height: 3rem;
  /* Fixed height */
  overflow-y: auto;
  /* Enables vertical scrolling if content overflows */
}

.card-title-section::-webkit-scrollbar {
  display: none;
}

.secondautomodel::-webkit-scrollbar {
  display: none;
}

// .modal-content {
//   overflow-y: auto;
// }

.listing-type-right-content {
  position: relative;
}

.Noresultfound-section {
  padding: 10px;
}

.Noresultfound {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ee762f;
}

@media (max-width: 960px) {
  .events-page .selected-listing-type-container .selected-listing-type-wrapper .listing-type-right input {
    width: 100%;
  }

  @media (max-width: 700px) {
    .automodel {
      margin-top: 0.2rem;
      z-index: 100;
      position: absolute;
      width: calc(100% - 2rem);

      height: fit-content;
      // overflow-y: auto;
      border-radius: 24px;
      background: #ffffff;
      border: unset;
      top: calc(100% + 0rem);
      left: 1rem;

      right: 1rem;

      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
  }

  .innterdata {
    display: flex !important;
    flex-direction: column !important;
    gap: unset !important;
  }

  .main-section {
    display: flex;
  }
}

.eventimg {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 80px !important;
  max-height: 80px;
  height: 100% !important;
  // height: fit-content;
}

.eventimg-responsive {
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;
  width: 80px !important;
  height: 80px !important;
  /* Example border radius for circular images */
}

.peopleimg {
  width: 60px !important;
  height: 60px !important;
  padding: 5px;
  gap: 0px;
  opacity: 1;
}

.eventname {
  width: 80%;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.eventnameres {
  width: 53%;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.conatinerviewres {
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 12px;
  border: 1px solid #dcdcdc;
}

.eventnamepeople {
  //  width: 80%;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #000000;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
}


.createdat,
.eventtype {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #000000;
}

.main-section-new {
  display: flex;
  align-items: center;
  /* Center items vertically */
  // gap: 10px;
  /* Adjust as needed */
}

.conatinerview {
  // height: 80px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 12px;
  border: 1px solid #dcdcdc;
  /* Adjust spacing between items */
}

.innterdata {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tabs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

// ul.suggestions-list {
//   position: absolute;
//   top: 100%;
//   /* Position suggestions below the input */
//   left: 0;
//   width: 100%;
//   max-height: 200px;
//   /* Adjust max-height as needed */
//   overflow-y: auto;
//   margin: 0;
//   padding: 0;
//   list-style-type: none;
//   background: #ffffff;
//   border: 1px solid #52545b;
//   border-top: none;
//   /* Remove top border to match your design */
//   border-radius: 0 0 24px 24px;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
// }

// ul.suggestions-list li {
//   padding: 10px;
//   cursor: pointer;
// }

// ul.suggestions-list li.selected {
//   background-color: #f0f0f0;
//   /* Example of selected suggestion styling */
// }

.multi-range-slider .thumb {
  z-index: 0 !important;
}

.filter-popup-container {
  min-width: 800px !important;

  @media screen and (max-width: "768px") {
    min-width: auto !important;
    margin: 0px !important;
    // margin-top: 20% !important;

  }

  .modal-content {
    // min-height: 100vh !important;
    height: 100%;
    max-height: calc(100dvh - 60px);
    width: 684px;
    overflow-y: auto;
    // display: flex;
    // flex-direction: column;
    // align-items: center;



    @media screen and (max-width: "768px") {
      min-height: 100vh !important;
      max-height: auto;
      width: 100% !important;

      display: flex;
      flex-direction: column;
      align-items: normal !important;
    }

    .modal-body {
      // border: 1px solid green;
      padding: 51px;
      position: relative;

      @media screen and (max-width: "768px") {
        padding: 0;
        margin-bottom: 102px;
        box-sizing: border-box;
      }

      .cross-btn {
        position: absolute;
        right: 51px;
        cursor: pointer;

        @media screen and (max-width: "768px") {
          right: 15px;
          margin-top: 10px;
        }

        img {
          @media screen and (max-width: "768px") {
            margin-top: 5px;
            width: 30px;
            height: 30px;
          }
        }
      }

      .heading {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        color: #25272d;



        @media screen and (max-width: "768px") {
          margin-top: 10px;
          font-size: 20px;
        }
      }

      .clickheading {
        display: none;
        padding: 16px;
        // font-style: normal;
        // font-weight: 600;
        // font-size: 24px;
        // line-height: 36px;
        // text-align: center;
        // color: #25272d;
      }

      .options-container {
        // border: 1px solid blue;
        display: flex;
        justify-content: center;
        margin-top: 28px;

        @media screen and (max-width: 800px) {
          display: flex;
          // justify-content:;
          margin-top: 20px;
        }

        .options-wrapper {
          display: flex;

          @media screen and (min-width: 235px) and (max-width:600px) {
            // width: 170px;
            width: 100%;
            box-sizing: border-box;
          }

          .selected-option {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            padding: 8px 8px;
            color: #25272d;
            width: 149px;
            text-align: center;
            border-bottom: 1px solid #dcdcdc;
            cursor: pointer;

            @media screen and (min-width: 235px) and (max-width:600px) {
              // width: 170px;
              width: 50%;
              box-sizing: border-box;
            }

          }

          .active {
            color: #ee762f;
            border-bottom: 2px solid;

            // border-image-source: linear-gradient(167.07deg,
            //     #ee7830 -15.49%,
            //     #ee762f 149.41%);
          }
        }
      }

      .sort-container {
        max-width: 298px;
        margin: 40px auto 10px auto;

        input[type="radio"] {
          appearance: none;
          width: 18px;
          height: 18px;
          border: 2px solid #555555;
          border-radius: 50%;
          background-clip: content-box;
          padding: 2px;
        }

        input[type="radio"]:checked {
          background-color: #ee762f;
          border: 2px solid #ee762f;
          transition: 0.2s all linear;
        }

        .sort-option {
          display: flex;
          align-items: center;
          gap: 20px;
          margin-bottom: 20px;

          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;


          color: #25272D;

        }
      }

      .filter-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 40px;

        @media screen and (max-width: 800px) {
          padding: 18px;
          margin-top: 10px;
        }

        .f-location {
          border-bottom: 1px solid #dcdcdc;
          width: 100%;

          .location-input-container {
            display: flex;
            align-items: start;
            gap: 12px;
            max-width: 539px;
            position: relative;

            // border: 1px solid red;
            .input_label_title {
              position: absolute;
              font-weight: 400;
              font-size: 10px;
              line-height: 14px;
              left: 12px;
              color: #25272d;
              top: 8px;
              left: 15px;
              z-index: 2;
            }

            .location-input {
              flex-grow: 1;
            }

            .google_location_input {
              min-width: 467px;
            }

            .cross-icon {
              position: absolute;
              right: 90px;
              top: 18px;
            }

            .location-icon-container {
              padding: 14px;
              display: flex;
              align-items: center;

              border: 1px solid #dcdcdc;
              border-radius: 14.4px;
            }
          }
        }

        .f-title {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #25272d;
          margin-bottom: 6px;
        }

        .f-sub-title {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: #72767b;
          margin-bottom: 10px;
        }

        .f-interest {
          border-bottom: 1px solid #dcdcdc;

          .interest-input-container {
            max-width: 539px;
            position: relative;

            .input_box img.cross-icon {
              right: 70px;
            }

            .input_box img.sort-icon {
              position: absolute;
              top: 3px;
              right: 3px;
              z-index: 5;
              cursor: pointer;
            }
          }

          .wth-dorpwodn {
            .data-setup {
              div {

                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;
                gap: 10px;

                span {
                  label {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    border: 1px solid #e0e0e0;
                    border-radius: 8px;
                    margin: 0px;
                    padding: 0px;



                    img {
                      border-radius: 8px 8px 0px 0px;
                      object-fit: cover;
                    }

                    h4 {
                      border-top: 1px solid #e0e0e0;
                      border-radius: 0px 0px 8px 8px;
                      padding: 6px 10px;
                      font-style: normal;
                      font-weight: 500;
                      font-size: 16px;
                      line-height: 24px;
                      color: #25272d;
                      display: flex;
                      justify-content: space-between;
                      margin-bottom: 0px;
                    }
                  }

                  .active {
                    border: 1px solid #ee762f;

                    h4 {
                      background: #fff4ee;
                      color: #ee762f;
                      border-top: 1px solid #ee762f;
                    }
                  }
                }
              }

              .no_data {
                color: #8f939a;
                text-align: center;
                display: block;

                img {
                  width: 32px;
                }

                h3 {
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 24px;
                }

                p {
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 20px;
                }
              }
            }
          }
        }

        .f-event-type {
          border-bottom: 1px solid #dcdcdc;
          padding-bottom: 24px;

          .event-options-container {
            display: flex;
            gap: 16px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #25272d;

            .select-option {
              white-space: nowrap;
              display: flex;
              align-items: center;
              gap: 10px;
            }


          }



          input[type="radio"] {
            appearance: none;
            width: 18px;
            height: 18px;
            border: 2px solid #555555;
            border-radius: 50%;
            background-clip: content-box;
            padding: 2px;
          }

          input[type="radio"]:checked {
            background-color: #ee762f;
            border: 2px solid #ee762f;
            transition: 0.2s all linear;
          }
        }

        .f-price-range {
          border-bottom: 1px solid #dcdcdc;
          padding-bottom: 24px;

          .minmax-price-container {
            display: flex;
            gap: 12px;


            @media screen and (max-width: 374px) {
              display: flex;
              justify-content: space-between;
            }


            @media screen and (min-width: 375px) and (max-width: 449px) {
              display: flex;
              justify-content: space-between;
            }

            /* Medium devices */
            @media screen and (min-width: 450px) and (max-width: 739px) {
              display: flex;
              justify-content: space-between;
            }


            // .max-price {
            //   @media screen and (max-width: "768px") {
            //     width: 50% !important;
            //   }
            // }

            .price-box {
              // max-width: 125px;
              padding: 12px;
              border-bottom: 1.5px solid #d0d0d0;


              input {
                border: none;
                outline: none;
              }

              label {
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 14px;

                color: #25272d;
              }
            }
          }
        }

        .f-date {
          border-bottom: 1px solid #dcdcdc;
          padding-bottom: 24px;

          .date-container {
            display: flex;
            gap: 16px;
          }


          .f-date-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 5px;
            border: 1px solid #DCDCDC;
            border-radius: 10px;
            box-sizing: border-box;
            width: 100%; // Adjust as needed






            .react-datepicker__tab-loop {
              .react-datepicker-popper {

                // top: 20px !important;
                .react-datepicker {
                  border: 1px solid #dcdcdc;
                }
              }
            }

            label {
              font-style: normal;
              font-weight: 400;
              font-size: 10px;
              line-height: 14px;

              color: #25272d;
            }

            input {
              border: none;
              outline: none;
            }
          }
        }

        /* Mobile devices */
        @media screen and (max-width: 374px) {
          .inputconatiner {
            max-width: 112px;
          }
        }

        /* Small devices */
        @media screen and (min-width: 375px) and (max-width: 449px) {
          .inputconatiner {
            max-width: 120px;
          }
        }

        /* Medium devices */
        @media screen and (min-width: 450px) and (max-width: 739px) {
          .inputconatiner {
            max-width: 120px;
          }
        }





        .arrow-icon {
          width: 24px;
          height: 24px;
          padding-right: 5px;
          display: block;
        }

        .f-gender {
          .gender-options-container {
            display: flex;
            gap: 16px;

            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #25272d;

            .select-option {
              display: flex;
              align-items: center;
              gap: 10px;

              @media screen and (max-width: 768px) {
                gap: 7px;
              }
            }
          }

          input[type="radio"] {
            appearance: none;
            width: 18px;
            height: 18px;
            border: 2px solid #555555;
            border-radius: 50%;
            background-clip: content-box;
            padding: 2px;
          }

          input[type="radio"]:checked {
            background-color: #ee762f;
            border: 2px solid #ee762f;
            transition: 0.2s all linear;
          }
        }

        .input_box_interest {
          height: 60px;
          border-radius: 10px;
          border: 1px solid #DCDCDC;
          color: #EE7830;
          background: #FFFFFF;
          padding: 0 12px;
          box-sizing: border-box;
        }

        .input_box_interest_text {

          border: none;

          outline: none;

          width: 100%;


          color: #25272d;


          font-size: 16px;

          font-weight: 500;

          line-height: 24px;

          text-align: left;

          // padding: 0 12px;

          box-sizing: border-box;
          // padding-top: 3.5%;

        }

        .input_box_interest_text::placeholder {
          color: #EE7830;


          font-size: 16px;

          font-weight: 500;

          line-height: 24px;

          text-align: left;

        }




        .f-age {
          border-bottom: 1px solid #dcdcdc;
          padding-bottom: 24px;

          .age-options-container {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;

            color: #25272d;

            .select-option {
              display: flex;
              align-items: center;
              gap: 10px;
              margin-bottom: 10px;
            }
          }

          .minmax-age-container {
            display: flex;
            gap: 12px;

            .age-box {
              padding: 12px;
              border-bottom: 1.5px solid #d0d0d0;

              input {
                border: none;
                outline: none;
              }

              label {
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 14px;

                color: #25272d;
              }
            }
          }

          input[type="radio"] {
            appearance: none;
            width: 18px;
            height: 18px;
            border: 2px solid #555555;
            border-radius: 50%;
            display: block;
            padding: 2px;
            background-clip: content-box;
          }

          input[type="radio"]:checked {
            background-color: #ee762f;
            border: 2px solid #ee762f;
            transition: 0.2s all linear;
          }
        }
      }

      .select_payout_headings {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: #EE7830;

        @media (max-width: 768px) {
          font-size: 15px;
        }

      }

      .f-price-container {
        // border: 5px solid green;
        max-width: 100%;

        .multi-range-slider {
          padding: 15px 0px;

          .bar {
            .bar-left {
              box-shadow: none;
              padding: 1.5px 0px;
            }

            .thumb::before {
              box-shadow: #dcdcdc 0px 1px 9px 2px;
              border: none;
            }

            .thumb {
              .caption {
                display: none;
              }
            }

            .bar-inner {
              box-shadow: none;
              border: none;
              padding: 1.5px 0px;
            }

            .bar-right {
              box-shadow: none;
              padding: 1.5px 0px;
            }
          }
        }

        .range-value-container {
          display: flex;
          justify-content: space-between;

          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;

          color: #25272d;
        }
      }

      .apply-btn-container {
        // display: flex;
        justify-content: center;
        margin-top: 51px;
        position: sticky;
        bottom: 0px;
        text-align: center;
        background: rgba(255, 255, 255, 0.8);
        padding-bottom: 15px;
        padding-top: 15px;
        z-index: 1;

        @media (max-width: 768px) {
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0;
          text-align: center;
          background: rgba(255, 255, 255, 0.8);
          padding: 15px 15px;
          width: 100%;
          z-index: 1000;
        }

        .apply-btn {
          max-width: 350px;
          height: 56px;
        }

        .clear-filter {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 20px;
          color: #25272d;
          margin-top: 5%;
        }
      }
    }
  }
}

@media screen and (max-width:768px) {
  .header-container {
    .new-header-container {
      .new-header {
        padding: 6px 0px;
      }
    }
  }

  .join-as-a-member {
    font-size: 12px !important;
  }
}

.input_box_new .cross-icon {
  top: 35% !important;
  right: 42px !important;
}

// .input_box input{
//   padding-right: 72px !important;
// }

.image-carousel-button:hover {
  background: linear-gradient(167.07deg,
      #ee7830 -15.49%,
      #ee762f 149.41%) !important;
  height: 26px !important;
  width: 26px !important;
}

.Tab-Section-Custom-CSS {
  width: 30%;
}

@media screen and (max-width: "768px") {
  .Tab-Section-Custom-CSS {
    width: 100%;
  }
}

.orange-color {
  background: #ee7830;
  color: white;
  border: 0px !important;
  border-radius: 12px !important;
  z-index: 10;
  font-weight: 600;
}

.orange-color:hover {
  background: #ee7830 !important;
  color: white;
  border: 0px !important;
  border-radius: 12px !important;
  z-index: 10;
  font-weight: 600;
}

.blue-color {
  background: #0099ff;
  color: white;
  border: 0px !important;
  border-radius: 12px !important;
  z-index: 10;
  font-weight: 600;
}

.blue-color:hover {
  background: #0099ff !important;
  color: white;
  border: 0px !important;
  border-radius: 12px !important;
  z-index: 10;
  font-weight: 600;
}

.normal-color {
  background: #f3f3f3;
  border: 0px !important;
  margin: auto;
  height: 44px !important;
  padding: 10px 9px !important;

  margin-right: -10px;
}

.normal-color-v {
  margin: auto;
  height: 44px !important;
  margin-left: -14px;
  padding: 10px 9px !important;
}

.normal-color-v-res {
  margin: auto;
  height: 38px !important;
  margin-left: -10px;
  padding: 10px 9px !important;
}

.carousal-item-custom div:nth-child(2) {
  border-radius: 12px !important;
}

.individual-image-video-block {
  display: flex;
  flex-flow: wrap;
  box-sizing: border-box;
  justify-content: center;
  width: 100%;
}

.individual-image-video-block-2 {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-flow: wrap;
  padding: 0px;
  overflow: hidden;
  max-width: 320px;
  border-radius: 12px !important;
}

.map-radius-button {
  position: absolute;
  z-index: 10;
  right: 10px;
  top: 10px;
}

.radius-selector {
  // display: flex;
  // align-items: center;
  border-radius: 12px;
  background: #fff;
  margin-top: 0 !important;
  margin-right: 0 !important;
  // padding-right: 8px;

  span {
    border-radius: 12px 0px 0px 12px;
    background: #ee762f;
    width: 44px;
    height: 49px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }

  .radiusSelect {
    border: none;
    outline: none;
    color: var(--SteelBlue, #25272d);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    // padding-left: 4px;
  }
}

@media screen and (max-width: "600px") {
  .listings {
    max-width: 350px;
    margin: auto;
  }

  .event_type {
    padding: 4px 13px 3px 6px !important;
  }

  .footer-second-part {
    font-size: 12px !important;
  }

  .individual-image-video-block-2 {
    max-width: 350px !important;
  }

  .event_img {
    max-width: 350px !important;
    width: 350px !important;
  }
}

.minmax-age-container .min-age input {
  max-width: 125px;
}

.minmax-age-container .max-age input {
  max-width: 125px;
}

@media screen and (max-width:600px) {
  .minmax-age-container .min-age {
    max-width: 125px;
  }

  .minmax-age-container .max-age {
    max-width: 125px;
  }

  .apply-btn-container {
    background: none !important;
  }

  .flex-mobile-set-search {
    flex-basis: 80%;
  }

  .flex-mobile-set-search-2 {
    flex-basis: 20%;
  }

  .footer_button_filter_unique {
    padding: 20px 20px !important;
    right: 0px;
    position: fixed !important;
  }
}